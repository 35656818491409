import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  specialFieldsValuesSelector,
  messagesSelector,
  showManagerEmailFieldSelector
} from '../../helpers/selectors';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import { USER_FORM_NAMES } from '../../constants/form';
import _isEmpty from 'lodash/isEmpty';
import TextField from './fields/text';
import { getMessage as t, trimStrings } from '../../utils/utils';
import { checkEmail } from '../../helpers';
import { injectIntl } from 'react-intl';

const validate = (values, props) => {
  const errors = {};
  const { m } = props;

  const { showManagerEmail } = props;
  const { managerEmail } = trimStrings(values);

  if (showManagerEmail) {
    const emailError = checkEmail(managerEmail, m);
    if (emailError) errors.managerEmail = emailError;
  }

  return errors;
};

class SpecialFieldsForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  componentDidMount() {
    this.passFormSubmit();
  }

  bindFunctions() {
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  passFormSubmit() {
    const { passFormSubmit } = this.props;
    if (passFormSubmit) passFormSubmit(this.validateAndSubmit);
  }

  validateAndSubmit(action) {
    const { handleSubmit, validate, fieldValues, change, dispatch } = this.props;
    const errors = validate(fieldValues, this.props);

    if (_isEmpty(errors)) {
      dispatch(handleSubmit(action));
    } else {
      change('_validate', new Date());
      dispatch(handleSubmit(() => ''));
    }
  }

  getLabelManagerEmail() {
    return t(this.props.m, 'label.manager.email');
  }

  render() {
    const { handleSubmit, showManagerEmail } = this.props;
    const labelManagerEmail = this.getLabelManagerEmail();

    return (
      <form
        className={classnames('special-fields-form', 'scope-section')}
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="scope-section-title pb-xs-0 pt-dt-0" />
        <div className="scope-section-fields">
          {showManagerEmail && (
            <TextField
              name="managerEmail"
              label={labelManagerEmail}
              placeholder={labelManagerEmail}
              mandatory
            />
          )}
        </div>
      </form>
    );
  }
}

SpecialFieldsForm.propTypes = {
  passFormSubmit: PropTypes.func
};

export const SPECIAL_FIELDS_FIELDS_NAMES = ['managerEmail'];

const reduxComponent = reduxForm({
  form: USER_FORM_NAMES.SPECIAL_FIELDS,
  validate,
  onSubmitFail: scrollToErrorInfo,
  fields: SPECIAL_FIELDS_FIELDS_NAMES,
  enableReinitialize: true,
  destroyOnUnmount: false
})(SpecialFieldsForm);

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    fieldValues: specialFieldsValuesSelector(state),
    showManagerEmail: showManagerEmailFieldSelector(state)
  };
}

const connectComponent = connect(mapStateToProps)(reduxComponent);

export default injectIntl(connectComponent);
