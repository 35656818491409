export const ONE_WAY = 'ONEWAY';
export const RETURN_TRIP = 'RETURNTRIP';

export const STORAGE_KEY = {
  LOCALE: 'LOCALE',
  USER: 'USER',
  CONFIG: 'CONFIG',
  IN_SSO: 'IN_SSO',
  RAW_CONTRACT: 'RAW_CONTRACT',
  SEARCH_DATA: 'SEARCH_DATA',
  RECAP_DATA: 'RECAP_DATA',
  VEHICLE_ID: 'VEHICLE_ID',
  ORDER_CODE: 'ORDER_CODE',
  SSO_TOKEN: 'SSO_TOKEN',
  SSO_REFRESH_TOKEN: 'SSO_REFRESH_TOKEN',
  AUTH_TOKEN: 'AUTH_TOKEN',
  AUTH_REFRESH_TOKEN: 'AUTH_REFRESH_TOKEN',
  LOGIN_PENDING: 'LOGIN_PENDING',
  KEYCLOAK_CONFIG: 'KEYCLOAK_CONFIG',
  IMPERSONATE: 'IMPERSONATE',
  COMPANY_CONFIG: 'COMPANY_CONFIG',
  SEARCH_HISTORY: 'SEARCH_HISTORY',
  BOOKING_WORLDPAY: 'BOOKING_WORLDPAY'
};

export const STORAGE_ENUM = {
  BAD_ORDER_CODE: 'BOC',
  TOKEN_TYPE: {
    NORMAL: 'NOR',
    SSO: 'SSO'
  }
};

export const NUMBER_FORMAT = {
  E164: 0,
  INTERNATIONAL: 1,
  NATIONAL: 2,
  RFC3966: 3
};

export const FLASH_MESSAGE_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info',
  BUG: 'bug',
  DISABLED: 'disabled'
};

export const LEGACY_SUBSCRIBE = 'subscribe';

export const PRE_BOOKING_TYPE = {
  TEST_DRIVE: 'TD',
  REPLACEMENT_VEHICLE: 'RV',
  PUBLIC_SERVICE: 'PS'
};

export const TEST_DRIVE_STRING = 'Test drive';

export const ADD_TO_CALENDAR = {
  DATE_FORMAT: 'YYYY-MM-DD',
  TIME_FORMAT: 'HH:mm',
  OPTIONS: [
    'Apple',
    'Google',
    'iCal',
    'Microsoft365',
    'MicrosoftTeams',
    'Outlook.com',
    'Yahoo'
  ]
};
