import React, { Component } from 'react';
import classnames from 'classnames';
import Credentials from './account/Credentials';
import { messagesSelector, userTemporaryPasswordSelector } from '../helpers/selectors';
import { connect } from 'react-redux';
import { getMessage as t } from '../utils/utils';
import { addSuccessMsg } from '../utils/flashMessage/creator';
import { browserHistory } from '../helpers/components';
import { bindActionCreators } from 'redux';
import {
  clearTemporaryPassword,
  forceUpdateUserPassword,
  updateMemberInKeycloakStatus
} from '../actions/user';

class ForcePasswordUpdate extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.submitUpdate = this.submitUpdate.bind(this);
  }

  handleSuccess(data) {
    if (data.type === 'user/FORCE_UPDATE_PASSWORD_SUCCESS') {
      addSuccessMsg(t(this.props.m, 'title.password.change.success'));
      this.props.updateMemberInKeycloakStatus(true);
      browserHistory.push('/');
    }
  }

  submitUpdate(formValues) {
    this.props
      .forceUpdateUserPassword({
        newPassword: formValues.newPassword,
        oldPassword: formValues.currentPassword
      })
      .then((data) => {
        this.props.clearTemporaryPassword();
        this.handleSuccess(data);
      });
  }

  render() {
    const { m } = this.props;

    return (
      <div className={classnames('force-password-update-page', 'profile-page')}>
        <div className={classnames('scope-content', 'active')}>
          <Credentials
            title={t(m, 'page.password.update.title')}
            subTitle={t(m, 'page.password.update.sub.title')}
            currentPassword={this.props.temporaryPassword}
            customSubmit={this.submitUpdate}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    temporaryPassword: userTemporaryPasswordSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { forceUpdateUserPassword, clearTemporaryPassword, updateMemberInKeycloakStatus },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ForcePasswordUpdate);
