import React, { Component } from 'react';
import config from '../constants/config';
import GoogleMapReact from 'google-map-react';
import mapMarker from './../assets/images/posMarker.svg';
import PropTypes from 'prop-types';
import ParkingMarker from '../components/svg/ParkingMarker';
import { safe } from '../utils/utils';

import PositionMarker from './partials/PositionMarker';
import memoizeOne from 'memoize-one';

class AgencyItem extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.selectAgency = () => {
      this.props.onMarkerSelect(this.props.agencyId);
    };
  }

  render() {
    return (
      <PositionMarker h_align="center" v_align="center" getSize={this.props.getSize}>
        <span onClick={this.selectAgency} className="agency-circle">
          <ParkingMarker color="#00ff00" />
          <span className="figure">{this.props.vehiclesCount}</span>
        </span>
      </PositionMarker>
    );
  }
}

// noinspection JSUnusedGlobalSymbols
AgencyItem.defaultProps = {
  getSize: (size) => size, // callback
  onMarkerSelect: (agencyId) => agencyId
};

AgencyItem.propTypes = {
  agencyId: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number
};

// ----

const default_lat = 48.8566;
const default_lng = 2.3522;

const getMapCenterMem = memoizeOne((mapCenter) => ({
  lat: safe(() => mapCenter.lat) || default_lat,
  lng: safe(() => mapCenter.lng) || default_lng
}));

class BookingsMap extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = { agency_v_shift: 0 };
  }

  setCallbacks() {
    this.getMapCenter = () => getMapCenterMem(this.props.mapCenter);

    this.getAgencyItemSize = (size) => {
      const height = safe(() => size.height) || 1;
      const shift = -(height / 2);
      this.setState({ agency_v_shift: shift });
    };

    const getAgenciesMem = memoizeOne((agencies) =>
      safe(() =>
        agencies.map((agency, index) => (
          <AgencyItem
            lat={safe(() => agency.coordinates.latitude)}
            lng={safe(() => agency.coordinates.longitude)}
            key={safe(() => agency.id) || this.getShortId(index)}
            agencyId={safe(() => agency.id)}
            onMarkerSelect={this.props.onMarkerSelect}
            getSize={this.getAgencyItemSize}
            vehiclesCount={safe(() => agency.vehiclesCount)}
          />
        ))
      )
    );
    this.getAgencies = () => getAgenciesMem(this.props.agencies);

    const formSelectedAgencyMem = memoizeOne((agency, shift_v) => {
      if (agency) {
        return (
          <PositionMarker
            lat={safe(() => agency.coordinates.latitude)}
            lng={safe(() => agency.coordinates.longitude)}
            shift_v={shift_v}
            h_align="center"
            v_align="top"
            outterDivStyle={{ pointerEvents: 'none' }}
            innerDivStyle={{ pointerEvents: 'none' }}
          >
            <div style={{ pointerEvents: 'none' }} className="map-info-wrap">
              <div style={{ pointerEvents: 'all' }} className="google-map-popup">
                <span className="agency-name">{safe(() => agency.name)}</span>
              </div>
              <div style={{ pointerEvents: 'all' }} className="arrow-down" />
            </div>
          </PositionMarker>
        );
      }
    });
    const getSelectedAgencyMem = memoizeOne((agencies, selectedAgency) => {
      return agencies.find((agency) => selectedAgency === (safe(() => agency.id) || -1));
    });
    this.getPointerInfo = () => {
      const agency = getSelectedAgencyMem(this.props.agencies, this.props.selectedAgency);
      return formSelectedAgencyMem(agency, this.state.agency_v_shift);
    };
  }

  getShortId(index) {
    return Math.floor(Math.random() * 1000000 + 1) + index;
  }

  isAgenciesFound() {
    return safe(() => this.props.agencies.length > 0);
  }

  locationMarker() {
    if (this.isAgenciesFound()) {
      const { lat, lng } = this.getMapCenter();

      return (
        <PositionMarker lat={lat} lng={lng} h_align="center" v_align="center">
          <img className="map-marker" src={mapMarker} alt="" />
        </PositionMarker>
      );
    }
  }

  render() {
    return (
      <div onClick={this.props.enableMapKeyboard} className="bookings-map">
        <GoogleMapReact
          bootstrapURLKeys={{ key: config.gMapsApiKey }}
          center={this.getMapCenter()}
          defaultZoom={13}
          options={{
            disableDoubleClickZoom: true,
            keyboardShortcuts: this.props.mapKeyboardState
          }}
        >
          {this.locationMarker()}
          {this.getAgencies()}
          {this.getPointerInfo()}
        </GoogleMapReact>
      </div>
    );
  }
}

BookingsMap.defaultProps = {
  onMarkerSelect: (agencyId) => agencyId,
  enableMapKeyboard: () => 0,
  agencies: [],
  numberOfCars: 0,
  selectedAgency: '', // agency-id
  mapCenter: {
    lat: default_lat, // number
    lng: default_lng // number
  },
  mapKeyboardState: false
};

export default BookingsMap;
