import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from 'react-bootstrap';
import classnames from 'classnames';
import IconBattery from '../svg/Battery';
import IconClock from '../svg/Clock';
import IconCancel from '../svg/Cancel';
import { ReactSVG } from 'react-svg';
import { BROWSER_OS_ANDROID, BROWSER_OS_APPLE_MOBILE } from '../../constants/staticData';

import {
  BOOKING_CANCELED,
  BOOKING_PAST,
  USAGE_TYPE_PRIVATE
} from '../../constants/backend';

import carPlaceholder from '../../assets/images/car-placeholder.svg';
import googlePlayBlackIcon from '../../assets/images/google-play-black.svg';
import googlePlayWhiteIcon from '../../assets/images/google-play-white.svg';
import appStoreBlackIcon from '../../assets/images/app-store-black.svg';
import appStoreWhiteIcon from '../../assets/images/app-store-white.svg';
import PastIcon from '../../assets/images/past.svg';
import UpcomingIcon from '../../assets/images/upcoming.svg';
import { currentBrandSelector } from '../../helpers/selectors';
import { connect } from 'react-redux';
import { savePreBookingStatus } from '../../actions/booking';
import { bindActionCreators } from 'redux';

import {
  handleCarImageFail,
  getBookingCurrency,
  getPrice,
  safe
} from '../../utils/utils';

import {
  isReplacementVehicleBooking,
  isTestDriveBooking,
  isPreBookng,
  currentThemeSelector
} from '../../helpers/selectors';

const capitalizeStyle = {
  textTransform: 'capitalize'
};

export class BookingHistoryDetails extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.settings(props);
  }

  bindFunctions() {
    this.closePreBookingTutorial = this.closePreBookingTutorial.bind(this);
  }

  showAndroidUrl() {
    const { browserOs, currentBrand } = this.props;
    const { playStoreLink } = currentBrand || {};
    return browserOs === BROWSER_OS_ANDROID && playStoreLink;
  }

  showAppleUrl() {
    const { browserOs, currentBrand } = this.props;
    const { appStoreLink } = currentBrand || {};
    return browserOs === BROWSER_OS_APPLE_MOBILE && appStoreLink;
  }

  isMobile() {
    return this.showAndroidUrl() || this.showAppleUrl();
  }

  settings() {
    this.imageColSize = 5;
    this.innerContentColSize = 7;
  }

  getCarPicture(booking) {
    const { vehicle } = booking || {};
    const { theme } = this.props;
    let { pictureUrl } = vehicle || {};

    if (!isTestDriveBooking(booking) && this.isReplacementVehicleBusinessRule(booking)) {
      pictureUrl = '';
    }

    return (
      <img
        alt=""
        onError={(e) => handleCarImageFail(e, theme)}
        src={pictureUrl || carPlaceholder}
        className="booking-history-details-picture"
      />
    );
  }

  getVehicleTitle(booking) {
    const { vehicle } = booking || {};
    const { brand, model } = vehicle || {};
    return (
      <span className="booking-history-details-car-title">{brand + ' ' + model}</span>
    );
  }

  getBatteryLevel(booking) {
    const { vehicle } = booking || {};
    const { fuelLevel } = vehicle || {};
    const { percentage } = fuelLevel || {};

    return (
      <span className="booking-history-details-battery-level">{percentage}&nbsp;%</span>
    );
  }

  getBatteryData(booking) {
    const { vehicle } = booking || {};
    const { fuelLevel } = vehicle || {};
    const { percentage } = fuelLevel || {};

    if (percentage) {
      return (
        <div className="booking-history-details-battery-container">
          <IconBattery
            className="booking-history-details-battery-icon"
            percentage={percentage}
          />
          {this.getBatteryLevel(booking)}
        </div>
      );
    }
  }

  getPlateNumber(booking) {
    const { vehicle } = booking || {};
    const { registrationNumber } = vehicle || {};

    return (
      <span className="booking-history-details-plate-number">{registrationNumber}</span>
    );
  }

  getStartAddress(booking) {
    const { start } = booking || {};
    const { address } = start || {};
    const { formattedAddress } = address || {};

    return (
      <span className="booking-history-details-start-address">{formattedAddress}</span>
    );
  }

  isPastBooking(booking) {
    const { state } = booking || {};
    return state === BOOKING_PAST;
  }

  isCanceledBooking(booking) {
    const { status } = booking || {};
    return status === BOOKING_CANCELED;
  }

  getStartDateTime(booking) {
    const { start, end } = booking || {};
    const { date: startDate } = start || {};
    const { date: endDate } = end || {};
    const pastBooking = this.isPastBooking(booking);
    const date = pastBooking ? endDate : startDate;
    const what = pastBooking ? (
      this.isCanceledBooking(booking) ? (
        <FormattedMessage id="generic.canceled" />
      ) : (
        <FormattedMessage id="generic.completed" />
      )
    ) : (
      <FormattedMessage id="generic.start" />
    );

    const momentDate = moment(date);
    const isToday = momentDate.isSame(new Date(), 'day');
    const at = isToday ? <FormattedMessage id="label.booking.today.part" /> : ':';
    const time = ' ' + momentDate.format('hh:mm a');

    return (
      <span className="booking-history-details-start-date-time">
        {what}
        {isToday ? ' ' : ''}
        {at}
        {time}
      </span>
    );
  }

  atLeastOneActionAllowed(booking) {
    const { allowedActions } = booking || {};
    const { cancel, update } = allowedActions || {};

    return cancel || update;
  }

  getExtendAction(booking) {
    const { updateAction } = this.props;
    const { allowedActions } = booking || {};
    const { update } = allowedActions || {};

    if (!update) return;

    return (
      <div
        className={classnames(
          'booking-history-details-action',
          'booking-history-details-action-grey'
        )}
        onClick={() => updateAction(booking)}
      >
        <IconClock />
        <div className="booking-history-details-action-name">
          <FormattedMessage id="generic.modify" />
        </div>
      </div>
    );
  }

  getCancelAction(booking) {
    const { cancelAction, cancellingBooking } = this.props;
    const { allowedActions, id } = booking || {};
    const { cancel } = allowedActions || {};
    const cancelling = cancellingBooking === id;

    if (!cancel) return;

    return (
      <div
        className={classnames(
          'booking-history-details-action',
          'booking-history-details-action-white',
          { 'sub-class-disabled': cancelling }
        )}
        onClick={() => !cancelling && cancelAction(id)}
      >
        <IconCancel className="booking-history-details-cancel-icon" />
        <div className="booking-history-details-action-name">
          <FormattedMessage id="generic.cancel" />
        </div>
      </div>
    );
  }

  isReplacementVehicleBusinessRule(booking) {
    return isPreBookng(booking) && isReplacementVehicleBooking(booking);
  }

  isTestDriveBusinessRule(booking) {
    return isPreBookng(booking) && isTestDriveBooking(booking);
  }

  getBookingActions(booking) {
    if (!this.atLeastOneActionAllowed(booking) || isPreBookng(booking)) return;

    return [
      <div key="hr" className="booking-history-details-hr" />,
      <div key="action-container" className="booking-history-details-action-container">
        {this.getExtendAction(booking)}
        {this.getCancelAction(booking)}
      </div>
    ];
  }

  getDownloadAppMessage(show) {
    return show && <FormattedMessage id="label.redirectMobileApp" />;
  }

  getMobileIcon() {
    const { useWhiteIcons } = this.props;
    const { currentBrand } = this.props;
    const { playStoreLink, appStoreLink } = currentBrand || {};

    if (this.showAndroidUrl()) {
      return (
        <a href={playStoreLink}>
          <img
            alt="download android app"
            src={useWhiteIcons ? googlePlayWhiteIcon : googlePlayBlackIcon}
          />
        </a>
      );
    }

    if (this.showAppleUrl()) {
      return (
        <a href={appStoreLink}>
          <img
            alt="download iOS app"
            src={useWhiteIcons ? appStoreWhiteIcon : appStoreBlackIcon}
          />
        </a>
      );
    }
  }

  getDownloadAppFooter(booking) {
    if (!this.isPastBooking(booking)) {
      return (
        <div className="booking-history-details-content-footer">
          {this.getDownloadAppMessage(!this.isMobile())}
          {this.getMobileIcon()}
        </div>
      );
    }
  }

  getMobileDownloadAppMessage(booking) {
    const showDownloadAppMessage = this.isMobile() && !this.isPastBooking(booking);

    return (
      <div className="scope-download-app-msg-container">
        {this.getDownloadAppMessage(showDownloadAppMessage)}
      </div>
    );
  }

  showFirstBookingInfoRows(booking) {
    if (this.isTestDriveBusinessRule(booking)) {
      return (
        <div className="booking-history-details-battery-parent-row">
          {this.getVehicleTitle(booking)}
          {this.getBatteryData(booking)}
        </div>
      );
    }

    if (!this.isReplacementVehicleBusinessRule(booking)) {
      return [
        <div className="booking-history-details-battery-parent-row" key="booking-title">
          {this.getVehicleTitle(booking)}
          {this.getBatteryData(booking)}
        </div>,
        <div className="booking-history-details-content-body-text-row" key="plate-number">
          {this.getPlateNumber(booking)}
        </div>
      ];
    }
  }

  getTotalPrice(booking) {
    const { carSharingInfo } = booking || {};
    const { cost } = carSharingInfo || {};
    const { totalPriceIncludingTaxes } = cost || {};
    return totalPriceIncludingTaxes;
  }

  getEstimatedPrice(booking) {
    const { carSharingInfo } = booking || {};
    const { cost } = carSharingInfo || {};
    const { estimatedPriceForDuration } = cost || {};
    return estimatedPriceForDuration;
  }

  closePreBookingTutorial() {
    this.props.savePreBookingStatus();
  }

  isPrivateUsage(booking) {
    return safe(() => booking.carSharingInfo.usageType) === USAGE_TYPE_PRIVATE;
  }

  getBookingPriceRow(booking) {
    const currency = getBookingCurrency(booking);

    let price = this.isPastBooking(booking)
      ? this.getTotalPrice(booking)
      : this.getEstimatedPrice(booking);

    price = getPrice(price, currency);

    if (price && this.isPrivateUsage(booking)) {
      return (
        <div className="booking-history-details-content-body-text-row">
          <span style={capitalizeStyle}>
            <FormattedMessage id="label.price" />
            {': '}
          </span>
          {price}
        </div>
      );
    }
  }

  adjustPreBookingTutorialData(groupedBookings) {
    const { preBookingStatus } = this.props;

    if (preBookingStatus) {
      groupedBookings = [groupedBookings[0], groupedBookings[0], 42];
    }

    return groupedBookings;
  }

  preBookingTutorialTitle() {
    return (
      <div
        key="tuto-title-key"
        className="scope-tutorial-title-container"
        onClick={this.closePreBookingTutorial}
      >
        <span className="scope-tutorial-title">
          <FormattedMessage id="title.bookings.welcome.tutorial" />
        </span>
        <span className="scope-overlay-button" />
      </div>
    );
  }

  shouldDisplayPreBookingTutorialTitle(index) {
    const { preBookingStatus } = this.props;
    return preBookingStatus && index === 2;
  }

  getGroupedBookingsKey(index) {
    const { preBookingStatus } = this.props;
    return preBookingStatus && index === 1 ? 'tuto-key' : index;
  }

  showGroupedBookings(groupedBookings) {
    groupedBookings = this.adjustPreBookingTutorialData(groupedBookings);

    return _map(groupedBookings, (booking, index) => {
      const ret = (
        <div
          className={classnames('booking-history-details-content', {
            'sub-class-mobile-msg': this.isMobile() && !this.isPastBooking(booking)
          })}
          key={this.getGroupedBookingsKey(index)}
        >
          <div className="booking-history-details-content-body">
            <Row className="remove-row-margin">
              <Col
                className={classnames(
                  'remove-col-padding',
                  'booking-history-details-picture-column'
                )}
                xs={12}
                sm={this.imageColSize}
                md={this.imageColSize}
                lg={this.imageColSize}
              >
                {this.getCarPicture(booking)}
              </Col>
              <Col
                className="remove-col-padding"
                xs={12}
                sm={this.innerContentColSize}
                md={this.innerContentColSize}
                lg={this.innerContentColSize}
              >
                {this.showFirstBookingInfoRows(booking)}
                <div className="booking-history-details-content-body-text-row">
                  {this.getStartAddress(booking)}
                </div>
                <div className="booking-history-details-content-body-text-row">
                  {this.getStartDateTime(booking)}
                </div>
                {this.getBookingPriceRow(booking)}
                {this.getBookingActions(booking)}
                {this.getMobileDownloadAppMessage(booking)}
              </Col>
            </Row>
          </div>
          {this.getDownloadAppFooter(booking)}
        </div>
      );

      if (this.shouldDisplayPreBookingTutorialTitle(index)) {
        return this.preBookingTutorialTitle();
      }

      return ret;
    });
  }

  displayGroups() {
    const { groupedBookings, bookingsType, theme } = this.props;
    const { pastBookings, upcomingBookings } = theme.icons || {};
    if (_isEmpty(groupedBookings)) {
      return (
        <span className="empty-list">
          {bookingsType === 'upcoming' ? (
            <span>
              <ReactSVG
                className="react-svg"
                src={upcomingBookings ? upcomingBookings : UpcomingIcon}
              />
              <FormattedMessage id="label.booking.upcoming.placeholder" />
            </span>
          ) : (
            <span>
              <ReactSVG
                className="react-svg"
                src={pastBookings ? pastBookings : PastIcon}
              />
              <FormattedMessage id="generic.past.placeholder" />
            </span>
          )}
        </span>
      );
    } else {
      return (
        <div className="scope-groups">
          {_map(groupedBookings, (bookings, index) => {
            return (
              <div key={index} className="booking-history-details-group">
                <div className="booking-history-details-day">
                  {moment(bookings.day).format('DD MMM YYYY')}
                </div>
                <div className="scope-grouped-items">
                  {this.showGroupedBookings(bookings.grouped)}
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }

  displayPreBookingOverlay() {
    const { preBookingStatus } = this.props;

    if (preBookingStatus) {
      return (
        <div
          className="scope-pre-booking-overlay"
          onClick={this.closePreBookingTutorial}
        />
      );
    }
  }

  render() {
    const { preBookingStatus } = this.props;

    return (
      <div
        className={classnames('booking-history-details', {
          'sub-class-pre-booking': preBookingStatus
        })}
      >
        {this.displayGroups()}
        {this.displayPreBookingOverlay()}
      </div>
    );
  }
}

BookingHistoryDetails.propTypes = {
  groupedBookings: PropTypes.array,
  useWhiteIcons: PropTypes.bool, // use white or black icons on 'theme' background
  browserOs: PropTypes.oneOf([BROWSER_OS_ANDROID, BROWSER_OS_APPLE_MOBILE, false]),
  cancelAction: PropTypes.func,
  bookingsType: PropTypes.string
};

const mapStateToProps = (state) => {
  const { preBookingStatus, cancellingBooking } = state.booking;

  return {
    currentBrand: currentBrandSelector(state),
    theme: currentThemeSelector(state),
    cancellingBooking,
    preBookingStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ savePreBookingStatus }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistoryDetails);
