import { getCountryAndLang } from '../helpers';
import { addActionError } from '../utils/flashMessage/creator';

import { STORAGE_KEY } from '../constants/generic';
import { getActionError } from '../helpers/selectors';
import { getPrevAction, isSubscribePage } from '../utils/utils';

const defaultState = {
  info: null
};

function saveBrandData() {
  const currentBrand = sessionStorage.getItem(STORAGE_KEY.CONFIG);
  const rawContract = sessionStorage.getItem(STORAGE_KEY.RAW_CONTRACT);

  if (currentBrand) {
    localStorage.setItem(STORAGE_KEY.CONFIG, currentBrand);
  }

  if (rawContract) {
    localStorage.setItem(STORAGE_KEY.RAW_CONTRACT, rawContract);
  }
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'stripe/card/GET_SUCCESS':
    case 'worldpay/card/GET_SUCCESS':
    case 'card/GET_SUCCESS': {
      state = { ...state, info: action.payload };
      break;
    }

    case 'stripe/card/CREATE_FAIL':
    case 'worldpay/card/CREATE_FAIL':
    case 'card/CREATE_FAIL': {
      addActionError(action);
      break;
    }

    case 'stripe/card/GET_FAIL':
    case 'worldpay/card/GET_FAIL':
    case 'card/GET_FAIL': {
      const { errorCode } = getActionError(action);

      if (errorCode === 'creditcard.data.insufficient.3ds') {
        if (isSubscribePage()) break;
      }

      addActionError(action);
      break;
    }

    case 'card/GET_URL_FAIL': {
      addActionError(action);
      break;
    }

    case 'card/GET_URL_SUCCESS': {
      const { returnUrl } = getPrevAction(action);
      const worldPayURL = action.payload.data.url;
      const { origin, pathname } = window.location;
      const defaultReturnUrl = origin + pathname;
      const currentURL = encodeURIComponent(returnUrl || defaultReturnUrl);
      const { country, language } = getCountryAndLang();

      saveBrandData();

      window.location = `${worldPayURL}&successURL=${currentURL}&cancelURL=${currentURL}&failureURL=${currentURL}&country=${country}&language=${language}`;

      break;
    }

    case 'stripe/card/DELETE_SUCCESS':
    case 'worldpay/card/DELETE_SUCCESS':
    case 'card/DELETE_SUCCESS': {
      state = { ...state, info: { data: null } };
      break;
    }

    default: {
      break;
    }
  }

  return state;
}
