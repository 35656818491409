import classnames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import {
  drivingLicenseAllowedSelector,
  identityDocumentAllowedSelector,
  isExternalIncompleteUserSelector,
  isProfileIncompleteUserSelector,
  isRegisterIncompleteUserSelector,
  messagesSelector,
  registerCompanySelector,
  showMoreInfoPageSelector,
  userIdSelector
} from '../../helpers/selectors';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import {
  getMessage as t,
  isCreationSuccessPage,
  isRMF_companyId,
  isValidId,
  navigateToHomePage
} from '../../utils/utils';
import { addErrorMsg } from '../../utils/flashMessage/creator';
import {
  autoUpdateRegisterIncompleteStatus,
  clearProfileFormData,
  getRegisterCompany,
  getUserProfiles,
  logout,
  setFlagToOpenRenaultFrancePopup,
  setSubscribeLoaderState
} from '../../actions/user';
import { creation as cr } from '../../constants/routes';
import { getCreationId } from '../../helpers/components';
import { getMemberCustomFields } from '../../actions/customFields';
import { setRegisterExternalUserStatus } from '../../actions/keycloak';

function Creation(p) {
  const { pathname } = useLocation();
  const [pageIndex, setPageIndex] = useState(0);

  const pageIndexMap = useMemo(() => {
    const arr = [];

    if (!(p.isExternalIncompleteUser || p.isProfileIncompleteUser)) {
      if (!p.isRegisterIncompleteUser) {
        arr.push(`${cr.container}/${cr.credentials}/*`);
      }
      arr.push(`${cr.container}/${cr.activate}/*`);
    }
    arr.push(`${cr.container}/${cr.info}/*`);

    if (p.drivingLicenseAllowed || p.identityDocumentAllowed) {
      arr.push(`${cr.container}/${cr.files}/*`);
    }
    if (p.showMoreInfoPage) {
      arr.push(`${cr.container}/${cr.moreInfo}/*`);
    }
    return arr;
  }, [
    p.isExternalIncompleteUser,
    p.isProfileIncompleteUser,
    p.isRegisterIncompleteUser,
    p.drivingLicenseAllowed,
    p.identityDocumentAllowed,
    p.showMoreInfoPage
  ]);

  function startFetch() {
    p.setSubscribeLoaderState(true);
  }

  function finishFetch() {
    p.setSubscribeLoaderState(false);
  }

  function handleRenaultFranceUser() {
    if (isRMF_companyId(getCreationId(p.routeParams))) {
      p.setFlagToOpenRenaultFrancePopup(true);
      finishFetch();
      p.logout(true);
    }
  }

  function getCompanyDataOnLoad() {
    const id = getCreationId(p.routeParams);
    if (isRMF_companyId(id)) return;
    startFetch();

    if (isValidId(id)) {
      p.getRegisterCompany(id).then((data) => {
        if (data?.type === 'user/GET_REGISTER_COMPANY_SUCCESS') {
          p.getMemberCustomFields(id).then(finishFetch);
        } else {
          finishFetch();
          navigateToHomePage();
        }
      });
    } else {
      addErrorMsg(t(p.m, 'error.invite.code.invalid'));
      finishFetch();
      navigateToHomePage();
    }
  }

  useEffect(() => {
    if (isRMF_companyId(getCreationId(p.routeParams))) return;
    if (p.userId) p.getUserProfiles();
  }, [p.userId]);

  useEffect(() => {
    setPageIndex(pageIndexMap.findIndex((route) => matchPath(route, pathname)));
  }, [pathname, pageIndexMap]);

  useEffect(() => {
    if (!isCreationSuccessPage()) {
      handleRenaultFranceUser();
      getCompanyDataOnLoad();
    }
    return () => {
      p.clearProfileFormData();
      p.setRegisterExternalUserStatus(false);
      p.autoUpdateRegisterIncompleteStatus();
      finishFetch();
    };
  }, []);

  function getTitlePart() {
    let titlePart = 'part.create.account.for';
    if (p.isProfileIncompleteUser) titlePart = 'part.link.account.to';
    if (p.isRegisterIncompleteUser || p.isExternalIncompleteUser) {
      titlePart = 'part.complete.register.for';
    }
    return (
      <div className="scope-title">
        <FormattedMessage id={titlePart} />
      </div>
    );
  }

  function renderCompanyName() {
    return p?.company?.name || <FormattedMessage id="generic.company" />;
  }

  function renderSteps() {
    const steps = [];

    for (let i = 0; i < pageIndexMap.length; i++) {
      function getActiveIndex() {
        return i === pageIndex;
      }

      function renderStepNumber() {
        return (
          <div
            key={i}
            className={classnames('scope-step', {
              'sub-class-active': getActiveIndex()
            })}
          >
            {i + 1}
          </div>
        );
      }

      function renderStepLine() {
        if (i < pageIndexMap.length - 1) {
          return <div key={i + '.sub'} className="scope-step-line" />;
        }
      }

      steps.push(renderStepNumber());
      steps.push(renderStepLine());
    }
    return steps;
  }

  return (
    <div className={classnames('creation-page', 'register-page', 'profile-page')}>
      {!isCreationSuccessPage() && (
        <div className={classnames('scope-page-header')}>
          <div className="scope-title-container">
            {getTitlePart()}
            <div className="scope-company-name">{renderCompanyName()}</div>
          </div>

          <div className="scope-steps-container">{renderSteps()}</div>
        </div>
      )}
      <div className={classnames('scope-content', 'active')}>
        <Outlet />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    company: registerCompanySelector(state),
    userId: userIdSelector(state),
    isRegisterIncompleteUser: isRegisterIncompleteUserSelector(state),
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    isExternalIncompleteUser: isExternalIncompleteUserSelector(state),
    drivingLicenseAllowed: drivingLicenseAllowedSelector(state),
    identityDocumentAllowed: identityDocumentAllowedSelector(state),
    showMoreInfoPage: showMoreInfoPageSelector(state)
  };
}

const mapDispatchToProps = {
  logout,
  getUserProfiles,
  setSubscribeLoaderState,
  getRegisterCompany,
  autoUpdateRegisterIncompleteStatus,
  getMemberCustomFields,
  clearProfileFormData,
  setRegisterExternalUserStatus,
  setFlagToOpenRenaultFrancePopup
};

Creation = connect(mapStateToProps, mapDispatchToProps)(Creation);

export default Creation;
