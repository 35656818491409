import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { updateInformations } from '../../actions/user';
import { formatSelfieForApiCall } from '../../helpers/formatters';
import SelfieForm from '../account/SelfieForm';
import { navigateToCreationMoreInfo } from '../../utils/utils';
import { getCreationId, showApiError } from '../../helpers/components';
import {
  identityDocumentAllowedSelector,
  identityDocumentFormInitialValuesSelector,
  messagesSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';

function CreationSelfie(p) {
  const r = useRef({ fetching: false }).current;

  useEffect(() => {
    if (!p.identityDocumentAllowed) {
      navigateToCreationMoreInfo(getCreationId(p.routeParams), true);
    }
  }, [p.identityDocumentAllowed]);

  const updateUserData = useCallback(
    (formValues) => {
      if (!r.fetching) {
        r.fetching = true;
        p.updateInformations(
          p.userId,
          formatSelfieForApiCall(p.userData, formValues)
        ).then((data) => {
          r.fetching = false;
          if (data?.type === 'user/UPDATE_INFO_SUCCESS') {
            navigateToCreationMoreInfo(getCreationId(p.routeParams));
          } else {
            showApiError(p.m, data);
          }
        });
      }
    },
    [r.fetching, p.userId, p.userData, p.routeParams, p.m]
  );

  return (
    <SelfieForm
      isSubscribePage
      onSubmit={updateUserData}
      initialValues={p.initialValues}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    identityDocumentAllowed: identityDocumentAllowedSelector(state),
    initialValues: identityDocumentFormInitialValuesSelector(state)
  };
};

const mapDispatchToProps = {
  updateInformations
};

export default connect(mapStateToProps, mapDispatchToProps)(CreationSelfie);
