import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  identityDocumentAllowedSelector,
  identityDocumentFormInitialValuesSelector,
  messagesSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';

import { updateInformations } from '../../actions/user';
import IdentityDocumentForm from '../account/IdentityDocumentForm';
import { formatIdentityDocumentForApiCall } from '../../helpers/formatters';
import { navigateToCreationMoreInfo, navigateToCreationSelfie } from '../../utils/utils';
import { getCreationId, showApiError } from '../../helpers/components';

function CreationIdentityDocument(p) {
  const r = useRef({ fetching: false }).current;

  useEffect(() => {
    if (!p.identityDocumentAllowed) {
      navigateToCreationMoreInfo(getCreationId(p.routeParams), true);
    }
  }, [p.identityDocumentAllowed]);

  const updateUserData = useCallback(
    (formValues) => {
      if (!r.fetching) {
        r.fetching = true;
        p.updateInformations(
          p.userId,
          formatIdentityDocumentForApiCall(p.userData, formValues)
        ).then((data) => {
          r.fetching = false;
          if (data?.type === 'user/UPDATE_INFO_SUCCESS') {
            navigateToCreationSelfie(getCreationId(p.routeParams));
          } else {
            showApiError(p.m, data);
          }
        });
      }
    },
    [r.fetching, p.userId, p.userData, p.routeParams, p.m]
  );

  return (
    <IdentityDocumentForm
      isSubscribePage
      onSubmit={updateUserData}
      initialValues={p.initialValues}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    identityDocumentAllowed: identityDocumentAllowedSelector(state),
    initialValues: identityDocumentFormInitialValuesSelector(state)
  };
};

const mapDispatchToProps = { updateInformations };

export default connect(mapStateToProps, mapDispatchToProps)(CreationIdentityDocument);
