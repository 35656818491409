import React from 'react';

export default function Battery({ percentage, ...props } = { percentage: 70 }) {
  const maxWidth = 16.82;
  const currentWidth = Number((percentage * maxWidth) / 100).toFixed(2);

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.01 12.44" {...props}>
      <path d="M20.57,8.29v1.89a2.18,2.18,0,0,1-2.16,2.26H2.17A2.19,2.19,0,0,1,0,10.18V2.26A2.2,2.2,0,0,1,2.17,0H18.41a2.18,2.18,0,0,1,2.16,2.26V4.13c.35,0,1.44.18,1.44,1V7.26C22,8.13,20.92,8.27,20.57,8.29Zm-1,2V2.19A1.08,1.08,0,0,0,18.46,1H2.12A1.11,1.11,0,0,0,1,2.19v8.06A1.11,1.11,0,0,0,2.12,11.4H18.46A1.11,1.11,0,0,0,19.55,10.25Z" />
      <rect
        id="Rectangle"
        x="1.88"
        y="1.9"
        width={currentWidth}
        height="8.64"
        rx="1.38"
      />
    </svg>
  );
}
