import classnames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { getErrorInfo } from './helpers';
import { Field } from 'redux-form';

function getDisabledPart({ input }) {
  return (
    <span className={classnames('new-design-field', 'sub-class-disabled')}>
      {input.value}
    </span>
  );
}

function getInputPart({ input, ...p }) {
  return (
    <input
      className="new-design-field"
      type={p.type}
      placeholder={p.placeholder}
      autoComplete={p.autoComplete}
      {...input}
    />
  );
}

function textField({ meta, ...p }) {
  return (
    <div className="scope-field-container">
      <div className="scope-field-label">{p.label} *</div>
      {p.disabled ? getDisabledPart(p) : getInputPart(p)}
      {getErrorInfo(meta)}
    </div>
  );
}

function TextField({ name, ...props }) {
  return <Field name={name} {...props} component={textField} />;
}

TextField.defaultProps = {
  type: 'text'
};

TextField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string,
  type: PropTypes.string
};

export default TextField;
