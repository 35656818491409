import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import classnames from 'classnames';
import {
  CUSTOM_FIELD_STATUS_YES,
  CUSTOM_FIELD_STATUS_CONDITIONED,
  CUSTOM_FIELD_TYPE_BOOLEAN,
  CUSTOM_FIELD_TYPE_FILE,
  CUSTOM_FIELD_TYPE_NUMERIC,
  CUSTOM_FIELD_TYPE_PHONE_NUMBER,
  CUSTOM_FIELD_TYPE_TEXT
} from '../../constants/backend';
import { connect } from 'react-redux';
import FileField from './fields/file';
import TextField from './fields/text';
import BoolField from './fields/bool';
import {
  localeSelector,
  customFieldsValuesSelector,
  selectFieldIdsByPosition,
  messagesSelector
} from '../../helpers/selectors';
import { isEmpty, strToBool } from '../../utils/utils';
import { injectIntl } from 'react-intl';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import { USER_FORM_NAMES } from '../../constants/form';
import _isEmpty from 'lodash/isEmpty';
import { customFieldIsVisible } from '../../helpers/components';

function fieldIsMandatory(field, fieldValues, fieldIdsByPosition) {
  const { companyCustomField } = field || {};
  const { mandatory, mandatoryCondition } = companyCustomField || {};
  const { dependsOnPosition, expectedValueForPosition } = mandatoryCondition || {};

  if (mandatory === CUSTOM_FIELD_STATUS_CONDITIONED) {
    const parentId = fieldIdsByPosition[dependsOnPosition];
    const parentValue = !!fieldValues[parentId];

    return parentValue === strToBool(expectedValueForPosition);
  } else return mandatory === CUSTOM_FIELD_STATUS_YES;
}

function getFieldId(field) {
  const { companyCustomField } = field || {};
  const { id } = companyCustomField || {};
  return id;
}

function setRangeErrors(field, values, errors, props) {
  const fieldId = getFieldId(field);
  const fieldValue = values[fieldId];
  const textLength = fieldValue.length;
  const { intl } = props;
  const { formatMessage } = intl;
  const { companyCustomField } = field || {};
  const { max, min, fieldType } = companyCustomField || {};

  if (fieldType === CUSTOM_FIELD_TYPE_TEXT) {
    if (textLength < min) {
      errors[fieldId] = formatMessage({ id: 'error.text.length.short' }, { count: min });
    } else if (textLength > max) {
      errors[fieldId] = formatMessage({ id: 'error.text.length.long' }, { count: max });
    }
  } else if (fieldType === CUSTOM_FIELD_TYPE_NUMERIC) {
    const number = Number(fieldValue);

    if (number < min) {
      errors[fieldId] = formatMessage({ id: 'error.number.below.min' }, { value: min });
    } else if (number > max) {
      errors[fieldId] = formatMessage({ id: 'error.number.above.max' }, { value: max });
    }
  }
}

const validate = (values, props) => {
  const { customFields, fieldIdsByPosition } = props;
  const errors = {};

  customFields.forEach((field) => {
    const visible = customFieldIsVisible(field, values, fieldIdsByPosition);

    if (visible) {
      const fieldId = getFieldId(field);
      const fieldValue = values[fieldId];
      const emptyField = isEmpty(fieldValue);

      if (emptyField) {
        const mandatory = fieldIsMandatory(field, values, fieldIdsByPosition);
        if (mandatory) errors[fieldId] = true;
      } else setRangeErrors(field, values, errors, props);
    }
  });

  return errors;
};

class CustomFieldsForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  componentDidMount() {
    this.passFormSubmit();
  }

  passFormSubmit() {
    const { passFormSubmit } = this.props;
    if (passFormSubmit) passFormSubmit(this.validateAndSubmit);
  }

  validateAndSubmit(action) {
    const { handleSubmit, validate, fieldValues, change, dispatch } = this.props;
    const errors = validate(fieldValues, this.props);

    if (_isEmpty(errors)) {
      dispatch(handleSubmit(action));
    } else {
      change('_validate', new Date());
      dispatch(handleSubmit(() => ''));
    }
  }

  selectCurrentLabel(companyCustomField = {}, m = []) {
    const { companyCustomFieldLabels, name } = companyCustomField;
    const { locale } = this.props;

    const found = _find(
      companyCustomFieldLabels,
      (label = {}) => label.language === locale
    );

    let { label } = found || {};

    if (!label) {
      const engPredicate = (label = {}) => label.language === 'en';
      const frPredicate = (label = {}) => label.language === 'fr';
      const eng = _find(companyCustomFieldLabels, engPredicate);
      const fr = _find(companyCustomFieldLabels, frPredicate);

      const { label: engLabel } = eng || {};
      const { label: frLabel } = fr || {};

      label = engLabel || frLabel;
    }

    return label || name;
  }

  selectFieldType(field) {
    const { m, fieldValues, fieldIdsByPosition } = this.props;
    const { companyCustomField } = field || {};
    const { fieldType, id } = companyCustomField || {};
    const label = this.selectCurrentLabel(companyCustomField, m);
    const mandatory = fieldIsMandatory(field, fieldValues, fieldIdsByPosition);

    if (fieldType === CUSTOM_FIELD_TYPE_FILE) {
      return <FileField key={id} name={id} label={label} mandatory={mandatory} />;
    } else if (fieldType === CUSTOM_FIELD_TYPE_NUMERIC) {
      return (
        <TextField
          key={id}
          name={id}
          type="number"
          label={label}
          mandatory={mandatory}
          placeholder={m['placeholder.enter.number']}
        />
      );
    } else if (
      fieldType === CUSTOM_FIELD_TYPE_TEXT ||
      fieldType === CUSTOM_FIELD_TYPE_PHONE_NUMBER
    ) {
      return (
        <TextField
          key={id}
          name={id}
          label={label}
          mandatory={mandatory}
          placeholder={m['placeholder.enter.text']}
        />
      );
    } else if (fieldType === CUSTOM_FIELD_TYPE_BOOLEAN) {
      return <BoolField key={id} name={id} label={label} mandatory={mandatory} />;
    }
  }

  customField(field) {
    const { fieldValues, fieldIdsByPosition } = this.props;

    if (customFieldIsVisible(field, fieldValues, fieldIdsByPosition)) {
      return this.selectFieldType(field);
    }
  }

  render() {
    const { handleSubmit, customFields } = this.props;

    return (
      <form
        className={classnames('custom-fields-form', 'scope-section')}
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="scope-section-title pb-xs-0 pt-dt-0" />
        <div className="scope-section-fields">
          {customFields.map((field) => this.customField(field))}
        </div>
      </form>
    );
  }
}

CustomFieldsForm.defaultProps = {
  customFields: []
};

CustomFieldsForm.propTypes = {
  passFormSubmit: PropTypes.func,
  customFields: PropTypes.array,
  fields: PropTypes.array
};

const formComponent = reduxForm({
  form: USER_FORM_NAMES.CUSTOM_FIELDS,
  validate,
  onSubmitFail: scrollToErrorInfo,
  enableReinitialize: true,
  destroyOnUnmount: false
})(CustomFieldsForm);

function mapStateToProps(state, props) {
  const { customFields } = props;

  return {
    locale: localeSelector(state),
    fieldIdsByPosition: selectFieldIdsByPosition(customFields),
    fieldValues: customFieldsValuesSelector(state),
    m: messagesSelector(state)
  };
}

const connectComponent = connect(mapStateToProps)(formComponent);

export default injectIntl(connectComponent);
