import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/popup';
import { connect } from 'react-redux';
import { browserHistory } from '../../helpers/components';

class FaqPopup extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.handleClick = this.handleClick.bind(this);
  }

  locale(id, options) {
    const { intl } = this.props;
    const { formatMessage } = intl;
    return formatMessage({ id }, options);
  }

  handleClick(infoUrl) {
    const { popup_Faq_SetVisibility } = this.props;

    popup_Faq_SetVisibility(false);
    if (!infoUrl) browserHistory.push('/faq');
  }

  getFlags() {
    const {
      brand: {
        branded: { mapCountries }
      }
    } = this.props;

    return Object.keys(mapCountries).map((isoCode) => {
      const { infosUrl } = mapCountries[isoCode] || {};

      return (
        <li key={isoCode}>
          <a target="_blank" href={infosUrl} onClick={() => this.handleClick(infosUrl)}>
            <i style={{ backgroundImage: `url(${mapCountries[isoCode].iconUrl})` }} />
            <span>{this.locale('iso__' + isoCode)}</span>
          </a>
        </li>
      );
    });
  }

  render() {
    return (
      <div className="faq-popup">
        <div className="text-center f-25 mt-40 mb-10">
          <FormattedMessage id="service.private.select_country" />
        </div>
        <div className="text-center mb-35">
          <ul className="flag-country-selector">{this.getFlags()}</ul>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions }, dispatch);
}

const connectComponent = connect(mapStateToProps, mapDispatchToProps)(FaqPopup);

export default injectIntl(connectComponent);
