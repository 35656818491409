import React from 'react';

export default function ParkingMarker({ color, ...props } = { color: '#000' }) {
  return (
    <svg
      width="131px"
      height="132px"
      viewBox="0 0 131 132"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-11.7%"
          y="-11.7%"
          width="123.5%"
          height="125.2%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0.678979846   0 0 0 0 0.678979846   0 0 0 0 0.678979846  0 0 0 0.5 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g id="Mockups" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="results"
          filter="url(#filter-1)"
          transform="translate(8.000000, 8.000000)"
          stroke="#FFFFFF"
          strokeWidth="5"
        >
          <path
            d="M57.5,0 C89.2563731,0 115,25.7436269 115,57.5 C115,89.2563731 89.2563731,115 57.5,115 C47.5024423,115 38.1008197,112.448499 29.9107844,107.961149 L12.6256313,114.874369 L18.626991,99.8695304 C7.177163,89.3590019 0,74.267659 0,57.5 C0,25.7436269 25.7436269,0 57.5,0 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
          ></path>
          <circle
            id="Combined-Shape-Copy"
            fill={color}
            cx="57.5"
            cy="57.5"
            r="57.5"
          ></circle>
        </g>
      </g>
    </svg>
  );
}
