import React, { Component } from 'react';
import { Outlet, useMatch } from 'react-router-dom';
import IndexLink from './partials/IndexLink';
import { browserHistory } from '../helpers/components';
import profileCircle from './../assets/images/profile-circle.svg';
import IconRightArrow from '../components/svg/NoTailArrowRight';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import * as UserActions from '../actions/user';
import { connect } from 'react-redux';

import {
  messagesSelector,
  profileCustomFieldsSelector,
  showMoreInfoPageSelector,
  userCompanyIdSelector,
  userDataSelector,
  userIdSelector,
  currentThemeSelector,
  activeProfileSelector,
  userProfilesSelector,
  userContractSelector
} from '../helpers/selectors';

import { getMessage as t, getMessageOptions, isSwitchPage, safe } from '../utils/utils';
import * as CustomFieldsActions from '../actions/customFields';
import { keysMemberStatus } from '../constants/translations';
import { FormattedMessage } from 'react-intl';

export class MyAccount extends Component {
  componentDidMount() {
    this.getCustomFieldsData();
    this.updateUserData();
    this.getProfiles();
  }

  getProfiles() {
    this.props.getUserProfiles();
  }

  getCustomFieldsData() {
    const { getMemberCustomFields, userData } = this.props;
    const { company } = userData || {};
    const { id: companyId } = company || {};

    if (companyId) getMemberCustomFields(companyId);
  }

  updateUserData() {
    this.props.getUserInfo();
  }

  getContent() {
    const { itemSelected, m } = this.props;
    if (itemSelected) return <Outlet />;
    return (
      <div className="scope-select-menu-item">{t(m, 'profile.select.menu.item')}</div>
    );
  }

  getUserStatusClass() {
    const { userData } = this.props;
    const { status, suspended } = userData;
    return `sub-class-${status} ${suspended ? 'suspended' : ''}`;
  }

  onlySsoAllowed() {
    return safe(() => {
      const { allowRegistrationWithoutSso } =
        this.props.userData.company.ssoConfiguration;
      return allowRegistrationWithoutSso === false;
    });
  }

  getCredentialsMenuItem() {
    if (this.onlySsoAllowed()) return;

    return (
      <div className="scope-menu-body-link-container">
        <IndexLink to="/profile/credentials" activeClassName="active primary-border">
          {t(this.props.m, 'profile.section.credentials')}
          <IconRightArrow />
        </IndexLink>
      </div>
    );
  }

  getDriverLicenceMenuItem() {
    const { m, userData } = this.props;
    const { company } = userData || {};
    const { drivingLicenceRequired } = company || {};

    if (drivingLicenceRequired) {
      return (
        <div className="scope-menu-body-link-container">
          <IndexLink to="/profile/driver-license" activeClassName="active primary-border">
            {t(m, 'label.driving_licence')}
            <IconRightArrow />
          </IndexLink>
        </div>
      );
    }
  }

  identityDocumentAllowed() {
    const { userData } = this.props;
    const { company } = userData || {};
    const { identityDocumentRequired } = company || {};
    return identityDocumentRequired;
  }

  getIdentityDocumentMenuItem() {
    const { m } = this.props;

    if (this.identityDocumentAllowed()) {
      return (
        <div className="scope-menu-body-link-container">
          <IndexLink
            to="/profile/identity-document"
            activeClassName="active primary-border"
          >
            {t(m, 'label.identity_card')}
            <IconRightArrow />
          </IndexLink>
        </div>
      );
    }
  }

  getNotificationsMenuItem() {
    const { m } = this.props;

    return (
      <div className="scope-menu-body-link-container">
        <IndexLink to="/profile/notifications" activeClassName="active primary-border">
          {t(m, 'profile.section.notifications')}
          <IconRightArrow />
        </IndexLink>
      </div>
    );
  }

  getSelfieMenuItem() {
    const { m } = this.props;

    if (this.identityDocumentAllowed()) {
      return (
        <div className="scope-menu-body-link-container">
          <IndexLink to="/profile/selfie" activeClassName="active primary-border">
            {t(m, 'profile.section.selfie')}
            <IconRightArrow />
          </IndexLink>
        </div>
      );
    }
  }

  getCustomFieldsMenuItem() {
    const { m, showMoreInfoPage } = this.props;

    if (showMoreInfoPage) {
      return (
        <div className="scope-menu-body-link-container">
          <IndexLink
            to="/profile/additional-info"
            activeClassName="active primary-border"
          >
            {t(m, 'profile.section.additional.info')}
            <IconRightArrow />
          </IndexLink>
        </div>
      );
    }
  }

  getStatusRow() {
    const { m, userData } = this.props;
    const { status, suspended } = userData;
    const _status = suspended ? 'profile.suspended' : status;

    return (
      <span className={classnames('scope-menu-header-status', this.getUserStatusClass())}>
        {getMessageOptions(m, keysMemberStatus, _status)}
      </span>
    );
  }

  getActiveAccountLabel() {
    return <FormattedMessage id="label.active.profile" />;
  }

  getActiveProfileRow() {
    return (
      <span className="scope-menu-header-active-profile">
        <strong>
          <FormattedMessage
            id="data.with.colon"
            values={{ data: this.getActiveAccountLabel() }}
          />
        </strong>
        <span> </span>
        <span className="scope-menu-header-company-name">
          {this.props.activeProfile.superCompanyName}
        </span>
      </span>
    );
  }

  navigateToSwitchPage() {
    browserHistory.push('/profile/switch');
  }

  getSwitchButtonRow() {
    return (
      <div className="scope-menu-header-switch-container">
        <button
          className="btn w-100 secondary-btn"
          disabled={isSwitchPage() || !this.props.profilesFetched}
          onClick={() => this.navigateToSwitchPage()}
        >
          <FormattedMessage id="label.switch.profile" />
        </button>
      </div>
    );
  }

  getPersonalInfoMenuItem() {
    const { m } = this.props;

    return (
      <div className="scope-menu-body-link-container">
        <IndexLink to="/profile/personal-info" activeClassName="active primary-border">
          {t(m, 'profile.section.personal.info')}
          <IconRightArrow />
        </IndexLink>
      </div>
    );
  }

  getPaymentInfoMenuItem() {
    const { m, contract } = this.props;
    const { privateCarSharing } = contract || {};

    if (privateCarSharing) {
      return (
        <div className="scope-menu-body-link-container">
          <IndexLink to="/profile/payment" activeClassName="active primary-border">
            {t(m, 'profile.section.payment.info')}
            <IconRightArrow />
          </IndexLink>
        </div>
      );
    }
  }

  getUserContainer() {
    const { userData, theme } = this.props;
    const { firstName, lastName } = userData;
    const { profile } = theme.icons || {};

    return (
      <div className="scope-menu-header-user-container">
        <img
          src={profile ? profile : profileCircle}
          alt={firstName + ' ' + lastName}
          className="scope-menu-header-icon"
        />
        <div className="scope-menu-header-text-container">
          <span className="scope-menu-header-user-name">
            {firstName} {lastName}
          </span>
          {this.getStatusRow()}
        </div>
      </div>
    );
  }

  getProfileContainer() {
    if (this.props.activeProfile) {
      return (
        <div className="scope-menu-header-profile-container">
          {this.getActiveProfileRow()}
          {this.getSwitchButtonRow()}
        </div>
      );
    }
  }

  getMenuHeader() {
    return (
      <div className="scope-menu-header">
        {this.getUserContainer()}
        {this.getProfileContainer()}
      </div>
    );
  }

  getProfileLanguageMenuItem() {
    return (
      <div className="scope-menu-body-link-container">
        <IndexLink to="/profile/language" activeClassName="active primary-border">
          {t(this.props.m, 'profile.section.language')}
          <IconRightArrow />
        </IndexLink>
      </div>
    );
  }

  getLegalInfoMenuItem() {
    return (
      <div className="scope-menu-body-link-container">
        <IndexLink to="/profile/legal-info" activeClassName="active primary-border">
          {t(this.props.m, 'profile.section.legal.info')}
          <IconRightArrow />
        </IndexLink>
      </div>
    );
  }

  render() {
    const { itemSelected } = this.props;

    return (
      <div className="profile-page">
        <div className={classnames('scope-menu', { active: !itemSelected })}>
          {this.getMenuHeader()}
          <div className="scope-menu-body">
            {this.getCredentialsMenuItem()}
            {this.getPersonalInfoMenuItem()}
            {this.getDriverLicenceMenuItem()}
            {this.getIdentityDocumentMenuItem()}
            {this.getSelfieMenuItem()}
            {this.getPaymentInfoMenuItem()}
            {this.getNotificationsMenuItem()}
            {this.getCustomFieldsMenuItem()}
            {this.getProfileLanguageMenuItem()}
            {this.getLegalInfoMenuItem()}
          </div>
        </div>
        <div className={classnames('scope-content', { active: itemSelected })}>
          {this.getContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    profilesFetched: userProfilesSelector(state),
    showMoreInfoPage: showMoreInfoPageSelector(state),
    customFields: profileCustomFieldsSelector(state),
    userData: userDataSelector(state),
    userId: userIdSelector(state),
    userCompanyId: userCompanyIdSelector(state),
    m: messagesSelector(state),
    activeProfile: activeProfileSelector(state),
    theme: currentThemeSelector(state),
    contract: userContractSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...UserActions,
      ...CustomFieldsActions
    },
    dispatch
  );
}

MyAccount = connect(mapStateToProps, mapDispatchToProps)(MyAccount);

export default (props) => {
  return <MyAccount {...props} itemSelected={!useMatch('/profile')} />;
};
