import { getAppObj } from './utils/utils';
import { configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import reducer from './reducers';
import { handleSessionExpired } from './actions/user';
import config from './constants/config';
import endpoints from './constants/endpoints';
import { getAppAuthTokens, getAppSsoTokens } from './utils/tokens';

const windowExist = typeof window !== 'undefined';

const client = axios.create({
  baseURL: config.getApiUrl(),
  responseType: 'json'
});

if (windowExist) getAppObj().axiosDefaults = client.defaults;

const store = configureStore({
  reducer,
  middleware: [promise, axiosMiddleware(client), thunk]
});

export default store;

// TODO: if a token expired call refresh endpoint instead of periodical refresh or refresh on load

client.interceptors.request.use((config) => {
  const token = config.headers['x-auth-token'];
  const refresh = config.headers['x-refresh-token'];

  if (refresh) {
    delete config.headers['x-auth-token'];
    return config;
  }
  if (token) return config;

  config.headers['x-auth-token'] = getAppAuthTokens().token || getAppSsoTokens().token;
  return config;
});

const excludedUrls = [
  endpoints.me,
  endpoints.refresh,
  endpoints.authenticate,
  endpoints.passwordReset
];

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const statusCode = error?.response?.status;

    if (statusCode === 403 || statusCode === 401) {
      const url = error.response.config.url.split(/[?#]/)[0];
      const exceptions = excludedUrls.includes(url);

      if (!exceptions) {
        store.dispatch(handleSessionExpired());
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
