import { Field } from 'redux-form';
import { profileTextField, showErrorInfo } from '../../forms/newFields';
import React from 'react';

export function TextFieldInner({ label, mandatory, ...props }) {
  return (
    <div className="scope-field-container">
      <div className="scope-field-label">
        {label}
        {mandatory && ' *'}
      </div>
      {profileTextField(props)}
      {showErrorInfo(props)}
    </div>
  );
}

export default function TextField({ name, ...props }) {
  return <Field name={name} component={TextFieldInner} {...props} />;
}

/* <Props>
   name,
   label,
   mandatory,
   disabled,
   placeholder
 */
