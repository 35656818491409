import {
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_FORM_SUBSCRIPTION
} from '../constants/backend';

import { safe } from '../utils/utils';

const defaultState = {
  memberFields: [],
  memberValues: [],
  bookingFields: [],
  bookingValues: []
};

export const reducerType = 'customFields';

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case `${reducerType}/GET_${CUSTOM_FIELD_FORM_SUBSCRIPTION}_FIELDS_SUCCESS`: {
      const data = action.payload.data;
      const { [CUSTOM_FIELD_FORM_SUBSCRIPTION]: memberFields } = data || {};

      state = {
        ...state,
        memberFields: memberFields || []
      };

      break;
    }

    case `${reducerType}/GET_${CUSTOM_FIELD_FORM_BOOKING}_FIELDS_SUCCESS`: {
      const data = action.payload.data;
      const { [CUSTOM_FIELD_FORM_BOOKING]: bookingFields } = data || {};

      state = {
        ...state,
        bookingFields: bookingFields || []
      };

      break;
    }

    case 'user/UPDATE_INFO_SUCCESS':
    case 'user/GET_INFO_SUCCESS': {
      state = {
        ...state,
        memberValues: safe(() => action.payload.data.memberCustomValues) || []
      };
      break;
    }

    default: {
      break;
    }
  }

  return state;
}
