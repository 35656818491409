export function getFileUploadUrl(mimeType) {
  return (dispatch) => {
    return dispatch({
      type: 'files/GET_UPLOAD_URL',
      payload: {
        request: {
          method: 'post',
          url: '/v3/files/upload-url',
          params: { mimeType }
        }
      }
    });
  };
}

export function getFileById(fileId) {
  return {
    type: 'files/GET_FILE_BY_ID',
    payload: {
      request: {
        method: 'get',
        url: `/v2/files/${fileId}`,
        headers: { 'Content-Type': 'application/json' }
      }
    }
  };
}
