import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { checkEmail } from '../../helpers';
import { renderPlaceholderTextField } from './fields';
import { fallbackFunc, safe, getMessage as t } from '../../utils/utils';
import Member from '../svg/Member';
import Lock from '../svg/Lock';
import classnames from 'classnames';
import { connect } from 'react-redux';

import {
  brandKeycloakSelector,
  currentBrandSelector,
  keycloakConfigSelector,
  messagesSelector
} from '../../helpers/selectors';

import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/popup';

const validate = (values, props) => {
  const errors = {};
  const { m } = props;

  const emailError = checkEmail(values.login, m);
  if (emailError) errors.login = emailError;

  if (!values.password) {
    errors.password = t(m, 'error.form.required');
  }

  return errors;
};

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.handleSsoButtonClick = () => {
      const { keycloakConfig, onDirectSso } = this.props;
      const { useBrandConfig } = keycloakConfig || {};

      if (useBrandConfig && onDirectSso) {
        onDirectSso(keycloakConfig);
      } else {
        this.props.popupStep_ExternalLogin_SetVisibility(true);
      }
    };
  }

  render() {
    const {
      handleSubmit,
      loginError,
      loginFetching,
      onFocus,
      keycloakConfig,
      currentBrand,
      brandKeycloak,
      m
    } = this.props;

    const colors = safe(() => currentBrand.theme.colors);
    const { useOnlySsoForLogin } = brandKeycloak || {};

    return (
      <div>
        <form className="form-content login-form" onSubmit={handleSubmit} noValidate>
          {!useOnlySsoForLogin && (
            <Field
              name="login"
              onFocus={onFocus}
              component={renderPlaceholderTextField}
              icon={<Member className="svg" color={colors.primary} />}
              placeholder={t(m, 'generic.email')}
              type="email"
            />
          )}
          {!useOnlySsoForLogin && (
            <Field
              name="password"
              onFocus={onFocus}
              component={renderPlaceholderTextField}
              placeholder={t(m, 'label.password')}
              icon={<Lock className="svg" color={colors.primary} />}
              type="password"
            />
          )}
          {loginError && (
            <div className="error-text text-danger text-center">
              <FormattedMessage id={loginError} />
            </div>
          )}
          {!(useOnlySsoForLogin && keycloakConfig) && (
            <div className="submit-wrapper">
              <button
                disabled={loginFetching ? 'disabled' : null}
                className="btn btn-md btn-accent primary-btn"
              >
                <FormattedMessage id="label.login" />
              </button>
            </div>
          )}
          {!useOnlySsoForLogin && keycloakConfig && (
            <div className="sep-container">
              <div className="sso-sep" />
              <span className="mid-sep">
                <FormattedMessage id="common_or" />
              </span>
              <div className="sso-sep" />
            </div>
          )}
          {keycloakConfig && (
            <div className="sso-container">
              <button
                type="button"
                className={classnames('sso-button', {
                  'secondary-btn': !useOnlySsoForLogin,
                  'primary-btn': useOnlySsoForLogin
                })}
                onClick={this.handleSsoButtonClick}
              >
                {keycloakConfig.buttonText}
              </button>
            </div>
          )}
          {!(useOnlySsoForLogin && keycloakConfig) && (
            <div className="text-center">
              <span
                className="forgot-password-label underline pointer"
                onClick={() => this.props.popupStep_ForgotPassword_SetVisibility(true)}
              >
                <FormattedMessage id="login.forgot_password" />
              </span>
            </div>
          )}
        </form>
      </div>
    );
  }
}

LoginForm.propTypes = {
  onDirectSso: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  loginError: PropTypes.string,
  loginFetching: PropTypes.bool
};

LoginForm.defaultProps = {
  onFocus: fallbackFunc
};

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    keycloakConfig: keycloakConfigSelector(state),
    currentBrand: currentBrandSelector(state),
    brandKeycloak: brandKeycloakSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions }, dispatch);
}

LoginForm = reduxForm({
  form: 'login',
  fields: ['login', 'password'],
  validate
})(LoginForm);

LoginForm = connect(mapStateToProps, mapDispatchToProps)(LoginForm);

export default LoginForm;
