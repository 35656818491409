import React, { Component } from 'react';
import {
  change,
  Field,
  reduxForm,
  getFormSyncErrors,
  getFormMeta,
  getFormValues
} from 'redux-form';
import pageIconSrc from '../../../assets/images/member-circle.svg';

import {
  profileAddressField,
  profileRadioGroup,
  profileTextField
} from '../../forms/newFields';

import { CIVILITY_MR, CIVILITY_MRS, MEMBER_STATUSES } from '../../../constants/backend';

import arrowLeftSrc from '../../../assets/images/arrow-left.svg';
import IndexLink from '../../partials/IndexLink';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  messagesSelector,
  userDataSelector,
  currentThemeSelector,
  isProfileIncompleteUserSelector,
  personalDetailsConfigSelector,
  personalDetailsSubscribeConfigSelector,
  acceptTermsInitialValuesSelector
} from '../../../helpers/selectors';
import { getMessage as t, safe } from '../../../utils/utils';
import classnames from 'classnames';
import { USER_FORM_NAMES } from '../../../constants/form';
import _forEach from 'lodash/forEach';
import TextField from '../fields/text';
import { scrollToErrorInfo } from '../../../helpers/scrollToError';
import DateField from '../fields/datePicker';
import { getLocationProp } from '../../partials/LocationInput/utils';
import { getErrorInfoExternal } from '../../../helpers/components';
import PhoneNumberField from '../../account/fields/phoneNumber';
import { checkPhoneNumber } from '../../../helpers';
import AcceptTermsForm from '../AcceptTermsForm';
import { compose } from 'redux';

const profileHonorificOptions = (m) => [
  {
    value: CIVILITY_MR,
    title: t(m, 'generic.mr')
  },
  {
    value: CIVILITY_MRS,
    title: t(m, 'generic.mrs')
  }
];

const { TO_COMPLETE, NOT_APPROVED, TO_REVIEW } = MEMBER_STATUSES;
const notApprovedStatuses = new Set([TO_COMPLETE, NOT_APPROVED, TO_REVIEW]);

const fieldEnabled = (userStatus) => {
  const notApprovedStatus = notApprovedStatuses.has(userStatus);
  return {
    civility: notApprovedStatus,
    lastName: notApprovedStatus,
    firstName: notApprovedStatus,
    address: true,
    city: true,
    postalCode: true,
    country: true,
    phoneNumber: true,
    birthDate: notApprovedStatus,
    login: false
  };
};

function isReadOnlyField(name, props) {
  const { isSubscribePage, userData, isProfileIncompleteUser } = props;
  const { status: userStatus } = userData || {};
  const subscribeCheck = !(isSubscribePage && !isProfileIncompleteUser);
  return subscribeCheck && !fieldEnabled(userStatus)[name];
}

function configSubscribeHide(name, props) {
  return (
    props.isSubscribePage && safe(() => props.brandCompanySubscribeConfig[name].hide)
  );
}

function configHide(name, props) {
  return !props.isSubscribePage && safe(() => props.brandCompanyConfig[name].hide);
}

function validate(values, props) {
  const { address, country, phoneNumber } = values || {};
  const { m } = props;
  const errors = {};

  const mandatoryFields = ['lastName', 'firstName', 'city', 'postalCode', 'birthDate'];

  _forEach(mandatoryFields, (fieldName) => {
    if (
      !values[fieldName] &&
      !isReadOnlyField(fieldName, props) &&
      !configSubscribeHide(fieldName, props) &&
      !configHide(fieldName, props)
    ) {
      errors[fieldName] = true;
    }
  });

  if (!getLocationProp(address, 'streetName')) errors.address = true;
  if (!getLocationProp(country, 'countryISO')) errors.country = true;

  errors.phoneNumber = checkPhoneNumber(phoneNumber, m);

  return errors;
}

class PersonalDetailsForm extends Component {
  constructor(props) {
    super(props);
    this.settings();
    this.bindFunctions();
  }

  settings() {
    this.addressSearchType = ['address'];
    this.countrySearchType = ['country'];
    this.maxDate = moment().subtract(18, 'years').toDate();
  }

  bindFunctions() {
    this.submitTermsAndParent = this.submitTermsAndParent.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.setAcceptTermsSubmit = this.setAcceptTermsSubmit.bind(this);
  }

  setAcceptTermsSubmit(submit) {
    this.termsSubmit = submit;
  }

  submitTermsAndParent(values) {
    this.termsSubmit((terms) => this.props.onSubmit(values, terms));
  }

  getSubmitAction() {
    const { handleSubmit: submit } = this.props;
    return this.termsSubmit ? submit(this.submitTermsAndParent) : submit;
  }

  handleAddressChange(address) {
    const { dispatch, form } = this.props;

    if (address) {
      const { country, postalCode, city, countryISO } = address;
      if (postalCode) dispatch(change(form, 'postalCode', postalCode));
      if (city) dispatch(change(form, 'city', city));
      if (country && countryISO) {
        dispatch(change(form, 'country', { value: country, countryISO }));
      }
    }
  }

  getSubmitButtonLabel() {
    const { isSubscribePage, m } = this.props;
    return isSubscribePage ? t(m, 'generic.next') : t(m, 'generic.save');
  }

  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  getEmailField() {
    const { m } = this.props;

    if (!this.props.isSubscribePage) {
      return (
        <div className="scope-field-container">
          <div className="scope-field-label">{t(m, 'generic.email')}</div>
          <Field
            name="login"
            disabled={isReadOnlyField('login', this.props)}
            placeholder={t(m, 'generic.email')}
            component={profileTextField}
          />
        </div>
      );
    }
  }

  showField(name) {
    const { fieldValues } = this.props;
    const { [name]: fieldValue } = fieldValues || {};

    return !(
      (!fieldValue && isReadOnlyField(name, this.props)) ||
      configSubscribeHide(name, this.props) ||
      configHide(name, this.props)
    );
  }

  getGenderAvatar() {
    const { fieldValues } = this.props;
    const { civility } = fieldValues || {};
    const { icons } = this.props.theme;
    const { avatarMen, avatarWomen } = icons || {};

    if (avatarMen) {
      if (civility === CIVILITY_MRS) return avatarWomen;
      else return avatarMen;
    }
    return pageIconSrc;
  }

  render() {
    const { userDataUpdating, m } = this.props;

    return (
      <form
        className="personal-details-page"
        onSubmit={this.getSubmitAction()}
        noValidate
      >
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={this.getGenderAvatar()}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'profile.section.personal.info')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-fields">
              {this.showField('civility') && (
                <div className="scope-field-container">
                  <div className="scope-field-label">{t(m, 'label.civility')}</div>
                  <Field
                    name="civility"
                    disabled={isReadOnlyField('civility', this.props)}
                    component={profileRadioGroup}
                    options={profileHonorificOptions(m)}
                  />
                  {getErrorInfoExternal('civility', this.props)}
                </div>
              )}
              {this.showField('firstName') && (
                <TextField
                  name="firstName"
                  disabled={isReadOnlyField('firstName', this.props)}
                  label={t(m, 'generic.first.name')}
                  placeholder={t(m, 'generic.first.name')}
                  mandatory
                />
              )}
              {this.showField('lastName') && (
                <TextField
                  name="lastName"
                  disabled={isReadOnlyField('lastName', this.props)}
                  label={t(m, 'generic.last.name')}
                  placeholder={t(m, 'generic.last.name')}
                  mandatory
                />
              )}
              {this.showField('login') && this.getEmailField()}
              {this.showField('address') && (
                <div className="scope-field-container">
                  <div className="scope-field-label">
                    {t(m, 'profile.label.street.field')} *
                  </div>
                  <Field
                    name="address"
                    searchType={this.addressSearchType}
                    disabled={isReadOnlyField('address', this.props)}
                    component={profileAddressField}
                    onChange={this.handleAddressChange}
                    placeholder={t(m, 'placeholder.search')}
                    isStreet
                  />
                  {getErrorInfoExternal('address', this.props)}
                </div>
              )}
              {this.showField('city') && (
                <TextField
                  name="city"
                  disabled={isReadOnlyField('city', this.props)}
                  label={t(m, 'generic.city')}
                  placeholder={t(m, 'generic.city')}
                  mandatory
                />
              )}
              {this.showField('postalCode') && (
                <TextField
                  name="postalCode"
                  disabled={isReadOnlyField('postalCode', this.props)}
                  label={t(m, 'generic.postal.code')}
                  placeholder={t(m, 'generic.postal.code')}
                  mandatory
                />
              )}
              {this.showField('country') && (
                <div className="scope-field-container">
                  <div className="scope-field-label">{t(m, 'generic.country')} *</div>
                  <Field
                    name="country"
                    disabled={isReadOnlyField('country', this.props)}
                    searchType={this.countrySearchType}
                    showLocationButton={false}
                    component={profileAddressField}
                    placeholder={t(m, 'placeholder.search')}
                    isValue
                  />
                  {getErrorInfoExternal('country', this.props)}
                </div>
              )}
              {this.showField('phoneNumber') && (
                <PhoneNumberField
                  name="phoneNumber"
                  label={t(m, 'generic.mobile.phone')}
                  mandatory
                />
              )}
              {this.showField('birthDate') && (
                <DateField
                  name="birthDate"
                  disabled={isReadOnlyField('birthDate', this.props)}
                  placeholder={t(m, 'label.birth.day')}
                  label={t(m, 'label.birth.day')}
                  maxDate={this.maxDate}
                  mandatory
                />
              )}
              {this.props.isCreationPage && (
                <AcceptTermsForm
                  isCreationPage
                  passFormSubmit={this.setAcceptTermsSubmit}
                  initialValues={this.props.acceptTermsInitialValues}
                />
              )}
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          <button
            type="submit"
            className={classnames('scope-submit-button', 'primary-btn', {
              'sub-class-disabled': userDataUpdating
            })}
          >
            {this.getSubmitButtonLabel()}
          </button>
        </div>
      </form>
    );
  }
}

PersonalDetailsForm.defaultProps = {
  isSubscribePage: false, // old register
  isCreationPage: false // new register
};

PersonalDetailsForm.propTypes = {
  isSubscribePage: PropTypes.bool
};

const mapStateToProps = (state, props) => {
  const { userDataUpdating } = state.user;
  const data = {
    userDataUpdating,
    m: messagesSelector(state),
    userData: userDataSelector(state),
    brandCompanyConfig: personalDetailsConfigSelector(state),
    brandCompanySubscribeConfig: personalDetailsSubscribeConfigSelector(state),
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    submitErrors: getFormSyncErrors(USER_FORM_NAMES.PERSONAL_DETAILS)(state), // getErrorInfoExternal
    fieldsMeta: getFormMeta(USER_FORM_NAMES.PERSONAL_DETAILS)(state), // getErrorInfoExternal
    fieldValues: getFormValues(USER_FORM_NAMES.PERSONAL_DETAILS)(state),
    theme: currentThemeSelector(state)
  };
  if (props.isCreationPage) {
    data.acceptTermsInitialValues = acceptTermsInitialValuesSelector(state);
  }
  return data;
};

export default compose(
  reduxForm({
    form: USER_FORM_NAMES.PERSONAL_DETAILS,
    fields: [
      'phoneNumber',
      'civility',
      'lastName',
      'firstName',
      'address',
      'city',
      'postalCode',
      'country',
      'birthDate',
      'login'
    ],
    validate,
    enableReinitialize: true,
    destroyOnUnmount: false,
    onSubmitFail: scrollToErrorInfo
  }),
  connect(mapStateToProps)
)(PersonalDetailsForm);
