import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import LoginForm from '../forms/LoginForm';
import SubscribeForm from '../forms/SubscribeForm';
import { ReactSVG } from 'react-svg';
import * as UserActions from '../../actions/user';
import * as PopupActions from '../../actions/popup';
import * as KeycloakActions from '../../actions/keycloak';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';

import * as brandActions from '../../actions/brand';
import { removeModalQueryParam } from '../../helpers';
import { getMessage as t, navigateToCreationPage, safe } from '../../utils/utils';

import {
  brandKeycloakSelector,
  currentBrandSelector,
  keycloakConfigSelector,
  messagesSelector,
  parseKeycloakConfig
} from '../../helpers/selectors';

import { initKeycloak, KEYCLOAK_STATUS } from '../../utils/keycloak';
import ExternalLoginForm from '../forms/ExternalLoginForm';
import { addErrorMsg } from '../../utils/flashMessage/creator';

export class LoginPopup extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.handleLoginSubmit = (data) => {
      this.props.login(data);
    };

    this.handleForgotPasswordSubmit = (data) => {
      this.props.sendForgotPasswordEmail(data);
      this.props.popupStep_ForgotPassword_SetVisibility(true);
    };

    this.goBack = () => {
      this.props.popupStep_Subscribe_SetVisibility(false);
      this.props.popupStep_ForgotPassword_SetVisibility(false);
      this.props.popupStep_ExternalLogin_SetVisibility(false);
      this.props.popupStep_ResetPasswordEmailSent_SetVisibility(false);
    };

    this.handleRegisterButton = () => {
      const {
        currentBrand: { mapCountries = {} }
      } = this.props;

      const countriesKeys = Object.keys(mapCountries);

      if (countriesKeys.length === 1) {
        const { companyId } = mapCountries[countriesKeys[0]] || {};

        if (companyId) {
          this.props.popup_Login_SetVisibility(false);
          navigateToCreationPage(companyId);
          return;
        }
      }

      this.props.popupStep_ExternalLogin_SetVisibility(false);
      this.props.popupStep_Subscribe_SetVisibility(true);
    };

    this.handleInviteSubmit = (data) => {
      this.props.checkInviteCode(data.inviteCode).then((data) => {
        if (data.type === 'user/CHECK_INVITE_CODE_SUCCESS') {
          const companyId = data.payload.data.id;
          this.props.closeAllPopups();
          navigateToCreationPage(companyId);
        }
      });
    };

    this.handleExternalEmailCheck = (data) => {
      this.props.getRemoteKeycloakConfig(data.email).then((data) => {
        if (data.type === 'keycloak/GET_REMOTE_CONFIG_SUCCESS') {
          const remoteConfig = data.payload.data;

          const parsedConfig = parseKeycloakConfig(
            { ...this.props.brandKeycloak, useBrandConfig: false },
            remoteConfig
          );

          const { initSettings } = parsedConfig || {};

          if (!initSettings) {
            this.props.setExternalLoginFetching(false);
            addErrorMsg(t(this.props.m, 'label.generic.error'));
            return;
          }

          initKeycloak(initSettings, (status, data) => {
            this.props.setExternalLoginFetching(false);

            if (status === KEYCLOAK_STATUS.FAIL) {
              let error = safe(() => data.error);
              if (!error) error = safe(() => JSON.stringify(data));
              addErrorMsg('Keycloak: ' + error);
            } else {
              this.props.handleKeycloakLogin(parsedConfig);
            }
          });
        }
      });
    };
  }

  componentWillUnmount() {
    this.props.removeLoginErrors();
    removeModalQueryParam();
  }

  renderBrandContent() {
    const { currentBrand } = this.props;

    return (
      <div className="branded text-center">
        <img src={currentBrand.theme.logoLogin} alt={currentBrand.productName} />
        <div className="tagline">{currentBrand.productName}</div>
      </div>
    );
  }

  headerText() {
    const { popup } = this.props;

    if (popup.step_ForgotPassword_IsVisible) {
      return (
        <h2>
          <span onClick={this.goBack}>
            <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
          </span>
          <FormattedMessage id="label.password_forgot" />
        </h2>
      );
    }

    if (popup.step_ExternalLogin_IsVisible) {
      return (
        <h2>
          <span onClick={this.goBack}>
            <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
          </span>
          <FormattedMessage id="sso.login.title" />
        </h2>
      );
    }

    if (popup.step_Subscribe_IsVisible) {
      const title = popup.step_AddAccount_IsVisible
        ? 'label.add.new.profile'
        : 'home.welcome_solo';

      return (
        <h2>
          {!popup.step_AddAccount_IsVisible && (
            <span onClick={this.goBack}>
              <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
            </span>
          )}
          <FormattedMessage id={title} />
        </h2>
      );
    }

    return this.renderBrandContent();
  }

  getContent() {
    const { popup } = this.props;

    if (popup.step_ResetPasswordEmailSent_IsVisible) {
      return (
        <div className="forgot-pass-desc text-center pt-30 pb-50">
          <FormattedMessage id="forgot_password.confirmation" />
        </div>
      );
    }

    if (popup.step_ForgotPassword_IsVisible) {
      return (
        <ForgotPasswordForm
          onSubmit={this.handleForgotPasswordSubmit}
          forgotPasswordError={this.props.user.forgotPasswordError}
          forgotPasswordFetching={this.props.user.forgotPasswordFetching}
        />
      );
    }

    if (popup.step_ExternalLogin_IsVisible) {
      return (
        <ExternalLoginForm
          onFocus={this.props.removeLoginErrors}
          onSubmit={this.handleExternalEmailCheck}
        />
      );
    }

    if (popup.step_Subscribe_IsVisible) {
      return (
        <SubscribeForm
          onSubmit={this.handleInviteSubmit}
          onRetry={this.props.removeInviteError}
          closeModal={this.goBack}
        />
      );
    }

    return (
      <LoginForm
        onSubmit={this.handleLoginSubmit}
        onDirectSso={this.props.handleKeycloakLogin}
        onFocus={this.props.removeLoginErrors}
        loginError={this.props.user.loginError}
        loginFetching={this.props.user.loginFetching}
      />
    );
  }

  footerLink() {
    const { popup, currentBrand } = this.props;
    const { restrictedAccess } = currentBrand || {};

    if (popup.step_AddAccount_IsVisible) return;

    if (popup.step_Subscribe_IsVisible || popup.step_ForgotPassword_IsVisible) {
      return (
        <div className="footer text-center">
          <hr />
          <FormattedMessage id="label.member_already" />{' '}
          <span onClick={this.goBack} className="text-center secondary pointer">
            <FormattedMessage id="label.login" />
          </span>
        </div>
      );
    }

    if (!restrictedAccess) {
      return (
        <div className="footer text-center">
          <hr />
          <FormattedMessage id="label.no_account" />{' '}
          <span
            className="text-center secondary pointer"
            onClick={this.handleRegisterButton}
          >
            <FormattedMessage id="login.register" />
          </span>
        </div>
      );
    }
  }

  render() {
    return (
      <div>
        {this.headerText()}
        {this.getContent()}
        {this.footerLink()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    keycloakConfig: keycloakConfigSelector(state),
    brandKeycloak: brandKeycloakSelector(state),
    currentBrand: currentBrandSelector(state),
    m: messagesSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...UserActions,
      ...PopupActions,
      ...brandActions,
      ...KeycloakActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPopup);
