import { STORAGE_KEY } from '../constants/generic';
import { localStorageGetJson } from '../utils/utils';

const defaultState = {
  userImpersonated: localStorageGetJson(STORAGE_KEY.IMPERSONATE)
};

export default function reducer(state = { ...defaultState }, action) {
  switch (action.type) {
    case 'IMPERSONATE/SET_STATUS': {
      localStorage.setItem(STORAGE_KEY.IMPERSONATE, action.payload);
      state = { ...state, userImpersonated: action.payload };
      break;
    }

    case 'user/LOGOUT':
    case 'user/LOGIN_SUCCESS':
    case 'user/FINAL_ACCESS_DENIED':
    case 'keycloak/VERIFY_USER_SUCCESS': {
      localStorage.removeItem(STORAGE_KEY.IMPERSONATE);
      state = { ...state, userImpersonated: false };
      break;
    }

    default: {
      break;
    }
  }

  return state;
}
