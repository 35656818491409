import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  formValueSelector,
  Field,
  reduxForm,
  getFormSyncErrors,
  getFormMeta
} from 'redux-form';
import pageIconSrc from '../../../src/assets/images/driving-licence-circle.svg';
import { profileTextField, roundSwitch } from '../forms/newFields';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import IndexLink from '../partials/IndexLink';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';

import {
  messagesSelector,
  currentThemeSelector,
  drivingLicenceStatusSelector
} from '../../helpers/selectors';

import { getMessage as t, trimFields } from '../../utils/utils';
import classnames from 'classnames';
import { USER_FORM_NAMES } from '../../constants/form';
import { DOCUMENT_STATUS } from '../../constants/backend';
import _forEach from 'lodash/forEach';
import TextField from './fields/text';
import DateField from './fields/datePicker';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import { documentPresent, getErrorInfoExternal } from '../../helpers/components';
import FileField from './fields/file';

function validate(values = {}, props = {}) {
  const errors = {};
  const mandatoryFields = ['licenceNumber', 'deliveranceDate'];

  values = trimFields({ ...values });

  _forEach(mandatoryFields, (field) => {
    if (!values[field]) errors[field] = true;
  });

  if (!values.expirationDateNotPresent && !values.expirationDate) {
    errors.expirationDate = true;
  }

  if (!values.cityDeliverance) {
    errors.cityDeliverance = true;
  }

  if (!props.hideFileUpload) {
    if (!documentPresent(values.documentFront)) errors.documentFront = true;
    if (!documentPresent(values.documentBack)) errors.documentBack = true;
  }

  return errors;
}

class DrivingLicence extends Component {
  getSubmitButtonLabel() {
    const { isSubscribePage, m } = this.props;
    return isSubscribePage ? t(m, 'generic.next') : t(m, 'generic.save');
  }

  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  render() {
    const {
      fieldValues: { expirationDateNotPresent },
      userDataUpdating,
      drivingLicenceStatus,
      isSubscribePage,
      hideFileUpload,
      handleSubmit,
      theme,
      m
    } = this.props;

    const { drivingLicence } = theme.icons || {};
    const drivingLicenceApproved = drivingLicenceStatus === DOCUMENT_STATUS.APPROVED;
    const readOnlyForm = !isSubscribePage && drivingLicenceApproved;

    return (
      <form className="driving-licence-page" onSubmit={handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={drivingLicence ? drivingLicence : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'label.driving_licence')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-fields">
              <TextField
                name="licenceNumber"
                label={t(m, 'label.licence_number')}
                placeholder={t(m, 'label.licence_number')}
                mandatory
                readOnly={readOnlyForm}
              />
              <DateField
                name="deliveranceDate"
                placeholder={t(m, 'document.issue.date')}
                label={t(m, 'document.issue.date')}
                mandatory
                disabled={readOnlyForm}
              />
              <div className="scope-field-container">
                <div className="scope-field-label">{t(m, 'label.delivery_place')} *</div>
                <Field
                  name="cityDeliverance"
                  placeholder={t(m, 'label.delivery_place')}
                  component={profileTextField}
                  readOnly={readOnlyForm}
                />
                {getErrorInfoExternal('cityDeliverance', this.props)}
              </div>
              {!expirationDateNotPresent && (
                <DateField
                  name="expirationDate"
                  placeholder={t(m, 'label.expiration_date')}
                  label={t(m, 'label.expiration_date')}
                  mandatory
                  disabled={readOnlyForm}
                />
              )}
              {!readOnlyForm && (
                <div className="scope-field-container">
                  <div className="scope-boolean-container">
                    <span className="scope-boolean-info">
                      {t(m, 'profile.label.expiration.date.status')}
                    </span>
                    <Field name="expirationDateNotPresent" component={roundSwitch} />
                  </div>
                </div>
              )}
              {hideFileUpload && (
                <div className="scope-field-container">
                  <div className="scope-info-text">
                    {t(m, 'label.file.upload.only.mobile')}
                  </div>
                </div>
              )}
              {!hideFileUpload && (
                <FileField
                  name="documentFront"
                  label={t(m, 'label.driving_licence_front')}
                  mandatory
                  disabled={readOnlyForm}
                />
              )}
              {!hideFileUpload && (
                <FileField
                  name="documentBack"
                  label={t(m, 'label.driving_licence_back')}
                  mandatory
                  disabled={readOnlyForm}
                />
              )}
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          {!readOnlyForm && (
            <button
              type="submit"
              className={classnames('scope-submit-button', 'primary-btn', {
                'sub-class-disabled': userDataUpdating
              })}
            >
              {this.getSubmitButtonLabel()}
            </button>
          )}
        </div>
      </form>
    );
  }
}

DrivingLicence.defaultProps = {
  isSubscribePage: false
};

DrivingLicence.propTypes = {
  isSubscribePage: PropTypes.bool,
  hideFileUpload: PropTypes.bool
};

const selector = formValueSelector('drivingLicence');

const formComponent = reduxForm({
  form: USER_FORM_NAMES.DRIVING_LICENCE,
  validate,
  onSubmitFail: scrollToErrorInfo,
  enableReinitialize: true,
  destroyOnUnmount: false,
  fields: [
    'licenceNumber',
    'deliveranceDate',
    'cityDeliverance',
    'expirationDateNotPresent',
    'expirationDate',
    'documentFront',
    'documentBack'
  ]
})(DrivingLicence);

// "submitErrors" and "fieldsMeta" are required for "getErrorInfo"

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;

  return {
    m: messagesSelector(state),
    userDataUpdating,
    drivingLicenceStatus: drivingLicenceStatusSelector(state),
    submitErrors: getFormSyncErrors(USER_FORM_NAMES.DRIVING_LICENCE)(state),
    fieldsMeta: getFormMeta(USER_FORM_NAMES.DRIVING_LICENCE)(state),
    theme: currentThemeSelector(state),
    fieldValues: {
      expirationDateNotPresent: selector(state, 'expirationDateNotPresent')
    }
  };
};

export default connect(mapStateToProps)(formComponent);
