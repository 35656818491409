import React, { Component } from 'react';
import { Slider, Rail, Handles, Tracks, Ticks } from 'react-compound-slider';
import SliderHandle from './Glideslider.Handle';
import SliderTrack from './Glideslider.Track';
import SliderTick from './Glideslider.Tick';

class Glideslider extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  setCallbacks() {
    this.onUpdate = (update) => {
      this.setState({ update });
      this.props.onUpdate && this.props.onUpdate(update[0]);
    };

    this.onChange = (values) => {
      this.setState({ values });
      this.props.onChange && this.props.onChange(values[0]);
    };
  }

  initState() {
    this.state = {
      values: [this.props.defaultValue],
      defaultValue: this.props.defaultValue
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.defaultValue !== state.defaultValue) {
      return {
        values: [props.defaultValue],
        defaultValue: props.defaultValue
      };
    }
    return null;
  }

  render() {
    const { values } = this.state;
    const { min, max, step, onFocus, onBlur, ...restProps } = this.props;

    return (
      <div>
        <Slider
          style={{
            position: 'relative',
            width: '100%',
            margin: '0 auto',
            height: 40
          }}
          mode={1}
          step={step}
          domain={[min, max]}
          onUpdate={this.onUpdate}
          onChange={this.onChange}
          values={values}
          {...restProps}
        >
          <Handles>
            {({ handles, getHandleProps }) => (
              <div className="slider-handles">
                {handles.map((handle) => (
                  <SliderHandle
                    key={handle.id}
                    handle={handle}
                    color={this.props.color}
                    domain={[this.props.min, this.props.max]}
                    getHandleProps={getHandleProps}
                    onFocus={onFocus}
                    onBlur={onBlur}
                  />
                ))}
              </div>
            )}
          </Handles>
          <Tracks right={false}>
            {({ tracks, getTrackProps }) => (
              <div>
                {tracks.map(({ id, source, target }) => (
                  <SliderTrack
                    key={id}
                    source={source}
                    target={target}
                    color={this.props.color}
                    getTrackProps={getTrackProps}
                  />
                ))}
              </div>
            )}
          </Tracks>
          <Rail>
            {({ getRailProps }) => <div className="rail" {...getRailProps()} />}
          </Rail>
          <Ticks values={[2, 4, 6, 8]}>
            {({ ticks }) => (
              <div>
                <svg
                  id="canvas-steps"
                  viewBox="0 0 1000 80"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {ticks.map((tick) => (
                    <SliderTick key={tick.id} tick={tick} count={ticks.length} />
                  ))}
                </svg>
              </div>
            )}
          </Ticks>
        </Slider>
      </div>
    );
  }
}

export default Glideslider;
