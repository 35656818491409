import React from 'react';
import { ReactSVG } from 'react-svg';
import en from './../assets/flags/rect/en.svg';
import fr from './../assets/flags/rect/fr.svg';
import es from './../assets/flags/rect/es.svg';
import it from './../assets/flags/rect/it.svg';
import pt from './../assets/flags/rect/pt.svg';
import de from './../assets/flags/rect/de.svg';
import da from './../assets/flags/rect/da.svg';
import nl from './../assets/flags/rect/nl.svg';
import pl from './../assets/flags/rect/pl.svg';
import sv from './../assets/flags/rect/sv.svg';
import cs from './../assets/flags/rect/cs.svg';
import pt_BR from './../assets/flags/rect/pt_BR.svg';
import sk from './../assets/flags/rect/sk.svg';

/*
new flag with same ratio 3x2 can be found here
https://catamphetamine.gitlab.io/country-flag-icons/3x2/

*/

export const allLanguages = {
  en: {
    text: 'English',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={en} />
  },
  fr: {
    text: 'Français',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={fr} />
  },
  es: {
    text: 'Español',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={es} />
  },
  it: {
    text: 'Italiano',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={it} />
  },
  pt: {
    text: 'Português',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={pt} />
  },
  de: {
    text: 'Deutsche',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={de} />
  },
  da: {
    text: 'Dansk',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={da} />
  },
  nl: {
    text: 'Nederlands',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={nl} />
  },
  pl: {
    text: 'Polski',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={pl} />
  },
  sv: {
    text: 'Svenska',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={sv} />
  },
  cs: {
    text: 'Český',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={cs} />
  },
  pt_BR: {
    text: 'Portugues do Brasil',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={pt_BR} />
  },
  sk: {
    text: 'Slovák',
    flag: <ReactSVG wrapper="span" className="react-svg flag" src={sk} />
  }
};
