import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Fuse from 'fuse.js';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';

import { localStorageGetJson, removeMultiSpace, safe } from '../../../utils/utils';

import { forwardGeocoding, reverseGeocoding } from '../../../helpers/api';
import { formatGeocodingApiParams } from '../../../helpers';
import { createElement } from '../../../helpers/components';
import { getMsg } from '../../../utils/IntlGlobalProvider';
import { STORAGE_KEY } from '../../../constants/generic';

import config from '../../../constants/config';
import closeIcon from '../../../assets/images/close.svg';
import addressIcon from './icons/address';
import cityIcon from './icons/city';
import countryIcon from './icons/country';
import interestIcon from './icons/interest';
import siteIcon from './icons/site';
import locationIcon from './icons/location';
import historyIcon from './icons/history';
import memoizeOne from 'memoize-one';

const alwaysOpen = false;

const maxOnlyFixtures = 5;
const maxOnlyHistory = 5;
const maxMergedResults = 5;
const maxMergedFixtures = 2;
const maxHistoryItems = 2;

const spinnerClass = 'spinner-active';

const fixturesFuseOptions = {
  keys: ['name'],
  threshold: 0.4
};

const defaultAcConfig = {
  debounce: 300,
  wrapper: false
};

const mergeFuseKeys = ({ item }) => ({ ...item, _searchKey: item.name });
const mergeFixtureKeys = (item) => ({ ...item, _searchKey: item.name });

const createIcon = (data) => {
  const { center, name, place_type = '', _locationButton, _searchHistory } = data;

  if (_searchHistory) {
    const icon = createElement('span', 'mc-suggestion-icon');
    icon.innerHTML = historyIcon;
    return [icon];
  }

  if (center) {
    const icon = createElement('span', 'mc-suggestion-icon');

    if (place_type.includes('country')) icon.innerHTML = countryIcon;
    else if (place_type.includes('poi')) icon.innerHTML = interestIcon;
    else if (place_type.includes('place')) icon.innerHTML = cityIcon;
    else icon.innerHTML = addressIcon;

    return [icon];
  }

  if (name) {
    const icon = createElement('span', 'mc-fixture-icon');
    icon.innerHTML = siteIcon;
    return [icon];
  }

  if (_locationButton) {
    const icon = createElement('span', 'mc-suggestion-icon', 'current-location-icon');
    const spinner = createElement('span', 'mc-suggestion-icon', 'spinner-container');
    const inner = createElement('span', 'location-spinner');

    spinner.appendChild(inner);
    icon.innerHTML = locationIcon;

    return [icon, spinner];
  }
};

const createName = (nameData, { _locationButton }) => {
  const classList = ['mc-name'];
  if (_locationButton) classList.push('location-name');

  const name = createElement('span', ...classList);
  name.innerHTML = nameData;
  return name;
};

const mergeAddressKeys = (item) => {
  const { matching_place_name, place_name } = item;
  return { ...item, _searchKey: matching_place_name || place_name };
};

const getDatasetChildren = (childNodes, data) => {
  let indexes = { address: [], fixture: [], location: [], history: [] };

  data.matches.forEach(({ value }, i) => {
    if (value._searchHistory) {
      indexes.history.push(childNodes[i]);
    } else if (value.center) {
      indexes.address.push(childNodes[i]);
    } else if (value.name) {
      indexes.fixture.push(childNodes[i]);
    } else if (value._locationButton) {
      indexes.location.push(childNodes[i]);
    }
  });

  return indexes;
};

const appendDataset = (list, nodes, titleKey) => {
  const dataset = createElement('div', 'mc-dataset');
  const suggestions = createElement('div', 'mc-suggestions');

  if (titleKey) {
    const header = createElement('div', 'mc-header');
    header.textContent = getMsg(titleKey);
    dataset.appendChild(header);
  }

  suggestions.append(...nodes);
  dataset.appendChild(suggestions);
  list.appendChild(dataset);

  return dataset;
};

const addMapboxFooter = (dataset) => {
  const footer = createElement('div', 'mc-footer');
  const url = createElement('a');

  url.href = config.mapboxUrl;
  url.textContent = 'Powered by Mapbox';
  url.setAttribute('target', '_blank');
  footer.appendChild(url);
  dataset.appendChild(footer);
};

class MapboxComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.setCallbacks();
    this.setAcConfig();
  }

  componentDidMount() {
    this.initAc();
    this.setEvents();
    this.forwardSetter();
  }

  componentWillUnmount() {
    this.removeEvents();
  }

  setAcConfig() {
    this.acConfig = {
      selector: this.getInputRef,
      data: {
        src: this.getAcResults,
        keys: ['_searchKey']
      },
      trigger: this.parseTrigger,
      searchEngine: this.customAcEngine,
      resultsList: {
        element: this.parseResults,
        maxResults: 200,
        class: 'mc-dropdown-menu'
      },
      resultItem: {
        class: 'mc-suggestion',
        selected: 'mc-cursor',
        element: this.parseResult
      },
      ...defaultAcConfig
    };
  }

  initAc() {
    this.acInstance = new window.autoComplete(this.acConfig);
  }

  forwardSetter() {
    const { getValueSetter } = this.props;
    if (getValueSetter) getValueSetter(this.updateInputValue);
  }

  setEvents() {
    const ref = this.getInputRef();

    if (ref) {
      ref.addEventListener('selection', this.handleSelect, { capture: true });
      ref.addEventListener('close', this.handleClose, { capture: true });
    }
  }

  removeEvents() {
    const ref = this.getInputRef();

    if (ref) {
      ref.removeEventListener('selection', this.handleSelect, { capture: true });
      ref.addEventListener('close', this.handleClose, { capture: true });
    }
  }

  executeTimedAction(action, time) {
    if (time) setTimeout(action, time);
    else action();
  }

  changeClass(newClass, toAdd, timeout) {
    const isSet = this.getContainerRef().classList.contains(newClass);

    if (toAdd) {
      if (!isSet) {
        this.executeTimedAction(() => {
          this.getContainerRef().classList.add(newClass);
        }, timeout);
      }
    } else {
      if (isSet) {
        this.executeTimedAction(() => {
          this.getContainerRef().classList.remove(newClass);
        }, timeout);
      }
    }
  }

  handleLocationButton() {
    this.changeClass(spinnerClass, true);

    this.locationButtonSelected = true;
    this.gettingCurrentLocation = true;

    navigator.geolocation.getCurrentPosition((response) => {
      const coords = response.coords;
      const lat = coords.latitude.toFixed(6);
      const lng = coords.longitude.toFixed(6);

      const payload = formatGeocodingApiParams({
        types: this.props.searchType,
        language: this.props.language
      });

      reverseGeocoding([lat, lng], payload).then((result) => {
        if (this.gettingCurrentLocation) {
          const suggestion = safe(() => result.data.features[0]);
          const { value: input } = this.getInputRef();

          if (suggestion) {
            this.updateInputValue(suggestion.place_name || input);
            this.selectCallback(suggestion);
          }
        }
        this.stopLocationUpdate();
      }, this.stopLocationUpdate);
    }, this.stopLocationUpdate);
  }

  getSavedHistory() {
    return localStorageGetJson(STORAGE_KEY.SEARCH_HISTORY);
  }

  saveSearchHistory(data) {
    localStorage.setItem(STORAGE_KEY.SEARCH_HISTORY, JSON.stringify(data));
  }

  saveHistoryItem(suggestion) {
    let [items = {}, ids = []] = this.getSavedHistory() || [];

    suggestion = { ...suggestion, _searchHistory: true };
    items[suggestion.id] = suggestion;
    ids = ids.filter((e) => e !== suggestion.id);
    ids.unshift(suggestion.id);

    if (ids.length > maxOnlyHistory) {
      const removed = ids.pop();
      delete items[removed];
    }

    this.saveSearchHistory([items, ids]);
  }

  selectCallback(suggestion) {
    const { onSelect } = this.props;

    if (onSelect) {
      if (this.props.searchHistoryEnabled) {
        this.saveHistoryItem(suggestion);
      }
      onSelect(suggestion);
    }
  }

  getLocationButton() {
    return {
      _locationButton: true,
      _searchKey: getMsg('search.get.location.button')
    };
  }

  getLocationItems(results) {
    if (this.props.showLocationButton) {
      results.push(this.getLocationButton());
    }
  }

  getHistoryItems(results, limit) {
    if (this.props.searchHistoryEnabled) {
      const [items = {}, ids = []] = this.getSavedHistory() || [];
      ids.slice(0, limit).forEach((id) => results.push(items[id]));
    }
  }

  getFixtureItems(results) {
    if (this.getFixturesFuse()) {
      const fixtures = safe(() => this.props.fixtures.map(mergeFixtureKeys)) || [];
      results.push(...fixtures);
    }
  }

  handleStaticResults(resolve) {
    const results = [];

    this.getLocationItems(results);
    this.getFixtureItems(results);
    this.getHistoryItems(results, maxHistoryItems);

    return resolve(results);
  }

  setCallbacks() {
    this.setInputRef = (e) => (this.inputRef = e);
    this.getInputRef = () => this.inputRef;
    this.setContainerRef = (e) => (this.containerRef = e);
    this.getContainerRef = () => this.containerRef;

    const getFixturesFuseMem = memoizeOne((fixtures) => {
      return safe(() => fixtures.length && new Fuse(fixtures, fixturesFuseOptions));
    });
    this.getFixturesFuse = () => getFixturesFuseMem(this.props.fixtures);

    this.handleIconCloseMouseDown = () => {
      this.iconCloseMouseDown = true;
    };

    this.handleIconCloseMouseUp = () => {
      this.iconCloseMouseDown = false;
    };

    this.handleInputBlur = (event) => {
      if (this.iconCloseMouseDown) {
        event.stopPropagation();
        event.preventDefault();
      }
    };

    this.stopLocationUpdate = () => {
      this.gettingCurrentLocation = false;
      this.changeClass(spinnerClass, false);
      this.getInputRef().blur();
    };

    this.handleOnChange = (event) => {
      const { value } = event.target;
      const { onInputChange } = this.props;

      this.handleInputButton(value);
      if (onInputChange) onInputChange(value);
    };

    this.handleClose = () => {
      if (this.locationButtonSelected) {
        this.locationButtonSelected = false;
        this.acInstance.open();
      }

      if (alwaysOpen) {
        this.acInstance.open();
      }
    };

    this.handleSelect = (event) => {
      const { value = {} } = safe(() => event.detail.selection) || {};
      const { value: input } = this.getInputRef();

      if (this.gettingCurrentLocation) {
        return (this.locationButtonSelected = true);
      }

      if (value._locationButton) {
        return this.handleLocationButton();
      }

      this.updateInputValue(value.name || value.place_name || input);
      this.selectCallback(value);
    };

    this.handleOnFocus = () => {
      const { value } = this.getInputRef() || {};
      const { query, results = [] } = safe(() => this.acInstance.feedback) || {};

      if (!removeMultiSpace(value)) {
        return this.acInstance.start();
      }

      if (query === value) {
        if (results.length) this.acInstance.open();
      } else {
        this.acInstance.start(value);
      }
    };

    this.handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        this.updateInputValue(this.props.inputValue);
        this.getInputRef().blur();
      }
    };

    this.updateInputValue = (value) => {
      this.handleInputButton(value);
      safe(() => (this.getInputRef().value = value));
      safe(() => this.props.onInputChange(value));
    };

    this.parseTrigger = (query) => {
      return !!(query.trim().length || this.getFixturesFuse());
    };

    this.parseResults = (list, data = {}) => {
      const datasetChildren = getDatasetChildren(list.childNodes, data);

      if (datasetChildren.location.length) {
        appendDataset(list, datasetChildren.location);
      }

      if (datasetChildren.fixture.length) {
        appendDataset(list, datasetChildren.fixture, 'generic.location.fixture.label');
      }

      if (datasetChildren.history.length) {
        appendDataset(list, datasetChildren.history, 'generic.location.history.label');
      }

      if (datasetChildren.address.length) {
        addMapboxFooter(
          appendDataset(list, datasetChildren.address, 'search.address.result.title')
        );
      }
    };

    this.parseResult = (item, { match, value }) => {
      const [nameData, ...addressData] = match.split(',');
      const { formattedAddress: siteDetails } = value.address || {};
      const details = siteDetails || safe(() => addressData.join(','));
      const children = [];

      children.push(...createIcon(value));
      children.push(createName(nameData, value));

      if (details) {
        const address = createElement('span', 'mc-address');
        address.innerHTML = details;
        children.push(address);
      }

      item.replaceChildren(...children);
    };

    this.getAcResults = (query) => {
      return new Promise((resolve) => {
        const { showLocationButton } = this.props;

        query = removeMultiSpace(query);
        if (!query) return this.handleStaticResults(resolve);

        const payload = formatGeocodingApiParams({
          types: this.props.searchType,
          language: this.props.language,
          proximity: 'ip'
        });

        forwardGeocoding(query, payload).then(
          (resp) => {
            const fixturesFuse = this.getFixturesFuse();
            let { features = [] } = safe(() => resp.data) || {};

            features = features.map(mergeAddressKeys);

            if (fixturesFuse) {
              const limit = features.length ? maxMergedFixtures : maxOnlyFixtures;

              const fixtures = fixturesFuse
                .search(query)
                .slice(0, limit)
                .map(mergeFuseKeys);

              fixtures.push(...features);
              features = fixtures.slice(0, maxMergedResults);
            }

            if (showLocationButton) {
              features.unshift(this.getLocationButton());
            }

            if (
              !features.length ||
              (features.length === 1 && features[0]._locationButton)
            ) {
              this.getHistoryItems(features, maxOnlyHistory);
            }

            if (document.activeElement !== this.getInputRef()) {
              return resolve([]);
            }

            return resolve(features);
          },
          (error) => {
            console.error('forwardGeocoding', error);
            resolve([]);
          }
        );
      });
    };

    this.customAcEngine = (query, record) => {
      const options = { mode: 'loose', highlight: true };
      const match = this.acInstance.search(query, record, options);
      return match || record;
    };

    this.handleIconClose = () => {
      this.updateInputValue('');
      this.stopLocationUpdate();
      this.getInputRef().focus();
    };
  }

  handleInputButton(value) {
    const { closeIconVisible } = this.state;

    if (value.length > 0) {
      if (!closeIconVisible) this.setState({ closeIconVisible: 'visible' });
    } else {
      if (closeIconVisible) this.setState({ closeIconVisible: '' });
    }
  }

  render() {
    return (
      <div className="mapbox-complete" ref={this.setContainerRef}>
        <div className="mapbox-main">
          <input
            className="mc-input"
            placeholder={this.props.placeholder}
            value={this.props.inputValue}
            ref={this.setInputRef}
            onFocus={this.handleOnFocus}
            onKeyDown={this.handleKeyDown}
            onChange={this.handleOnChange}
            onBlur={this.handleInputBlur}
          />
          <ReactSVG
            src={closeIcon}
            className={classnames('react-svg', 'close-icon', this.state.closeIconVisible)}
            onClick={this.handleIconClose}
            onMouseDown={this.handleIconCloseMouseDown}
            onMouseLeave={this.handleIconCloseMouseUp}
            onMouseUp={this.handleIconCloseMouseUp}
          />
        </div>
      </div>
    );
  }
}

MapboxComplete.defaultProps = {
  inputValue: ''
};

MapboxComplete.propTypes = {
  /** Input placeholder */
  placeholder: PropTypes.string,
  /** Fired when input changes */
  onInputChange: PropTypes.func,
  /** Controlled value for input field */
  inputValue: PropTypes.string,
  /** Get function to set input value */
  getValueSetter: PropTypes.func,
  /** Custom data to search for */
  fixtures: PropTypes.array,
  /** Fired when you select the suggestion */
  onSelect: PropTypes.func,
  /** https://docs.mapbox.com/api/search/geocoding/#data-types */
  searchType: PropTypes.arrayOf(PropTypes.string),
  /** https://docs.mapbox.com/api/search/geocoding/#forward-geocoding */
  language: PropTypes.string,
  /** Show current location suggestion */
  showLocationButton: PropTypes.bool,
  /** Enable search history **/
  searchHistoryEnabled: PropTypes.bool
};

export default MapboxComplete;
