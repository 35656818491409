import { STORAGE_KEY } from '../constants/generic';
import { getAppObj } from './utils';

export function parseAuthTokensFromApi(headers) {
  let { 'x-auth-token': token, 'x-refresh-token': refreshToken } = headers || {};
  if (token === 'null') token = undefined;
  if (refreshToken === 'null') refreshToken = undefined;
  return { token, refreshToken };
}

export function clearAuthTokens() {
  delete getAppObj()[STORAGE_KEY.AUTH_TOKEN];
  localStorage.removeItem(STORAGE_KEY.AUTH_TOKEN);
  delete getAppObj()[STORAGE_KEY.AUTH_REFRESH_TOKEN];
  localStorage.removeItem(STORAGE_KEY.AUTH_REFRESH_TOKEN);
}

export function clearSsoTokens() {
  delete getAppObj()[STORAGE_KEY.SSO_TOKEN];
  localStorage.removeItem(STORAGE_KEY.SSO_TOKEN);
  delete getAppObj()[STORAGE_KEY.SSO_REFRESH_TOKEN];
  localStorage.removeItem(STORAGE_KEY.SSO_REFRESH_TOKEN);
}

export function saveSsoTokens({ token, refreshToken } = getAppObj().keycloak || {}) {
  if (token) {
    getAppObj()[STORAGE_KEY.SSO_TOKEN] = token;
    localStorage.setItem(STORAGE_KEY.SSO_TOKEN, token);
  }
  if (refreshToken) {
    getAppObj()[STORAGE_KEY.SSO_REFRESH_TOKEN] = refreshToken;
    localStorage.setItem(STORAGE_KEY.SSO_REFRESH_TOKEN, refreshToken);
  }
  clearAuthTokens();
}

export function saveAuthTokens({ token, refreshToken }) {
  if (token) {
    getAppObj()[STORAGE_KEY.AUTH_TOKEN] = token;
    localStorage.setItem(STORAGE_KEY.AUTH_TOKEN, token);
  }
  if (refreshToken) {
    getAppObj()[STORAGE_KEY.AUTH_REFRESH_TOKEN] = refreshToken;
    localStorage.setItem(STORAGE_KEY.AUTH_REFRESH_TOKEN, refreshToken);
  }
  clearSsoTokens();
}

export function restoreAuthTokens() {
  const token = localStorage.getItem(STORAGE_KEY.AUTH_TOKEN);
  if (token) getAppObj()[STORAGE_KEY.AUTH_TOKEN] = token;
  const refreshToken = localStorage.getItem(STORAGE_KEY.AUTH_REFRESH_TOKEN);
  if (refreshToken) getAppObj()[STORAGE_KEY.AUTH_REFRESH_TOKEN] = refreshToken;
}

export function restoreSsoTokens() {
  const token = localStorage.getItem(STORAGE_KEY.SSO_TOKEN);
  if (token) getAppObj()[STORAGE_KEY.SSO_TOKEN] = token;
  const refreshToken = localStorage.getItem(STORAGE_KEY.SSO_REFRESH_TOKEN);
  if (refreshToken) getAppObj()[STORAGE_KEY.SSO_REFRESH_TOKEN] = refreshToken;
}

export function getAppAuthTokens() {
  return {
    token: getAppObj()[STORAGE_KEY.AUTH_TOKEN],
    refreshToken: getAppObj()[STORAGE_KEY.AUTH_REFRESH_TOKEN]
  };
}

export function getAppSsoTokens() {
  return {
    token: getAppObj()[STORAGE_KEY.SSO_TOKEN],
    refreshToken: getAppObj()[STORAGE_KEY.SSO_REFRESH_TOKEN]
  };
}

export function noTokensFound() {
  const tokens = getAppAuthTokens();
  const ssoTokens = getAppSsoTokens();

  return !(
    tokens.token ||
    tokens.refreshToken ||
    ssoTokens.token ||
    ssoTokens.refreshToken
  );
}
