import React, { Component } from 'react';
import { Col, Row, Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import classnames from 'classnames';
import _findIndex from 'lodash/findIndex';
import _forEach from 'lodash/forEach';
import _map from 'lodash/map';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import _isEqual from 'lodash/isEqual';
import * as BookingActions from '../../actions/booking';
import * as PopupActions from '../../actions/popup';

import {
  scheduleNewDataFormat,
  momentDateTimeFormat,
  formatDataForSearchBookingsApiCall
} from '../../helpers';

import {
  gtmPushBasicBookingData,
  gtmPushBasicSearchData,
  getBookingCurrency,
  handleCarImageFail,
  jsonParseSafe,
  getPrice,
  isEmpty,
  trySet,
  safe,
  parsePrice
} from '../../utils/utils';

import { ReactSVG } from 'react-svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import checked from '../../../src/assets/images/checked-black.svg';
import info from '../../../src/assets/images/info.svg';
import gaz from '../../../src/assets/images/gaz.svg';
import { USAGE_TYPE_PRIVATE } from '../../constants/backend';
import * as CustomFieldsActions from '../../actions/customFields';

import {
  bookingCustomFieldsSelector,
  customFieldsValuesSelector,
  selectCustomFieldsNames,
  userCompanyIdSelector,
  userIdSelector,
  checkCustomFieldsPresent,
  currentThemeSelector,
  termsOfUseUrlSelector,
  chorusFieldsValuesSelector
} from '../../helpers/selectors';

import CustomFieldsForm from '../account/CustomFieldsForm';
import ChorusFieldsForm from '../account/ChorusFieldsForm';
import { createCustomFieldsArray } from '../../actions/customFields/helpers';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import { getLabelFromOptions } from '../../helpers/components';
import { otherFiltersOptions } from '../../constants/options';
import { STORAGE_KEY } from '../../constants/generic';
import { change } from 'redux-form';
import { USER_FORM_NAMES } from '../../constants/form';
import memoizeOne from 'memoize-one';

const formatShortDate = 'D MMM H:mm';

const errorStatusesDefault = {
  ACCEPT_TERMS: true
};

function getKeyDepositDetails(safetyDeposit, damageSafetyDeposit) {
  if (safetyDeposit > 0 && damageSafetyDeposit > 0) return 'booking.deposit.db';
  if (safetyDeposit > 0 && damageSafetyDeposit === 0) return 'booking.deposit.d';
  if (safetyDeposit === 0 && damageSafetyDeposit > 0) return 'booking.deposit.b';
}

const isPrivateUsage = (selectedVehicle) => {
  return safe(() => selectedVehicle.carSharingInfo.usageType) === USAGE_TYPE_PRIVATE;
};

const getPrices = memoizeOne((selectedVehicle) => {
  const { carSharingInfo } = selectedVehicle || {};
  const { cost } = carSharingInfo || {};
  const { estimatedPriceWithoutVoucher, estimatedPriceForDuration } = cost || {};
  const { currency } = cost || {};

  if (isPrivateUsage(selectedVehicle)) {
    const price = getPrice(estimatedPriceForDuration, currency);
    const priceBeforeVoucher = getPrice(estimatedPriceWithoutVoucher, currency);
    return { price, priceBeforeVoucher };
  }
  return {};
});

function AccordionToggle({ children, eventKey, callback }) {
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey)
  );

  return (
    <button type="button" onClick={decoratedOnClick}>
      {children}
    </button>
  );
}

export class BookingRecapPopup extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = {
      confirmStep: false,
      scrollToErrorStatus: false,
      errorStatuses: errorStatusesDefault
    };
  }

  setCallbacks() {
    this.validateAndSubmit = () => {
      const { errorStatuses, submitPressed, confirmStep } = this.state;
      let canSubmit = true;

      _forEach(errorStatuses, (errorStatus) => {
        if (errorStatus) {
          canSubmit = false;
          return false;
        }
      });

      if (!confirmStep) canSubmit = true;
      if (!submitPressed) this.setState({ submitPressed: true });
      if (canSubmit) this.handleVehicleBooking();
      else this.initScrollToError();
    };

    this.onWindowResize = () => {
      const isDesktop = window.innerWidth > 991;
      if (isDesktop || !this.bookBoxElem) return;
      const { style: elemStyles } = this.bookBoxElem;
      if (elemStyles.top !== 'auto') elemStyles.top = 'auto';
    };

    this.handleScrollEvent = (event) => {
      const isDesktop = window.innerWidth > 991;
      if (!isDesktop || !this.bookBoxElem) return;

      const top = event.target.scrollTop;
      const { style: elemStyles } = this.bookBoxElem;

      if (top >= 0 && top <= 24) {
        elemStyles.top = 50 - top + 'px';
      } else if (top >= 25) {
        elemStyles.top = 25 + 'px';
      }
    };

    this.handleSubmitButton = () => {
      const { popup_Login_SetVisibility, user, customFieldsPresent } = this.props;
      const { data: userData } = user || {};

      if (!userData) {
        popup_Login_SetVisibility(true);
      } else if (this.canGoToConfirmStep()) {
        this.goToConfirmStep();
      } else {
        if (customFieldsPresent) this.customFieldsSubmit(this.validateAndSubmit);
        else this.validateAndSubmit();
      }
    };

    this.goToPrevStep = () => {
      this.setState({ confirmStep: false });
    };

    this.applyVoucher = () => {
      const {
        search,
        loadBookingPopupData,
        searchBookingsForm,
        userId,
        booking,
        userCompanyId,
        brand: {
          branded: { contract }
        }
      } = this.props;

      const { values: searchFormValues } = searchBookingsForm || {};
      const { selectedVehicle } = booking;

      const formattedData = formatDataForSearchBookingsApiCall(
        {
          ...searchFormValues,
          voucherCode: this.voucherCode
        },
        userCompanyId,
        contract
      );

      search(formattedData).then((data) => {
        if (data.type === 'booking/SEARCH_SUCCESS') {
          const index = this.selectVehicleIndex(
            this.getVehicleIdAndUsageType(selectedVehicle)
          );

          gtmPushBasicSearchData(data, searchFormValues, userId);

          if (index >= 0) loadBookingPopupData(index);
        }
      });
    };

    this.setVoucherCode = (data) => {
      const { target } = data || {};
      const { value } = target || {};
      this.voucherCode = value;
    };

    this.setAcceptTermsStatus = (e) => {
      const { errorStatuses } = this.state;
      this.setState({
        errorStatuses: { ...errorStatuses, ACCEPT_TERMS: !e.target.checked }
      });
    };

    this.setCustomFieldsSubmit = (submit) => {
      this.customFieldsSubmit = submit;
    };

    this.setChorusFieldsSubmit = (submit) => {
      this.chorusFieldsSubmit = submit;
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.scrollToError(prevState);
    this.handlePaymentRedirect();
    this.handleFieldsRestore();
  }

  componentDidMount() {
    const { getBookingCustomFields, userCompanyId, user } = this.props;
    const { data: userData } = user || {};

    if (userData) {
      getBookingCustomFields(userCompanyId).then(() => (this.fieldsChecked = true));
    }

    this.addResizeListener();
    this.addRecapListener();
    this.onWindowResize();
  }

  componentWillUnmount() {
    this.removeRecapListener();
    this.removeResizeListener();
  }

  handlePaymentRedirect() {
    if (this.fieldsChecked) {
      const recapData = localStorage.getItem(STORAGE_KEY.RECAP_DATA);

      if (recapData) {
        if (this.canGoToConfirmStep()) {
          this.goToConfirmStep();
          this.fieldsFormReady = true;
        }
      }

      this.fieldsChecked = false;
    }
  }

  handleFieldsRestore() {
    if (this.fieldsFormReady) {
      const recapDataEncoded = localStorage.getItem(STORAGE_KEY.RECAP_DATA);
      const recapData = jsonParseSafe(recapDataEncoded);

      localStorage.removeItem(STORAGE_KEY.RECAP_DATA);

      _forEach(recapData, (v, k) => {
        this.props.changeFormValue(USER_FORM_NAMES.CUSTOM_FIELDS, k, v);
      });

      this.fieldsFormReady = false;
    }
  }

  addResizeListener() {
    window.addEventListener('resize', this.onWindowResize, { capture: true });
  }

  removeResizeListener() {
    window.removeEventListener('resize', this.onWindowResize, { capture: true });
  }

  addRecapListener() {
    this.bookBoxElem = document.getElementById('book-box');
    const recapPopup = document.getElementById('recap-popup');

    if (recapPopup)
      recapPopup.addEventListener('scroll', this.handleScrollEvent, { capture: true });
  }

  removeRecapListener() {
    const recapPopup = document.getElementById('recap-popup');
    if (recapPopup)
      recapPopup.removeEventListener('scroll', this.handleScrollEvent, { capture: true });
  }

  getConfirmRecapTitleKey() {
    return isPrivateUsage(this.getSelectedVehicle())
      ? 'booking.confirm_and_pay'
      : 'booking.confirm';
  }

  getSelectedVehicle() {
    return safe(() => this.props.booking.selectedVehicle);
  }

  handleVehicleBooking() {
    if (this.state.loading) return;

    const {
      userId,
      customFieldsFormValues,
      setAddToCalendarData,
      setActiveBooking,
      bookVehicle,
      editBookingId,
      chorusData,
      chorusFieldsValues
    } = this.props;

    const numberOfVehicles = _get(this.props, 'booking.vehiclesData.length');
    let vehicleData = { ...this.props.booking.selectedVehicle };

    if (safe(() => chorusData.administration)) {
      const chorusData = {
        administration: true,
        ...chorusFieldsValues
      };
      trySet(vehicleData, 'chorusData', chorusData);
      vehicleData.chorusData = { ...chorusData };
    }
    trySet(
      vehicleData,
      'voucherCode',
      _get(vehicleData, 'carSharingInfo.cost.voucherCode')
    );

    trySet(
      vehicleData,
      'bookingCustomValues',
      createCustomFieldsArray(customFieldsFormValues)
    );

    this.setState({ loading: true });

    bookVehicle(vehicleData, editBookingId).then((data) => {
      if (data.type === 'booking/BOOK_VEHICLE_SUCCESS') {
        setActiveBooking(data.payload.data);
        setAddToCalendarData(data.payload.data);
        gtmPushBasicBookingData(data, numberOfVehicles, userId);
      } else this.setState({ loading: false });
    });
  }

  initScrollToError() {
    const { scrollToErrorStatus } = this.state;
    this.setState({ scrollToErrorStatus: !scrollToErrorStatus });
  }

  scrollToError(state) {
    if (this.scrollToErrorStatusUpdated(state)) scrollToErrorInfo();
  }

  scrollToErrorStatusUpdated(state) {
    const { scrollToErrorStatus: prevValue } = state;
    const { scrollToErrorStatus: nextValue } = this.state;
    return nextValue !== prevValue;
  }

  canGoToConfirmStep() {
    return !this.state.confirmStep;
  }

  goToConfirmStep() {
    this.setState({ confirmStep: true });
  }

  getPriceBeforeVoucherPart(priceBeforeVoucher) {
    if (priceBeforeVoucher)
      return (
        <span className="scope-before-voucher-price-field">{priceBeforeVoucher}</span>
      );
  }

  getFreeKmPerDayField(freeKmPerDay) {
    if (freeKmPerDay) {
      return (
        <div className="scope-confirm-recap-price-field">
          <div className="scope-field-name">
            <FormattedMessage id="car.pricing.free_km_per_day" />
          </div>
          <div className="scope-field-value">{freeKmPerDay} km</div>
        </div>
      );
    }
  }

  getPricePerKmField(pricePerKm, currency) {
    const price = getPrice(pricePerKm, currency);
    if (price) {
      return (
        <div className="scope-confirm-recap-price-field">
          <div className="scope-field-name">
            <FormattedMessage id="car.pricing.extra_km_cost_per_day" />
          </div>
          <div className="scope-field-value">{price}</div>
        </div>
      );
    }
  }

  getSecurityDeposit(securityDeposit, currency) {
    const price = getPrice(securityDeposit, currency);
    if (price) {
      return (
        <div className="scope-confirm-recap-price-field">
          <div className="scope-field-name">
            <FormattedMessage id="booking.deposit" />
          </div>
          <div className="scope-field-value">{price}</div>
        </div>
      );
    }
  }

  getConfirmRecapPriceDetails() {
    const selectedVehicle = this.getSelectedVehicle();
    const { carSharingInfo } = selectedVehicle || {};
    const { cost } = carSharingInfo || {};
    const { freeKmPerDay, pricePerKm, securityDeposit } = cost || {};
    const currency = getBookingCurrency(selectedVehicle);
    const { price, priceBeforeVoucher } = getPrices(selectedVehicle);

    if (isPrivateUsage(selectedVehicle)) {
      return (
        <Row className="scope-inner-row-padding">
          <Col md={12} className="scope-confirm-recap-price-col">
            <hr className="scope-booking-recap-hr" />
            <div className="scope-confirm-recap-sub-title">
              <FormattedMessage id="car.pricing" />
            </div>
            <div className="scope-confirm-recap-price-field">
              <div className="scope-field-name">
                <FormattedMessage id="car.pricing.estimate" />
              </div>
              <div className="scope-field-value">
                {this.getPriceBeforeVoucherPart(priceBeforeVoucher)}
                <span>{price}</span>
              </div>
            </div>
            {this.getFreeKmPerDayField(freeKmPerDay)}
            {this.getPricePerKmField(pricePerKm, currency)}
            {this.getSecurityDeposit(securityDeposit, currency)}
          </Col>
        </Row>
      );
    }
  }

  getConfirmRecapCarDetails() {
    const { vehicle, start, end } = this.props.booking.selectedVehicle;
    const { pictureUrl, brand, model } = vehicle || {};
    const { date: startDate } = start || {};
    const { date: endDate } = end || {};
    const { address: endLocation } = end || {};

    return (
      <Row className="scope-confirm-recap-header-row">
        <Col md={12} className="scope-confirm-recap-header">
          <div className="scope-confirm-recap-title-container">
            <div
              onClick={this.goToPrevStep}
              className="scope-confirm-recap-button-return"
            >
              <ReactSVG className="react-svg" src={arrowLeftSrc} />
            </div>
            <span className="scope-confirm-recap-title">
              <FormattedMessage id={this.getConfirmRecapTitleKey()} />
            </span>
          </div>
          <div
            className={classnames(
              'scope-confirm-recap-car-details',
              'scope-inner-row-padding'
            )}
          >
            <img
              onError={(e) => handleCarImageFail(e, this.props.theme)}
              src={pictureUrl}
              className="scope-confirm-recap-picture"
              alt="car"
            />
            <div className="scope-confirm-recap-car-time-name">
              <div className="scope-confirm-recap-car-name">
                <span>{brand}</span> <span>{model}</span>
              </div>
              <div
                className={classnames(
                  'scope-confirm-recap-time-range',
                  'scope-date-range'
                )}
              >
                {momentDateTimeFormat(startDate, formatShortDate)}
                {!_isEmpty(endLocation) && (
                  <ReactSVG className="react-svg" src={arrowLeftSrc} />
                )}
                {!_isEmpty(endLocation) && momentDateTimeFormat(endDate, formatShortDate)}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  getVehicleIdAndUsageType(selectedVehicle) {
    const { carSharingInfo, vehicle } = selectedVehicle || {};
    const { usageType } = carSharingInfo || {};
    const { id: vehicleId } = vehicle || {};

    return [vehicleId, usageType];
  }

  selectVehicleIndex(oldData) {
    const { vehiclesData } = this.props;

    const isPrevVehicle = (vehicle) => {
      const data = this.getVehicleIdAndUsageType(vehicle);
      return _isEqual(data, oldData);
    };

    return _findIndex(vehiclesData, isPrevVehicle);
  }

  getVoucherRow() {
    const { formatMessage } = this.props.intl;
    const placeholder = formatMessage({ id: 'booking.voucher.placeholder' });

    if (isPrivateUsage(this.getSelectedVehicle())) {
      return (
        <Row className="scope-inner-row-padding">
          <Col md={12}>
            <hr className="scope-booking-recap-hr" />
            <div className="scope-voucher-container">
              <input
                onChange={this.setVoucherCode}
                type="text"
                placeholder={placeholder}
              />
              <button className="primary-btn" onClick={this.applyVoucher} type="button">
                <FormattedMessage id="label.apply" />
              </button>
            </div>
          </Col>
        </Row>
      );
    }
  }

  showErrorField(errorType) {
    const { errorStatuses, submitPressed } = this.state;

    if (errorStatuses[errorType] && submitPressed) {
      return <div className="field-error-info border" />;
    }
  }

  getAcceptTermsRow() {
    if (!this.props.termsOfUseUrl) return;

    return (
      <Row className="scope-inner-row-padding">
        <Col md={12}>
          <div className="scope-accept-terms-container">
            <hr className="scope-booking-recap-hr" />
            <div className="scope-confirm-recap-sub-title">
              <FormattedMessage id="booking.accept_usage_terms.title" />
            </div>
            <div className="scope-field-container">
              <div className="scope-boolean-container">
                <span className="scope-boolean-info">
                  <FormattedMessage
                    id="booking.accept_usage_terms.text"
                    values={{
                      link: (
                        <a target="_blank" href={this.props.termsOfUseUrl}>
                          <FormattedMessage id="booking.accept_usage_terms.link.label" />
                        </a>
                      )
                    }}
                  />
                </span>
                <label className="switch">
                  <input type="checkbox" onChange={this.setAcceptTermsStatus} />
                  <span className="slider round" />
                </label>
              </div>
              {this.showErrorField('ACCEPT_TERMS')}
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  getCustomFields() {
    const { customFields, customFieldsNames, customFieldsPresent } = this.props;

    if (customFieldsPresent) {
      return (
        <Row className="scope-inner-row-padding">
          <Col md={12}>
            <div className="profile-page">
              <hr className="scope-booking-recap-hr" />
              <CustomFieldsForm
                fields={customFieldsNames}
                customFields={customFields}
                passFormSubmit={this.setCustomFieldsSubmit}
              />
            </div>
          </Col>
        </Row>
      );
    }
  }

  getChorusFields() {
    const { chorusData } = this.props;
    if (safe(() => chorusData.administration)) {
      return (
        <Row className="scope-inner-row-padding">
          <Col md={12}>
            <div className="profile-page">
              <hr className="scope-booking-recap-hr" />
              <ChorusFieldsForm
                initialValues={chorusData}
                passFormSubmit={this.setChorusFieldsSubmit}
              />
            </div>
          </Col>
        </Row>
      );
    }
  }

  getConfirmRecapBody() {
    return (
      <Col md={8} className={classnames('scope-confirm-recap', 'recap-modal-body')}>
        {this.getConfirmRecapCarDetails()}
        {this.getConfirmRecapPriceDetails()}
        {!this.props.editBookingId && this.getVoucherRow()}
        {this.getBookingProvision()}
        {this.getCustomFields()}
        {this.props.chorusData && this.getChorusFields()}
        {this.getAcceptTermsRow()}
      </Col>
    );
  }

  getFreeKmForDuration(selectedVehicle) {
    const kmPerDay = safe(() => selectedVehicle.carSharingInfo.cost.freeKmPerDay) || 0;
    const endDate = safe(() => selectedVehicle.end.date);
    const startDate = safe(() => selectedVehicle.start.date);

    if (startDate && endDate) {
      return Math.ceil(moment(endDate).diff(moment(startDate), 'hours') / 24) * kmPerDay;
    }
  }

  getRecapPriceRow() {
    const selectedVehicle = this.getSelectedVehicle();
    const { cost } = safe(() => selectedVehicle.carSharingInfo) || {};
    const currency = getBookingCurrency(selectedVehicle);
    const pricePerKm = safe(() => getPrice(cost.pricePerKm, currency));
    const freeKmForDuration = this.getFreeKmForDuration(selectedVehicle);
    const { price } = getPrices(selectedVehicle);

    if (isPrivateUsage(selectedVehicle) && price) {
      return (
        <Row>
          <Col md={12}>
            <hr className="scope-booking-recap-hr" />
            <div className="title">
              <FormattedMessage id="car.pricing" />
            </div>
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.pricing.estimate" />
              </span>
              <span className="value">{price}</span>
            </div>
            {freeKmForDuration > 0 && (
              <div className="content">
                <span className="label">
                  <FormattedMessage id="car.km_included" />
                </span>
                <span className="value">{freeKmForDuration}&nbsp;km</span>
              </div>
            )}
            {pricePerKm && (
              <div className="content">
                <span className="label">
                  <FormattedMessage id="car.for_every_additional_km" />
                </span>
                <span className="value">{pricePerKm}</span>
              </div>
            )}
          </Col>
        </Row>
      );
    }
  }

  getLegend() {
    const { parkingSchedule } = this.props.booking;
    const hasSpecialOpening = _get(parkingSchedule, 'oneTimeSlots.length') > 0;

    if (hasSpecialOpening) {
      return (
        <div className="content">
          <div>
            <span className="label special" />
            <span className="spacing">
              <FormattedMessage id="parking.special_opening_legend" />
            </span>
          </div>
        </div>
      );
    }
  }

  getRecapCarImage() {
    const { selectedVehicle } = this.props.booking;
    return (
      <Row>
        <Col md={12} className="wrapper-image">
          <div className="car-popup-label">
            <FormattedMessage id="car.details" />
          </div>
          <div className="car-image">
            <img
              onError={(e) => handleCarImageFail(e, this.props.theme)}
              src={selectedVehicle.vehicle.pictureUrl}
              className="w-100 car-detail-pic"
              alt={selectedVehicle.vehicle.brand + ' ' + selectedVehicle.vehicle.model}
            />
          </div>
        </Col>
      </Row>
    );
  }

  showFuelLevel(fuelPercent) {
    if (!isEmpty(fuelPercent)) {
      return (
        <div className="gaz">
          <ReactSVG className="react-svg" src={gaz} />
          {fuelPercent + '%'}
        </div>
      );
    }
  }

  getCarDetails() {
    const { selectedVehicle } = this.props.booking;
    const fuelPercent = _get(selectedVehicle, 'vehicle.fuelLevel.percentage');
    const parkingLocation = _get(selectedVehicle, 'start.parking.site.address');
    const parkingName = _get(selectedVehicle, 'start.parking.name');

    return (
      <Row>
        <Col md={12} className="header">
          <div className="car-info">
            <div className="brand-model">
              {selectedVehicle.vehicle.brand + ' ' + selectedVehicle.vehicle.model}
            </div>
            <div className="plate-number">
              ({selectedVehicle.vehicle.registrationNumber})
            </div>
            {this.showFuelLevel(fuelPercent)}
          </div>
          <div className="parking-info">
            <div className="parking-name">{parkingName}</div>
            <div>
              {parkingLocation.streetNumber && parkingLocation.streetNumber + ', '}
              {parkingLocation.streetName + ','}
            </div>
            <div>{parkingLocation.postalCode + ' ' + parkingLocation.city}</div>
          </div>
        </Col>
      </Row>
    );
  }

  getDescription() {
    const { selectedVehicle } = this.props.booking;
    const { vehicle } = selectedVehicle || {};
    const { description } = vehicle || '';

    if (description && description !== '')
      return (
        <Row>
          <Col md={12}>
            <hr className="scope-booking-recap-hr" />
            <div className="title">
              <FormattedMessage id="car.description" />
            </div>
            <div className="content text">{description}</div>
          </Col>
        </Row>
      );
  }

  getSpecifications() {
    const { selectedVehicle } = this.props.booking;
    const { messages } = this.props.i18n;

    return (
      <Row>
        <Col md={12}>
          <hr className="scope-booking-recap-hr" />
          <div className="title">
            <FormattedMessage id="car.specifications" />
          </div>
          {_get(selectedVehicle, 'vvehicle.version') !== '' && (
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.version" />
              </span>
              <span className="value">{selectedVehicle.vehicle.version}</span>
            </div>
          )}
          {_get(selectedVehicle, 'vvehicle.fuelType') !== '' && (
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.motorization" />
              </span>
              <span className="value">
                <FormattedMessage id={'car.' + selectedVehicle.vehicle.fuelType} />
              </span>
            </div>
          )}
          {_get(selectedVehicle, 'vehicle.transmissionType') !== '' && (
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.transmission" />
              </span>
              <span className="value">
                {messages['car.' + selectedVehicle.vehicle.transmissionType]}
              </span>
            </div>
          )}
          {selectedVehicle.vehicle.seats > 0 ? (
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.places" />
              </span>
              <span className="value">{selectedVehicle.vehicle.seats}</span>
            </div>
          ) : null}
          {_get(selectedVehicle, 'vehicle.color') !== '' && (
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.color" />
              </span>
              <span className="value">{selectedVehicle.vehicle.color}</span>
            </div>
          )}
          {selectedVehicle.vehicle.doorsNumber > 0 ? (
            <div className="content">
              <span className="label">
                <FormattedMessage id="car.doors" />
              </span>
              <span className="value">{selectedVehicle.vehicle.doorsNumber}</span>
            </div>
          ) : null}
        </Col>
      </Row>
    );
  }

  getCarOptions() {
    const { selectedVehicle } = this.props.booking;
    const { messages } = this.props.i18n;

    if (selectedVehicle.vehicle.accessories.length > 0) {
      return (
        <Row>
          <Col md={12}>
            <hr className="scope-booking-recap-hr" />
            <div className="title">
              <FormattedMessage id="car.options" />
            </div>
            {selectedVehicle.vehicle.accessories.map((item, index) => (
              <div className="content" key={'accessory ' + index}>
                <span className="label">
                  {getLabelFromOptions(otherFiltersOptions(messages), item)}
                </span>
                <span className="value">
                  <ReactSVG src={checked} className="react-svg" />
                </span>
              </div>
            ))}
          </Col>
        </Row>
      );
    }
  }

  getBookingOptions() {
    const { selectedVehicle } = this.props.booking;
    const { formatMessage } = this.props.intl;

    const formatFullDate = `dddd D MMMM [${formatMessage({
      id: 'generic.at'
    })}] H:mm A`;

    return (
      <Row>
        <Col md={12}>
          <hr className="scope-booking-recap-hr" />
          <div className="title">
            <FormattedMessage id="car.booking_details" />
          </div>
          <ul>
            <li>
              {momentDateTimeFormat(selectedVehicle.start.date, formatFullDate)}
              <br />
              {_get(selectedVehicle, 'start.address.formattedAddress')}
            </li>
            <li>
              {momentDateTimeFormat(selectedVehicle.end.date, formatFullDate)}
              <br />
              {_get(selectedVehicle, 'end.address.formattedAddress')}
            </li>
          </ul>
        </Col>
      </Row>
    );
  }

  getOpenHours() {
    const { selectedVehicle, parkingSchedule } = this.props.booking;
    const { messages } = this.props.i18n;
    const schedule = scheduleNewDataFormat(parkingSchedule, messages);

    if (!_get(selectedVehicle, 'vehicle.parking.alwaysOpen', true)) {
      return (
        <Row>
          <Col md={12}>
            <hr className="scope-booking-recap-hr" />
            <div className="title">{messages['label.schedule'] + ' :'}</div>
            <div>
              {_map(schedule, (item, index) => {
                const key = item.day + '-' + index;
                return (
                  <div className="content" key={key}>
                    <span className={`label ${item.labelDay === '' ? 'special' : ''}`}>
                      {item.labelDay !== '' ? item.labelDay : item.day}
                    </span>
                    <span className="value">{item.time}</span>
                  </div>
                );
              })}
              <br />
              {this.getLegend()}
            </div>
          </Col>
        </Row>
      );
    }
  }

  getParkingInfo() {
    const { selectedVehicle } = this.props.booking;

    if (_get(selectedVehicle, 'vehicle.parking.additionalInformation')) {
      return (
        <Row>
          <Col md={12}>
            <hr className="scope-booking-recap-hr" />
            <div className="parking-additional-info">
              <FormattedMessage id="car.parking_additional_info" />
            </div>
            <div className="content-text">
              {selectedVehicle.vehicle.parking.additionalInformation}
            </div>
          </Col>
        </Row>
      );
    }
  }

  getRecapBody() {
    return (
      <Col md={8} className={classnames('wrapper', 'recap-modal-body')}>
        {this.getRecapCarImage()}
        {this.getCarDetails()}
        {this.getRecapPriceRow()}
        {this.getDescription()}
        {this.getParkingInfo()}
        {this.getSpecifications()}
        {this.getCarOptions()}
        {this.getBookingOptions()}
        {this.getOpenHours()}
      </Col>
    );
  }

  getSideModalConfirmTitle() {
    const { confirmStep } = this.state;

    return confirmStep ? (
      <FormattedMessage id={this.getConfirmRecapTitleKey()} />
    ) : (
      <FormattedMessage id="generic.book" />
    );
  }

  getSideModalPrice() {
    const selectedVehicle = this.getSelectedVehicle();
    const { price } = getPrices(selectedVehicle);

    if (isPrivateUsage(selectedVehicle) && price) {
      return <span className="price">{price}</span>;
    }
  }

  getSideModalPriceBeforeVoucher() {
    const selectedVehicle = this.getSelectedVehicle();
    const { priceBeforeVoucher } = getPrices(selectedVehicle);

    if (isPrivateUsage(selectedVehicle) && priceBeforeVoucher) {
      return (
        <span className="scope-side-modal-price-before-voucher">
          {priceBeforeVoucher}
        </span>
      );
    }
  }

  getSideModal() {
    const { confirmStep, loading } = this.state;
    const { selectedVehicle } = this.props.booking;
    const { end } = selectedVehicle || {};
    const { address: endLocation } = end || {};

    return (
      <Col className="book-box-col" md={4}>
        <div
          className={classnames('book-box', {
            'sub-class-confirm-step': confirmStep
          })}
          id="book-box"
        >
          {this.getSideModalPrice()}
          {this.getSideModalPriceBeforeVoucher()}
          <div className={classnames('datetime-range', 'scope-date-range')}>
            {momentDateTimeFormat(selectedVehicle.start.date, formatShortDate)}
            {!_isEmpty(endLocation) && (
              <ReactSVG className="react-svg" src={arrowLeftSrc} />
            )}
            {!_isEmpty(endLocation) && momentDateTimeFormat(end.date, formatShortDate)}
          </div>
          <div>
            <button
              className={classnames('btn', 'sub-class-confirm-button', 'primary-btn', {
                'sub-class-disabled': loading
              })}
              onClick={this.handleSubmitButton}
            >
              {this.getSideModalConfirmTitle()}
            </button>
          </div>
        </div>
      </Col>
    );
  }

  getBookingProvision() {
    const selectedVehicle = this.getSelectedVehicle();
    const { carSharingInfo } = selectedVehicle || {};
    const { cost } = carSharingInfo || {};
    const { safetyDeposit, damageSafetyDepositAmount, estimatedPriceForDuration, currency } =
      cost || {};
    const damageSafetyDepositWithCurrency = parsePrice(damageSafetyDepositAmount, currency);
    const estimatedPriceForDurationWithCurrency = parsePrice(
      estimatedPriceForDuration,
      currency
    );
    const bufferPrice = parsePrice(
      (estimatedPriceForDuration || 0) + (safetyDeposit || 0),
      currency
    );

    if (!isPrivateUsage(selectedVehicle) || (!safetyDeposit && !damageSafetyDepositAmount)) {
      return;
    }

    return (
      <Row className="scope-inner-row-padding">
        <Col md={12} className="scope-confirm-recap-price-col">
          <hr className="scope-booking-recap-hr" />
          <div className="scope-confirm-recap-sub-title">
            <FormattedMessage id="booking.view_payment_terms.title" />
          </div>
          <Accordion id={selectedVehicle.vehicle.id}>
            <Accordion.Header>
              <span>
                <FormattedMessage id="booking.deposit.info" />
              </span>
              <AccordionToggle className="value">
                <ReactSVG src={info} className="react-svg info" />
              </AccordionToggle>
            </Accordion.Header>
            <Accordion.Body collapsible>
              <FormattedMessage
                id={getKeyDepositDetails(safetyDeposit, damageSafetyDepositAmount)}
                values={{
                  bufferPrice,
                  damageSafetyDeposit: damageSafetyDepositWithCurrency,
                  bookingPrice: estimatedPriceForDurationWithCurrency
                }}
              />
            </Accordion.Body>
          </Accordion>
        </Col>
      </Row>
    );
  }

  render() {
    if (!this.props.booking.selectedVehicle) return <div />;
    const { confirmStep } = this.state;

    return (
      <div className="car-popup recap">
        <div className="container">
          <Row>
            {confirmStep ? this.getConfirmRecapBody() : this.getRecapBody()}
            {this.getSideModal()}
          </Row>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { searchBookings: searchBookingsForm } = state.form;
  const { vehiclesData, editBookingData } = state.booking;
  const { data } = state.user;
  const { enterprise } = data || {};
  const { chorusData } = enterprise || {};
  const { id: editBookingId } = editBookingData || {};
  const customFields = bookingCustomFieldsSelector(state);
  const customFieldsValues = customFieldsValuesSelector(state);
  const customFieldsNames = selectCustomFieldsNames(customFields);
  const chorusFieldsValues = chorusFieldsValuesSelector(state);

  return {
    ...state,
    searchBookingsForm,
    vehiclesData: vehiclesData || [],
    userCompanyId: userCompanyIdSelector(state),
    userId: userIdSelector(state),
    chorusData,
    chorusFieldsValues,
    customFields,
    customFieldsNames,
    editBookingId,
    theme: currentThemeSelector(state),
    customFieldsPresent: checkCustomFieldsPresent(customFields, customFieldsValues),
    customFieldsFormValues: customFieldsValues,
    termsOfUseUrl: termsOfUseUrlSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CustomFieldsActions,
      ...BookingActions,
      ...PopupActions,
      changeFormValue: change
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BookingRecapPopup));
