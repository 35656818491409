import React, { Component } from 'react';
import pageIconSrc from '../../../src/assets/images/plus-circle.svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import IndexLink from '../partials/IndexLink';
import { ReactSVG } from 'react-svg';
import AcceptTermsForm from './AcceptTermsForm';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _isEmpty from 'lodash/isEmpty';
import CustomFieldsForm from './CustomFieldsForm';
import {
  acceptTermsValuesSelector,
  selectCustomFieldsInitialValues,
  customFieldsValuesSelector,
  selectCustomFieldsNames,
  messagesSelector,
  profileCustomFieldsSelector,
  userCompanyIdSelector,
  userDataSelector,
  userIdSelector,
  specialFieldsValuesSelector,
  sharedCompanyNameSelector,
  specialFieldsInitialValuesSelector,
  acceptTermsInitialValuesSelector,
  showSpecialFieldsSelector,
  checkCustomFieldsPresent,
  showMoreInfoPageSelector,
  currentThemeSelector,
  isProfileIncompleteUserSelector,
  memberCustomFieldsValuesSelector
} from '../../helpers/selectors';
import { bindActionCreators } from 'redux';
import * as CustomFieldsActions from '../../actions/customFields';
import * as UserActions from '../../actions/user';
import { formatCustomFieldsForApiCall } from '../../actions/customFields/helpers';
import PropTypes from 'prop-types';
import { getMessage as t, safe } from '../../utils/utils';
import SpecialFieldsForm from './SpecialFieldsForm';
import {
  formatAcceptTermsForApiCall,
  formatSpecialFieldsForApiCall
} from '../../helpers/formatters';
import { cleanDeep } from '../../utils/cleanDeep';
import { displayProfileUpdateSuccess } from '../../helpers/components';

class MoreInfo extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  componentDidMount() {
    if (this.props.isSubscribePage && !this.props.isCreationPage) {
      const id = safe(() => this.props.registerCompany.id);
      if (id) this.props.getMemberCustomFields(id);
    }
  }

  bindFunctions() {
    this.updateCustomFields = this.updateCustomFields.bind(this);
    this.setAcceptTermsSubmit = this.setAcceptTermsSubmit.bind(this);
    this.setCustomFieldsSubmit = this.setCustomFieldsSubmit.bind(this);
    this.setSpecialFieldsSubmit = this.setSpecialFieldsSubmit.bind(this);
  }

  addSubmit(check, newSubmit, oldSubmit) {
    if (check) return () => newSubmit(oldSubmit);
    else return oldSubmit;
  }

  updateCustomFields() {
    const {
      m,
      userId,
      userData,
      updateInformations,
      customFieldsValues,
      acceptTermsValues,
      specialFieldsValues,
      isSubscribePage,
      isCreationPage,
      onSubmit,
      showSpecialFields,
      userDataUpdating,
      customFieldsPresent
    } = this.props;

    const { company } = userData || {};

    if (userDataUpdating) return;

    if (isSubscribePage) {
      if (onSubmit) {
        let submitAction = () =>
          onSubmit({ acceptTermsValues, customFieldsValues, specialFieldsValues });

        if (!isCreationPage) {
          submitAction = this.addSubmit(true, this.termsSubmit, submitAction);
        }

        submitAction = this.addSubmit(
          customFieldsPresent,
          this.customFieldsSubmit,
          submitAction
        );

        submitAction = this.addSubmit(
          showSpecialFields,
          this.specialFieldsSubmit,
          submitAction
        );

        submitAction();
      }
    } else {
      let payload = formatCustomFieldsForApiCall(userData, customFieldsValues);

      payload = formatSpecialFieldsForApiCall(payload, specialFieldsValues, company);
      payload = formatAcceptTermsForApiCall(payload, acceptTermsValues);
      payload = cleanDeep(payload);

      let submitAction = () =>
        updateInformations(userId, payload).then((data) => {
          if (data.type === 'user/UPDATE_INFO_SUCCESS') {
            displayProfileUpdateSuccess(m, 'profile.section.additional.info');
          }
        });

      submitAction = this.addSubmit(true, this.termsSubmit, submitAction);

      submitAction = this.addSubmit(
        customFieldsPresent,
        this.customFieldsSubmit,
        submitAction
      );

      submitAction = this.addSubmit(
        showSpecialFields,
        this.specialFieldsSubmit,
        submitAction
      );

      submitAction();
    }
  }

  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  getSubmitButtonLabel() {
    const { isSubscribePage, isRegisterIncompleteUser, isProfileIncompleteUser, m } =
      this.props;

    if (isSubscribePage) {
      if (isRegisterIncompleteUser) return t(m, 'action.complete.my.account');
      else if (isProfileIncompleteUser) return t(m, 'label.add.new.profile');
      else return t(m, 'action.create.my.account');
    } else return t(m, 'generic.save');
  }

  getSpecialFields() {
    const { specialFieldsInitialValues, showSpecialFields } = this.props;

    if (showSpecialFields) {
      return (
        <SpecialFieldsForm
          passFormSubmit={this.setSpecialFieldsSubmit}
          initialValues={specialFieldsInitialValues}
        />
      );
    }
  }

  getCustomFields() {
    const {
      customFieldsNames,
      customFields,
      customFieldsInitialValues,
      customFieldsPresent
    } = this.props;

    if (customFieldsPresent) {
      return (
        <CustomFieldsForm
          initialValues={customFieldsInitialValues}
          fields={customFieldsNames}
          customFields={customFields}
          passFormSubmit={this.setCustomFieldsSubmit}
        />
      );
    }
  }

  setAcceptTermsSubmit(submit) {
    this.termsSubmit = submit;
  }

  setCustomFieldsSubmit(submit) {
    this.customFieldsSubmit = submit;
  }

  setSpecialFieldsSubmit(submit) {
    this.specialFieldsSubmit = submit;
  }

  getAcceptanceForm() {
    if (!this.props.isCreationPage) {
      return (
        <AcceptTermsForm
          isSubscribePage={this.props.isSubscribePage}
          initialValues={this.props.acceptTermsInitialValues}
          passFormSubmit={this.setAcceptTermsSubmit}
        />
      );
    }
  }

  showInfoText() {
    const { companyName, m } = this.props;

    return (
      <div className="scope-field-container">
        <div className="scope-info-text">
          <strong>{companyName}</strong> {t(m, 'part.company.fill.extra.info')}
        </div>
      </div>
    );
  }

  noInfoFound() {
    const { isSubscribePage, showMoreInfoPage } = this.props;
    return !showMoreInfoPage && !isSubscribePage;
  }

  noInfoPage() {
    return <div className="scope-select-menu-item">404</div>;
  }

  render() {
    const { userDataUpdating, m, theme } = this.props;
    const { additionalInfos } = theme.icons || {};

    if (this.noInfoFound()) return this.noInfoPage();

    return (
      <div className="more-info-page">
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={additionalInfos ? additionalInfos : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'profile.section.additional.info')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          {this.showInfoText()}
          {this.getSpecialFields()}
          {this.getCustomFields()}
          {this.getAcceptanceForm()}
        </div>
        <div className="scope-content-footer">
          <button
            onClick={this.updateCustomFields}
            className={classnames('scope-submit-button', 'primary-btn', {
              'sub-class-disabled': userDataUpdating
            })}
          >
            {this.getSubmitButtonLabel()}
          </button>
        </div>
      </div>
    );
  }
}

MoreInfo.defaultProps = {
  isSubscribePage: false
};

MoreInfo.propTypes = {
  isCreationPage: PropTypes.bool,
  isSubscribePage: PropTypes.bool,
  onSubmit: PropTypes.func,
  acceptTermsInitialValues: PropTypes.object,
  customFieldsInitialValues: PropTypes.object,
  specialFieldsInitialValues: PropTypes.object
};

function mapStateToProps(state, props) {
  const { userDataUpdating, registerCompany, isRegisterIncompleteUser } = state.user;

  const customFields = profileCustomFieldsSelector(state);
  const customFieldsValues = customFieldsValuesSelector(state);
  const customFieldsNames = selectCustomFieldsNames(customFields);
  const memberCustomFieldsValues = memberCustomFieldsValuesSelector(state);

  const { customFieldsInitialValues: customFieldsExternalValues } = props;
  const { specialFieldsInitialValues: specialFieldsExternalValues } = props;
  const { acceptTermsInitialValues: acceptTermsExternalValues } = props;

  const customFieldsInitialValues = _isEmpty(customFieldsExternalValues)
    ? selectCustomFieldsInitialValues(memberCustomFieldsValues)
    : customFieldsExternalValues;

  const specialFieldsInitialValues = _isEmpty(specialFieldsExternalValues)
    ? specialFieldsInitialValuesSelector(state)
    : specialFieldsExternalValues;

  const acceptTermsInitialValues = _isEmpty(acceptTermsExternalValues)
    ? acceptTermsInitialValuesSelector(state)
    : acceptTermsExternalValues;

  return {
    companyName: sharedCompanyNameSelector(state),
    userDataUpdating,
    isRegisterIncompleteUser,
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    userData: userDataSelector(state),
    userId: userIdSelector(state),
    userCompanyId: userCompanyIdSelector(state),
    showSpecialFields: showSpecialFieldsSelector(state),
    customFieldsPresent: checkCustomFieldsPresent(customFields, customFieldsValues),
    showMoreInfoPage: showMoreInfoPageSelector(state),
    registerCompany,
    customFields,
    customFieldsNames,
    customFieldsInitialValues,
    specialFieldsInitialValues,
    acceptTermsInitialValues,
    m: messagesSelector(state),
    customFieldsValues,
    acceptTermsValues: acceptTermsValuesSelector(state),
    specialFieldsValues: specialFieldsValuesSelector(state),
    theme: currentThemeSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...CustomFieldsActions,
      ...UserActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreInfo);
