import React from 'react';

export default function SliderHandle({
  className,
  domain: [min, max],
  handle: { id, value, percent },
  getHandleProps,
  color,
  ...restProps
}) {
  return (
    <button
      style={{
        left: `${percent === 100 ? 97 : percent}%`,
        border: `2px solid ${color}`
      }}
      role="slider"
      className="handle"
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={value}
      {...restProps}
      {...getHandleProps(id)}
    />
  );
}
