import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { USAGE_TYPE_PRIVATE } from '../../constants/backend';
import { safe, getPrice, handleCarImageFail } from '../../utils/utils';
import memoizeOne from 'memoize-one';

const isPrivateUsage = (booking) => {
  return safe(() => booking.carSharingInfo.usageType) === USAGE_TYPE_PRIVATE;
};

const getPrices = memoizeOne((booking) => {
  const { carSharingInfo } = booking || {};
  const { cost } = carSharingInfo || {};
  const { estimatedPriceWithoutVoucher, estimatedPriceForDuration } = cost || {};
  const { currency } = cost || {};

  if (isPrivateUsage(booking)) {
    const price = getPrice(estimatedPriceForDuration, currency);
    const priceBeforeVoucher = getPrice(estimatedPriceWithoutVoucher, currency);
    return { price, priceBeforeVoucher };
  }
  return {};
});

class VehicleCard extends Component {
  render() {
    const { vehicle: booking, theme, color, onClick } = this.props;

    const { price, priceBeforeVoucher } = getPrices(booking);
    const vehicleLabel = booking.vehicle.brand + ' ' + booking.vehicle.model;
    const fuelValue = safe(() => booking.vehicle.fuelLevel.percentage);
    const fuelLevel = fuelValue ? `(${fuelValue}%)` : '';

    const callToActionBtn = (
      <button className="btn btn-book primary-btn" style={{ backgroundColor: color }}>
        <FormattedMessage id="generic.book" />
      </button>
    );

    return (
      <div className="card-wrapper">
        <div className="card-car" onClick={onClick}>
          <div className="car-image">
            <img
              onError={(e) => handleCarImageFail(e, theme)}
              src={booking.vehicle.pictureUrl}
              alt={vehicleLabel}
              className="w-100"
            />
          </div>

          <div className="card-content">
            <div className="title">{vehicleLabel}</div>
            <div className="car-plate-number">({booking.vehicle.registrationNumber})</div>
            <div className="car-infos">
              <FormattedMessage id={'car.' + booking.vehicle.fuelType} />
              {fuelLevel} {' - '}
              <FormattedMessage id={'car.' + booking.vehicle.transmissionType} />
            </div>
            {price && isPrivateUsage(booking) && (
              <div className="car-price">
                {priceBeforeVoucher && (
                  <div className="price-before-voucher">{priceBeforeVoucher}</div>
                )}
                <span className="booking-cost">{price}</span>
              </div>
            )}
            <div className="visible-md">{callToActionBtn}</div>
          </div>

          <div className="card-content fullspace visible-sm visible-xs">
            {callToActionBtn}
          </div>
        </div>
      </div>
    );
  }
}

VehicleCard.defaultProps = {
  vehicle: {}
};

export default VehicleCard;
