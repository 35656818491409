import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _partial from 'lodash/partial';
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import { FormattedMessage } from 'react-intl';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import loaderImage from '../../assets/images/loader.gif';
import { formatDataForSearchBookingsApiCall } from '../../helpers';
import config from '../../constants/config';
import { currentBrandSelector, currentThemeSelector } from '../../helpers/selectors';
import VehicleCard from './VehicleCard';
import { ReactSVG } from 'react-svg';

import { STORAGE_KEY } from '../../constants/generic';

import * as BookingActions from '../../actions/booking';
import * as PopupActions from '../../actions/popup';
import * as UserActions from '../../actions/user';
import { safe } from "../../utils/utils";

export class SearchBookingsList extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.showRecapPopup = this.showRecapPopup.bind(this);
    this.backToList = this.backToList.bind(this);
  }

  componentDidMount() {
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.selectVehicleAfterPaymentRedirect(pp);
  }

  selectVehicleAfterPaymentRedirect(pp) {
    if (this.vehiclesUpdated(pp)) {
      const storedVehicleId = localStorage.getItem(STORAGE_KEY.VEHICLE_ID);

      if (storedVehicleId) {
        const { vehiclesData } = this.props.booking;
        const vehiclesFound = (vehiclesData || []).length;

        if (vehiclesFound) {
          localStorage.removeItem(STORAGE_KEY.VEHICLE_ID);

          const selectIndex = _findIndex(vehiclesData, (item) => {
            const { vehicle } = item || {};
            const { id } = vehicle || {};
            return id === storedVehicleId;
          });

          if (selectIndex === -1) {
            localStorage.removeItem(STORAGE_KEY.RECAP_DATA);
          } else {
            this.showRecapPopup(selectIndex);
          }
        }
      }
    }
  }

  vehiclesUpdated(pp) {
    const { vehiclesData: prevValue } = safe(() => pp.booking) || {};
    const { vehiclesData: nextValue } = this.props.booking || {};
    return nextValue !== prevValue || !pp;
  }

  showRecapPopup(index) {
    this.props.popup_BookingRecap_SetVisibility(true);
    this.props.loadBookingPopupData(index);
  }

  backToList() {
    const { emptyAgenciesData, search, contract, previousSearch } = this.props;
    const companyId = _get(this.props, 'user.data.company.id');
    const data = formatDataForSearchBookingsApiCall(previousSearch, companyId, contract);

    emptyAgenciesData();
    search(data);
  }

  render() {
    const {
      vehiclesData,
      vehiclesLimit,
      searchBookingsFetching,
      selectedAgency,
      selectedAgencyName
    } = this.props.booking;
    const { messages } = this.props.i18n;

    if (searchBookingsFetching) {
      return (
        <div className="vehicles-loader">
          <img src={loaderImage} alt="loader" />
        </div>
      );
    }

    return vehiclesData && vehiclesData.length > 0 ? (
      <div className="result-wrapper">
        {selectedAgency && (
          <div className="parking-set">
            <span className="agency-name" onClick={this.backToList}>
              <ReactSVG className="react-svg" src={arrowLeftSrc} />
              {selectedAgencyName}
            </span>
          </div>
        )}
        {!selectedAgency && (
          <div className="result-filter">
            <div className="info-text">
              <FormattedMessage
                id="label.vehicles_found"
                values={{ count: vehiclesData.length }}
              />
            </div>
            <div className="container-select">
              <FormattedMessage id="label.filter_by" />
              <div className="custom-select custom-select-xs sort">
                <select
                  onChange={(e) => {
                    this.props.sortBookings(e.target.value);
                  }}
                >
                  <option value="default">{messages['search.filter_by']}</option>
                  <option value="price">{messages['search.filter_by_price']}</option>
                  <option value="alphabetically">
                    {messages['search.filter_alphabetically']}
                  </option>
                </select>
              </div>
            </div>
          </div>
        )}
        <div className="result-content">
          <div className="container-fluid">
            <div className="row-sm">
              {vehiclesData.slice(0, vehiclesLimit).map((vehicle, index) => (
                <VehicleCard
                  vehicle={vehicle}
                  key={index}
                  theme={this.props.theme}
                  showAgency={true}
                  color={this.props.brand.branded.theme.colors.primary}
                  onClick={_partial(this.showRecapPopup, index)}
                />
              ))}
            </div>
            <div className="text-center pt-15">
              {vehiclesData.length > vehiclesLimit ? (
                <button
                  onClick={_partial(this.props.loadMoreBookings, config.bookingsLimit)}
                  className="btn btn-accent secondary-bg"
                >
                  {messages['label.load_more']}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="no-result">{messages['search.result.empty']}</div>
    );
  }
}

function mapStateToProps(state) {
  const branded = currentBrandSelector(state);
  const theme = currentThemeSelector(state);
  const contract = branded.contract || {};
  const previousSearch = _get(state, 'form.searchBookings.values');
  return { contract, previousSearch, theme, ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...PopupActions,
      ...UserActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBookingsList);
