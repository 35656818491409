import _has from 'lodash/has';
import {
  isPreBookng,
  isPublicServiceBooking,
  isReplacementVehicleBooking,
  isTestDriveBooking,
  searchRemoteCompanyIdSelector,
  messagesSelector
} from '../helpers/selectors';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { PRE_BOOKING_TYPE } from '../constants/generic';

export function search(data) {
  return (dispatch, getState) => {
    const state = getState();
    let companyId;
    if (!state.user.data) {
      companyId = searchRemoteCompanyIdSelector(state);
      if (!companyId) {
        const messages = messagesSelector(state);
        addErrorMsg(messages['anonymous.search.location.not.available']);
        return Promise.reject('failure');
      }

      data = { ...data, companyId };
    }
    let searchLocation = {};
    let isSite = false;
    if (_has(data.start, 'address.coordinates')) {
      searchLocation = {
        lat: data.start.address.coordinates.latitude,
        lng: data.start.address.coordinates.longitude
      };
    }
    if (_has(data.start, 'siteId')) {
      isSite = true;
    }

    return dispatch({
      type: 'booking/SEARCH',
      payload: {
        request: {
          method: 'post',
          url: '/v2/bookings/search-filtered',
          data: data
        },
        searchLocation,
        isSite
      }
    });
  };
}

export function setSelectedAgency(data) {
  return (dispatch, getState) => {
    const state = getState();
    let companyId;

    if (!state.user.data) {
      companyId = searchRemoteCompanyIdSelector(state);
      data = { ...data, companyId };
    }

    return dispatch({
      type: 'booking/SET_SELECTED_AGENCY',
      payload: {
        request: {
          method: 'post',
          url: '/v2/bookings/search-filtered',
          data: data
        }
      }
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function cancelBooking(bookingId) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'booking/CANCEL_BOOKING',
      payload: {
        request: {
          method: 'post',
          url: '/v2/bookings/' + bookingId + '/cancel',
          data: bookingId
        }
      }
    });
  };
}

export function bookVehicle(data, bookingId) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'booking/BOOK_VEHICLE',
      payload: {
        request: {
          method: 'post',
          url: bookingId ? '/v2/bookings/' + bookingId + '/update' : '/v2/bookings',
          data: data
        }
      }
    });
  };
}

export function sortBookings(filter) {
  return {
    type: 'booking/SORT_BOOKINGS',
    payload: filter
  };
}

export function loadMoreBookings(number) {
  return {
    type: 'booking/LOAD_MORE_BOOKINGS',
    payload: number
  };
}

// noinspection JSUnusedGlobalSymbols
export function loadBookingPopupData(index) {
  return (dispatch, getState) => {
    dispatch(getVehicleData(index)).then(() => {
      dispatch(getScheduleData(getState().booking.selectedVehicle));
    });
  };
}

export function getScheduleData(booking) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'booking/GET_PARKING_SCHEDULE',
      payload: {
        request: {
          url:
            `/v2/parkings/${booking.vehicle.parking.id}/schedule/computed?start=` +
            encodeURIComponent(booking.start.date) +
            '&end=' +
            encodeURIComponent(booking.end.date)
        }
      }
    });
  };
}

function getVehicleData(index) {
  return (dispatch) => {
    return new Promise((resolve) => {
      resolve(
        dispatch({
          type: 'booking/LOAD_BOOKINGS_POPUP_DATA',
          payload: index
        })
      );
    });
  };
}

export function loadBookingCancelId(bookingId) {
  return {
    type: 'booking/LOAD_BOOKING_CANCEL_ID',
    payload: bookingId
  };
}

export function loadBookingEditData(bookingData) {
  return {
    type: 'booking/LOAD_EDIT_DATA',
    payload: bookingData
  };
}

export function clearBookingEditData(bookingData) {
  return {
    type: 'booking/CLEAR_EDIT_DATA'
  };
}

export function loadBookingEditPopupData(bookingData) {
  return {
    type: 'booking/LOAD_EDIT_POPUP_DATA',
    payload: bookingData
  };
}

export function clearBookingEditPopupData() {
  return {
    type: 'booking/CLEAR_EDIT_POPUP_DATA'
  };
}

export function loadBookingEditFormData(formData) {
  return {
    type: 'booking/LOAD_EDIT_FORM_DATA',
    payload: formData
  };
}

export function clearBookingEditFormData() {
  return {
    type: 'booking/CLEAR_EDIT_FORM_DATA'
  };
}

export function emptyVehicleData() {
  return {
    type: 'booking/EMPTY_VEHICLE_DATA'
  };
}

export function setMapKeyboard(state) {
  if (state)
    return {
      type: 'booking/ENABLE_MAP_KEYBOARD'
    };
  else
    return {
      type: 'booking/DISABLE_MAP_KEYBOARD'
    };
}

export function emptyVehiclesData() {
  return {
    type: 'booking/EMPTY_VEHICLES_DATA'
  };
}

export function emptyBookingCancelId() {
  return {
    type: 'booking/EMPTY_BOOKING_CANCEL_ID'
  };
}

export function getBookingsByMemberId(memberId) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'booking/GET_BOOKINGS_BY_MEMBER_ID',
      payload: {
        request: {
          method: 'get',
          url: '/v2/members/' + memberId + '/bookings'
        }
      }
    });
  };
}

export function getPaginatedBookings(memberId, data) {
  return (dispatch, getState) => {
    return dispatch({
      type: 'booking/GET_BOOKINGS_BY_PAGE',
      payload: {
        request: {
          method: 'post',
          url: '/v2/members/' + memberId + '/bookings/page',
          data
        }
      }
    });
  };
}

export function savePreBookingStatus(status) {
  return {
    type: 'booking/SAVE_PRE_BOOKING_STATUS',
    status
  };
}

export function saveRegisterUpcomingBookings(bookings = []) {
  return {
    type: 'booking/SAVE_REGISTER_UPCOMING_BOOKINGS',
    bookings
  };
}

export function getPreBookingStatus(memberId) {
  return (dispatch) => {
    const data = {
      page: { number: 1, size: 5 },
      sort: { direction: 'ASC', property: 'START_DATE' },
      states: ['UPCOMING']
    };

    return dispatch(getPaginatedBookings(memberId, data)).then((data = {}) => {
      if (data.type === 'booking/GET_BOOKINGS_BY_PAGE_SUCCESS') {
        const { results = [] } = data.payload.data || {};
        let status = '';

        results.some((booking) => {
          if (isPreBookng(booking)) {
            if (isTestDriveBooking(booking)) {
              status = PRE_BOOKING_TYPE.TEST_DRIVE;
            } else if (isPublicServiceBooking(booking)) {
              status = PRE_BOOKING_TYPE.PUBLIC_SERVICE;
            } else if (isReplacementVehicleBooking(booking)) {
              status = PRE_BOOKING_TYPE.REPLACEMENT_VEHICLE;
            }
          }

          return status;
        });

        dispatch(saveRegisterUpcomingBookings(results));
        dispatch(savePreBookingStatus(status));
        return status;
      }
    });
  };
}

export function emptyAgenciesData() {
  return {
    type: 'booking/EMPTY_AGENCIES_DATA'
  };
}

export function setActiveBooking(data) {
  return {
    type: 'booking/SET_ACTIVE_BOOKING',
    payload: data
  };
}

export function clearVoucherData(data) {
  return {
    type: 'booking/CLEAR_VOUCHER_DATA',
    payload: data
  };
}
