import React, { Component } from 'react';
import { bindActionCreators } from 'redux';

import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as BookingActions from '../actions/booking';

import { change } from 'redux-form';
import { userIdSelector } from '../helpers/selectors';
import { getUsageFromContract, setDeepRouterQuery } from '../helpers';

import SearchBookingsHomepageForm from './forms/SearchBookingsHomepageForm';
import { addQuery, safe } from '../utils/utils';
import { routeNavigate } from '../helpers/components';
import { book } from '../constants/routes';

export class SearchBookingsSolo extends Component {
  constructor(props) {
    super(props);
    this.searchBookings = this.searchBookings.bind(this);
  }

  componentDidMount() {
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.updateUsageTypesField(pp);
  }

  contractUpdated(pp) {
    const { contract: next } = safe(() => this.props.brand.branded) || {};
    const { contract: prev } = safe(() => pp.brand.branded) || {};
    return next !== prev || !pp;
  }

  updateUsageTypesField(pp) {
    if (this.contractUpdated(pp)) {
      const { contract } = safe(() => this.props.brand.branded);
      const usage = getUsageFromContract(contract);
      this.props.dispatch(change('searchBookings', 'usageTypes', usage));
    }
  }

  searchBookings(formData) {
    const objQuery = setDeepRouterQuery(formData);
    routeNavigate(`/${book}` + addQuery(objQuery));
  }

  render() {
    return (
      <div className="searchbookings search-booking-homepage">
        <SearchBookingsHomepageForm
          contract={this.props.contract}
          onSubmit={this.searchBookings}
          classname="search-booking-homepage"
          i18n={this.props.i18n}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: userIdSelector(state),
    i18n: state.i18n,
    brand: state.brand,
    contract: state.brand.branded.contract
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      dispatch
    },
    dispatch
  );
}

const connectComponent = connect(mapStateToProps, mapDispatchToProps)(SearchBookingsSolo);

export default injectIntl(connectComponent);
