import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import pageIconSrc from '../../../src/assets/images/passport-circle.svg';
import { ProfileSelectField, profileTextField } from '../forms/newFields';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import IndexLink from '../partials/IndexLink'
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { messagesSelector, currentThemeSelector } from '../../helpers/selectors';
import { getMessage as t } from '../../utils/utils';
import classnames from 'classnames';
import { USER_FORM_NAMES } from '../../constants/form';
import FileField from './fields/file';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import { documentPresent } from '../../helpers/components';

const identityDocumentOptions = (m) => ({
  IDENTITY_CARD: t(m, 'label.identity.card'),
  PASSPORT: t(m, 'generic.passport')
});

function validate(values = {}, props = {}) {
  const errors = {};

  if (!props.hideFileUpload) {
    if (!documentPresent(values.documentFront)) errors.documentFront = true;
    if (!documentPresent(values.documentBack)) errors.documentBack = true;
  }

  return errors;
}

class IdentityDocument extends Component {
  getSubmitButtonLabel() {
    const { isSubscribePage, m } = this.props;
    return isSubscribePage ? t(m, 'generic.next') : t(m, 'generic.save');
  }

  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      m,
      userDataUpdating,
      hideFileUpload,
      theme
    } = this.props;

    const { identityDocument } = theme.icons || {};

    return (
      <form className="identity-document-page" onSubmit={handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={identityDocument ? identityDocument : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'label.identity_card')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            {hideFileUpload ? (
              <div className="scope-section-fields">
                <div className="scope-field-container">
                  <div className="scope-info-text">
                    {t(m, 'label.file.upload.only.mobile')}
                  </div>
                </div>
              </div>
            ) : (
              <div className="scope-section-fields">
                {this.identityDocumentPresentOnApi && (
                  <div className="scope-field-container">
                    <div className="scope-field-label">
                      {t(m, 'label.identity.document.type')} *
                    </div>
                    <Field
                      name="documentType"
                      options={identityDocumentOptions(m)}
                      component={ProfileSelectField}
                    />
                  </div>
                )}
                {this.identityDocumentPresentOnApi && (
                  <div className="scope-field-container">
                    <div className="scope-field-label">
                      {t(m, 'label.identity.document.number')} *
                    </div>
                    <Field
                      name="documentNumber"
                      placeholder={t(m, 'label.identity.document.number')}
                      component={profileTextField}
                    />
                  </div>
                )}
                {!hideFileUpload && (
                  <FileField
                    name="documentFront"
                    label={t(m, 'label.identity_card_front')}
                    mandatory
                  />
                )}
                {!hideFileUpload && (
                  <FileField
                    name="documentBack"
                    label={t(m, 'label.identity_card_back')}
                    mandatory
                  />
                )}
              </div>
            )}
          </div>
        </div>
        {!hideFileUpload && (
          <div className="scope-content-footer">
            <button
              type="submit"
              className={classnames('scope-submit-button', 'primary-btn', {
                'sub-class-disabled': userDataUpdating
              })}
            >
              {this.getSubmitButtonLabel()}
            </button>
          </div>
        )}
      </form>
    );
  }
}

IdentityDocument.defaultProps = {
  isSubscribePage: false
};

IdentityDocument.propTypes = {
  isSubscribePage: PropTypes.bool
};

// Add this fields when API will be ready:
// 'documentType', 'documentNumber'

const formComponent = reduxForm({
  form: USER_FORM_NAMES.IDENTITY_DOCUMENT,
  fields: ['documentFront', 'documentBack'],
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
  onSubmitFail: scrollToErrorInfo
})(IdentityDocument);

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;
  return {
    m: messagesSelector(state),
    userDataUpdating,
    theme: currentThemeSelector(state)
  };
};

export default connect(mapStateToProps)(formComponent);
