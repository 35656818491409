const backupApiUrl = 'https://gwweb-prod-glide.tech.rcimobility.com/api';
const backUpEnv = 'prod';

function safe(f) {
  try {
    return f();
  } catch (e) {}
}

function getAppObj() {
  return safe(() => window.APP) || {};
}

function getApiUrl() {
  const { apiUrl } = getAppObj();
  if (apiUrl) return apiUrl;

  const sessionApiUrl = safe(() => window.sessionStorage.getItem('API_URL'));
  if (sessionApiUrl) return sessionApiUrl;

  return backupApiUrl;
}

function getLocalEnv() {
  if (getApiUrl().includes('-prod-')) {
    return 'prod';
  } else if (getApiUrl().includes('-dev-')) {
    return 'dev';
  } else if (getApiUrl().includes('-valid-')) {
    return 'valid';
  } else if (getApiUrl().includes('-staging-')) {
    return 'staging';
  }
}

function isLocalEnv() {
  return process.env.NODE_ENV === 'development';
}

function isTestEnv(config) {
  const { env } = config;
  return env && env !== 'prod';
}

function setLocalData(config) {
  config.env = getLocalEnv();
  return setDevData(config);
}

function setEnvData(config) {
  Object.assign(config, getAppObj().settings);

  if (isTestEnv(config)) {
    setDevData(config);
  }

  return config;
}

function setDevData(config) {
  config.awsBrandsMapFileName = 'brandsMapDev.json';
  return config;
}

function getConfig() {
  const defaultConfig = {
    bookingsLimit: 4,
    amountToTake: '2,00',
    geoIpUrl: 'https://ip2c.org/self',
    awsBaseUrl: 'https://rcimob-assets.s3-eu-west-1.amazonaws.com/web-app',
    awsCompanyFolder: 'super-company',
    awsBrandsMapFileName: 'brandsMap.json',
    awsBrandsSelectFileName: 'brandsSelect.json',
    gMapsApiKey: 'AIzaSyDBcYCWGegc-v4Bamwnb-HQ2z2wS3ykH-s',
    mapboxEndpoint: 'https://api.mapbox.com/geocoding/v5',
    mapboxApiKey: 'pk.eyJ1Ijoib2xlZy1rdXoiLCJhIjoiY2wycmR2ejd2MDFwNDNqbWd0YzRuNGZiaiJ9.Pg-UmverhwsJyzQNTizNGg',
    mapboxUrl: 'https://www.mapbox.com/search-service',
    minimumBookingDurationMinutes: 30,
    appLoaderMinTime: 500,
    fileSizeLimitInMb: 5,
    luminosityRatio: 0.5,
    env: backUpEnv,
    getLocalEnv,
    getApiUrl
  };

  if (isLocalEnv()) {
    return setLocalData(defaultConfig);
  }

  return setEnvData(defaultConfig);
}

module.exports = getConfig();
