import { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider, useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { setNavigate } from './helpers/components';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useParams,
  useNavigate
} from 'react-router-dom';

import './assets/styles/main.scss';

import store from './store';
import App from './components/App';
import Homepage from './components/Homepage';
import SearchBookings from './components/SearchBookings';
import MyAccount from './components/MyAccount';
import ResetPassword from './components/ResetPassword';
import Authenticated from './security/Authenticated';
import BookingHistory from './components/BookingHistory';
import FAQ from './components/FAQ';
import Credentials from './components/account/Credentials';
import SearchBookingsSolo from './components/SearchBookingsSolo';
import PersonalDetails from './components/account/PersonalDetails';
import DrivingLicence from './components/account/DrivingLicence';
import IdentityDocument from './components/account/IdentityDocument';
import Selfie from './components/account/Selfie';
import MoreInfo from './components/account/MoreInfo';
import SwitchProfile from './components/account/SwitchProfile';
import PaymentInfo from './components/account/PaymentInfo';
import Notifications from './components/account/Notifications';
import SearchEditBookings from './components/SearchEditBookings';
import Redirect from './components/Redirect';
import ProfileLanguage from './components/account/ProfileLanguage';
import WelcomeMessage from './components/WelcomeMessage';
import ForcePasswordUpdate from './components/ForcePasswordUpdate';
import LegalInfo from './components/account/LegalInfo';
import SelectBrandCompany from './components/SelectBrandCompany';
import { saveGlobalState } from './helpers/selectors';
import Creation from './components/Creation';
import CreationCredentials from './components/Creation/Credentials';
import { validateAccount, creation as cr, book } from './constants/routes';
import { loadStripe } from '@stripe/stripe-js';
import { getAppObj, isDevEnv } from './utils/utils';
import { STRIPE_PUBLISHABLE_KEYS } from './constants/backend';
import CreationSuccess from './components/Creation/Success';
import CreationActivation from './components/Creation/Activation';
import CreationPersonalInfo from './components/Creation/PersonalInfo';
import CreationDrivingLicense from './components/Creation/DrivingLicense';
import CreationIdentityDocument from './components/Creation/IdentityDocument';
import CreationSelfie from './components/Creation/Selfie';
import CreationMoreInfo from './components/Creation/MoreInfo';

function initStripe() {
  const stripeKey = isDevEnv()
    ? STRIPE_PUBLISHABLE_KEYS.test
    : STRIPE_PUBLISHABLE_KEYS.production;
  getAppObj().Stripe = loadStripe(stripeKey);
}

function initGA() {
  ReactGA.initialize('UA-87106237-1');
}

function RouteProps({ component: Component }) {
  return <Component location={useLocation()} routeParams={useParams()} />;
}

function addElement(Component) {
  return { element: <RouteProps component={Component} /> };
}

function updateGA({ pathname, search }) {
  ReactGA.pageview(pathname + search);
}

function PreApp(props) {
  const navigate = useNavigate();
  const { location } = props;

  useSelector(saveGlobalState);

  useEffect(() => {
    updateGA(location);
  }, [location]);

  useEffect(() => {
    setNavigate(navigate);
  }, [navigate]);

  return <App {...props} />;
}

function Render() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" {...addElement(PreApp)}>
            <Route path="/index.html" {...addElement(Redirect)} />
            <Route index {...addElement(Homepage)} />
            <Route {...addElement(Authenticated)}>
              <Route {...addElement(MyAccount)} path="profile">
                <Route path="/profile/credentials" {...addElement(Credentials)} />
                <Route path="/profile/personal-info" {...addElement(PersonalDetails)} />
                <Route path="/profile/driver-license" {...addElement(DrivingLicence)} />
                <Route
                  path="/profile/identity-document"
                  {...addElement(IdentityDocument)}
                />
                <Route path="/profile/selfie" {...addElement(Selfie)} />
                <Route path="/profile/payment" {...addElement(PaymentInfo)} />
                <Route path="/profile/payment/success" {...addElement(PaymentInfo)} />
                <Route path="/profile/language" {...addElement(ProfileLanguage)} />
                <Route path="/profile/notifications" {...addElement(Notifications)} />
                <Route path="/profile/additional-info" {...addElement(MoreInfo)} />
                <Route path="/profile/switch" {...addElement(SwitchProfile)} />
                <Route path="/profile/legal-info" {...addElement(LegalInfo)} />
              </Route>
              <Route path="/password-update" {...addElement(ForcePasswordUpdate)} />
              <Route path="/welcome-message" {...addElement(WelcomeMessage)} />
              <Route path="my-bookings" {...addElement(BookingHistory)} />
              <Route path="edit-booking" {...addElement(SearchEditBookings)} />
              <Route path="mes-reservations" {...addElement(Redirect)} />
              <Route path="redirect" {...addElement(Redirect)} />
            </Route>
            <Route path="faq" {...addElement(FAQ)} />
            <Route {...addElement(Creation)} path={cr.container}>
              <Route
                path={`${cr.credentials}/:${cr.id}`}
                {...addElement(CreationCredentials)}
              />
              <Route
                path={`${cr.activate}/:${cr.id}`}
                {...addElement(CreationActivation)}
              />
              <Route
                path={`${cr.info}/:${cr.id}`}
                {...addElement(CreationPersonalInfo)}
              />
              <Route path={cr.files}>
                <Route
                  path={`${cr.drivingLicense}/:${cr.id}`}
                  {...addElement(CreationDrivingLicense)}
                />
                <Route
                  path={`${cr.identityDocument}/:${cr.id}`}
                  {...addElement(CreationIdentityDocument)}
                />
                <Route path={`${cr.selfie}/:${cr.id}`} {...addElement(CreationSelfie)} />
              </Route>
              <Route
                path={`${cr.moreInfo}/:${cr.id}`}
                {...addElement(CreationMoreInfo)}
              />
              <Route path={cr.success} {...addElement(CreationSuccess)} />
            </Route>
            <Route path="creation/:id" {...addElement(Redirect)} />
            <Route path="select-company" {...addElement(SelectBrandCompany)} />
            <Route path="/search-bookings" {...addElement(SearchBookingsSolo)} />
            <Route path="reset-password/:token" {...addElement(ResetPassword)} />
            <Route path={`${validateAccount}/:token`} {...addElement(Homepage)} />
            <Route path={book} {...addElement(SearchBookings)} />
            <Route path="reserver" {...addElement(Redirect)} />
            <Route path="create-booking" {...addElement(Redirect)} />
            <Route path="contactez-nous" {...addElement(Redirect)} />
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

function startApp() {
  ReactDOM.createRoot(document.getElementById('root')).render(<Render />);
}

initGA();
initStripe();
startApp();
