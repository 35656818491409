import React, { Component } from 'react';
import { newFileUpload } from '../../helpers/api';
import classnames from 'classnames';
import { ReactSVG } from 'react-svg';
import addDocumentIcon from '../../assets/images/add-image.svg';
import fileCheckMark from '../../assets/images/check-smooth.svg';
import fileErrorMark from '../../assets/images/error-mark.svg';
import fileAttachmentIcon from '../../assets/images/file-attachment.svg';
import Dropzone from 'react-dropzone';
import { userFilesSelector, messagesSelector } from '../../helpers/selectors';
import { connect } from 'react-redux';
import { getMessage as t } from '../../utils/utils';
import PropTypes from 'prop-types';
import { addErrorMsg } from '../../utils/flashMessage/creator';
import { getErrorMsg, partialErrorCodes } from '../../helpers/errors';
import config from '../../constants/config';
import { getFileById } from '../../actions/files';

class NewFileUpload extends Component {
  constructor(props) {
    super(props);
    this.setRefs();
    this.bindFunctions();
    this.initState();
  }

  setRefs() {
    this.setInputRef = (e) => (this.inputRef = e);
  }

  initState() {
    this.fileStatus = {
      STANDBY: 0,
      LOADING: 1,
      LOADED: 2,
      ERROR: 3
    };

    this.fileId = '';

    this.state = {
      fileStatus: this.fileStatus.STANDBY,
      fileContent: ''
    };
  }

  bindFunctions() {
    this.dropzoneContent = this.dropzoneContent.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.displayImage = this.displayImage.bind(this);
    this.handleOpenFile = this.handleOpenFile.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    this.componentPropsUpdated();
  }

  componentDidUpdate() {
    this.componentPropsUpdated();
  }

  componentPropsUpdated() {
    this.getFileContent();
  }

  getFileContent() {
    const { input } = this.props;
    const { value } = input;
    const { fileId } = value || {};

    if (fileId && fileId !== this.fileId) {
      this.fileId = fileId;
      this.setState({ fileStatus: this.fileStatus.LOADING });

      this.props.getFileById(fileId).then((resp) => {
        const content = resp?.payload?.data?.content;
        const type = resp?.payload?.data?.mimeType || 'image/*';

        if (content) {
          this.setState({
            fileStatus: this.fileStatus.LOADED,
            fileContent: `data:${type};base64,${content} `
          });
        } else {
          this.setState({ fileStatus: this.fileStatus.LOADED });
        }
      });
    }
  }

  showErrorPopup({ error, msg } = {}) {
    const { data } = error?.response || {};

    const errorMsg = getErrorMsg({
      bundle: this.props.m,
      error: msg || data,
      partialErrors: [partialErrorCodes.fileSizeExceeded]
    });

    addErrorMsg(errorMsg);
  }

  setInputErrorStatus(input, fileName) {
    this.setState({ fileStatus: this.fileStatus.ERROR });
    input.onChange({ fileId: '', fileName });
  }

  handleOnDrop(data) {
    if (this.state.fileStatus === this.fileStatus.LOADING) {
      return;
    }

    const firstFile = data[0];
    if (!firstFile) return;

    const { input } = this.props;
    const { name: fileName } = firstFile;
    const reader = new FileReader();

    if (firstFile.size > config.fileSizeLimitInMb * 1000 * 1000) {
      this.setInputErrorStatus(input, fileName);
      addErrorMsg(this.props.m['file_upload_size_exceeded']);
      return;
    }

    this.setState({ fileStatus: this.fileStatus.LOADING });
    input.onChange({ fileId: '', fileName });

    newFileUpload(firstFile).then(
      (success) => {
        const { fileId } = success;

        if (!fileId) {
          this.showErrorPopup({ msg: 'label.generic.error' });
          this.setInputErrorStatus(input, fileName);
        }
        reader.onload = () => {
          this.fileId = fileId;
          input.onChange({ fileId, fileName });

          this.setState({
            fileStatus: this.fileStatus.LOADED,
            fileContent: reader.result
          });
        };
        reader.onerror = () => {
          input.onChange({ fileId, fileName });
        };
        reader.readAsDataURL(firstFile);
      },
      (error) => {
        this.showErrorPopup({ error });
        this.setInputErrorStatus(input, fileName);
      }
    );
  }

  handleOpenFile() {
    this.inputRef.click();
  }

  handleInputChange(e) {
    const file = e.target.files[0];
    this.handleOnDrop([file]);
  }

  displayImage() {
    if (this.state.fileStatus === this.fileStatus.LOADED && this.state.fileContent) {
      return (
        <div className="img-container">
          <img src={this.state.fileContent} alt="" />
        </div>
      );
    } else if (this.state.fileStatus === this.fileStatus.LOADING) {
      return (
        <div className="img-container">
          <span className="spinner" />
        </div>
      );
    }
  }

  dropzoneContent({ getRootProps, isDragActive }) {
    const { m, disabled } = this.props;
    const fileUploading = this.state.fileStatus === this.fileStatus.LOADING;
    const onClickHandler = disabled || fileUploading ? undefined : this.handleOpenFile;

    return (
      <div
        {...getRootProps()}
        className={classnames('new-design-dropzone', {
          'sub-class-drag-active': isDragActive,
          'sub-class-uploading': fileUploading,
          'sub-class-disabled': disabled
        })}
        onClick={onClickHandler}
      >
        {this.displayImage()}
        {this.showFileNameAndStatus()}

        {!disabled && !fileUploading && (
          <div
            className={classnames({
              'new-design-dropzone-update': this.state.fileContent,
              'new-design-dropzone-create': !this.state.fileContent
            })}
          >
            <ReactSVG
              className={classnames('react-svg', 'new-design-dropzone-svg-container')}
              src={addDocumentIcon}
            />
            <span className="new-design-dropzone-title">
              {t(m, 'dropzone.inner.title')}
            </span>
            <span className="new-design-dropzone-info">
              {t(m, 'dropzone.inner.info')}
            </span>
            <input
              type="file"
              className="new-design-dropzone-input"
              onChange={this.handleInputChange}
              disabled={disabled}
              ref={this.setInputRef}
            />
          </div>
        )}
      </div>
    );
  }

  getStatusIcon() {
    if (this.state.fileStatus === this.fileStatus.LOADED) {
      return (
        <ReactSVG
          className="react-svg"
          beforeInjection={(svg) => {
            svg.classList.add('scope-file-uploaded-icon');
          }}
          src={fileCheckMark}
        />
      );
    } else if (this.state.fileStatus === this.fileStatus.ERROR) {
      return (
        <ReactSVG
          className="react-svg"
          beforeInjection={(svg) => {
            svg.classList.add('scope-file-error-icon');
          }}
          src={fileErrorMark}
        />
      );
    }
  }

  showFileNameAndStatus() {
    const { input } = this.props;
    const { value } = input;
    const { fileName } = value || {};

    if (fileName) {
      return (
        <div className="scope-file-attachment-container">
          <ReactSVG
            className="react-svg"
            beforeInjection={(svg) => {
              svg.classList.add('scope-file-attachment-icon');
            }}
            src={fileAttachmentIcon}
          />
          <span className="scope-file-attachment-name">{fileName}</span>
          <div className="scope-status-icon-container">{this.getStatusIcon()}</div>
        </div>
      );
    }
  }

  render() {
    const { label, mandatory, disabled } = this.props;

    return (
      <div className="new-design-file-upload">
        <div className="scope-field-label">
          {label}
          {mandatory && ' *'}
        </div>
        <Dropzone onDrop={this.handleOnDrop} disabled={disabled}>
          {this.dropzoneContent}
        </Dropzone>
      </div>
    );
  }
}

NewFileUpload.propTypes = {
  label: PropTypes.any,
  mandatory: PropTypes.bool,
  disabled: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    files: userFilesSelector(state)
  };
};

const mapDispatchToProps = { getFileById };

export default connect(mapStateToProps, mapDispatchToProps)(NewFileUpload);
