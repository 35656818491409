import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelfieForm from './SelfieForm';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import { displayProfileUpdateSuccess } from '../../helpers/components';
import { formatSelfieForApiCall } from '../../helpers/formatters';
import {
  isRenaultFranceUserSelector,
  messagesSelector,
  selfieFormInitialValuesSelector,
  userCompanyIdSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';

class Selfie extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.updateSelfieDocument = this.updateSelfieDocument.bind(this);
  }

  updateSelfieDocument(formValues) {
    const { m, updateInformations, userId, userData, userDataUpdating } = this.props;

    if (!userDataUpdating) {
      updateInformations(userId, formatSelfieForApiCall(userData, formValues)).then(
        (data) => {
          if (data.type === 'user/UPDATE_INFO_SUCCESS') {
            displayProfileUpdateSuccess(m, 'profile.section.selfie');
          }
        }
      );
    }
  }

  render() {
    const { initialValues, isRenaultFranceUser } = this.props;

    return (
      <SelfieForm
        onSubmit={this.updateSelfieDocument}
        initialValues={initialValues}
        hideFileUpload={isRenaultFranceUser}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;

  return {
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    m: messagesSelector(state),
    isRenaultFranceUser: isRenaultFranceUserSelector(state),
    userCompanyId: userCompanyIdSelector(state),
    initialValues: selfieFormInitialValuesSelector(state),
    userDataUpdating
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Selfie);
