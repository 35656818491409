import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import store from '../../store';
import { popup_Login_SetVisibility } from '../../actions/popup';

class LoginMsgForm extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.openLoginPopup = () => store.dispatch(popup_Login_SetVisibility(true));
  }

  render() {
    return (
      <div className="login-msg-form">
        <div className="scope-title">
          <FormattedMessage id={this.props.msgKey} />
        </div>
        <button
          onClick={this.openLoginPopup}
          className={classnames('btn', 'btn-md', 'btn-accent', 'primary-btn')}
        >
          <FormattedMessage id="label.login" />
        </button>
      </div>
    );
  }
}

LoginMsgForm.propTypes = {
  msgKey: PropTypes.string
};

export default LoginMsgForm;
