import endpoints from '../constants/endpoints';
import { getAppObj, serialize } from '../utils/utils';
import { brandIdSelector } from '../helpers/selectors';
import { setAppLoaderState } from './brand';
import { setLoginPending } from '../utils/keycloak';

export function verifyUser(updateIncompleteStatus = true) {
  return (dispatch) => {
    return dispatch({
      type: 'keycloak/VERIFY_USER',
      updateIncompleteStatus,
      payload: {
        request: {
          method: 'get',
          url: endpoints.me
        }
      }
    });
  };
}

// noinspection JSUnusedGlobalSymbols
export function handleVerifyUserFail(payload) {
  return {
    type: 'keycloak/VERIFY_FAILED',
    payload
  };
}

export function setRegisterExternalUserStatus(payload) {
  return {
    type: 'keycloak/SET_REGISTER_STATUS',
    payload
  };
}

export function getRemoteKeycloakConfig(email) {
  return (dispatch, getState) => {
    const params = { brandId: brandIdSelector(getState()), email };

    return dispatch({
      type: 'keycloak/GET_REMOTE_CONFIG',
      payload: {
        request: {
          method: 'get',
          url: `/v2/companies/sso-registration?${serialize(params)}`
        }
      }
    });
  };
}

export function handleKeycloakLogin(keycloakConfig) {
  return (dispatch) => {
    const { keycloak } = getAppObj();
    const { createLoginUrl } = keycloak || {};
    const { identityProvider } = keycloakConfig;

    if (createLoginUrl) {
      dispatch(setAppLoaderState(true));
      setLoginPending();

      window.location.href = createLoginUrl({
        idpHint: identityProvider,
        prompt: 'login'
      });
    }
  };
}
