import DrivingLicenceForm from '../account/DrivingLicenceForm';
import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  drivingLicenceFormInitialValuesSelector,
  drivingLicenseAllowedSelector,
  messagesSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';
import { updateInformations } from '../../actions/user';
import { navigateToCreationIdentityDocument } from '../../utils/utils';
import { getCreationId, showApiError } from '../../helpers/components';
import { formatDrivingLicenseForApiCall } from '../../helpers/formatters';

function CreationDrivingLicense(p) {
  const r = useRef({ fetching: false }).current;

  useEffect(() => {
    if (!p.drivingLicenseAllowed) {
      navigateToCreationIdentityDocument(getCreationId(p.routeParams), true);
    }
  }, [p.drivingLicenseAllowed]);

  const updateUserData = useCallback(
    (formValues) => {
      if (!r.fetching) {
        r.fetching = true;
        p.updateInformations(
          p.userId,
          formatDrivingLicenseForApiCall(p.userData, formValues)
        ).then((data) => {
          r.fetching = false;
          if (data?.type === 'user/UPDATE_INFO_SUCCESS') {
            navigateToCreationIdentityDocument(getCreationId(p.routeParams));
          } else {
            showApiError(p.m, data);
          }
        });
      }
    },
    [r.fetching, p.userId, p.userData, p.routeParams, p.m]
  );

  return (
    <DrivingLicenceForm
      isSubscribePage
      onSubmit={updateUserData}
      initialValues={p.initialValues}
    />
  );
}

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    initialValues: drivingLicenceFormInitialValuesSelector(state),
    drivingLicenseAllowed: drivingLicenseAllowedSelector(state)
  };
}

const mapDispatchToProps = { updateInformations };

export default connect(mapStateToProps, mapDispatchToProps)(CreationDrivingLicense);

// TODO: preserve driving license for linked profile
