import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import cautionIcon from '../../assets/images/caution.svg';

const AnonymizeConfirmPopup = ({
  handleSubmit,
  onCloseRequested,
  children,
  cancelMsg = <FormattedMessage id="generic.cancel" />,
  submitMsg = <FormattedMessage id="label.confirm" />,
  iconSrc = cautionIcon
}) => {
  return (
    <div className="p-15 text-center anonymize-confirm-popup">
      <p className="bold mt-xs-25 mt-dt-35 f-25 mb-dt-25 mb-xs-15">
        <img className="scope-icon" src={iconSrc} alt="" />
      </p>
      <p className="f-15 mt-40">{children}</p>
      <div className="mt-40 hr-dashed" />

      <Row className="mt-30">
        <Col
          xs={12}
          sm={6}
          md={6}
          className="f-0 pr-md-5 mb-xs-10 scope-button-container sub-class-left"
          onClick={onCloseRequested}
        >
          <div className="scope-button secondary-bg scope-button-return">{cancelMsg}</div>
        </Col>
        <Col
          xs={12}
          sm={6}
          md={6}
          className="f-0 pl-md-5 scope-button-container sub-class-right"
          onClick={handleSubmit}
        >
          <div className="scope-button primary-btn  scope-button-cancel">{submitMsg}</div>
        </Col>
      </Row>
    </div>
  );
};

export default AnonymizeConfirmPopup;
