/* global $ */

import { browserHistory } from './components';
import moment from 'moment';
import validator from 'validator';
import _ from 'lodash';

import { USAGE_TYPE_PRIVATE, USAGE_TYPE_BUSINESS } from '../constants/backend';
import { ONE_WAY, RETURN_TRIP } from '../constants/generic';
import { daysOfWeek } from '../constants/staticData';

import {
  append,
  trimStrings,
  trySet,
  getMessage as t,
  safe,
  getNavigatorCountry
} from '../utils/utils';

import config from '../constants/config';
import _map from 'lodash/map';
import { cleanDeep } from '../utils/cleanDeep';
import { keysDaysOfWeek } from '../constants/translations';
import { getDefaultLocale } from '../utils/utils';
import memoizeOne from 'memoize-one';

// TODO: check if there is no whitespace at the start/end
export const checkPassword = (password, messages) => {
  if (!password) {
    return messages['error.form.required'];
  } else if (password.length < 8) {
    return messages['error.form.password.at_least'];
  } else if (password.length > 25) {
    return messages['error.form.password.at_most'];
  } else if (!password.match(/\d/g)) {
    return messages['error.form.password.digit'];
  } else if (!password.match(/[A-Z]/g)) {
    return messages['error.form.password.upper_letter'];
  } else if (!password.match(/[a-z]/g)) {
    return messages['error.form.password.lower_letter'];
  }
};

export const checkPasswordConfirmation = (confirmationPassword, password, messages) => {
  const check = checkPassword(confirmationPassword, messages);
  if (check) {
    return check;
  }

  if (password && password !== confirmationPassword) {
    return messages['error.form.password_confirmation.the_same'];
  }
};

export const checkText = (field = '', m = {}) => {
  if (!field.trim()) return t(m, 'error.form.required');
};

export const checkEmail = (email = '', m = {}) => {
  if (!email.trim()) {
    return t(m, 'error.form.required');
  } else if (!validator.isEmail(email)) {
    return t(m, 'error.form.email_invalid');
  }
};

export const checkPhoneFormat = (phoneNumber, messages) => {
  if (!phoneNumber) {
    return messages['error.form.required'];
  } else if (
    !/(^([67])\d{8}$)|(^(06|07)\d{8}$)/.test(phoneNumber) &&
    !/(^([123459])\d{8}$)|(^(01|02|03|04|05|09)\d{8}$)/.test(phoneNumber)
  ) {
    return messages['error.phone.format.invalid'];
  }
};

export const checkPhoneNumber = (phoneNumberObject = {}, messages = []) => {
  const { valid, phoneSuffix } = phoneNumberObject;
  if (!phoneSuffix) return true;
  if (!valid) return messages['error.phone.format.invalid'];
};

export const checkPhoneNumberFull = (phoneNumberObject = {}, messages = []) => {
  const check = checkPhoneNumber(phoneNumberObject, messages);
  if (check === true) return messages['error.form.required'];
  return check;
};

export const checkShortId = (inviteCode, m) => {
  if (!inviteCode) {
    return t(m, 'error.form.inviteCode.notEmpty');
  } else if (!/^[A-Za-z0-9]{8}$/.test(inviteCode)) {
    return t(m, 'error.invite.code.invalid');
  }
};

export const range = (start, end) => {
  return Array(Math.abs(end - start) + 1).map((_, idx) =>
    end > start ? start + idx : start - idx
  );
};

export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export function setDeepRouterQuery(paramsObject = {}, prefix) {
  const flatObject = {};

  for (const param in paramsObject) {
    const key = prefix ? prefix + '[' + param + ']' : param;
    const value = paramsObject[param];

    if (typeof value === 'object') {
      Object.assign(flatObject, setDeepRouterQuery(value, key));
    } else flatObject[key] = value;
  }

  return flatObject;
}

export function decodeRouterString(str = '') {
  return decodeURIComponent(str.replace(/\+/g, '%20'));
}

export const getObjectFromQueryMemoize = memoizeOne((query) => {
  if (query.charAt(0) === '?') query = query.substring(1);
  if (!query) return; // must return 'undefined'

  const result = {};

  query.split('&').forEach((part) => {
    const item = part.split('=');
    const value = decodeRouterString(item[1]);
    const key = decodeRouterString(item[0]);

    _.set(result, key, value);
  });

  return result;
});

export const getObjectFromQuery = (query = window.location.search) => {
  return getObjectFromQueryMemoize(query);
};

export const getObjectFromHash = () => {
  if (!window.location.hash) return {};
  const query = window.location.hash.substring(window.location.hash.indexOf('?') + 1);
  return getObjectFromQuery(query);
};

export const getUsageFromContract = memoizeOne((contract) => {
  if (!contract) return USAGE_TYPE_PRIVATE;
  const hasPrivateUsage = contract.privateCarSharing;
  const hasBusinessUsage = contract.businessCarSharing;
  return hasPrivateUsage && !hasBusinessUsage ? USAGE_TYPE_PRIVATE : USAGE_TYPE_BUSINESS;
});

export const formatDataForSearchBookingsApiCall = (
  data = {},
  companyId,
  contract,
  bookingId = undefined
) => {
  data = trimStrings(data);

  const { location } = data;
  const { coordinates, siteId } = location || {};
  const contractUsage = getUsageFromContract(contract);

  let dataObject = {
    page: {
      number: 0,
      size: 100
    },
    companyId,
    usageTypes: [!data.usageTypes ? contractUsage : data.usageTypes]
  };

  const roundTrip = (!data.endDate && !data.endTime) === false;

  trySet(dataObject, 'start.address', append({ coordinates }));
  trySet(dataObject, 'start.date', data.startDate + 'T' + data.startTime);
  trySet(dataObject, 'start.siteId', siteId);
  trySet(dataObject, 'end.address', append({ coordinates }));

  if (roundTrip && !!data.endDate && !!data.endTime) {
    trySet(dataObject, 'end.date', data.endDate + 'T' + data.endTime);
  }

  trySet(dataObject, 'end.siteId', siteId);
  trySet(dataObject, 'vehicleType', data.vehicleType);
  trySet(dataObject, 'passengers', +Number(data.places), 1);
  trySet(dataObject, 'doorsNumber', +data.portes);
  trySet(dataObject, 'categories', data.categories);
  trySet(dataObject, 'originalBookingId', bookingId);
  trySet(dataObject, 'voucherCode', data.voucherCode);
  trySet(dataObject, 'fuelTypes', data.motorisation);
  trySet(dataObject, 'transmissionTypes', data.transmission);
  trySet(dataObject, 'accessories', data.accessories);

  if (_.get(contract, 'interfaceConfig.oneWayDisplay') && !roundTrip) {
    delete dataObject.end;
  }
  return dataObject;
};

export const isNumeric = (n) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

export const timeFormat = (time) => {
  return time ? moment(time, 'hh:mm').format('HH[:]mm A') : '';
};

export const timeSlotFormat = (timeIntervals, scheduleString) => {
  timeIntervals.map((timeItem, timeIndex) => {
    if (timeIndex === 0) {
      scheduleString += timeFormat(timeItem.start) + ' - ' + timeFormat(timeItem.end);
    } else {
      scheduleString +=
        ', ' + timeFormat(timeItem.start) + ' - ' + timeFormat(timeItem.end);
    }

    return scheduleString;
  });

  return scheduleString;
};

export const weekDayFormat = (recurringSlot, messages) => {
  let schedule = [];

  _.map(recurringSlot.days, (dayItem) => {
    let timesStr = timeSlotFormat(recurringSlot.timeIntervals, '');
    const labelDay = messages[keysDaysOfWeek[dayItem]];

    const day = {
      day: dayItem,
      labelDay,
      time: timesStr
    };
    schedule.push(day);
  });

  return schedule;
};

export const oneTimeSlotNewFormat = (oneTimeSlot, messages) => {
  let schedule = [];
  const formattedDay =
    moment(oneTimeSlot.startDate).diff(moment(oneTimeSlot.endDate), 'days') !== 0
      ? _.startCase(moment(oneTimeSlot.startDate).format('dddd DD MMMM')) +
        ' ' +
        messages['label.the'] +
        ' ' +
        _.startCase(moment(oneTimeSlot.endDate).format('dddd DD MMMM'))
      : _.startCase(moment(oneTimeSlot.startDate).format('dddd DD MMMM'));
  let day = {
    day: formattedDay,
    labelDay: ''
  };

  if (oneTimeSlot.open) {
    day.time = timeSlotFormat(oneTimeSlot.timeIntervals, '');
  } else {
    day.time = ' : ' + messages['label.close'].toUpperCase();
  }
  schedule.push(day);

  return schedule;
};

export const orderedArray = (arr) =>
  _.sortBy(arr, (item) => _.indexOf(daysOfWeek, item.day));

export const scheduleNewDataFormat = (data, messages) => {
  let schedule = [];
  if (data) {
    data.recurringSlots.map((item) => {
      const tmp = weekDayFormat(item, messages);
      tmp.map((day) => schedule.push(day));
      return schedule;
    });

    data.oneTimeSlots.map((item) => {
      const tmp1 = oneTimeSlotNewFormat(item, messages);
      schedule.push(tmp1[0]);
      return schedule;
    });
  }
  schedule = orderedArray(schedule);
  return schedule;
};

export const scrollToFirstError = () => {
  setTimeout(() => {
    _.some($('.error-msg'), (elem) => {
      if ($(elem).html().length) {
        $('html, body').animate(
          {
            scrollTop: $(elem).offset().top - 50
          },
          700
        );
        return true;
      }
    });
  }, 0);
};

export const removeModalQueryParam = () => {
  const queryParams = getObjectFromQuery() || {};
  const { modal, ...restQuery } = queryParams;

  if (modal) {
    const queryExist = !_.isEmpty(restQuery);
    const params = { pathname: window.location.pathname };

    if (queryExist) params.query = restQuery;

    browserHistory.replace(params);
  }
};

export function momentDateTimeFormat(date, format) {
  return (
    date && moment.utc(date).utcOffset(moment.parseZone(date).utcOffset()).format(format)
  );
}

export function setMomentTimeForRequest(data) {
  if (_.get(data, 'startTime', null) && isNumeric(data.startTime)) {
    data.startTime = moment.utc(data.startTime).format('HH:mm:ss');
  }

  if (_.get(data, 'endTime', null) && isNumeric(data.endTime)) {
    data.endTime = moment.utc(data.endTime).format('HH:mm:ss');
  }

  return data;
}

export const getCountryAndLang = () => {
  const language = getDefaultLocale();
  const country = getNavigatorCountry() || 'FR';

  return { language, country };
};

export function cleanDocumentFile(fileData) {
  const goodFile = { ...fileData };
  const { files } = goodFile;

  goodFile.files = _map(files, (file = {}) => {
    const { fileId, type } = file;
    return { fileId, type };
  });

  delete goodFile.fileId;
  return goodFile;
}

export function cleanFilesForApi(apiData = {}) {
  const newData = { ...apiData };
  const { drivingLicence, identityDocument } = newData;

  if (drivingLicence) newData.drivingLicence = cleanDocumentFile(drivingLicence);
  if (identityDocument) newData.identityDocument = cleanDocumentFile(identityDocument);

  return newData;
}

export const filterCompanyCustomFields = (companyFields = [], payloadFields = []) => {
  return safe(() => {
    const companyFieldIds = companyFields.reduce((fields, field) => {
      fields[field.id] = true;
      return fields;
    }, {});

    return payloadFields.reduce((fields, field) => {
      const validField = safe(() => companyFieldIds[field.companyCustomFieldId]);
      if (validField) fields.push(field);
      return fields;
    }, []);
  });
};

export function cleanCustomFieldsForApi(apiData = {}) {
  const newData = { ...apiData };
  const { memberCustomValues } = newData;

  newData.memberCustomValues = _map(memberCustomValues, (item = {}) => {
    const { value, companyCustomField } = item;
    const { id } = companyCustomField || {};
    return { companyCustomFieldId: id, value };
  });

  return newData;
}

export function cleanUserDataForApi(apiData) {
  return cleanDeep(cleanFilesForApi(cleanCustomFieldsForApi(apiData)));
}

export function formatBirthDateForApi(date) {
  if (date) return moment(date).format('YYYY-MM-DD');
}

export function getAWayFromContract(contract) {
  if (!contract) return RETURN_TRIP;
  const hasOneWay = contract.interfaceConfig.oneWayDisplay;
  const hasReturnTrip = contract.interfaceConfig.returnTripDisplay;
  return hasOneWay && !hasReturnTrip ? ONE_WAY : RETURN_TRIP;
}

export function formatGeocodingApiParams(params) {
  return cleanDeep({
    ...params,
    access_token: config.mapboxApiKey,
    types: safe(() => params.types.join())
  });
}
