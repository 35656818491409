import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

export default ({ className, activeClassName, children, ...props }) => {
  return (
    <NavLink
      {...props}
      className={({ isActive, isPending }) =>
        classnames(className, { [activeClassName]: isPending || isActive })
      }
    >
      {children}
    </NavLink>
  );
};
