/* global $ */

import React, { Component } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import shortid from 'shortid';
import config from '../../constants/config';
import { connect } from 'react-redux';
import { timePickerIntervalSelector } from '../../helpers/selectors';
import { testProp } from '../../utils/utils';

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.setVars();
    this.setCallbacks();
    this.initState();
  }

  setVars() {
    this.inputId = shortid.generate();
    this.inputSelector = '#' + this.inputId;
    this.displayFormat = 'HH:i';
  }

  initState() {
    this.state = { picker: null };
  }

  setCallbacks() {
    this.handleOnOpen = () => {
      document.getElementById(this.inputId).parentNode.classList.add('picker--active');
    };

    this.handleOnClose = () => {
      const element = document.getElementById(this.inputId);
      if (element) element.parentNode.classList.remove('picker--active');
    };

    this.openPicker = () => {
      this.updateInterval();

      if (this.props.value) {
        this.state.picker.set('select', this.props.value, { format: this.displayFormat });
      }

      if (this.props.isEndTime && this.props.isSameDay) {
        const end = moment(this.props.startTime, 'HH:mm:ss')
          .add(config.minimumBookingDurationMinutes, 'minutes')
          .format('HH:mm');

        this.state.picker.set('select', end, { format: this.displayFormat });
        const endHour = end.split(':');
        const h = endHour[0];
        const m = endHour[1];
        this.state.picker.set('min', [h, m]);
      } else {
        this.state.picker.set('min', false);
      }

      if (!this.props.isEndTime && !!this.props.startDate) {
        if (moment(this.props.startDate).isSame(new Date(), 'day')) {
          this.state.picker.set('min', true);
        } else this.state.picker.set('min', false);
      }

      this.state.picker.open();
    };
  }

  componentDidMount() {
    this.setupPickatime();
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.setupPickatime();
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.updateError(pp);
  }

  updateError(pp) {
    const { showError, onError } = this.props;

    if (testProp.call(this, pp, { showError }) && onError) {
      onError(showError);
    }
  }

  getContextTime(context) {
    const { select } = context;
    const { pick } = select || {};
    if (typeof select === 'number') return select;
    if (pick) return pick;
  }

  setupPickatime() {
    if (!this.state.picker) {
      let $picker = $(this.inputSelector).pickatime({
        format: 'HH:i',
        interval: this.props.interval,
        onSet: (context) => {
          let selectedTime = this.getContextTime(context) * 60000;
          if (selectedTime) this.props.onTimeSelected({ value: selectedTime });
        },
        onOpen: this.handleOnOpen,
        onClose: this.handleOnClose
      });

      this.setState({
        picker: $picker.pickatime('picker')
      });
    }
  }

  updateInterval() {
    this.state.picker.set('interval', this.props.interval);
  }

  placeholder() {
    return (
      <i className={classnames({ 'text-danger': this.props.showError })}>
        {this.props.label}
      </i>
    );
  }

  getValidTime(momentValue) {
    if (momentValue.isValid()) return momentValue.format(this.props.inputFormat);
    else return this.placeholder();
  }

  displayTime() {
    if (this.props.value) {
      if (this.props.inputFormat) {
        const momentValue = moment.utc(this.props.value, this.props.inputFormat);
        return this.getValidTime(momentValue);
      } else {
        const momentValue = moment.utc(this.props.value);
        return this.getValidTime(momentValue);
      }
    } else return this.placeholder();
  }

  render() {
    return (
      <div className="time-picker" onClick={this.openPicker}>
        <div
          className={classnames({
            value: true,
            'text-danger': this.props.showError
          })}
        >
          {this.displayTime()}
        </div>
        <input id={this.inputId} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { interval: timePickerIntervalSelector(state) };
}

export default connect(mapStateToProps)(TimePicker);
