import React, { Component } from 'react';
import { keycloakConfigSelector } from '../../helpers/selectors';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { handleKeycloakLogin } from '../../actions/keycloak';
import { bindActionCreators } from 'redux';

class OnlySsoForm extends Component {
  constructor(p) {
    super(p);
    this.setCallbacks();
  }

  setCallbacks() {
    this.handleSsoButtonClick = () => {
      const { keycloakConfig } = this.props;
      const { useBrandConfig } = keycloakConfig || {};

      if (useBrandConfig) {
        this.props.handleKeycloakLogin(keycloakConfig);
      }
    };
  }

  render() {
    return (
      <div className="only-sso-form">
        <div className="sc-title">
          <FormattedMessage id="only.sso.homepage.title" />
        </div>
        <button
          className="btn btn-md btn-accent primary-btn"
          onClick={this.handleSsoButtonClick}
        >
          <FormattedMessage id="common_connect" />
        </button>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { keycloakConfig: keycloakConfigSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ handleKeycloakLogin }, dispatch);
}

OnlySsoForm = connect(mapStateToProps, mapDispatchToProps)(OnlySsoForm);

export default OnlySsoForm;
