import { Component } from 'react';
import { connect } from 'react-redux';
import { getBrandCompanies } from '../actions/user';
import { brandCompaniesSelector, brandIdSelector } from '../helpers/selectors';
import { ReactSVG } from 'react-svg';

import companyIconSrc from '../assets/images/usage-types/corporate.svg';
import { FormattedMessage } from 'react-intl';
import { navigateToCreationPage } from '../utils/utils';

class SelectBrandCompany extends Component {
  getCompaniesData() {
    const { props: p } = this;
    p.dispatch(getBrandCompanies(p.brandId));
  }

  componentDidMount() {
    this.getCompaniesData();
  }

  renderItem(item) {
    return (
      <button
        key={item.id}
        className="sc-item"
        onClick={() => navigateToCreationPage(item.id)}
      >
        <span className="sc-img-container">
          <img alt="company-logo" src={item.logoUrl} />
        </span>
        <span className="sc-company-name">{item.name}</span>
      </button>
    );
  }

  render() {
    const { props: p } = this;

    return (
      <div className="select-brand-company">
        <div className="sc-wrap">
          <div className="sc-entry-icon">
            <ReactSVG wrapper="span" className="react-svg" src={companyIconSrc} />
            <FormattedMessage id="service.usage.corporate" />
          </div>
          <div className="sc-inner">
            <div className="sc-title-msg">
              {p.list && <FormattedMessage id="only.sso.select.company.select.title" />}
              {!p.list && <FormattedMessage id="only.sso.select.company.select.empty" />}
            </div>
            <div className="sc-list">
              {p.list && p.list.map((item) => this.renderItem(item))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    brandId: brandIdSelector(state),
    list: brandCompaniesSelector(state)
  };
}

SelectBrandCompany = connect(mapStateToProps)(SelectBrandCompany);

export default SelectBrandCompany;
