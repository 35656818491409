import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { getAppObj } from "../utils/utils";

const maximumScrollTries = 4;

function setScrollTries() {
  const { errorInfoTries } = getAppObj();

  if (typeof errorInfoTries === 'number') getAppObj().errorInfoTries++;
  else getAppObj().errorInfoTries = 0;
}

function tryScrollToError() {
  const { errorInfoTries } = getAppObj();

  if (errorInfoTries < maximumScrollTries) {
    setTimeout(scrollToErrorInfo, 100);
  } else resetScrollTries();
}

function resetScrollTries() {
  getAppObj().errorInfoTries = undefined;
}

export function scrollToErrorInfo() {
  scrollToErrorClass('field-error-info');
}

export function scrollToErrorClass(className) {
  const nodeArray = document.getElementsByClassName(className);

  if (nodeArray && nodeArray.length > 0) {
    scrollIntoView(nodeArray[0]);
    resetScrollTries();
  } else {
    setScrollTries();
    tryScrollToError();
  }
}
