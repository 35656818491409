import RegisterSuccess from '../account/RegisterSuccess';
import { connect } from 'react-redux';
import {
  creationSuccessDataSelector,
  isProfileIncompleteUserSelector,
  isRegisterIncompleteUserSelector,
  preBookingStatusSelector
} from '../../helpers/selectors';
import { navigateToBookings, navigateToHomePage } from '../../utils/utils';
import { useCallback } from 'react';

function CreationSuccess(p) {
  const d = p.creationSuccessData;

  const handleSubmit = useCallback(() => {
    if (p.preBookingStatus) {
      navigateToBookings();
    } else {
      navigateToHomePage();
    }
  }, [p.preBookingStatus]);

  return (
    <RegisterSuccess
      isCreationPage
      email={d.email}
      firstName={d.firstName}
      onSubmit={handleSubmit}
      isRegisterIncompleteUser={p.isRegisterIncompleteUser}
      isProfileIncompleteUser={p.isProfileIncompleteUser}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    isRegisterIncompleteUser: isRegisterIncompleteUserSelector(state),
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    preBookingStatus: preBookingStatusSelector(state),
    creationSuccessData: creationSuccessDataSelector(state)
  };
};

CreationSuccess = connect(mapStateToProps)(CreationSuccess);

export default CreationSuccess;
