import classnames from 'classnames';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getErrorInfo } from './helpers';
import { Field } from 'redux-form';
import { ReactSVG } from 'react-svg';
import eyeOpen from '../../../assets/images/eye/open.svg';
import eyeClosed from '../../../assets/images/eye/closed.svg';

function getInputPart({ input, ...p }) {
  const [showPassword, setShowPassword] = useState(false);

  function getFieldType() {
    return showPassword ? 'text' : 'password';
  }

  function getPasswordButton() {
    function changePasswordState() {
      setShowPassword(!showPassword);
    }

    return (
      <button
        type="button"
        className={classnames('password-toggle-button', { 'sc-show': showPassword })}
        onClick={changePasswordState}
      >
        <ReactSVG
          wrapper="span"
          src={eyeOpen}
          className={classnames('react-svg', 'sc-open')}
        />
        <ReactSVG
          wrapper="span"
          src={eyeClosed}
          className={classnames('react-svg', 'sc-closed')}
        />
      </button>
    );
  }

  return (
    <div className="password-toggle-scope">
      <input
        className="new-design-field"
        type={getFieldType()}
        placeholder={p.placeholder}
        autoComplete={p.autoComplete}
        {...input}
      />
      {getPasswordButton()}
    </div>
  );
}

function passwordField({ meta, ...p }) {
  return (
    <div className="scope-field-container">
      <div className="scope-field-label">{p.label} *</div>
      {getInputPart(p)}
      {getErrorInfo(meta)}
    </div>
  );
}

function PasswordField({ name, ...props }) {
  return <Field name={name} {...props} component={passwordField} />;
}

PasswordField.defaultProps = {
  autoComplete: 'current-password'
};

PasswordField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  autoComplete: PropTypes.string
};

export default PasswordField;
