import React, { useCallback, useState } from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { FormattedMessage } from 'react-intl';

import * as CreditCardActions from '../actions/creditCard';
import { useDispatch } from 'react-redux';
import { addInfoMsg } from '../utils/flashMessage/creator';
import Loader from './svg/Loader';
import classnames from 'classnames';

export default function SetupIntentForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    addInfoMsg(error.message);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe) return;
    setLoading(true);

    const { error: submitError } = await elements.submit();

    if (submitError) {
      handleError(submitError);
      return;
    }

    const resp = dispatch(CreditCardActions.setupIntentStripe());

    const {
      payload: {
        data: { clientSecret }
      }
    } = await resp;

    const { error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: window.location.origin + '/profile/payment'
      }
    });

    if (error) {
      handleError(error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={classnames('scope-content-body', { load: loading })}>
        <div className="scope-section">
          <div className="scope-section-fields">
            <div className="scope-field-container">
              {loading && (
                <div className="loader">
                  <Loader className="svg-loader" color={props.theme.colors.primary} />
                </div>
              )}
              <PaymentElement />
            </div>
          </div>
        </div>
      </div>
      {stripe && (
        <div className="scope-content-footer small-screen">
          <button
            type="submit"
            className="scope-submit-button primary-btn"
            disabled={!stripe || loading}
          >
            <FormattedMessage id="action.add.payment" />
          </button>
        </div>
      )}
    </form>
  );
}
