import React from 'react';
import PropTypes from 'prop-types';

export const dims = [
  1000, // Adjusted dimensions width
  80 // Adjusted dimensions height
];

export default function Tick({ tick, count, format }) {
  const x = dims[0] * (tick.percent / 100);

  return (
    <g transform={`translate(${x},0)`} style={{ pointerEvents: 'none', zIndex: 2 }}>
      <text stroke="#333" y="30" x="30" textAnchor="middle" fontSize="40px">
        {format(tick.value)}
      </text>
    </g>
  );
}

Tick.propTypes = {
  tick: PropTypes.shape({
    id: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    percent: PropTypes.number.isRequired
  }).isRequired,
  format: PropTypes.func
};

Tick.defaultProps = {
  format: (d) => d
};
