import React, { Component } from 'react';
import { connect } from 'react-redux';
import DrivingLicenceForm from './DrivingLicenceForm';
import {
  isRenaultFranceUserSelector,
  drivingLicenceFormInitialValuesSelector,
  messagesSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import { displayProfileUpdateSuccess } from '../../helpers/components';
import { formatDrivingLicenseForApiCall } from '../../helpers/formatters';

class DrivingLicence extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.updateDrivingLicence = this.updateDrivingLicence.bind(this);
  }

  updateDrivingLicence(formValues) {
    const { m, updateInformations, userId, userData, userDataUpdating } = this.props;

    if (!userDataUpdating) {
      updateInformations(
        userId,
        formatDrivingLicenseForApiCall(userData, formValues)
      ).then((data) => {
        if (data.type === 'user/UPDATE_INFO_SUCCESS') {
          displayProfileUpdateSuccess(m, 'label.driving_licence');
        }
      });
    }
  }

  render() {
    const { initialValues, isRenaultFranceUser } = this.props;

    return (
      <DrivingLicenceForm
        onSubmit={this.updateDrivingLicence}
        hideFileUpload={isRenaultFranceUser}
        initialValues={initialValues}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;
  return {
    userId: userIdSelector(state),
    isRenaultFranceUser: isRenaultFranceUserSelector(state),
    userData: userDataSelector(state),
    m: messagesSelector(state),
    initialValues: drivingLicenceFormInitialValuesSelector(state),
    userDataUpdating
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DrivingLicence);
