import moment from 'moment';
import { getDefaultLocale } from '../utils/utils';

const defaultState = {
  locale: getDefaultLocale(),
  messages: null
};

export default function i18nReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SET_LANGUAGE': {
      moment.locale(action.payload.locale);

      return {
        ...state,
        locale: action.payload.locale,
        messages: action.payload.messages
      };
    }

    default:
      return state;
  }
}
