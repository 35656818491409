import React, { Component } from 'react';
import { Field, reduxForm, change, formValueSelector } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators, compose } from 'redux';
import moment from 'moment';
import { connect } from 'react-redux';
import * as BookingActions from '../../actions/booking';
import classnames from 'classnames';

import {
  renderPlaceholderLocationField,
  renderDatePicker,
  renderTimePicker,
  renderRadioGroup
} from './fields';
import { usageOptions } from '../../constants/options';
import { STORAGE_KEY, STORAGE_ENUM, ONE_WAY } from '../../constants/generic';
import {
  getMessage as t,
  safeDeepClone,
  setQueryValues,
  trimFields,
  isHomePage,
  safe,
  isObjEmpty,
  testProp
} from '../../utils/utils';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _hasIn from 'lodash/hasIn';
import _isString from 'lodash/isString';
import { getUsageFromContract, getAWayFromContract } from '../../helpers';
import config from '../../constants/config';
import { searchBookingsFields } from '../../constants/form';
import {
  searchValuesSelector,
  mapCountriesSelector,
  messagesSelector,
  userIdSelector
} from '../../helpers/selectors';
import { addErrorMsg, addInfoMsg } from '../../utils/flashMessage/creator';
import * as CreditCardActions from '../../actions/creditCard';

const validate = (values, props) => {
  const errors = {};
  const { messages } = props.i18n;
  const { contract } = props || {};

  trimFields(safeDeepClone(values));

  const tripType = _isEmpty(props.bookingTypeValues)
    ? getAWayFromContract(props.contract)
    : props.bookingTypeValues.tripType;

  if (
    contract &&
    contract.businessCarSharing &&
    contract.privateCarSharing &&
    !_isEmpty(props.user)
  ) {
    if (!values.usageTypes) {
      errors.usageTypes = messages['error.form.required'];
    }
  }

  if (!values.startDate) {
    errors.startDate = messages['error.form.required'];
  }

  if (!values.startTime) {
    errors.startTime = messages['error.form.required'];
  }

  if (tripType !== ONE_WAY) {
    if (!values.endDate) {
      errors.endDate = messages['error.form.required'];
    }

    if (!values.endTime) {
      errors.endTime = messages['error.form.required'];
    }
  }

  if (tripType !== ONE_WAY) {
    if (values.startDate && values.endDate) {
      if (moment(values.endDate).diff(values.startDate, 'days') < 0) {
        errors.startDate = messages['error.form.required'];
        errors.endDate = messages['error.form.required'];
      }
    }

    if (values.startDate && values.startTime && values.endDate && values.endTime) {
      if (
        moment(values.endDate + 'T' + values.endTime).diff(
          values.startDate + 'T' + values.startTime,
          'minutes'
        ) < config.minimumBookingDurationMinutes
      ) {
        errors.endTime = messages['error.form.required'];
      }
    }

    if (values.startDate && values.startTime) {
      if (moment().diff(values.startDate + 'T' + values.startTime, 'minutes') > 0) {
        errors.startTime = messages['error.form.required'];
      }
    }
  }

  if (
    !values.location ||
    (typeof values.location === 'object' && _isEmpty(values.location))
  ) {
    errors.location = messages['error.form.required'];
  }

  if (values.location) {
    if (_isString(values.location.value)) {
      errors.location = messages['error.form.suggestion.address.required'];
    }
  }

  return errors;
};

function getSizeProps() {
  return {
    isMobile: window.innerWidth < 768
  };
}

export class SearchBookingsHomepageForm extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = {
      startingDate: null,
      endingDate: null,
      mobileHideSection: !this.props.noExpandingClick,
      ...getSizeProps()
    };
  }

  setCallbacks() {
    this.setWindowSize = () => {
      this.setState(getSizeProps());
    };

    this.updateStartingDate = (date) => {
      this.setState({
        startingDate: date
      });

      setTimeout(() => {
        const i = isHomePage() ? 0 : this.state.isMobile ? 2 : 0;
        document.querySelectorAll('div.time-picker')[i].click();
      }, 10);
    };

    this.showForm = (e) => {
      e.preventDefault();
      this.setState({ mobileHideSection: false });
    };

    this.innerSubmit = (data) => {
      if (!isHomePage()) {
        setQueryValues(this.props.values);
      }
      this.props.handleSubmit(data);
    };

    this.updateEndingDate = (date) => {
      const { contract } = this.props;
      this.setState({
        endingDate: date
      });

      if (contract && contract.interfaceConfig.returnTripDisplay) {
        setTimeout(() => {
          const i = isHomePage() ? 1 : this.state.isMobile ? 3 : 1;
          document.querySelectorAll('div.time-picker')[i].click();
        }, 10);
      }
    };
  }

  componentDidMount() {
    this.addResizeListener();
    this.setWindowSize();
    this.handlePaymentRedirect();
    this.searchByQuery();
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.handleSearchUpdate(pp);
  }

  handleSearchUpdate(pp) {
    const { updateOnChange, contract, dispatch, values } = this.props;
    const valuesChanged = testProp.call(this, pp, { values });

    if (this.querySearchDone && valuesChanged) {
      return (this.querySearchDone = false);
    }

    if (updateOnChange && valuesChanged && !isHomePage() && !this.state.isMobile) {
      const hasErrors = this.props.validate(values, this.props);

      if (isObjEmpty(hasErrors)) {
        setQueryValues(values);
        this.props.onSubmit(values);
      } else if (hasErrors.usageTypes) {
        const usage = getUsageFromContract(contract);
        dispatch(change('searchBookings', 'usageTypes', usage));
      }
    }
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  handlePaymentRedirect() {
    const afterRedirect = localStorage.getItem(STORAGE_KEY.SEARCH_DATA);
    const orderCode = localStorage.getItem(STORAGE_KEY.ORDER_CODE);

    if (afterRedirect) {
      this.handleSearchAfterRedirect();
    } else if (orderCode) {
      this.handleAddCreditCard(orderCode);
    }
  }

  handleSearchAfterRedirect() {
    localStorage.removeItem(STORAGE_KEY.SEARCH_DATA);
    window.location.reload();
  }

  handleAddCreditCard(orderCode) {
    const { m, userId, createCreditCard } = this.props;
    const showError = () => addErrorMsg(t(m, 'error.credit.card.refused'));

    localStorage.removeItem(STORAGE_KEY.ORDER_CODE);

    if (orderCode === STORAGE_ENUM.BAD_ORDER_CODE) {
      showError();
    } else {
      createCreditCard(userId, orderCode).then((data) => {
        if (data.type === 'card/CREATE_SUCCESS') {
          addInfoMsg(t(m, 'info.worldpay.return.success'));
        } else showError();
      });
    }
  }

  callSafeSubmit(values) {
    this.props.handleSubmit(() => this.props.onSubmit(values))();
  }

  searchByQuery() {
    const { values: fieldValues, updateOnChange } = this.props;

    if (updateOnChange) {
      this.querySearchDone = true;
      const queryExist = !isObjEmpty(fieldValues);
      const errors = this.props.validate(fieldValues, this.props);
      if (queryExist && isObjEmpty(errors)) this.callSafeSubmit(fieldValues);
    }
  }

  addResizeListener() {
    window.addEventListener('resize', this.setWindowSize, { capture: true });
  }

  removeResizeListener() {
    window.removeEventListener('resize', this.setWindowSize, { capture: true });
  }

  hasBothUsageTypes() {
    const { contract, user } = this.props;
    const privateUsage = _get(contract, 'privateCarSharing');
    const businessUsage = _get(contract, 'businessCarSharing');

    return (
      privateUsage &&
      businessUsage &&
      user &&
      this.props.classname === 'search-booking-homepage'
    );
  }

  renderUsageTypes() {
    const {
      i18n: { messages }
    } = this.props;

    if (this.hasBothUsageTypes()) {
      return (
        <div
          className={classnames('usage-types', {
            hide: this.state.isMobile && this.state.mobileHideSection
          })}
        >
          <Field
            name="usageTypes"
            component={renderRadioGroup}
            options={usageOptions(messages)}
          />
        </div>
      );
    } else return '';
  }

  isSameDay() {
    if (!!this.state.startingDate && !!this.state.endingDate) {
      return moment(this.state.startingDate).isSame(this.state.endingDate, 'day')
        ? safe(() => this.props.values.startTime)
        : null;
    } else {
      return moment(_get(this.props.values, 'startDate'), 'YYYY-MM-DD').isSame(
        moment(_get(this.props.values, 'endDate'), 'YYYY-MM-DD'),
        'day'
      )
        ? safe(() => this.props.values.startTime)
        : null;
    }
  }

  getDatesStateErrors() {
    const {
      _form: { searchBookings },
      values,
      bookingTypeValues
    } = this.props;

    const { startTime, endTime, startDate, endDate } = values || {};

    const sd = _hasIn(searchBookings, 'syncErrors.startDate')
      ? _get(searchBookings, 'syncErrors.startDate')
      : _isString(startDate)
      ? null
      : false;

    const st = _hasIn(searchBookings, 'syncErrors.startTime')
      ? _get(searchBookings, 'syncErrors.startTime')
      : _isString(startTime)
      ? undefined
      : null;

    const ed = _hasIn(searchBookings, 'syncErrors.endDate')
      ? _get(searchBookings, 'syncErrors.endDate')
      : _isString(endDate)
      ? null
      : false;

    const et = _hasIn(searchBookings, 'syncErrors.endTime')
      ? _get(searchBookings, 'syncErrors.endTime')
      : _isString(endTime)
      ? undefined
      : null;

    return bookingTypeValues.tripType === ONE_WAY ? { sd, st } : { sd, st, ed, et };
  }

  render() {
    const { requestError, classname, contract, bookingTypeValues } = this.props;
    const { isMobile, mobileHideSection } = this.state;
    const { messages } = this.props.i18n;
    const hasEndDate = this.state.endingDate || _get(this.props.values, 'endDate');
    const hasStartDate = this.state.startingDate || _get(this.props.values, 'startDate');
    const mobileAndHidden = isMobile && mobileHideSection;
    const mobileCallback = mobileAndHidden ? this.showForm : undefined;

    return (
      <div
        className="filters-panel base-filters-panel"
        onClick={mobileCallback}
        id={this.state.isMobile ? 'mobileSearch' : 'desktopSearch'}
      >
        <form
          className={`form-content search-form ${classname} ${
            this.hasBothUsageTypes() ? 'both-usage-types' : ''
          }`}
          onSubmit={this.innerSubmit}
          noValidate
        >
          <div className="field-wrapper location">
            <Field
              name="location"
              component={renderPlaceholderLocationField}
              label={messages['generic.address']}
              placeholder={messages['generic.location.search.placeholder']}
              externalErrors={this.getDatesStateErrors()}
              getLocation={true}
              isValue
              smInput={true}
              openDatePickerOnSelect={true}
              fixtures={this.props.user.data && this.props.user.data.company.sites}
              fixturesTitle={messages['generic.location.fixture.label']}
              historyTitle={messages['generic.location.history.label']}
              searchHistoryEnabled={true}
            />
            {this.renderUsageTypes()}
          </div>
          <div
            className={classnames('field-wrapper dates', {
              'both-usage-types': this.hasBothUsageTypes(),
              hide: mobileAndHidden
            })}
          >
            <label className="hidden-xs">{messages['label.date']}</label>
            <div className="dates-wrapper">
              <div className="date-row">
                <div className="date-time-picker-field">
                  <div className={classnames('date-wrapper', { hasDate: hasStartDate })}>
                    <Field
                      name="startDate"
                      component={renderDatePicker}
                      isEndDate={false}
                      disabledStartUntil={this.state.endingDate}
                      changeCallback={this.updateStartingDate}
                      label={messages['generic.start']}
                    />
                  </div>
                  <div
                    className={
                      this.state.startingDate ||
                      _get(this.props.values, 'startDate') ||
                      this.props.classname === 'search-header'
                        ? 'time-wrapper'
                        : 'time-wrapper hide'
                    }
                  >
                    <Field
                      name="startTime"
                      component={renderTimePicker}
                      label={messages['label.hours']}
                      startDate={this.state.startingDate}
                      changeCallback={() => {
                        if (
                          contract.interfaceConfig.returnTripDisplay &&
                          bookingTypeValues.tripType !== ONE_WAY
                        ) {
                          if (!this.state.endingDate) {
                            const i = isHomePage() ? 1 : this.state.isMobile ? 3 : 1;
                            document
                              .querySelectorAll('div.date-picker')
                              [i].querySelector('input')
                              .click();
                          }
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {contract && contract.interfaceConfig.returnTripDisplay && (
                <div className="date-row">
                  {bookingTypeValues.tripType !== ONE_WAY && (
                    <div className="date-time-picker-field">
                      <div
                        className={classnames('date-wrapper', { hasDate: hasEndDate })}
                      >
                        <Field
                          name="endDate"
                          component={renderDatePicker}
                          isEndDate={true}
                          disabledUntil={this.state.startingDate}
                          changeCallback={this.updateEndingDate}
                          label={messages['generic.end']}
                        />
                      </div>
                      <div
                        className={
                          this.state.endingDate ||
                          _get(this.props.values, 'endDate') ||
                          this.props.classname === 'search-header'
                            ? 'time-wrapper'
                            : 'time-wrapper hide'
                        }
                      >
                        <Field
                          name="endTime"
                          isEndTime={true}
                          isSameDay={this.isSameDay()}
                          startTime={_get(this.props.values, 'startTime')}
                          component={renderTimePicker}
                          label={messages['label.hours']}
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          <button
            type="submit"
            className={`btn search-booking-homepage-btn primary-btn ${
              this.hasBothUsageTypes() ? 'both-usage-types' : ''
            }`}
          >
            <FormattedMessage id="label.search" />
          </button>
        </form>
        {requestError && (
          <div className="error-msg error-request">
            <FormattedMessage id="error.request_error" />
          </div>
        )}
      </div>
    );
  }
}

const bookingTypeFields = ['tripType', 'usageTypes'];
const selectorBookingType = formValueSelector('bookingType');

const mapStateToProps = (state) => {
  const bookingTypeValues = selectorBookingType(state, ...bookingTypeFields);
  const { form: _form, ...reducers } = state;

  return {
    _form,
    mapCountries: mapCountriesSelector(state),
    values: searchValuesSelector(state),
    m: messagesSelector(state),
    userId: userIdSelector(state),
    bookingTypeValues,
    ...reducers
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...CreditCardActions,
      dispatch
    },
    dispatch
  );
}

export default compose(
  reduxForm({
    form: 'searchBookings',
    validate,
    fields: searchBookingsFields,
    touchOnChange: true,
    destroyOnUnmount: false
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(SearchBookingsHomepageForm);
