import { redirectIfNotAuthenticated } from '../actions/user';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userIdSelector } from '../helpers/selectors';
import { Outlet } from 'react-router-dom';

export default function Authenticated({ location }) {
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);

  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (userId) {
      setRedirected(false);
    } else if (!redirected) {
      setRedirected(true);
      dispatch(redirectIfNotAuthenticated(location));
    }
  }, [userId, location]);

  if (userId) return <Outlet />;
  return null;
}
