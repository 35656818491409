import React, { Component } from 'react';
import replacementVehicleImage from './../assets/images/tools-circle.svg';
import testDriveImage from './../assets/images/drive-circle.svg';
import publicServiceImage from './../assets/images/usage-types/private.svg';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PRE_BOOKING_TYPE, TEST_DRIVE_STRING } from '../constants/generic';
import { navigateToCreationPage } from '../utils/utils';

import {
  userCompanyIdSelector,
  userFirstNameSelector,
  productNameSelector,
  preBookingStatusSelector
} from '../helpers/selectors';

class WelcomeMessage extends Component {
  constructor(props) {
    super(props);

    this.bindFunctions();
    this.setVariables();
  }

  bindFunctions() {
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  setVariables() {
    const programKey = 'title.welcome.to.program';

    const replacementVehicleData = {
      msgKey: programKey,
      src: replacementVehicleImage,
      getProgramString: () => <FormattedMessage id="service.usage.vr" />
    };

    const testDriveData = {
      msgKey: programKey,
      src: testDriveImage,
      getProgramString: () => TEST_DRIVE_STRING
    };

    const publicServiceData = {
      msgKey: 'title.welcome.to.brand',
      src: publicServiceImage
    };

    this.programData = {
      [PRE_BOOKING_TYPE.TEST_DRIVE]: testDriveData,
      [PRE_BOOKING_TYPE.REPLACEMENT_VEHICLE]: replacementVehicleData,
      [PRE_BOOKING_TYPE.PUBLIC_SERVICE]: publicServiceData
    };
  }

  getProgramData(status) {
    const defaultData = this.programData[PRE_BOOKING_TYPE.REPLACEMENT_VEHICLE];
    return this.programData[status] || defaultData;
  }

  handleConfirm() {
    navigateToCreationPage(this.props.companyId);
  }

  applyNoWrap(data) {
    return <span className="nowrap">{data}</span>;
  }

  render() {
    const { preBookingStatus, firstName, brandName } = this.props;
    const { src, getProgramString, msgKey } = this.getProgramData(preBookingStatus);
    const program = getProgramString && getProgramString();

    return (
      <div className="welcome-message-page">
        <div className="scope-container">
          <div className="scope-page-body">
            <div className="scope-img-container">
              <img className="scope-img" alt={preBookingStatus} src={src} />
            </div>
            <div className="scope-title-msg">
              <FormattedMessage
                id={msgKey}
                values={{
                  firstName,
                  brandName: this.applyNoWrap(brandName),
                  program: program && this.applyNoWrap(program)
                }}
              />
            </div>
            <div className="scope-info-msg">
              <FormattedMessage id="label.welcome.finish.registration" />
            </div>
          </div>
          <div className="scope-page-footer">
            <button
              type="submit"
              className={classnames('scope-submit-button', 'primary-btn')}
              onClick={this.handleConfirm}
            >
              <FormattedMessage id="action.confirm.welcome.msg" />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    preBookingStatus: preBookingStatusSelector(state),
    companyId: userCompanyIdSelector(state),
    firstName: userFirstNameSelector(state),
    brandName: productNameSelector(state)
  };
};

export default connect(mapStateToProps)(WelcomeMessage);
