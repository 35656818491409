import { useIntl } from 'react-intl';

let INTL, instance;

class IntlTranslator {
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  getMsg(id, values) {
    return INTL.formatMessage({ id }, values);
  }
}

const IntlGlobalProvider = (props) => {
  INTL = useIntl();
  return props.children;
};

export const { getMsg } = new IntlTranslator();
export default IntlGlobalProvider;
