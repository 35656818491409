import CreationCredentialsForm from './Form';
import keyIconSrc from '../../../assets/images/key-circle.svg';
import { FormattedMessage } from 'react-intl';
import {
  fallbackFunc,
  navigateToCreationActivateAccount,
  navigateToCreationInfo,
  navigateToCreationSuccess
} from '../../../utils/utils';
import AcceptTermsForm, {
  ACCEPT_TERMS_FIELDS_NAMES
} from '../../account/AcceptTermsForm';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { submit } from 'redux-form';
import { USER_FORM_NAMES } from '../../../constants/form';
import { connect } from 'react-redux';

import { login, register, saveCreationSuccessData } from '../../../actions/user';
import { cleanDeep } from '../../../utils/cleanDeep';

import {
  isProfileIncompleteUserSelector,
  localeSelector,
  messagesSelector,
  registerCompanySelector
} from '../../../helpers/selectors';
import { formatAcceptTermsForApiCall } from '../../../helpers/formatters';
import _pick from 'lodash/pick';
import classnames from 'classnames';
import { getCreationId, showApiError } from '../../../helpers/components';

function CreationCredentials(p) {
  const termsSubmit = useRef(fallbackFunc);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    if (p.isProfileIncompleteUser) {
      navigateToCreationInfo(getCreationId(p.routeParams), true);
    }
  }, [p.isProfileIncompleteUser]);

  const setAcceptTermsSubmit = useCallback(
    (submit) => (termsSubmit.current = submit),
    [termsSubmit]
  );

  const onFieldsSubmit = useCallback(
    (fields) => {
      termsSubmit.current((terms) => {
        const f = { ...fields, ...terms };

        function formatForApi() {
          const { phonePrefix, phoneSuffix } = f.phoneNumber || {};

          let payload = {
            companyId: getCreationId(p.routeParams),
            locale: p.locale,
            phoneNumber: {
              countryCode: phonePrefix,
              nationalNumber: phoneSuffix
            },
            ..._pick(f, ['firstName', 'lastName', 'login', 'password'])
          };

          payload = formatAcceptTermsForApiCall(
            payload,
            _pick(f, ACCEPT_TERMS_FIELDS_NAMES),
            p.registerCompany
          );
          return cleanDeep(payload);
        }

        setFetching(true);
        p.register(formatForApi()).then((data) => {
          if (data?.type === 'user/REGISTER_SUCCESS') {
            const { login, password } = f;

            p.login({ login, password }, false).then((resp) => {
              setFetching(false);

              if (resp?.type === 'user/LOGIN_SUCCESS') {
                navigateToCreationActivateAccount(getCreationId(p.routeParams));
              } else {
                p.saveCreationSuccessData({ email: f.login, firstName: f.firstName });
                navigateToCreationSuccess();
              }
            });
          } else {
            setFetching(false);
            showApiError(p.m, data);
          }
        });
      });
    },
    [termsSubmit.current, p.routeParams, p.locale, p.registerCompany, p.m]
  );

  const handleSubmit = () => {
    if (fetching) return;
    p.submit(USER_FORM_NAMES.REGISTER_CREDENTIALS);
  };

  return (
    <div className="account-credentials-page">
      <div className="scope-content-header">
        <img className="scope-content-header-picture" src={keyIconSrc} alt="key icon" />
        <div className="scope-content-header-title-container">
          <div className="scope-content-header-title">
            <FormattedMessage id="profile.section.credentials" />
          </div>
        </div>
      </div>
      <div className="scope-content-body">
        <CreationCredentialsForm onSubmit={onFieldsSubmit} />
        <AcceptTermsForm isSubscribePage passFormSubmit={setAcceptTermsSubmit} />
      </div>
      <div className="scope-content-footer">
        <button
          type="submit"
          className={classnames('scope-submit-button', 'primary-btn', {
            'sub-class-disabled': fetching
          })}
          onClick={handleSubmit}
        >
          <FormattedMessage id="generic.next" />
        </button>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    locale: localeSelector(state),
    m: messagesSelector(state),
    registerCompany: registerCompanySelector(state)
  };
}

const mapDispatchToProps = {
  saveCreationSuccessData,
  register,
  submit,
  login
};

CreationCredentials = connect(mapStateToProps, mapDispatchToProps)(CreationCredentials);

export default CreationCredentials;
