import { Field } from 'redux-form';
import { roundSwitch, showErrorInfo } from '../../forms/newFields';
import React from 'react';

export function BoolFieldInner({ label, mandatory, ...props }) {
  return (
    <div className="scope-field-container">
      <div className="scope-boolean-container">
        <span className="scope-boolean-info">
          {label}
          {!!mandatory && ' *'}
        </span>
        {roundSwitch(props)}
      </div>
      {showErrorInfo(props)}
    </div>
  );
}

export default function BoolField({ name, label, mandatory, ...props }) {
  return (
    <Field
      name={name}
      label={label}
      mandatory={mandatory}
      component={BoolFieldInner}
      {...props}
    />
  );
}
