import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  messagesSelector,
  notificationsFormInitialValuesSelector,
  userIdSelector
} from '../../helpers/selectors';

import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import NotificationsForm from './NotificationsForm';
import { displayProfileUpdateSuccess } from '../../helpers/components';

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  componentDidMount() {
    const { getUserSettings, userId } = this.props;
    getUserSettings(userId);
  }

  bindFunctions() {
    this.updateData = this.updateData.bind(this);
  }

  updateData(values) {
    const { m, updateUserSettings, userId, updateSettingsFetching } = this.props;
    const { smsBeforeArrivalEnabled, smsBeforeDepartureEnabled, ...rest } = values || {};
    const { smsBeforeArrivalTime, smsBeforeDepartureTime } = rest;

    if (!smsBeforeDepartureEnabled || !smsBeforeDepartureTime) {
      rest.smsBeforeDepartureTime = -1;
    }

    if (!smsBeforeArrivalEnabled || !smsBeforeArrivalTime) {
      rest.smsBeforeArrivalTime = -1;
    }

    if (typeof smsBeforeArrivalTime === 'string') Number(smsBeforeArrivalTime);
    if (typeof smsBeforeDepartureTime === 'string') Number(smsBeforeDepartureTime);

    if (!updateSettingsFetching) {
      updateUserSettings(userId, rest).then((data) => {
        if (data.type === 'user/UPDATE_SETTINGS_SUCCESS') {
          displayProfileUpdateSuccess(m, 'profile.section.notifications');
        }
      });
    }
  }

  render() {
    const { initialValues } = this.props;
    return <NotificationsForm onSubmit={this.updateData} initialValues={initialValues} />;
  }
}

const mapStateToProps = (state) => {
  const { updateSettingsFetching } = state.user;

  return {
    userId: userIdSelector(state),
    m: messagesSelector(state),
    initialValues: notificationsFormInitialValuesSelector(state),
    updateSettingsFetching
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
