import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import MobileBanner from './partials/MobileBanner';

import { gtmPush } from '../utils/utils';
import {
  currentBrandSelector,
  localeSelector,
  productNameSelector,
  userIdSelector
} from '../helpers/selectors';

export class HowItWorks extends Component {
  componentDidMount() {
    gtmPush(
      {
        event: '_virtualPageview',
        pageName: 'mobility|commentcamarche'
      },
      this.props.userId
    );
  }

  render() {
    const { currentBrand, locale, productName } = this.props;
    const { howItWorks, howItWorksShowNumber } = currentBrand || {};

    return (
      <div>
        <div>
          {howItWorks && howItWorks.length > 0 && (
            <div className="container instruction-container pb-25">
              <Row>
                <h2 className="heading-lg pb-5 pl-xs-15 pr-xs-15">
                  <FormattedMessage id="label.how_it_works" />
                </h2>
              </Row>
              <Row className="text-center">
                {howItWorks.map((item = {}, index) => {
                  const { i18n, text, image } = item;
                  const msg = i18n ? i18n[locale] || text : text;

                  return (
                    <Col key={index} sm={6} md={4} className="instruction-wrapper">
                      <div
                        style={{ backgroundImage: `url('${image}')` }}
                        className="instruction-image"
                      >
                        {howItWorksShowNumber && (
                          <span className="step-index primary-bg">{index + 1}</span>
                        )}
                      </div>
                      <p className={howItWorksShowNumber ? 'need-margin' : ''}>
                        <span dangerouslySetInnerHTML={{ __html: msg }} />
                      </p>
                    </Col>
                  );
                })}
              </Row>
            </div>
          )}
        </div>
        <MobileBanner productName={productName} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    userId: userIdSelector(state),
    productName: productNameSelector(state),
    currentBrand: currentBrandSelector(state),
    locale: localeSelector(state)
  };
}

export default connect(mapStateToProps)(HowItWorks);
