import React, { Component } from 'react';
import { initialize, reduxForm } from 'redux-form';
import keyIconSrc from '../../../src/assets/images/key-circle.svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import IndexLink from '../partials/IndexLink'
import { ReactSVG } from 'react-svg';
import { connect } from 'react-redux';
import { getMessage as t, safe } from '../../utils/utils';
import { messagesSelector, currentThemeSelector } from '../../helpers/selectors';
import { USER_FORM_NAMES } from '../../constants/form';
import classnames from 'classnames';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import { checkPassword } from '../../helpers';
import TextField from './fields/text';
import { addSuccessMsg } from '../../utils/flashMessage/creator';
import PropTypes from 'prop-types';

const validate = (values, props) => {
  const errors = {};
  const { m } = props;

  if (!values.newPassword || !values.currentPassword) {
    errors.newPassword = m['error.form.required'];
  }

  const passError = checkPassword(values.newPassword, m);
  if (passError) errors.newPassword = passError;

  if (
    values.newPassword === values.currentPassword &&
    !!values.currentPassword &&
    !!values.newPassword
  ) {
    errors.newPassword = m['error.form.password.cannot_reset_same'];
  }

  return errors;
};

class Credentials extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions(props);
  }

  bindFunctions(props) {
    this.setSubmitCallbacks(props);
  }

  setSubmitCallbacks(props) {
    let callback = (data) => this.submitCallback(data);
    if (props.customSubmit) callback = props.customSubmit;
    this.handleSubmit = props.handleSubmit(callback);
  }

  submitCallback(formValues) {
    const { currentPassword, newPassword } = formValues || {};
    const { updateUserPassword, resetUpdateCredentialsForm, m } = this.props;

    updateUserPassword({ newPassword, oldPassword: currentPassword }).then((data) => {
      if (data.type === 'user/UPDATE_PASSWORD_SUCCESS') {
        resetUpdateCredentialsForm();
        addSuccessMsg(t(m, 'title.password.change.success'));
      }
    });
  }

  render() {
    const { m, updatePasswordFetching, theme } = this.props;
    const { credential } = theme.icons || {};

    return (
      <form className="account-credentials-page" onSubmit={this.handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={credential ? credential : keyIconSrc}
            alt="key icon"
          />
          <div className="scope-content-header-title-container">
            <IndexLink to="/profile" className="scope-content-header-button-return">
              <ReactSVG className="react-svg" src={arrowLeftSrc} />
            </IndexLink>
            <div className="scope-content-header-title">
              {this.props.title || t(m, 'profile.section.credentials')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-title">
              {this.props.subTitle || t(m, 'profile.title.update.password')}
            </div>
            <div className="scope-section-fields">
              {!safe(() => this.props.initialValues.currentPassword) && (
                <div className="scope-field-container">
                  <div className="scope-field-label">
                    {t(m, 'profile.label.current.password')} *
                  </div>
                  <TextField
                    name="currentPassword"
                    type="password"
                    placeholder={t(m, 'profile.label.current.password')}
                  />
                </div>
              )}
              <div className="scope-field-container">
                <div className="scope-field-label">{t(m, 'label.new.password')} *</div>
                <TextField
                  name="newPassword"
                  type="password"
                  placeholder={t(m, 'label.new.password')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          <button
            type="submit"
            className={classnames('scope-submit-button primary-btn', {
              'sub-class-disabled': updatePasswordFetching
            })}
          >
            {t(m, 'generic.save')}
          </button>
        </div>
      </form>
    );
  }
}

Credentials.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  currentPassword: PropTypes.string
};

const reduxComponent = reduxForm({
  enableReinitialize: true,
  destroyOnUnmount: false,
  form: USER_FORM_NAMES.CREDENTIALS,
  fields: ['newPassword', 'currentPassword'],
  validate
})(Credentials);

const mapStateToProps = (state, props) => {
  const { user } = state;
  const { updatePasswordFetching } = user || {};

  return {
    updatePasswordFetching,
    m: messagesSelector(state),
    theme: currentThemeSelector(state),
    initialValues: {
      currentPassword: props.currentPassword
    }
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...UserActions,
      resetUpdateCredentialsForm: () => dispatch(initialize(USER_FORM_NAMES.CREDENTIALS))
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxComponent);
