export const USER_FORM_NAMES = {
  CUSTOM_FIELDS: 'customFields',
  SPECIAL_FIELDS: 'specialFields',
  ACCEPT_TERMS: 'acceptTerms',
  REGISTER_CREDENTIALS: 'registerCredentials',
  CREDENTIALS: 'credentials',
  PERSONAL_DETAILS: 'personalDetails',
  DRIVING_LICENCE: 'drivingLicence',
  IDENTITY_DOCUMENT: 'identityDocument',
  SELFIE: 'selfie',
  NOTIFICATIONS: 'notifications',
  PROFILE_LANGUAGE: 'profileLanguage'
};

export const searchBookingsFields = [
  'location',
  'startDate',
  'startTime',
  'endDate',
  'endTime',
  'usageTypes',
  'vehicleType',
  'motorisation',
  'transmission',
  'accessories',
  'places'
];
