/* global $ */

import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import classnames from 'classnames';
import shortid from 'shortid';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.setVars();
    this.setCallbacks();
    this.initState();
  }

  setVars() {
    this.inputId = shortid.generate();
    this.inputSelector = '#' + this.inputId;
  }

  initState() {
    this.state = { picker: null };
  }

  setCallbacks() {
    this.getMsg = (id) => this.props.intl.formatMessage({ id });

    this.handleOnClose = () => {
      const element = document.getElementById(this.inputId);
      if (element) element.parentNode.classList.remove('picker--active');
      document.activeElement.blur();
      this.props.onBlur();
    };

    this.handleOnOpen = () => {
      document.getElementById(this.inputId).parentNode.classList.add('picker--active');
    };

    this.openPicker = () => {
      if (this.props.isEndDate && this.props.disabledUntil) {
        this.state.picker.set(
          'min',
          moment(this.props.disabledUntil).format('D MMMM, YYYY')
        );
      }

      if (this.props.value) {
        this.state.picker.set('select', this.props.value, { format: 'yyyy-mm-dd' });
      }

      this.state.picker.open();
    };
  }

  componentDidMount() {
    this.setupPickadate();
  }

  componentDidUpdate() {
    this.props.onError(this.props.showError);
  }

  getContextDate(context) {
    const { select } = context;
    const { pick } = select || {};

    if (typeof select === 'number') return select;
    if (pick) return pick;
  }

  setupPickadate() {
    const monthsFull = moment.months();
    const monthsShort = moment.monthsShort();
    const weekdaysShort = moment.weekdaysShort();

    if (!this.state.picker) {
      let $picker = $(this.inputSelector).pickadate({
        onClose: this.handleOnClose,
        onOpen: this.handleOnOpen,
        min:
          this.props.disableUntil && this.props.isEndDate
            ? moment(this.props.disableUntil).startOf('d').toDate()
            : moment().startOf('d').toDate(),
        max: this.props.isEndDate
          ? moment().add(95, 'd').startOf('d').toDate()
          : moment().add(90, 'd').startOf('d').toDate(),
        onSet: (context) => {
          let selectedDate = this.getContextDate(context);
          if (selectedDate) {
            this.props.onDateSelected({
              value: selectedDate
            });
          }
        },
        firstDay: 1,
        monthsFull: monthsFull,
        monthsShort: monthsShort,
        weekdaysShort: weekdaysShort,
        today: this.getMsg('generic.today'),
        clear: this.getMsg('generic.clear')
      });

      this.setState({
        picker: $picker.pickadate('picker')
      });
    }
  }

  render() {
    const value = this.props.value || null;
    const mainClassName = 'date-picker';
    const selectedClassName = value ? mainClassName + '__selected' : '';
    const parsedDate = value ? moment(value).format('DD MMM') : null;
    return (
      <div className={`${mainClassName} ${selectedClassName}`} onClick={this.openPicker}>
        {!value && <span className="label">{this.props.label}</span>}
        <input id={this.inputId} />
        {value ? (
          <div className="value">
            <span
              className={classnames({
                capitalize: true,
                'text-danger': this.props.showError
              })}
            >
              {parsedDate}
            </span>
          </div>
        ) : (
          <i className={classnames({ 'text-danger': this.props.showError })} />
        )}
      </div>
    );
  }
}

export default injectIntl(DatePicker);
