import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import classnames from 'classnames';
import moment from 'moment';

import { setMapKeyboard } from '../../actions/booking';
import _isEmpty from 'lodash/isEmpty';
import _get from 'lodash/get';
import _pick from 'lodash/pick';
import _hasIn from 'lodash/hasIn';
import BookingsMap from '../BookingsMap';
import SearchBookingsList from '../partials/SearchBookingsList';
import SearchBookingsHomepageForm from './SearchBookingsHomepageForm';
import FiltersBookings from './FiltersBookings';
import { ReactSVG } from 'react-svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import { bindActionCreators } from 'redux';
import { searchValuesSelector } from '../../helpers/selectors';
import { safe } from '../../utils/utils';

function getSizeProps() {
  return {
    isMobile: window.innerWidth < 768
  };
}

export class SearchBookingsForm extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = {
      showFilters: false,
      showMap: true,
      showLargeMap: false,
      showMobileSearchForm: false,
      ...getSizeProps()
    };
  }

  changeMapKeyboard(state) {
    this.props.dispatch(setMapKeyboard(state));
  }

  setCallbacks() {
    this.setWindowSize = () => {
      this.setState(getSizeProps());
    };

    this.enableMapKeyboard = () => {
      if (this.props.mapKeyboardState === false) {
        this.changeMapKeyboard(true);
      }
    };

    this.showMobileSearch = () => {
      this.setState({ showMobileSearchForm: true });
    };

    this.searchBookings = () => {
      this.setState({ showMobileSearchForm: false });
      this.props.onSubmit();
    };
  }

  componentDidMount() {
    this.addResizeListener();
    this.setWindowSize();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  addResizeListener() {
    window.addEventListener('resize', this.setWindowSize, { capture: true });
  }

  removeResizeListener() {
    window.removeEventListener('resize', this.setWindowSize, { capture: true });
  }

  searchResume() {
    const {
      booking: { searchBookingsFetching },
      contract,
      values
    } = this.props;

    const startDetail =
      !_isEmpty(values) && values.hasOwnProperty('startTime')
        ? Number.isInteger(values.startTime)
          ? moment(values.startDate)
              .add(moment.duration(values.startTime))
              .format('D MMM. H:mm')
          : moment(values.startDate + ' ' + values.startTime).format('D MMM. H:mm')
        : '';

    const endDetail =
      !_isEmpty(values) && values.hasOwnProperty('endTime')
        ? Number.isInteger(values.endTime)
          ? moment(values.endDate)
              .add(moment.duration(values.endTime))
              .format('D MMM. H:mm')
          : values.endDate && values.endTime
          ? moment(values.endDate + ' ' + values.endTime).format('D MMM. H:mm')
          : ''
        : '';

    const locationSearched = _hasIn(values, 'location.siteId')
      ? _get(values, 'location.streetName')
      : _get(values, 'location.formattedAddress');

    return (
      <div
        className="visible-xs"
        onClick={searchBookingsFetching ? undefined : this.showMobileSearch}
      >
        {searchBookingsFetching ? (
          <div className="search-detail">
            <div className="content-placeholder">
              <svg height="0" width="0">
                <defs>
                  <clipPath id="resumePath">
                    <rect x="0" y="5" rx="6" ry="6" width="320" height="12" />
                    <rect x="0" y="25" rx="6" ry="6" width="100" height="12" />
                    <rect x="120" y="25" rx="6" ry="6" width="100" height="12" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        ) : !this.state.showMobileSearchForm && !_isEmpty(_get(values, 'location')) ? (
          <div className="search-detail">
            <div className="address">{locationSearched}</div>
            <span>{startDetail}</span>
            <ReactSVG className="svg-arrow" src={arrowLeftSrc} />
            <span>{endDetail}</span>
          </div>
        ) : (
          <div>
            <SearchBookingsHomepageForm
              onSubmit={this.searchBookings}
              i18n={this.props.i18n}
              classname="search-booking-homepage mobileOnly"
              initialValues={values}
              contract={contract}
              noExpandingClick={true}
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const { values } = this.props;
    const filtersValues = _pick(values, [
      'motorisation',
      'accessories',
      'places',
      'transmission',
      'vehicleType'
    ]);

    const mapPanelClassNames = classnames({
      gmap: true,
      'col-sm-6 col-md-9': !this.state.showLargeMap,
      'col-sm-12 col-md-12': this.state.showLargeMap
    });

    return (
      <div className="search-filters-layout">
        {this.state.isMobile && this.searchResume()}

        <FiltersBookings i18n={this.props.i18n} values={filtersValues} />

        <div className="flexit">
          {!this.state.showLargeMap ? (
            <div
              className={classnames({
                'col-sm-6 col-md-3 list': true,
                'hidden-xs': this.state.showFilters
              })}
            >
              <div className="filters-panel base-filters-panel">
                {this.props.booking && (
                  <SearchBookingsList
                    booking={this.props.booking}
                    selectedAgency={this.props.selectedAgency}
                    i18n={this.props.i18n}
                  />
                )}
              </div>
            </div>
          ) : null}
          {this.state.showMap || this.state.showLargeMap ? (
            <div className={mapPanelClassNames}>
              <div
                className={
                  'filters-panel map-panel' +
                  (this.state.showLargeMap ? ' large-panel' : '')
                }
              >
                <div className="map">
                  <BookingsMap
                    agencies={this.props.agencies}
                    numberOfCars={this.props.numberOfCars}
                    selectedAgency={this.props.selectedAgency}
                    onMarkerSelect={this.props.agencySelectCallBack}
                    mapCenter={this.props.mapCenter}
                    enableMapKeyboard={this.enableMapKeyboard}
                    mapKeyboardState={this.props.mapKeyboardState}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const fields = [
  'location',
  'startDate',
  'startTime',
  'endDate',
  'endTime',
  'places',
  'transmission',
  'motorisation',
  'accessories',
  'voucherCode',
  'vehicleType'
];

const mapStateToProps = (state) => {
  const booking = state.booking;
  const contract = safe(() => state.brand.branded.contract) || {};

  return {
    ...state,
    contract,
    booking,
    values: searchValuesSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ dispatch }, dispatch);
}

SearchBookingsForm = connect(mapStateToProps, mapDispatchToProps)(SearchBookingsForm);

SearchBookingsForm = reduxForm({
  form: 'searchBookings',
  fields: fields,
  touchOnChange: true,
  destroyOnUnmount: false
})(SearchBookingsForm);

export default SearchBookingsForm;
