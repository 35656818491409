import React, { useCallback, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { saveCreationSuccessData, updateInformations } from '../../actions/user';
import { formatSpecialFieldsForApiCall } from '../../helpers/formatters';
import { navigateToCreationSuccess } from '../../utils/utils';
import { showApiError } from '../../helpers/components';
import {
  messagesSelector,
  profileCustomFieldsSelector,
  registerCompanySelector,
  selectCustomFieldsInitialValues,
  showMoreInfoPageSelector,
  specialFieldsInitialValuesSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';
import MoreInfo from '../account/MoreInfo';
import { formatCustomFieldsForApiCall } from '../../actions/customFields/helpers';
import { cleanDeep } from '../../utils/cleanDeep';

function CreationMoreInfo(p) {
  const r = useRef({ fetching: false }).current;

  useEffect(() => {
    if (!p.showMoreInfoPage) {
      const { login: email, firstName } = p.userData || {};
      p.saveCreationSuccessData({ email, firstName });
      navigateToCreationSuccess(true);
    }
  }, [p.showMoreInfoPage]);

  const updateUserData = useCallback(
    ({ customFieldsValues, specialFieldsValues }) => {
      if (!r.fetching) {
        r.fetching = true;

        let payload = formatCustomFieldsForApiCall(p.userData, customFieldsValues);
        payload = formatSpecialFieldsForApiCall(
          payload,
          specialFieldsValues,
          p.registerCompany
        );
        payload = cleanDeep(payload);

        p.updateInformations(p.userId, payload).then((data) => {
          r.fetching = false;
          if (data?.type === 'user/UPDATE_INFO_SUCCESS') {
            const { login: email, firstName } = p.userData || {};
            p.saveCreationSuccessData({ email, firstName });
            navigateToCreationSuccess();
          } else {
            showApiError(p.m, data);
          }
        });
      }
    },
    [r.fetching, p.userData, p.registerCompany, p.userId, p.routeParams, p.m]
  );

  return (
    <MoreInfo
      isCreationPage
      isSubscribePage
      onSubmit={updateUserData}
      customFieldsInitialValues={p.customFields}
      specialFieldsInitialValues={p.specialFields}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    registerCompany: registerCompanySelector(state),
    showMoreInfoPage: showMoreInfoPageSelector(state),
    customFields: selectCustomFieldsInitialValues(profileCustomFieldsSelector(state)),
    specialFields: specialFieldsInitialValuesSelector(state)
  };
};

const mapDispatchToProps = {
  updateInformations,
  saveCreationSuccessData
};

export default connect(mapStateToProps, mapDispatchToProps)(CreationMoreInfo);
