import React, { Component } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import { companySitesSelector, messagesSelector } from '../../../helpers/selectors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BookingActions from '../../../actions/booking';
import { trimFields } from '../../../utils/utils';
import { genericAddressField } from '../../forms/newFields';
import { renderDatePicker, renderTimePicker } from '../../forms/fields';
import { FormattedMessage } from 'react-intl';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import config from '../../../constants/config';
import _isString from 'lodash/isString';

function setLocationError(values, errors, messages) {
  const { location } = values;

  if (_isEmpty(location)) {
    errors.location = messages['error.form.required'];
  } else if (_isString(location.value)) {
    errors.location = messages['error.form.suggestion.address.required'];
  }
}

function setEmptyDatesError(values, errors, messages) {
  if (!values.startDate) {
    errors.startDate = messages['error.form.required'];
  }

  if (!values.startTime) {
    errors.startTime = messages['error.form.required'];
  }

  if (!values.endDate) {
    errors.endDate = messages['error.form.required'];
  }

  if (!values.endTime) {
    errors.endTime = messages['error.form.required'];
  }
}

function setMinDurationError(values, errors, messages) {
  const { startDate, startTime, endDate, endTime } = values;

  if (startDate && startTime && endDate && endTime) {
    const start = startDate + 'T' + startTime;
    const end = endDate + 'T' + endTime;
    const datesDiff = moment(end).diff(start, 'minutes');

    if (datesDiff < config.minimumBookingDurationMinutes) {
      errors.endTime = messages['error.form.required'];
    }
  }
}

function setStartDatePastError(values, errors, messages) {
  const { startDate, startTime } = values;

  if (startDate && startTime) {
    if (moment().diff(startDate + 'T' + startTime, 'minutes') > 0) {
      errors.startTime = messages['error.form.required'];
    }
  }
}

function setEndBeforeStartError(values, errors, messages) {
  const { startDate, endDate } = values;

  if (startDate && endDate) {
    if (moment(endDate).diff(startDate, 'days') < 0) {
      errors.startDate = messages['error.form.required'];
      errors.endDate = messages['error.form.required'];
    }
  }
}

const validate = (values, props) => {
  const errors = {};
  const { messages } = props;

  values = trimFields({ ...values });

  setEmptyDatesError(values, errors, messages);
  setEndBeforeStartError(values, errors, messages);
  setMinDurationError(values, errors, messages);
  setStartDatePastError(values, errors, messages);
  setLocationError(values, errors, messages);

  return errors;
};

class BookingEditPopupForm extends Component {
  isSameDay() {
    const { formValues } = this.props;
    const { startDate, endDate } = formValues || {};
    return moment(startDate).isSame(endDate, 'day');
  }

  render() {
    const { companySites, messages, handleSubmit, formValues } = this.props;
    const { startDate, startTime } = formValues || {};

    return (
      <form className="booking-edit-popup-form" noValidate onSubmit={handleSubmit}>
        <div className="scope-location-wrap">
          <Field
            name="location"
            component={genericAddressField}
            label={messages['generic.address']}
            placeholder={messages['generic.location.search.placeholder']}
            fixtures={companySites}
            fixturesTitle={messages['generic.location.fixture.label']}
            historyTitle={messages['generic.location.history.label']}
            searchHistoryEnabled
          />
        </div>
        <div className="scope-dates-wrap">
          <div className="scope-date-time-wrap">
            <Field name="startDate" component={renderDatePicker} />
            <Field name="startTime" startDate={startDate} component={renderTimePicker} />
          </div>
          <div className="scope-date-time-wrap">
            <Field
              name="endDate"
              isEndDate
              component={renderDatePicker}
              disabledUntil={startDate}
            />
            <Field
              name="endTime"
              isEndTime
              component={renderTimePicker}
              isSameDay={this.isSameDay()}
              startTime={startTime}
            />
          </div>
        </div>
        <div className="scope-action-wrap">
          <button type="submit" className="generic-submit-button primary-btn">
            <FormattedMessage id="action.search.update.bookings" />
          </button>
        </div>
      </form>
    );
  }
}

const formName = 'editBooking';

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingActions }, dispatch);
}

const mapStateToProps = (state) => {
  return {
    messages: messagesSelector(state),
    companySites: companySitesSelector(state),
    formValues: getFormValues(formName)(state)
  };
};

BookingEditPopupForm = reduxForm({
  form: formName,
  touchOnChange: true,
  validate,
  fields: ['location', 'startDate', 'startTime', 'endDate', 'endTime']
})(BookingEditPopupForm);

BookingEditPopupForm = connect(mapStateToProps, mapDispatchToProps)(BookingEditPopupForm);

export default BookingEditPopupForm;
