export const modalClassNames = {
  // The element states
  input: 'pickerModal__input',
  active: 'pickerModal__input--active',

  // The root picker and states *
  picker: 'pickerModal',
  opened: 'pickerModal--opened',
  focused: 'pickerModal--focused',

  // The picker holder
  holder: 'pickerModal__holder',

  // The picker frame, wrapper, and box
  frame: 'pickerModal__frame',
  wrap: 'pickerModal__wrap',
  box: 'pickerModal__box',

  // The picker header
  header: 'pickerModal__header',

  // Month navigation
  navPrev: 'pickerModal__nav--prev',
  navNext: 'pickerModal__nav--next',
  navDisabled: 'pickerModal__nav--disabled',

  // Month & year labels
  month: 'pickerModal__month',
  year: 'pickerModal__year',

  // Month & year dropdowns
  selectMonth: 'pickerModal__select--month',
  selectYear: 'pickerModal__select--year',

  // Table of dates
  table: 'pickerModal__table',

  // Weekday labels
  weekdays: 'pickerModal__weekday',

  // Day states
  day: 'pickerModal__day',
  disabled: 'pickerModal__day--disabled',
  selected: 'pickerModal__day--selected',
  highlighted: 'pickerModal__day--highlighted',
  now: 'pickerModal__day--today',
  infocus: 'pickerModal__day--infocus',
  outfocus: 'pickerModal__day--outfocus',

  // The picker footer
  footer: 'pickerModal__footer',

  // Today, clear, & close buttons
  buttonClear: 'pickerModal__button--clear',
  buttonClose: 'pickerModal__button--close',
  buttonToday: 'pickerModal__button--today'
};
