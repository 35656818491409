export const keysDaysOfWeek = {
  MONDAY: 'week.day.monday',
  TUESDAY: 'week.day.tuesday',
  WEDNESDAY: 'week.day.wednesday',
  THURSDAY: 'week.day.thursday',
  FRIDAY: 'week.day.friday',
  SATURDAY: 'week.day.saturday',
  SUNDAY: 'week.day.sunday'
};

export const keysMemberStatus = {
  NOT_APPROVED: 'status.NOT_APPROVED',
  APPROVED: 'status.APPROVED',
  TO_REVIEW: 'status.TO_REVIEW',
  TO_COMPLETE: 'status.TO_COMPLETE'
};

export const keysCustomFieldTypes = {
  BOOLEAN: 'custom.field.type.boolean',
  FILE: 'generic.file',
  NUMERIC: 'generic.number',
  PHONE_NUMBER: 'generic.phone.number',
  TEXT: 'generic.text'
};
