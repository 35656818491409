import React, { Component } from 'react';
import { Accordion } from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { gtmPush } from '../utils/utils';
import IndexLink from './partials/IndexLink';
import { FormattedMessage } from 'react-intl';
import { hotlineSelector, userIdSelector } from '../helpers/selectors';

const numberIssuesVehicleHelpSection = 4;
const numberIssuesCarSharingHelpSection = 6;
const numberIssuesDamagesHelpSection = 2;

const tabList = [
  {
    key: 'helpPage_tab_vehicles',
    getItemFunction: () => helpItemsList('vehicles', numberIssuesVehicleHelpSection)
  },
  {
    key: 'helpPage_tab_carSharing',
    getItemFunction: () => helpItemsList('carSharing', numberIssuesCarSharingHelpSection)
  },
  {
    key: 'helpPage_tab_damages',
    getItemFunction: () => helpItemsList('damages', numberIssuesDamagesHelpSection)
  }
];

function helpItemsList(sectionName, numberOfIssues) {
  const items = [];

  for (let i = 0; i < numberOfIssues; i++) {
    items.push({
      issueKey: `helpPage_${sectionName}_issue${i + 1}_title`,
      answerKey: `helpPage_${sectionName}_issue${i + 1}_answer`
    });
  }

  return items;
}

function computePhoneNumber(phoneNumbers = []) {
  let firstNumber = '';

  phoneNumbers.forEach((number, index) => {
    const { countryCode, nationalNumber } = number || {};
    const lastIndex = phoneNumbers.length - 1;
    const fullNumber = ((countryCode || '') + ' ' + (nationalNumber || '')).trim();

    if (index === 0) {
      firstNumber = fullNumber;
    } else if (index === 1) {
      firstNumber = firstNumber + ' ' + '(' + fullNumber;
    } else {
      firstNumber = firstNumber + ', ' + fullNumber;
    }

    if (index !== 0 && index === lastIndex) {
      if (index === 1) firstNumber += ')';
      else firstNumber += ')';
    }
  });

  return firstNumber;
}

export class FAQ extends Component {
  componentDidMount() {
    const { userId } = this.props;

    gtmPush(
      {
        event: '_virtualPageview',
        pageName: 'mobility|FAQ'
      },
      userId
    );
  }

  format(id, options) {
    const {
      intl: { formatMessage }
    } = this.props;

    return formatMessage({ id }, options);
  }

  renderItems(items) {
    const { hotlineNumber, contactEmail } = this.props;

    return items.map((item) => (
      <Accordion.Item key={item.issueKey} eventKey={item.issueKey}>
        <Accordion.Header>{this.format(item.issueKey)}</Accordion.Header>
        <Accordion.Body>
          {this.format(item.answerKey, {
            contactPhone: hotlineNumber || this.format('faq.contactPhoneFallback'),
            contactEmail: contactEmail || this.format('faq.contactEmailFallback')
          })}
        </Accordion.Body>
      </Accordion.Item>
    ));
  }

  renderTabs() {
    return tabList.map((item) => (
      <Accordion.Item key={item.key} eventKey={item.key}>
        <Accordion.Header>{this.format(item.key)}</Accordion.Header>
        <Accordion.Body>
          <Accordion className="accordion-level2">
            {this.renderItems(item.getItemFunction())}
          </Accordion>
        </Accordion.Body>
      </Accordion.Item>
    ));
  }

  render() {
    return (
      <div className="faq">
        <section className="section1">
          <div className="container">
            <h1 className="heading-lg">{this.format('helpPage_title')}</h1>
            <p className="description">{this.format('helpPage_subtitle')}</p>
          </div>
        </section>
        <section className="section2">
          <div className="container">
            <Accordion className="accordion-level1">{this.renderTabs()}</Accordion>
          </div>
        </section>
        {this.timeHasCome && (
          <section className="section3">
            <div className="container">
              <div className="description">
                <FormattedMessage id="faq.other_question" />
              </div>
              <IndexLink to="/contact-us" className="btn btn-accent">
                <FormattedMessage id="label.click_here" />
              </IndexLink>
            </div>
          </section>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { customerServiceEmail, phoneNumbers } = hotlineSelector(state);
  const hotlineNumber = computePhoneNumber(phoneNumbers);

  return {
    hotlineNumber,
    userId: userIdSelector(state),
    contactEmail: customerServiceEmail
  };
}

const connectComponent = connect(mapStateToProps)(FAQ);

export default injectIntl(connectComponent);
