import React, { Component } from 'react';

import pageIconSrc from '../../../src/assets/images/credit-card.svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import visaLogo from '../../../src/assets/images/visa-logo.svg';
import masterCardLogo from '../../../src/assets/images/master-card-logo.svg';
import genericCardLogo from '../../../src/assets/images/credit-card-logo.svg';
import closeIcon from '../../../src/assets/images/close.svg';

import IndexLink from '../partials/IndexLink';
import { ReactSVG } from 'react-svg';
import {
  messagesSelector,
  userIdSelector,
  currentThemeSelector,
  activeProfileSelector,
  localeSelector,
  userCompanyCurrencySelector,
  userCompanyPaymentProviderSelector
} from '../../helpers/selectors';
import { bindActionCreators } from 'redux';
import * as CreditCardActions from '../../actions/creditCard';
import * as PopupActions from '../../actions/popup';
import * as UserActions from '../../actions/user';

import { connect } from 'react-redux';
import { getObjectFromQuery } from '../../helpers';
import { PAYMENT_PROVIDER, WORLDPAY_PAYMENT_AUTHORIZED } from '../../constants/backend';
import PropTypes from 'prop-types';
import { getMessage as t, removeQueryFromUrl, getAppObj } from '../../utils/utils';
import { addErrorMsg, addInfoMsg } from '../../utils/flashMessage/creator';
import { getErrorMsg } from '../../helpers/errors';
import SetupIntentForm from '../SetupIntentForm';
import { Elements } from '@stripe/react-stripe-js';

const CARD_TYPES = {
  VISA: 0,
  MASTER_CARD: 1,
  OTHER: 2
};

const cardSrcMap = {
  [CARD_TYPES.VISA]: visaLogo,
  [CARD_TYPES.MASTER_CARD]: masterCardLogo,
  [CARD_TYPES.OTHER]: genericCardLogo
};

const masterCardChars = {
  51: true,
  52: true,
  53: true,
  54: true,
  55: true
};

class PaymentInfo extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.initState();
  }

  initState() {
    this.state = {
      creditCardInfo: {},
      createCard: false
    };
  }

  bindFunctions() {
    this.handleSubmit = this.handleSubmit.bind(this);
    this.registerUpdateCard = this.registerUpdateCard.bind(this);
    this.handleDeleteCard = this.handleDeleteCard.bind(this);
  }

  componentDidMount() {
    const { isSubscribePage, getUserProfiles } = this.props;

    if (!isSubscribePage) {
      this.getCreditCardInfo();
      this.parseReturnFromProvider();
    }
  }

  parseReturnFromProvider() {
    const { createCreditCard, userId, m, paymentProvider } = this.props;

    const { orderKey, paymentStatus, setup_intent, redirect_status } =
      getObjectFromQuery() || {};
    const orderCode = orderKey ? orderKey.split('^').pop() : '';

    if (orderKey && paymentProvider === PAYMENT_PROVIDER.WORLDPAY) {
      if (orderCode && paymentStatus === WORLDPAY_PAYMENT_AUTHORIZED) {
        createCreditCard(userId, orderCode).then((data) => {
          if (data.type === 'worldpay/card/CREATE_SUCCESS') {
            addInfoMsg(t(m, 'info.worldpay.return.success'));
            setTimeout(() => this.getCreditCardInfo(), 500);
          }
        });
      } else if (paymentStatus) {
        addErrorMsg(t(m, 'error.credit.card.refused'));
      } else {
        addErrorMsg(t(m, 'label.generic.error'));
      }

      removeQueryFromUrl();
    }
    if (paymentProvider === PAYMENT_PROVIDER.STRIPE && setup_intent) {
      if (redirect_status === 'succeeded') {
        addInfoMsg(t(m, 'info.stripe.return.success'));
        setTimeout(() => this.getCreditCardInfo(), 500);
      } else {
        addErrorMsg(t(m, 'label.generic.error'));
      }
      removeQueryFromUrl();
    }
  }

  showErrorMsg(data) {
    addErrorMsg(
      getErrorMsg({
        bundle: this.props.m,
        error: ((data.error || {}).response || {}).data
      })
    );
  }

  handleSubmit() {
    const {
      popup_CreditCard_SetVisibility,
      onSubmit,
      paymentCode,
      paymentProvider,
      onNext
    } = this.props;
    if (paymentProvider === PAYMENT_PROVIDER.WORLDPAY) {
      if (paymentCode && onNext) return onNext();
      if (onSubmit) onSubmit();
      popup_CreditCard_SetVisibility(true);
    } else this.addCard();
  }

  getCardType(cardNumber = '') {
    const firstChar = cardNumber.substring(0, 1);

    if (firstChar === '4') return CARD_TYPES.VISA;
    else {
      const firstTwoChars = cardNumber.substring(0, 2);

      if (masterCardChars[firstTwoChars]) return CARD_TYPES.MASTER_CARD;
      else return CARD_TYPES.OTHER;
    }
  }

  addCard() {
    this.setState({ createCard: true });
  }

  displayStripeElements() {
    const { locale, currency, theme } = this.props;

    const options = {
      mode: 'setup',
      currency: currency.toLowerCase(),
      locale
    };

    if (getAppObj().Stripe && this.state.createCard) {
      return (
        <Elements stripe={getAppObj().Stripe} options={options}>
          <SetupIntentForm theme={theme} />
        </Elements>
      );
    }
  }

  getCreditCardInfo() {
    const { getCreditCardInfo, userId, paymentProvider } = this.props;

    getCreditCardInfo(userId).then((data) => {
      if (
        data.type === 'card/GET_FAIL' ||
        data.type === 'worldpay/card/GET_FAIL' ||
        data.type === 'stripe/card/GET_FAIL'
      )
        return;
      if (data?.payload?.status === 200) {
        const cardData = data.payload.data || {};
        let { number, expiryMonth, expiryYear } = cardData;
        const cardNumberSplit = (number || '').match(/.{1,4}/g) || [];
        const cardNumber =
          cardNumberSplit.length === 1
            ? '**** **** **** ' + cardNumberSplit.join(' ')
            : cardNumberSplit.join(' ');
        const parsedExpiryMonth = expiryMonth < 10 ? `0${expiryMonth}` : expiryMonth;
        const parsedExpiryYear = (expiryYear + '').substring(2, 4);
        this.setState({
          creditCardInfo: {
            number: cardNumber,
            cardType: this.getCardType(number),
            expiryMonth: parsedExpiryMonth,
            expiryYear: parsedExpiryYear
          }
        });
      }
    });
  }

  cardIsMissing() {
    const { m } = this.props;
    return (
      <div className="scope-field-container">
        <div className="scope-info-text">
          {this.state.createCard
            ? t(m, 'title.provide.payment.method')
            : t(m, 'title.add.payment.method')}
        </div>
      </div>
    );
  }

  paymentPresent() {
    const { m } = this.props;
    return (
      <div className="scope-field-container">
        <div className="scope-title-text">{t(m, 'register.info.payment.saved')}</div>
      </div>
    );
  }

  getCardImage() {
    const { cardType } = this.state.creditCardInfo;
    return <ReactSVG className="react-svg" src={cardSrcMap[cardType]} />;
  }

  currentCard() {
    const { m } = this.props;
    const { expiryMonth, expiryYear, number } = this.state.creditCardInfo;
    if (this.state.createCard) {
      return (
        <div className="scope-field-container">
          <div className="scope-field-label">{t(m, 'title.provide.payment.method')}</div>
        </div>
      );
    } else {
      return (
        <div className="scope-field-container">
          <div className="scope-field-label">{t(m, 'label.actual.payment.method')}</div>
          <div className="scope-credit-card-container">
            {this.getCardImage()}
            <div className="scope-credit-card-inner">
              <div className="scope-credit-card-number">{number}</div>
              <div className="scope-credit-card-date">
                {t(m, 'generic.expires.at')} {expiryMonth}/{expiryYear}
              </div>
            </div>
            <ReactSVG
              src={closeIcon}
              className="react-svg close-icon"
              onClick={this.handleDeleteCard}
            />
          </div>
        </div>
      );
    }
  }

  handleDeleteCard() {
    const { deleteCreditCard, activeProfile, m } = this.props;
    const memberProfileId = activeProfile.id;

    deleteCreditCard(memberProfileId).then((data) => {
      if (data.error) this.showErrorMsg(data);
      else {
        addInfoMsg(t(m, 'info.credit.card.removed'));
        this.setState({ creditCardInfo: {} });
      }
    });
  }

  showCardStatus() {
    const { paymentCode } = this.props;
    const { number } = this.state.creditCardInfo;

    if (paymentCode) return this.paymentPresent();
    if (number) return this.currentCard();
    else return this.cardIsMissing();
  }

  getSubmitTitle() {
    const { paymentCode, m } = this.props;
    const { number } = this.state.creditCardInfo;

    if (paymentCode) return t(m, 'generic.next');
    return number ? t(m, 'action.update.payment') : t(m, 'action.add.payment');
  }

  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  registerUpdateCard() {
    const { popup_CreditCard_SetVisibility, onSubmit, paymentProvider } = this.props;
    if (paymentProvider === PAYMENT_PROVIDER.WORLDPAY) {
      if (onSubmit) onSubmit();
      popup_CreditCard_SetVisibility(true);
    } else {
      this.addCard();
    }
  }

  render() {
    const { m, theme, activeProfileId, paymentProvider } = this.props;
    const { paymentCard } = theme.icons || {};

    return (
      <div className="payment-info-page">
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={paymentCard ? paymentCard : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'profile.section.payment.info')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-fields">{this.showCardStatus()}</div>
          </div>
        </div>
        {this.displayStripeElements()}
        <div className="scope-content-footer">
          {!this.state.createCard && this.state.creditCardInfo && (
            <button
              onClick={this.handleSubmit}
              className="scope-submit-button primary-btn"
            >
              {this.getSubmitTitle()}
            </button>
          )}
        </div>
      </div>
    );
  }
}

PaymentInfo.defaultProps = {
  isSubscribePage: false
};

PaymentInfo.propTypes = {
  isSubscribePage: PropTypes.bool,
  creditCardMandatory: PropTypes.bool,
  paymentCode: PropTypes.any, // to indicate saved payment for register form
  onSubmit: PropTypes.func,
  onNext: PropTypes.func
};

function mapStateToProps(state) {
  return {
    userId: userIdSelector(state),
    m: messagesSelector(state),
    activeProfile: activeProfileSelector(state),
    theme: currentThemeSelector(state),
    paymentProvider: userCompanyPaymentProviderSelector(state),
    currency: userCompanyCurrencySelector(state),
    locale: localeSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...CreditCardActions, ...PopupActions, ...UserActions },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentInfo);
