import React from 'react';

export default function InviteCode({ color, ...props } = { color: '#000' }) {
  return (
    <svg
      width="23px"
      height="14px"
      viewBox="0 0 23 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="subscribe" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop-Artboard-14-Copy-3"
          transform="translate(-755.000000, -516.000000)"
          fill={color}
          fillRule="nonzero"
        >
          <g id="noun_voucher_2112209" transform="translate(755.000000, 516.000000)">
            <path
              d="M21.3359996,5.18699991 C21.9624905,4.85072711 22.3664476,4.21024251 22.3999996,3.5 L22.3999996,2.79999995 C22.3999996,1.25360268 21.1463969,0 19.5999997,0 L2.79999995,0 C1.25360268,0 0,1.25360268 0,2.79999995 L0,3.5 C0.0213517349,4.22292751 0.427015955,4.87946303 1.06399998,5.22199991 C1.70825239,5.5985772 2.10426839,6.28876156 2.10426839,7.03499988 C2.10426839,7.7812382 1.70825239,8.47142256 1.06399998,8.84799985 C0.437509083,9.18427265 0.0335519881,9.82475725 0,10.5349998 L0,11.1999998 C0,12.7463971 1.25360268,14 2.79999995,14 L19.5999997,14 C21.1463969,14 22.3999996,12.7463971 22.3999996,11.1999998 L22.3999996,10.5349998 C22.3786479,9.81207225 21.9729837,9.15553674 21.3359996,8.81299985 C20.6917472,8.43642256 20.2957312,7.7462382 20.2957312,7 C20.2957312,6.25376156 20.6917472,5.5635772 21.3359996,5.18699991 L21.3359996,5.18699991 Z M20.6359996,3.97599993 C19.5599885,4.60304022 18.898221,5.75461659 18.898221,6.99999988 C18.898221,8.24538317 19.5599885,9.39695955 20.6359996,10.0239998 C20.8338353,10.1257084 20.9715518,10.3153933 21.0069996,10.5349998 L21.0069996,11.1999998 C21.0069996,11.9731984 20.3801983,12.5999998 19.6069997,12.5999998 L2.79999995,12.5999998 C2.02680132,12.5999998 1.39999998,11.9731984 1.39999998,11.1999998 L1.39999998,10.5349998 C1.43544784,10.3153933 1.57316431,10.1257084 1.77099997,10.0239998 C2.84701113,9.39695955 3.50877861,8.24538317 3.50877861,6.99999988 C3.50877861,5.75461659 2.84701113,4.60304022 1.77099997,3.97599993 C1.58177427,3.88152771 1.4454071,3.70656605 1.39999998,3.49999994 L1.39999998,2.79999995 C1.39999998,2.02680132 2.02680132,1.39999998 2.79999995,1.39999998 L19.5999997,1.39999998 C20.3731983,1.39999998 20.9999996,2.02680132 20.9999996,2.79999995 L20.9999996,3.49999994 C20.9545925,3.70656605 20.8182253,3.88152771 20.6289996,3.97599993 L20.6359996,3.97599993 Z"
              id="Shape"
            ></path>
            <circle id="Oval" cx="15.3999997" cy="2.79999995" r="1"></circle>
            <circle id="Oval" cx="15.3999997" cy="4.89999992" r="1"></circle>
            <circle id="Oval" cx="15.3999997" cy="6.99999988" r="1"></circle>
            <circle id="Oval" cx="15.3999997" cy="9.09999985" r="1"></circle>
            <circle id="Oval" cx="15.3999997" cy="11.1999998" r="1"></circle>
            <path
              d="M10.8989998,2.79999995 L7.30099988,2.79999995 C5.97655995,2.8038445 4.90384447,3.87655998 4.89999992,5.20099991 L4.89999992,8.79899985 C4.90384447,10.1234398 5.97655995,11.1961553 7.30099988,11.1999998 L10.8989998,11.1999998 C12.2234397,11.1961553 13.2961552,10.1234398 13.2999998,8.79899985 L13.2999998,5.20099991 C13.2961552,3.87655998 12.2234397,2.8038445 10.8989998,2.79999995 L10.8989998,2.79999995 Z M11.8999998,8.79899985 C11.8999998,9.35183688 11.4518368,9.79999983 10.8989998,9.79999983 L7.30099988,9.79999983 C6.74816285,9.79999983 6.29999989,9.35183688 6.29999989,8.79899985 L6.29999989,5.20099991 C6.29999989,4.64816289 6.74816285,4.19999993 7.30099988,4.19999993 L10.8989998,4.19999993 C11.4518368,4.19999993 11.8999998,4.64816289 11.8999998,5.20099991 L11.8999998,8.79899985 Z"
              id="Shape"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}
