import React, { Component } from 'react';
import intlTelInput from 'intl-tel-input';
import utils from 'intl-tel-input/build/js/utils';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import { fallbackFunc } from '../../utils/utils';
import { createGetCountryIsoByIpAction } from '../../helpers/api';
import { NUMBER_FORMAT } from '../../constants/generic';

class PhoneNumber extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.refSetter = (input) => {
      this.inputRef = input;
    };

    this.handleOnChange = throttle(() => {
      const number = this.telInstance.getNumber(NUMBER_FORMAT.E164);
      const country = this.telInstance.getSelectedCountryData();
      const valid = this.telInstance.isValidNumber();
      const phoneSuffix = number.substring(country.dialCode.length + 1);
      const suffixFallback = this.inputRef?.value;

      this.currentValue = {
        phonePrefix: '+' + country.dialCode,
        phoneSuffix: phoneSuffix || suffixFallback,
        valid
      };

      this.props.onChange(this.currentValue);
    }, 300);

    this.handleOnBlur = () => {
      this.handleOnChange();
      this.props.onBlur();
    };
  }

  componentDidMount() {
    this.createTelInstance();
    this.componentPropsUpdated();
  }

  createTelInstance() {
    const options = {
      utilsScript: utils,
      separateDialCode: true
    };

    const { fallbackCountry, value } = this.props;
    const { phoneSuffix, phonePrefix } = value || {};
    const noValue = !(phonePrefix && phoneSuffix);

    if (noValue) {
      options.initialCountry = 'auto';
      options.geoIpLookup = createGetCountryIsoByIpAction(fallbackCountry);
    }

    this.telInstance = intlTelInput(this.inputRef, options);
  }

  componentWillUnmount() {
    this.telInstance.destroy();
  }

  componentPropsUpdated(pp) {
    this.updateCurrentValue(pp);
  }

  updateCurrentValue(pp) {
    if (this.valueUpdated(pp)) {
      const { value } = this.props;

      if (!this.numberIsEqual(value, this.currentValue)) {
        const phonePrefix = value.phonePrefix || '';
        const phoneSuffix = value.phoneSuffix || '';

        this.telInstance.setNumber(phonePrefix + phoneSuffix);
      }
    }
  }

  valueUpdated(pp) {
    const { value: prevValue } = pp || {};
    const { value: nextValue } = this.props;
    return !pp || !this.numberIsEqual(nextValue, prevValue);
  }

  numberIsEqual(aNumber = {}, bNumber = {}) {
    const { phonePrefix: aPrefix, phoneSuffix: aSuffix } = aNumber;
    const { phonePrefix: bPrefix, phoneSuffix: bSuffix } = bNumber;
    return aPrefix === bPrefix && aSuffix === bSuffix;
  }

  render() {
    return (
      <input
        className={this.props.inputClass}
        ref={this.refSetter}
        onChange={this.handleOnChange}
        onBlur={this.handleOnBlur}
        type="tel"
      />
    );
  }
}

PhoneNumber.defaultProps = {
  onChange: fallbackFunc,
  onBlur: fallbackFunc,
  fallbackCountry: 'fr'
};

const phoneNumberType = PropTypes.shape({
  phonePrefix: PropTypes.string,
  phoneSuffix: PropTypes.string,
  valid: PropTypes.bool
});

// empty string is provided by redux-form when there is no value
const valueTypes = [phoneNumberType, PropTypes.string];

PhoneNumber.propTypes = {
  fallbackCountry: PropTypes.string,
  inputClass: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.oneOfType(valueTypes)
};

export default PhoneNumber;
