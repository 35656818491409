import React from 'react';

const SliderTrack = ({
  source,
  target,
  getTrackProps,
  className,
  color,
  ...restProps
}) => (
  <div
    className="track"
    style={{
      backgroundColor: `${color}`,
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`
    }}
    {...restProps}
    {...getTrackProps()}
  />
);

export default SliderTrack;
