import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import * as i18nActions from '../../actions/i18nActions';
import { Dropdown } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import earth from '../../assets/flags/earth.svg';

import { currentBrandSelector, localeSelector } from '../../helpers/selectors';
import PropTypes from 'prop-types';
import { pt_BR_map } from '../../actions/i18nActions/helpers';
import { allLanguages } from '../../constants/all-languages';

export class LanguageSwitch extends Component {
  componentDidMount() {
    const { locale } = this.props.i18n;
    const doc = document.querySelector('html');
    doc.lang = locale;
  }

  getLocaleTitle(locale) {
    return pt_BR_map[locale] || locale;
  }

  render() {
    const {
      i18n: { locale },
      branded,
      dropup
    } = this.props;

    const title = (
      <span className="selected-locale-container">
        <ReactSVG className="react-svg" src={earth} />
        {this.getLocaleTitle(locale)}
        <i className="icon-angle-down" />
      </span>
    );

    const MenuItems = branded.language.reduce((sum, locale) => {
      if (allLanguages[locale]) {
        sum.push(
          <Dropdown.Item
            as="li"
            eventKey="1"
            key={locale}
            onClick={() => this.props.setLanguage(locale)}
          >
            <a>
              <span className="sub-menu">
                {allLanguages[locale].flag} {allLanguages[locale].text}
              </span>
            </a>
          </Dropdown.Item>
        );
      }
      return sum;
    }, []);

    return (
      <div className="container-language">
        <Dropdown id="dropdown-language-switch" drop="up">
          <Dropdown.Toggle className="current-lang">{title}</Dropdown.Toggle>
          <Dropdown.Menu className="language-menu" as="ul">
            <Dropdown.Header as="li">
              <FormattedMessage id="language.header" />
            </Dropdown.Header>
            {MenuItems}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    );
  }
}

LanguageSwitch.propTypes = {
  dropup: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    ...state,
    branded: currentBrandSelector(state),
    locale: localeSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...i18nActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitch);
