import Popup from './Popup';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProfilePopup from './ProfilePopup';
import { FormattedMessage } from 'react-intl';
import * as PopupActions from '../../actions/popup';
import * as CreditCardActions from '../../actions/creditCard';
import { getObjectFromQuery, setDeepRouterQuery } from '../../helpers';
import { removeQueryFromUrl } from '../../utils/utils';

import { STORAGE_KEY } from '../../constants/generic';
import { customFieldsValuesSelector, userIdSelector } from '../../helpers/selectors';
import { browserHistory } from '../../helpers/components';
import _isEmpty from 'lodash/isEmpty';
import popupIconSrc from '../../assets/images/credit-card.svg';
import { book } from '../../constants/routes';

class PaymentErrorPopup extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.closePaymentErrorPopup = () => {
      this.props.popup_PaymentError_SetVisibility(false);
    };

    this.handleUpdatePayment = () => {
      const query = getObjectFromQuery();

      this.setRedirectData();
      this.closePaymentErrorPopup();
      this.saveVehicleId();
      this.saveBookingRecapData();
      this.saveQueryData(query);
      this.redirectToPaymentProvider(query);
    };
  }

  setRedirectData() {
    window.localStorage.setItem(STORAGE_KEY.BOOKING_WORLDPAY, 'true');
  }

  saveBookingRecapData() {
    const { customFieldsValues } = this.props;
    const data = _isEmpty(customFieldsValues) ? true : customFieldsValues;

    window.localStorage.setItem(STORAGE_KEY.RECAP_DATA, JSON.stringify(data));
  }

  redirectToPaymentProvider(query) {
    const returnUrl = window.location.origin + '/redirect';

    this.props.getCreditCardURL(this.props.userId, returnUrl).then((data) => {
      if (data.type === 'card/GET_URL_FAIL') this.restoreQueryData(query);
    });
  }

  saveQueryData(query) {
    if (!_isEmpty(query)) {
      window.localStorage.setItem(STORAGE_KEY.SEARCH_DATA, JSON.stringify(query));
      removeQueryFromUrl();
    }
  }

  restoreQueryData(query) {
    if (!_isEmpty(query)) {
      browserHistory.replace({
        pathname: `/${book}`,
        query: setDeepRouterQuery(query)
      });
    }
  }

  saveVehicleId() {
    const { selectedVehicle } = this.props;
    const { vehicle } = selectedVehicle || {};
    const { id } = vehicle || {};

    if (id) window.localStorage.setItem(STORAGE_KEY.VEHICLE_ID, id);
  }

  render() {
    const { type_PaymentError_IsVisible, popupMessage } = this.props;

    if (type_PaymentError_IsVisible) {
      return (
        <Popup
          isOpen={type_PaymentError_IsVisible}
          onCloseRequested={this.closePaymentErrorPopup}
          isError
          id="profileError-popup"
        >
          <ProfilePopup
            bodyMsg={<FormattedMessage id={popupMessage} />}
            submitMsg={<FormattedMessage id="action.confirm.welcome.msg" />}
            iconSrc={popupIconSrc}
            handleSubmit={this.handleUpdatePayment}
            handleCancel={this.closePaymentErrorPopup}
          />
        </Popup>
      );
    } else return null;
  }
}

const mapStateToProps = (state) => {
  const { popup, booking } = state;
  const { selectedVehicle } = booking;

  return {
    ...popup,
    userId: userIdSelector(state),
    selectedVehicle,
    customFieldsValues: customFieldsValuesSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions, ...CreditCardActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentErrorPopup);
