import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import cautionIcon from '../../assets/images/caution.svg';

const ProfilePopup = ({
  handleSubmit,
  handleCancel,
  bodyMsg,
  cancelMsg = <FormattedMessage id="generic.cancel" />,
  submitMsg = <FormattedMessage id="label.continue" />,
  iconSrc = cautionIcon,
  singleButton
}) => {
  return (
    <div className="p-15 text-center booking-cancel-popup">
      <p className="bold mt-xs-25 mt-dt-35 f-25 mb-dt-25 mb-xs-15">
        <img className="scope-icon" src={iconSrc} alt="" />
      </p>
      <p className="f-15 mt-40">{bodyMsg}</p>
      <div className="mt-40 hr-dashed" />
      {singleButton ? (
        <Row className="mt-30">
          <Col
            xs={12}
            sm={12}
            md={12}
            className="f-0 scope-button-container scope-button-center"
            onClick={handleSubmit}
          >
            <div className="scope-button primary-btn scope-button-cancel">{submitMsg}</div>
          </Col>
        </Row>
      ) : (
        <Row className="mt-30">
          <Col
            xs={12}
            sm={6}
            md={6}
            className="f-0 pr-md-5 mb-xs-10 scope-button-container sub-class-left"
            onClick={handleCancel}
          >
            <div className="scope-button secondary-bg scope-button-return">
              {cancelMsg}
            </div>
          </Col>
          <Col
            xs={12}
            sm={6}
            md={6}
            className="f-0 pl-md-5 scope-button-container sub-class-right"
            onClick={handleSubmit}
          >
            <div className="scope-button primary-btn  scope-button-cancel">
              {submitMsg}
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProfilePopup;
