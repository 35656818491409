import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { Row, Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import { checkPassword, checkPasswordConfirmation } from '../../helpers';
import { renderTextField } from './fields';

const validate = (values, props) => {
  const errors = {};
  const { messages } = props.i18n;

  const passError = checkPassword(values.password, messages);

  const confirmPassError = checkPasswordConfirmation(
    values.confirmationPassword,
    values.password,
    messages
  );

  if (passError) errors.password = passError;
  if (confirmPassError) errors.confirmationPassword = confirmPassError;

  return errors;
};

class ResetPasswordForm extends Component {
  render() {
    const { messages } = this.props.i18n;
    const { handleSubmit, resetPasswordFetching } = this.props;

    return (
      <form className="form-content" onSubmit={handleSubmit} noValidate>
        <Field
          name="password"
          component={renderTextField}
          label={messages['label.new.password']}
          type="password"
        />
        <Field
          name="confirmationPassword"
          component={renderTextField}
          label={messages['label.confirm_new_password']}
          type="password"
        />

        <div className="action-buttons">
          <Row>
            <Col sm={3} md={3} />
            <Col sm={6} md={6}>
              <button
                disabled={resetPasswordFetching ? 'disabled' : null}
                className="btn btn-accent primary-btn mb-25 w-100"
              >
                <FormattedMessage id="generic.save" />
              </button>
            </Col>
            <Col sm={3} md={3} />
          </Row>
        </div>
      </form>
    );
  }
}

export default reduxForm({
  form: 'resetPassword',
  destroyOnUnmount: false,
  validate,
  fields: ['password', 'confirmationPassword']
})(ResetPasswordForm);
