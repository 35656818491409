import React from 'react';

export default function Lock({ color, ...props } = { color: '#000' }) {
  return (
    <svg
      width="19px"
      height="21px"
      viewBox="-3 0 19 21"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="subscribe" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop-Artboard-14"
          transform="translate(-758.000000, -579.000000)"
          fillRule="nonzero"
        >
          <g id="Group-6" transform="translate(705.000000, 242.000000)">
            <g id="Group-2" transform="translate(41.000000, 262.000000)">
              <g id="Lock" transform="translate(12.000000, 76.000000)">
                <path
                  d="M7.55048314,0.0372023389 C4.78323107,0.0372023389 2.53129947,2.28244222 2.53129947,5.04147128 L2.53129947,7.96141233 L1.84137407,7.96141233 C0.905114166,7.96141233 0.14345918,8.72080403 0.14345918,9.6533408 L0.14345918,17.7412858 C0.14345918,18.6747636 0.905114166,19.4341553 1.84137407,19.4341553 L13.2586484,19.4341553 C14.1949083,19.4341553 14.9565633,18.6747636 14.9565633,17.7412858 L14.9565633,9.6533408 C14.9565633,8.71986302 14.1949083,7.96141233 13.2586484,7.96141233 L12.5696668,7.96141233 L12.5696668,5.04147128 C12.5696668,2.28244222 10.3177352,0.0372023389 7.55048314,0.0372023389 Z M3.65866654,5.15244057 C3.65866654,2.87306906 5.41708353,1.01720207 7.57866641,1.01720207 C9.74024929,1.01720207 11.4986663,2.8721699 11.4986663,5.15244057 L11.4986663,7.94253517 L3.65866654,7.94253517 L3.65866654,5.15244057 Z M13.2595922,8.63893652 C13.8211594,8.63893652 14.2779636,9.09438334 14.2779636,9.6542818 L14.2779636,17.7422268 C14.2779636,18.3021253 13.8211594,18.7575721 13.2595922,18.7575721 L1.84137407,18.7575721 C1.27980689,18.7575721 0.823002662,18.3021253 0.823002662,17.7422268 L0.823002662,9.6542818 C0.823002662,9.09438334 1.27980689,8.63893652 1.84137407,8.63893652 L2.8701274,8.63893652 L12.2298951,8.63893652 L13.2595922,8.63893652 Z"
                  id="Shape"
                  strokeWidth="0.3"
                  fill={color}
                ></path>
                <path
                  d="M13.2595922,8.63893652 C13.8211594,8.63893652 14.2779636,9.09438334 14.2779636,9.6542818 L14.2779636,17.7422268 C14.2779636,18.3021253 13.8211594,18.7575721 13.2595922,18.7575721 L1.84137407,18.7575721 C1.27980689,18.7575721 0.823002662,18.3021253 0.823002662,17.7422268 L0.823002662,9.6542818 C0.823002662,9.09438334 1.27980689,8.63893652 1.84137407,8.63893652 L2.8701274,8.63893652 L12.2298951,8.63893652 L13.2595922,8.63893652 Z"
                  id="Shape"
                  strokeWidth="0.5"
                ></path>
                <path
                  d="M7.55048314,10.3553311 C8.70665087,10.3553311 9.64668602,11.2935139 9.64668602,12.4453051 C9.64668602,13.1698795 9.27482472,13.8276426 8.6641794,14.20875 L8.6641794,14.20875 L8.6641794,15.9194985 C8.6641794,16.5320933 8.1649037,17.0298854 7.55048314,17.0298854 C6.93606257,17.0298854 6.43678687,16.5320933 6.43678687,15.9194985 L6.43678687,15.9194985 L6.43678687,14.20875 C5.82614155,13.8276426 5.45428026,13.1698795 5.45428026,12.4453051 C5.45428026,11.2925729 6.39431541,10.3553311 7.55048314,10.3553311 Z M7.55048314,11.0328553 C6.76900813,11.0328553 6.13287993,11.6670932 6.13287993,12.4462461 C6.13287993,12.9826194 6.43678687,13.4662964 6.92662447,13.7071939 C7.04176934,13.7645952 7.11538655,13.8822209 7.11538655,14.0111387 L7.11538655,14.0111387 L7.11538655,15.9204395 C7.11538655,16.159455 7.3107553,16.3542432 7.55048314,16.3542432 C7.79021098,16.3542432 7.98557973,16.159455 7.98557973,15.9204395 L7.98557973,15.9204395 L7.98557973,14.0111387 C7.98557973,13.8822209 8.05825313,13.7645952 8.17434181,13.7071939 C8.6641794,13.4662964 8.96808635,12.9826194 8.96808635,12.4462461 C8.96808635,11.6670932 8.33195814,11.0328553 7.55048314,11.0328553 Z"
                  id="Combined-Shape"
                  strokeWidth="0.3"
                  fill={color}
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
