import React, { Component } from 'react';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import {
  userCompanySelector,
  currentBrandSelector,
  userIdSelector
} from '../../helpers/selectors';
import { ReactSVG } from 'react-svg';
import twitterIcon from '../../assets/images/twitter.svg';
import linkedIcon from '../../assets/images/linkedin.svg';
import classnames from 'classnames';
import LanguageSwitch from './LanguageSwitch';
import { addStrPart, isObjEmpty, safe } from '../../utils/utils';

class Footer extends Component {
  isPhoneNumbersEmpty() {
    const { computedConfiguration } = this.props.companyInfos || {};
    const { hotline } = computedConfiguration || {};
    const { phoneNumbers } = hotline || {};
    return isObjEmpty(phoneNumbers);
  }

  trimString(url) {
    return typeof url === 'string' ? url.trim() : '';
  }

  getTermUrl() {
    const { termsOfUseUrl } = this.props.companyInfos || {};
    return termsOfUseUrl;
  }

  footerItem(t, u) {
    if (t && u) {
      return (
        <li className="footer_subItem">
          <a href={u} className="footer_link" target="_blank">
            <FormattedMessage id={t} />
          </a>
        </li>
      );
    }
  }

  getTermsOfUse() {
    let url = this.trimString(this.getTermUrl());
    return this.footerItem('footer_society_term', url);
  }

  getPolicyPrivacyLink() {
    const { privacyPolicyUrl } = this.props.companyInfos || {};
    const url = this.trimString(privacyPolicyUrl);

    return this.footerItem('footer_society_policy_privacy', url);
  }

  getPersonalDataLink() {
    if (this.props.userLoggedIn) return;

    const { footerData } = this.props.brand || {};
    const { personalDataUrl } = footerData || {};
    const url = this.trimString(personalDataUrl);

    return this.footerItem('footer.label.personal.data', url);
  }

  getLegalInformationLink() {
    if (this.props.userLoggedIn) return;

    const { footerData } = this.props.brand || {};
    const { legalInformationUrl } = footerData || {};
    const url = this.trimString(legalInformationUrl);

    return this.footerItem('footer.label.legal.information', url);
  }

  getSocialLinks() {
    const { footerData } = this.props.brand || {};
    const { linkedinUrl, twitterUrl } = footerData || {};

    if (linkedinUrl || twitterUrl) {
      return (
        <div className="footer_share">
          <FormattedMessage id="footer_share" />
          <div className="footer_share_icons">
            {linkedinUrl && (
              <a href={linkedinUrl} target="_blank">
                <ReactSVG
                  className={classnames('react-svg', 'linkedIn')}
                  src={linkedIcon}
                />
              </a>
            )}
            {twitterUrl && (
              <a href={twitterUrl} target="_blank">
                <ReactSVG
                  className={classnames('react-svg', 'twitter')}
                  src={twitterIcon}
                />
              </a>
            )}
          </div>
        </div>
      );
    }
  }

  reduceLinks(linkArray) {
    return linkArray.reduce((links, getLink, i) => {
      let link = getLink.call(this);

      if (link) {
        link = React.cloneElement(link, { key: i });
        links.push(link);
      }

      return links;
    }, []);
  }

  getOtherSection() {
    const linkArray = [
      this.getSocialLinks,
      this.getTermsOfUse,
      this.getPolicyPrivacyLink,
      this.getPersonalDataLink,
      this.getLegalInformationLink
    ];

    const linkMap = this.reduceLinks(linkArray);

    if (!!linkMap.length) {
      return (
        <li className="footer_item">
          <span className="footer_item_title">
            <FormattedMessage id="footer_other" />
          </span>
          <div className="footer_item_section">
            <ul className="footer_subItems">{linkMap}</ul>
          </div>
        </li>
      );
    }
  }

  showNumber(name, countryCode, nationalNumber) {
    if (name) {
      name = (
        <span>
          <FormattedMessage id="data.with.colon" values={{ data: name }} />{' '}
        </span>
      );
    }

    return (
      <span>
        {name}
        {addStrPart(countryCode, ' ') + nationalNumber}
      </span>
    );
  }

  render() {
    const { companyInfos, brand } = this.props;
    const { computedConfiguration } = companyInfos || {};
    const { hotline } = computedConfiguration || {};
    const { customerServiceEmail: hotlineEmail, phoneNumbers } = hotline || {};
    const { aboutUrl, hotline: brandHotline } = safe(() => brand.footerData) || {};
    const hotlines = this.isPhoneNumbersEmpty() ? brandHotline : phoneNumbers;

    return (
      <footer className="footer">
        <hr />
        <div className="footer_innerWrapper">
          <ul className="footer_items">
            <li className="footer_item switch_items">
              <LanguageSwitch dropup />
            </li>
            {hotlines && hotlines.length > 0 && (
              <li className="footer_item">
                <span className="footer_item_title">
                  <FormattedMessage id="footer_callUs" />
                </span>
                <div className="footer_item_section">
                  <ul className="footer_subItems">
                    {hotlines.map((item, idx) => {
                      const { name, countryCode, nationalNumber } = item || {};

                      return (
                        <li
                          className={classnames('footer_subItem', 'phone-number')}
                          key={'contact' + idx}
                        >
                          {this.showNumber(name, countryCode, nationalNumber)}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            )}
            {hotlineEmail && (
              <li className="footer_item">
                <span className="footer_item_title">
                  <FormattedMessage id="footer_writeUs" />
                </span>
                <div className="footer_item_section">
                  <a
                    href={'mailto:' + hotlineEmail}
                    className="footer_link footer_link--email"
                    target="_blank"
                  >
                    {hotlineEmail}
                  </a>
                </div>
              </li>
            )}
            {aboutUrl && (
              <li className="footer_item">
                <span className="footer_item_title">
                  <FormattedMessage id="generic.company" />
                </span>
                <div className="footer_item_section">
                  <ul className="footer_subItems">
                    <li className="footer_subItem">
                      <a href={aboutUrl} className="footer_link" target="_blank">
                        <FormattedMessage id="footer_society_about" />
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            )}
            {this.getOtherSection()}
          </ul>
        </div>
      </footer>
    );
  }
}

export default connect((state) => ({
  userLoggedIn: userIdSelector(state),
  companyInfos: userCompanySelector(state),
  brand: currentBrandSelector(state)
}))(Footer);
