import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';

import { checkEmail } from '../../helpers';
import { renderPlaceholderTextField } from './fields';
import { connect } from 'react-redux';
import { getMessage as t } from '../../utils/utils';
import { messagesSelector } from '../../helpers/selectors';
import { FormattedMessage } from 'react-intl';

const validate = (values, props) => {
  const errors = {};

  const emailError = checkEmail(values.email, props.m);
  if (emailError) errors.email = emailError;

  return errors;
};

class ExternalLoginForm extends Component {
  render() {
    const { handleSubmit, externalLoginError, externalLoginFetching, m } = this.props;

    return (
      <div className="external-login-main">
        <div className="external-login-desc">
          <FormattedMessage id="sso.login.description" />
        </div>
        <form
          className="form-content external-login-form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Field
            onFocus={this.props.onFocus}
            name="email"
            component={renderPlaceholderTextField}
            placeholder={t(m, 'generic.email')}
            type="email"
          />
          <div className="text-center">
            <button
              type="submit"
              disabled={!!externalLoginFetching}
              className="btn btn-accent primary-btn btn-md mt-40"
            >
              {t(m, 'label.continue')}
            </button>
          </div>
          {externalLoginError && (
            <div className="text-danger text-center mt-25">{externalLoginError}</div>
          )}
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    externalLoginError: state.user.externalLoginError,
    externalLoginFetching: state.user.externalLoginFetching
  };
};

ExternalLoginForm = reduxForm({
  form: 'externalLogin',
  fields: ['email'],
  validate
})(ExternalLoginForm);

ExternalLoginForm = connect(mapStateToProps)(ExternalLoginForm);

export default ExternalLoginForm;
