import React, { Component } from 'react';
import { Field, getFormValues, reduxForm } from 'redux-form';
import pageIconSrc from '../../../src/assets/images/bell-circle.svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import IndexLink from '../partials/IndexLink'
import { ReactSVG } from 'react-svg';
import { messagesSelector, currentThemeSelector } from '../../helpers/selectors';
import { connect } from 'react-redux';
import { getMessage as t } from '../../utils/utils';
import classnames from 'classnames';
import { USER_FORM_NAMES } from '../../constants/form';
import { profileRadioGroup, roundSwitch } from '../forms/newFields';

export const timeRangeOptions = (m) => [
  {
    value: '15',
    title: t(m, 'profile.label.min.15')
  },
  {
    value: '30',
    title: t(m, 'profile.label.min.30')
  },
  {
    value: '45',
    title: t(m, 'profile.label.min.45')
  }
];

class NotificationsForm extends Component {
  displayBackButton() {
    return (
      <IndexLink to="/profile" className="scope-content-header-button-return">
        <ReactSVG className="react-svg" src={arrowLeftSrc} />
      </IndexLink>
    );
  }

  render() {
    const { handleSubmit, m, updateSettingsFetching, fieldValues, theme } = this.props;
    const { smsBeforeDepartureEnabled, smsBeforeArrivalEnabled } = fieldValues || {};
    const { notificationsEmails } = theme.icons || {};

    return (
      <form className="selfie-page" onSubmit={handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={notificationsEmails ? notificationsEmails : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'profile.section.notifications')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-title">
              {t(m, 'profile.title.email.notifications')}
            </div>
            <div className="scope-section-fields">
              <div className="scope-field-container">
                <div className="scope-boolean-container">
                  <span className="scope-boolean-info">
                    {t(m, 'profile.label.charges.my')}
                  </span>
                  <Field name="sendMailForPersonalChange" component={roundSwitch} />
                </div>
              </div>
              <div className="scope-field-container">
                <div className="scope-boolean-container">
                  <span className="scope-boolean-info">
                    {t(m, 'profile.label.charges.call.center')}
                  </span>
                  <Field name="sendMailForCallCenterChange" component={roundSwitch} />
                </div>
              </div>
            </div>
          </div>
          <div className="scope-section">
            <div className="scope-section-title">
              {t(m, 'profile.title.sms.notifications')}
            </div>
            <div className="scope-section-fields">
              <div className="scope-field-container">
                <div className="scope-boolean-container">
                  <span className="scope-boolean-info">
                    {t(m, 'profile.label.before.departure')}
                  </span>
                  <Field name="smsBeforeDepartureEnabled" component={roundSwitch} />
                </div>
              </div>
              {smsBeforeDepartureEnabled && (
                <div className="scope-field-container">
                  <Field
                    name="smsBeforeDepartureTime"
                    component={profileRadioGroup}
                    options={timeRangeOptions(m)}
                  />
                </div>
              )}
              <div className="scope-field-container">
                <div className="scope-boolean-container">
                  <span className="scope-boolean-info">
                    {t(m, 'profile.label.before.arrival')}
                  </span>
                  <Field name="smsBeforeArrivalEnabled" component={roundSwitch} />
                </div>
              </div>
              {smsBeforeArrivalEnabled && (
                <div className="scope-field-container">
                  <Field
                    name="smsBeforeArrivalTime"
                    component={profileRadioGroup}
                    options={timeRangeOptions(m)}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          <button
            type="submit"
            className={classnames('scope-submit-button primary-btn', {
              'sub-class-disabled': updateSettingsFetching
            })}
          >
            {t(m, 'generic.save')}
          </button>
        </div>
      </form>
    );
  }
}

const reduxComponent = reduxForm({
  form: USER_FORM_NAMES.NOTIFICATIONS,
  enableReinitialize: true,
  destroyOnUnmount: false,
  fields: [
    'sendMailForPersonalChange',
    'sendMailForCallCenterChange',
    'smsBeforeArrivalTime',
    'smsBeforeArrivalEnabled',
    'smsBeforeDepartureTime',
    'smsBeforeDepartureEnabled'
  ]
})(NotificationsForm);

const mapStateToProps = (state) => {
  const { updateSettingsFetching } = state.user;
  return {
    m: messagesSelector(state),
    updateSettingsFetching,
    fieldValues: getFormValues(USER_FORM_NAMES.NOTIFICATIONS)(state),
    theme: currentThemeSelector(state)
  };
};

export default connect(mapStateToProps)(reduxComponent);
