import { browserHistory } from '../helpers/components';
import { removeModalQueryParam } from '../helpers';
import { BOOKING_VOUCHER_DIFFERENT_CODE } from '../constants/backend';
import { getErrorMsg, partialErrorCodes } from '../helpers/errors';
import { getActionError, messagesSelector, getGlobalState } from '../helpers/selectors';
import _values from 'lodash/values';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { getErrorStatus } from '../helpers/formatters';

const defaultState = {
  type_Login_IsVisible: false,
  step_AddAccount_IsVisible: false,
  type_BookingEdit_IsVisible: false,
  type_Faq_IsVisible: false,
  step_Subscribe_IsVisible: false,
  type_CreditCard_IsVisible: false,
  type_AnonymConfirm_IsVisible: false,
  step_ForgotPassword_IsVisible: false,
  step_ExternalLogin_IsVisible: false,
  step_ResetPasswordEmailSent_IsVisible: false,
  type_AccountValidation_IsVisible: false,
  type_BookingRecap_IsVisible: false,
  type_BookingCancel_IsVisible: false,
  type_ResetPassword_IsVisible: false,
  type_FiltersBookings_IsVisible: false,
  type_Generic_IsVisible: false,
  type_BookingSuccess_IsVisible: false,
  type_ActivationEmailSent_IsVisible: false,
  type_PaymentError_IsVisible: false,
  directSubscribe: false,
  addToCalendarData: {},
  bookingErrorPopupKey: '',
  genericPopupKey: '',
  genericPopupText: ''
};

function hideLoginSteps(state) {
  return {
    ...state,
    step_ForgotPassword_IsVisible: false,
    step_ExternalLogin_IsVisible: false,
    step_ResetPasswordEmailSent_IsVisible: false,
    step_Subscribe_IsVisible: false,
    step_AddAccount_IsVisible: false
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'POPUP/TYPE/LOGIN/SET_VISIBILITY': {
      const closingPopup = !action.payload;

      if (closingPopup) {
        removeModalQueryParam();
        state = hideLoginSteps();
      }

      state = { ...state, type_Login_IsVisible: action.payload };

      break;
    }

    case 'POPUP/STEP/ADD_ACCOUNT/SHOW': {
      state = {
        ...state,
        type_Login_IsVisible: true,
        step_Subscribe_IsVisible: true,
        step_AddAccount_IsVisible: true
      };

      break;
    }

    case 'POPUP/ACTION/DIRECT_SUBSCRIBE/ENABLE': {
      state = { ...state, directSubscribe: true };
      break;
    }

    case 'POPUP/DATA/ADD_TO_CALENDAR/SET': {
      state = {
        ...state,
        addToCalendarData: action.payload,
        type_BookingSuccess_IsVisible: true
      };

      break;
    }

    case 'POPUP/DATA/ADD_TO_CALENDAR/CLEAR': {
      state = { ...state, addToCalendarData: {} };
      break;
    }

    case 'POPUP/TYPE/CREDIT_CARD/SET_VISIBILITY': {
      state = { ...state, type_CreditCard_IsVisible: action.payload };

      break;
    }

    case 'POPUP/TYPE/ANONYM_CONFIRM/SET_VISIBILITY': {
      state = { ...state, type_AnonymConfirm_IsVisible: action.payload };

      break;
    }

    case 'POPUP/TYPE/FILTERS_BOOKINGS/SET_VISIBILITY': {
      state = { ...state, type_FiltersBookings_IsVisible: action.payload };

      break;
    }

    case 'POPUP/TYPE/ACCOUNT_VALIDATION/SET_VISIBILITY': {
      if (!action.payload) {
        browserHistory.replace('/');
      }
      state = { ...state, type_AccountValidation_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/ACTIVATION_EMAIL_SENT/SET_VISIBILITY': {
      if (!action.payload) {
        browserHistory.replace('/');
      }
      state = { ...state, type_ActivationEmailSent_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/BOOKING_RECAP/SET_VISIBILITY': {
      state = { ...state, type_BookingRecap_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/BOOKING_CANCEL/SET_VISIBILITY': {
      state = { ...state, type_BookingCancel_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/BOOKING_SUCCESS/SET_VISIBILITY': {
      state = { ...state, type_BookingSuccess_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/PAYMENT_ERROR/SET_VISIBILITY': {
      state = { ...state, type_PaymentError_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/GENERIC/SET_VISIBILITY': {
      const updateText = !action.payload && {
        genericPopupKey: '',
        genericPopupText: ''
      };

      state = {
        ...state,
        ...action.popupData,
        ...updateText,
        type_Generic_IsVisible: action.payload
      };

      break;
    }

    case 'booking/BOOK_VEHICLE_FAIL': {
      const messages = messagesSelector(getGlobalState());
      let newState = '';
      let errorMsg = '';

      switch (getActionError(action).errorCode) {
        case 'booking.payment.invalid': {
          newState = {
            ...state,
            type_PaymentError_IsVisible: true,
            popupMessage: 'error.booking.no_credit_card'
          };
          break;
        }

        case 'booking.existing.same.period': {
          errorMsg = messages['error.booking.same_period'];
          break;
        }

        case 'booking.driving.licence.invalid': {
          errorMsg = messages['error.booking.member_invalid'];
          break;
        }

        case 'booking.member.invalid': {
          errorMsg = messages['error.booking.member_invalid'];
          break;
        }

        case 'booking.payment.prebilling.refused.error': {
          errorMsg = messages['booking.payment.prebilling.refused.error'];
          break;
        }

        case 'booking.payment.prebilling.technical.error': {
          errorMsg = messages['booking.payment.prebilling.technical.error'];
          break;
        }

        case 'creditcard.data.insufficient.3ds': {
          newState = {
            ...state,
            type_PaymentError_IsVisible: true,
            popupMessage: 'creditcard.data.insufficient.3ds'
          };
          break;
        }

        case BOOKING_VOUCHER_DIFFERENT_CODE: {
          errorMsg = BOOKING_VOUCHER_DIFFERENT_CODE;
          break;
        }

        default: {
          errorMsg = getErrorMsg({
            bundle: messages,
            error: getActionError(action)
          });
        }
      }

      if (newState) {
        state = newState;
        break;
      }

      addErrorMsg(errorMsg);
      break;
    }

    case 'user/FORCE_UPDATE_PASSWORD_FAIL':
    case 'user/UPDATE_PASSWORD_FAIL': {
      const messages = messagesSelector(getGlobalState());
      let errorMsg = '';

      switch (getErrorStatus(action)) {
        case 400: {
          errorMsg = messages['error.change_password.400.bad_request'];
          break;
        }

        default: {
          errorMsg = getErrorMsg({
            bundle: messages,
            error: getActionError(action)
          });
        }
      }

      addErrorMsg(errorMsg);
      break;
    }

    case 'user/UPDATE_SETTINGS_FAIL':
    case 'user/GET_SETTINGS_FAIL':
    case 'user/UPDATE_INFO_FAIL': {
      const messages = messagesSelector(getGlobalState());

      const errorMsg = getErrorMsg({
        bundle: messages,
        error: getActionError(action),
        partialErrors: _values(partialErrorCodes.profile)
      });

      addErrorMsg(errorMsg);
      break;
    }

    case 'booking/BOOK_VEHICLE_SUCCESS': {
      state = { ...state, type_BookingRecap_IsVisible: false };
      break;
    }

    case 'POPUP/STEP/FORGOT_PASSWORD/SET_VISIBILITY': {
      state = { ...state, step_ForgotPassword_IsVisible: action.payload };
      break;
    }

    case 'POPUP/STEP/EXTERNAL_LOGIN/SET_VISIBILITY': {
      state = { ...state, step_ExternalLogin_IsVisible: action.payload };
      break;
    }

    case 'user/FORGOT_PASSWORD_SUCCESS': {
      state = { ...state, step_ResetPasswordEmailSent_IsVisible: true };
      break;
    }

    case 'POPUP/STEP/RESET_PASSWORD_EMAIL_SENT/SET_VISIBILITY': {
      state = { ...state, step_ResetPasswordEmailSent_IsVisible: action.payload };
      break;
    }

    case 'user/LOGIN_SUCCESS': {
      state = { ...state, type_Login_IsVisible: false };
      break;
    }

    case 'POPUP/TYPE/RESET_PASSWORD/SET_VISIBILITY': {
      if (!action.payload) {
        browserHistory.replace('/');
      }

      state = {
        ...state,
        type_ResetPassword_IsVisible: action.payload
      };
      break;
    }

    case 'POPUP/STEP/SUBSCRIBE/SET_VISIBILITY': {
      state = {
        ...state,
        step_Subscribe_IsVisible: action.payload
      };
      break;
    }

    case 'POPUP/TYPE/FAQ/SET_VISIBILITY': {
      state = { ...state, type_Faq_IsVisible: action.payload };
      break;
    }

    case 'POPUP/TYPE/BOOKING_EDIT/SET_VISIBILITY': {
      state = { ...state, type_BookingEdit_IsVisible: action.payload };
      break;
    }

    case 'user/CHECK_RESET_PASSWORD_TOKEN_FAIL': {
      state = { ...state, type_ResetPassword_IsVisible: true };
      break;
    }

    case 'user/RESET_PASSWORD_FAIL': {
      // the error is set on user reducer
      state = {
        ...state,
        type_ResetPassword_IsVisible: true
      };
      break;
    }

    case 'user/RESET_PASSWORD_SUCCESS': {
      state = {
        ...state,
        type_ResetPassword_IsVisible: true
      };
      break;
    }

    case 'POPUP/ACTION/CLOSE_ALL': {
      state = { ...defaultState };
      break;
    }

    default: {
      break;
    }
  }

  return state;
}
