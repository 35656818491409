import { Field } from 'redux-form';
import React from 'react';
import NewFileUpload from '../../forms/NewFileUpload';
import { showErrorInfo } from '../../forms/newFields';

export function FileFieldInner({ label, mandatory, disabled, ...props }) {
  return (
    <div className="scope-field-container">
      <NewFileUpload label={label} mandatory={mandatory} disabled={disabled} {...props} />
      {showErrorInfo(props)}
    </div>
  );
}

export default function FileField({ name, label, mandatory, disabled, ...props }) {
  return (
    <Field
      name={name}
      label={label}
      mandatory={mandatory}
      disabled={disabled}
      component={FileFieldInner}
      {...props}
    />
  );
}
