import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import _find from 'lodash/find';
import classnames from 'classnames';
import { connect } from 'react-redux';
import TextField from './fields/text';
import { localeSelector, messagesSelector } from '../../helpers/selectors';
import { safe } from '../../utils/utils';
import { injectIntl } from 'react-intl';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import _isEmpty from 'lodash/isEmpty';

class ChorusFieldsForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  componentDidMount() {
    this.passFormSubmit();
  }

  passFormSubmit() {
    const { passFormSubmit } = this.props;
    if (passFormSubmit) passFormSubmit(this.validateAndSubmit);
  }

  validateAndSubmit() {
    const { handleSubmit, dispatch } = this.props;
    dispatch(handleSubmit(() => ''));
  }

  getChorusFields() {
    const { initialValues, m } = this.props;
    if (safe(() => initialValues.administration)) {
      return (
        <div className="specificities">
          <div className="scope-confirm-recap-sub-title">
            {m['members_detail_enterprise_chorus_detail']}
          </div>

          <TextField
            label={m['members_detail_enterprise_chorus_chorusAlias']}
            name="chorusAlias"
          />
          <TextField
            label={m['members_detail_enterprise_chorus_chorusEngagement']}
            name="chorusEngagement"
          />
          <TextField
            label={m['members_detail_enterprise_chorus_chorusMarche']}
            name="chorusMarche"
          />
          <TextField
            label={m['members_detail_enterprise_chorus_chorusService']}
            name="chorusService"
          />
        </div>
      );
    }
  }
  render() {
    const { handleSubmit } = this.props;
    return (
      <form
        className={classnames('custom-fields-form', 'scope-section')}
        onSubmit={handleSubmit}
        noValidate
      >
        <div className="scope-section-title pb-xs-0 pt-dt-0" />
        <div className="scope-section-fields">{this.getChorusFields()}</div>
      </form>
    );
  }
}

ChorusFieldsForm.defaultProps = {
  initialValues: null
};

ChorusFieldsForm.propTypes = {
  passFormSubmit: PropTypes.func,
  initialValues: PropTypes.object,
  fields: PropTypes.array
};

const formComponent = reduxForm({
  form: 'chorusForm',
  fields: ['chorusAlias', 'chorusEngagement', 'chorusMarche', 'chorusService'],
  onSubmitFail: scrollToErrorInfo,
  enableReinitialize: true,
  destroyOnUnmount: false
})(ChorusFieldsForm);

function mapStateToProps(state, props) {
  return {
    locale: localeSelector(state),
    m: messagesSelector(state)
  };
}

const connectComponent = connect(mapStateToProps)(formComponent);

export default injectIntl(connectComponent);
