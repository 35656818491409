import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import SearchBookingsForm from './forms/SearchBookingsForm';

import { currentBrandSelector } from '../helpers/selectors';
import {
  formatDataForSearchBookingsApiCall,
  getObjectFromQuery,
  setMomentTimeForRequest
} from '../helpers';

import * as BookingActions from '../actions/booking';
import * as PopupActions from '../actions/popup';
import * as UserActions from '../actions/user';

import { gtmPush, gtmPushBasicSearchData, safe } from '../utils/utils';
import { destroy } from 'redux-form';

export class SearchBookings extends Component {
  constructor(props) {
    super(props);
    this.handleSearchBookings = this.handleSearchBookings.bind(this);
    this.onAgencySelect = this.onAgencySelect.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.closeBurgerMenu();
  }

  componentDidMount() {
    this.closeBurgerMenu();
    this.handleGtm();
  }

  handleGtm() {
    gtmPush(
      {
        event: '_virtualPageview',
        pageName: 'mobility|réserver'
      },
      _get(this.props, 'user.data.id')
    );
  }

  componentWillUnmount() {
    this.props.emptyVehiclesData();
    this.props.emptyAgenciesData();
    this.props.dispatch(destroy('searchBookings'));
  }

  closeBurgerMenu() {
    if (document.getElementById('menu-btn')) {
      document.getElementById('menu-btn').checked = false;
    }
  }

  onAgencySelect(agencyId) {
    const {
      brand: { contract }
    } = this.props;
    const prevSearch = _get(this.props, 'form.searchBookings.values');
    const companyId = _get(this.props, 'user.data.company.id');
    const search = formatDataForSearchBookingsApiCall(
      setMomentTimeForRequest(prevSearch),
      companyId,
      contract
    );

    const dataObj = Object.assign({}, search, {
      startParkingId: agencyId
    });
    this.props.setSelectedAgency(dataObj);
  }

  handleSearchBookings(data) {
    const { bookingId } = getObjectFromQuery();
    const {
      brand: { contract },
      search
    } = this.props;
    const searchFormValues = _get(this.props, 'form.searchBookings.values');

    if (!data) data = searchFormValues;

    const companyId = _get(this.props, 'user.data.company.id');
    let formattedData = formatDataForSearchBookingsApiCall(
      setMomentTimeForRequest(data),
      companyId,
      contract,
      bookingId
    );

    const userId = _get(this.props, 'user.data.id');

    search(formattedData).then((data) => {
      if (_get(data, 'type') === 'booking/SEARCH_SUCCESS') {
        gtmPushBasicSearchData(data, searchFormValues, userId);
      }
    });
  }

  render() {
    return (
      <div>
        <SearchBookingsForm
          onSubmit={this.handleSearchBookings}
          agencies={this.props.booking.agencies}
          selectedAgency={this.props.booking.selectedAgency}
          numberOfCars={safe(() => this.props.booking.vehiclesData.length)}
          agencySelectCallBack={this.onAgencySelect}
          mapCenter={this.props.booking.mapCenter}
          mapKeyboardState={this.props.booking.enableMapKeyboard}
          i18n={this.props.i18n}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state, brand: currentBrandSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...PopupActions,
      ...UserActions,
      dispatch
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBookings);
