import _reduce from 'lodash/reduce';
import _get from 'lodash/get';
import _hasIn from 'lodash/hasIn';
import _forEach from 'lodash/forEach';
import _sortBy from 'lodash/sortBy';

import config from '../constants/config';
import { addActionError, addErrorMsg } from '../utils/flashMessage/creator';
import { append, getMessage as t, getPrevAction } from '../utils/utils';
import { getErrorStatus } from '../helpers/formatters';
import { getGlobalState, messagesSelector } from '../helpers/selectors';

const defaultState = {
  searchBookingsFetching: false,
  enableMapKeyboard: true,
  selectedAgencyName: '',
  preBookingStatus: '',
  data: [],
  agencies: [],
  vehiclesData: null,
  selectedVehicle: null,
  activeBooking: null,
  parkingSchedule: null,
  myActiveBookings: null,
  myUpcomingBookings: null,
  myFinishedBookings: null,
  registerUpcomingBookings: null,
  cancelBookingId: null,
  editBookingData: null,
  editBookingFormData: null,
  editBookingPopupData: null,
  selectedAgency: null,
  currentSearchCompanyId: null,
  cancellingBooking: null,
  vehiclesLimit: config.bookingsLimit,
  mapCenter: {
    lat: 48.864716,
    lng: 2.349014
  }
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'booking/SEARCH': {
      state = {
        ...state,
        mapCenter: action.payload.searchLocation,
        searchBookingsFetching: true
      };
      break;
    }

    case 'booking/SAVE_PRE_BOOKING_STATUS': {
      state = { ...state, preBookingStatus: action.status };
      break;
    }

    case 'booking/SAVE_REGISTER_UPCOMING_BOOKINGS': {
      state = { ...state, registerUpcomingBookings: append(action.bookings) };
      break;
    }

    case 'booking/SEARCH_SUCCESS': {
      const results = _get(action.payload.data, 'results') || [];

      const agencies = _reduce(
        results,
        (agencies, booking) => {
          if (
            _get(booking, 'start.parking.id') &&
            !agencies.map((agency) => agency.id).includes(booking.start.parking.id)
          ) {
            const item = {
              ...booking.start.parking,
              vehiclesCount: 1
            };
            agencies.push(item);
          } else {
            let item = agencies.find(
              (element = {}) => element.id === _get(booking, 'start.parking.id')
            );
            if (item) item.vehiclesCount++;
          }
          return agencies;
        },
        []
      );

      if (results.length) {
        if (_hasIn(action.meta, 'previousAction.payload.request.data.start.siteId')) {
          const respData = _get(action.payload.data, 'results[0]');
          state = {
            ...state,
            mapCenter: {
              lat: _get(respData, 'vehicle.parking.coordinates.latitude'),
              lng: _get(respData, 'vehicle.parking.coordinates.longitude')
            }
          };
        }
      }

      state = {
        ...state,
        vehiclesLimit: config.bookingsLimit,
        agencies: agencies,
        vehiclesData: action?.payload?.data?.results,
        selectedAgency: null,
        searchBookingsFetching: false,
        currentSearchCompanyId: getPrevAction(action).payload?.request?.data?.companyId
      };
      break;
    }

    case 'booking/GET_PARKING_SCHEDULE_SUCCESS': {
      state = { ...state, parkingSchedule: action.payload.data };
      break;
    }

    case 'booking/GET_PARKING_SCHEDULE_FAIL': {
      addActionError(action);
      break;
    }

    case 'booking/SEARCH_FAIL': {
      if (getErrorStatus(action) === 403) {
        const m = messagesSelector(getGlobalState());
        addErrorMsg(t(m, 'error.search.vehicles.403'));
      } else {
        addActionError(action);
      }
      state = { ...state, searchBookingsFetching: false };
      break;
    }

    case 'booking/CANCEL_BOOKING': {
      state = { ...state, cancellingBooking: action.payload.request.data };
      break;
    }

    case 'booking/CANCEL_BOOKING_SUCCESS': {
      state = { ...state, cancellingBooking: null };
      break;
    }

    case 'booking/CANCEL_BOOKING_FAIL': {
      state = { ...state, cancellingBooking: null };
      addActionError(action);
      break;
    }

    case 'booking/SET_SELECTED_AGENCY': {
      state = { ...state, searchBookingsFetching: true };
      break;
    }

    case 'booking/SET_SELECTED_AGENCY_FAIL': {
      state = { ...state, searchBookingsFetching: false };
      addActionError(action);
      break;
    }

    case 'booking/SET_SELECTED_AGENCY_SUCCESS': {
      let firstAgency = null;
      let agency = null;

      _forEach(_get(action.payload.data, 'results'), (booking) => {
        if (_get(booking, 'start.parking.id')) {
          firstAgency = booking.start.parking.id;
          return false;
        }
      });
      agency = state.agencies.filter((agency) => agency.id === firstAgency)[0];
      state = {
        ...state,
        mapCenter: {
          lat: _get(agency, 'coordinates.latitude'),
          lng: _get(agency, 'coordinates.longitude')
        },
        selectedAgency: firstAgency,
        selectedAgencyName: agency.name,
        vehiclesData: action.payload.data.results,
        searchBookingsFetching: false
      };
      break;
    }
    case 'booking/LOAD_MORE_BOOKINGS': {
      state = { ...state, vehiclesLimit: state.vehiclesLimit + action.payload };
      break;
    }

    case 'booking/SORT_BOOKINGS': {
      switch (action.payload) {
        case 'price': {
          let newArr = _sortBy(
            state.vehiclesData,
            'carSharingInfo.cost.estimatedPriceForDuration'
          );
          state = { ...state, vehiclesData: newArr };
          break;
        }
        case 'alphabetically': {
          let newArr = _sortBy(state.vehiclesData, function (v) {
            return v.vehicle.model.toLowerCase();
          });
          state = { ...state, vehiclesData: newArr };
          break;
        }
        default: {
          break;
        }
      }
      break;
    }
    case 'booking/LOAD_BOOKINGS_POPUP_DATA': {
      state = {
        ...state,
        selectedVehicle: state.vehiclesData[action.payload]
      };
      break;
    }

    case 'booking/LOAD_BOOKING_CANCEL_ID': {
      state = { ...state, cancelBookingId: action.payload };
      break;
    }

    case 'booking/LOAD_EDIT_DATA': {
      state = { ...state, editBookingData: action.payload };
      break;
    }

    case 'booking/CLEAR_EDIT_DATA': {
      state = { ...state, editBookingData: null };
      break;
    }

    case 'booking/LOAD_EDIT_POPUP_DATA': {
      state = { ...state, editBookingPopupData: action.payload };
      break;
    }

    case 'booking/CLEAR_EDIT_POPUP_DATA': {
      state = { ...state, editBookingPopupData: null };
      break;
    }

    case 'booking/LOAD_EDIT_FORM_DATA': {
      state = { ...state, editBookingFormData: action.payload };
      break;
    }

    case 'booking/CLEAR_EDIT_FORM_DATA': {
      state = { ...state, editBookingFormData: null };
      break;
    }

    case 'booking/DISABLE_MAP_KEYBOARD': {
      state = { ...state, enableMapKeyboard: false };
      break;
    }
    case 'booking/ENABLE_MAP_KEYBOARD': {
      state = { ...state, enableMapKeyboard: true };
      break;
    }
    case 'booking/EMPTY_VEHICLE_DATA': {
      state = {
        ...state,
        selectedVehicle: null,
        parkingSchedule: null
      };
      break;
    }
    case 'booking/EMPTY_VEHICLES_DATA': {
      state = { ...state, vehiclesData: null, currentSearchCompanyId: null };
      break;
    }
    case 'booking/EMPTY_BOOKING_CANCEL_ID': {
      state = { ...state, cancelBookingId: null };
      break;
    }

    case 'booking/GET_BOOKINGS_BY_MEMBER_ID_SUCCESS': {
      let activeBookings = [],
        upcomingBookings = [],
        finishedBookings = [];

      action.payload.data.map((item) => {
        switch (item.status) {
          case 'IN_PROGRESS': {
            activeBookings.push(item);
            break;
          }
          case 'SCHEDULED': {
            upcomingBookings.push(item);
            break;
          }
          case 'COMPLETED':
          case 'CANCELED': {
            finishedBookings.push(item);
            break;
          }
          default: {
            break;
          }
        }

        return item;
      });

      state = {
        ...state,
        myActiveBookings: activeBookings,
        myUpcomingBookings: upcomingBookings,
        myFinishedBookings: finishedBookings
      };

      break;
    }

    case 'booking/GET_BOOKINGS_BY_MEMBER_ID_FAIL': {
      addActionError(action);
      break;
    }

    case 'booking/EMPTY_AGENCIES_DATA': {
      state = { ...state, agencies: [], selectedAgency: null };
      break;
    }
    case 'booking/SET_ACTIVE_BOOKING': {
      state = {
        ...state,
        activeBooking: action.payload
      };
      break;
    }
    default: {
      break;
    }
  }

  return state;
}
