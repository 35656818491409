import { Field } from 'redux-form';
import { showErrorInfo } from '../../forms/newFields';
import React from 'react';
import PhoneNumber from '../../partials/PhoneNumber';
import classnames from 'classnames';

export function PhoneNumberInner({ label, mandatory, disabled, meta, input, ...props }) {
  if (disabled) {
    let disabledValue = '';

    if (input.value) {
      const phonePrefix = input.value.phonePrefix || '';
      const phoneSuffix = input.value.phoneSuffix || '';
      disabledValue = phonePrefix + ' ' + phoneSuffix;
    }

    return (
      <div className="scope-field-container">
        <div className="scope-field-label">{label}</div>
        <div className={classnames('new-design-field', 'sub-class-disabled')}>
          {disabledValue}
        </div>
      </div>
    );
  } else {
    return (
      <div className="scope-field-container">
        <div className="scope-field-label">
          {label}
          {mandatory && ' *'}
        </div>
        <div className="phone-number-container">
          <PhoneNumber
            inputClass="new-design-field"
            onChange={input.onChange}
            onBlur={input.onBlur}
            value={input.value}
            {...props}
          />
          {showErrorInfo({ meta })}
        </div>
      </div>
    );
  }
}

export default function PhoneNumberField({ name, ...props }) {
  return <Field name={name} component={PhoneNumberInner} {...props} />;
}

/* <Props>
   name,
   label,
   mandatory,
   disabled
 */
