import React, { Component } from 'react';
import { browserHistory } from '../helpers/components';
import { Col } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import ResetPasswordForm from './forms/ResetPasswordForm';

import * as UserActions from '../actions/user';
import * as PopupActions from '../actions/popup';
import { safe } from '../utils/utils';

export class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handleResetPassword(data) {
    const { routeParams } = this.props;
    this.props.resetPassword(data, routeParams.token);
  }

  componentDidMount() {
    this.checkToken();
    this.componentPropsUpdated();
  }

  checkToken() {
    const { routeParams } = this.props;
    this.props.checkResetPasswordToken(routeParams.token);
  }

  componentDidUpdate() {
    this.componentPropsUpdated();
  }

  componentPropsUpdated() {
    this.openPasswordModal();
  }

  openPasswordModal() {
    if (safe(() => this.props.popup.type_ResetPassword_IsVisible)) {
      browserHistory.push({
        pathname: '/',
        query: { modal: 'reset-password-popup' }
      });
    }
  }

  render() {
    return (
      <div className="bt">
        <div className="container">
          <Col md={12} className="text-center">
            <h2>
              <FormattedMessage id="reset_password.title" />
            </h2>
          </Col>
          <ResetPasswordForm
            onSubmit={this.handleResetPassword}
            i18n={this.props.i18n}
            resetPasswordFetching={this.props.user.resetPasswordFetching}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...UserActions,
      ...PopupActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
