import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  messagesSelector,
  profileLanguageInitialValuesSelector,
  userDataSelector,
  userIdSelector,
  currentThemeSelector
} from '../../helpers/selectors';

import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import headerIconSrc from '../../assets/images/globus-circle.svg';
import IndexLink from '../partials/IndexLink'
import { ReactSVG } from 'react-svg';
import arrowLeftSrc from '../../assets/images/arrow-left.svg';
import { getMessage as t } from '../../utils/utils';
import classnames from 'classnames';
import { Field, reduxForm } from 'redux-form';
import { ProfileSelectField } from '../forms/newFields';
import _mapValues from 'lodash/mapValues';
import { allLanguages } from '../../constants/all-languages';
import { USER_FORM_NAMES } from '../../constants/form';
import { displayProfileUpdateSuccess } from '../../helpers/components';
import { cleanUserDataForApi } from '../../helpers';

const profileLanguageOptions = _mapValues(allLanguages, (language) => language.text);

class ProfileLanguage extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions(props);
  }

  bindFunctions(props) {
    this.handleSubmit = props.handleSubmit((data) => this.submitCallback(data));
  }

  submitCallback(formValues = {}) {
    const { language } = formValues;

    const {
      userDataUpdating,
      userData,
      userId,
      m,
      updateInformations
    } = this.props;

    const newData = {
      ...userData,
      locale: language || 'fr'
    };

    if (!userDataUpdating) {
      updateInformations(userId, cleanUserDataForApi(newData)).then((data) => {
        if (data.type === 'user/UPDATE_INFO_SUCCESS') {
          displayProfileUpdateSuccess(m, 'profile.section.language');
        }
      });
    }
  }

  render() {
    const { m, userDataUpdating, theme } = this.props;
    const { profilLanguage } = theme.icons || {};

    return (
      <form className="profile-language-page" onSubmit={this.handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={profilLanguage ? profilLanguage : headerIconSrc}
            alt="key icon"
          />
          <div className="scope-content-header-title-container">
            <IndexLink to="/profile" className="scope-content-header-button-return">
              <ReactSVG className="react-svg" src={arrowLeftSrc} />
            </IndexLink>
            <div className="scope-content-header-title">
              {t(m, 'profile.section.language')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-title">
              {t(m, 'profile.title.language.select')}
            </div>
            <div className="scope-section-fields">
              <div className="scope-field-container">
                <div className="scope-field-label">{t(m, 'label.selected.language')}</div>
                <Field
                  name="language"
                  options={profileLanguageOptions}
                  component={ProfileSelectField}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          <button
            type="submit"
            className={classnames('scope-submit-button primary-btn', {
              'sub-class-disabled': userDataUpdating
            })}
          >
            {t(m, 'generic.save')}
          </button>
        </div>
      </form>
    );
  }
}

const reduxComponent = reduxForm({
  form: USER_FORM_NAMES.PROFILE_LANGUAGE,
  enableReinitialize: true,
  destroyOnUnmount: false,
  fields: ['language']
})(ProfileLanguage);

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;

  return {
    m: messagesSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    initialValues: profileLanguageInitialValuesSelector(state),
    userDataUpdating,
    theme: currentThemeSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxComponent);
