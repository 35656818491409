import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { checkSearchExternalErrors, safe } from '../../../utils/utils';
import _isString from 'lodash/isString';
import MapboxComplete from '../MapboxComplete';
import { cleanDeep } from '../../../utils/cleanDeep';
import { connect } from 'react-redux';
import { localeSelector } from '../../../helpers/selectors';

class LocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  setCallbacks() {
    this.handleSuggestSelected = (suggestion = {}) => {
      this.formatAddress(suggestion);
      this.searchPageNextFieldSelect();
    };

    this.onChange = (value) => {
      const { onChange, onEmpty, isValue, isStreet } = this.props;
      if (value) (isStreet || isValue) && onChange({ value });
      else onEmpty && onEmpty({});
    };

    this.setValueSetter = (setter) => {
      this.valueSetter = setter;
    };
  }

  searchPageNextFieldSelect() {
    if (this.props.openDatePickerOnSelect) {
      let errors = checkSearchExternalErrors(this.props.externalErrors);

      let i = document.getElementById('mobileSearch')
        ? document.querySelector('form.mobileOnly')
          ? 2
          : 0
        : 0;

      let selector = '';

      if (
        errors.hasOwnProperty('sd') &&
        (errors.sd === false || (_isString(errors.sd) && errors.sd !== ''))
      ) {
        selector = 'div.date-picker';
      } else if (errors.st === null) {
        selector = 'div.time-picker';
      } else if (
        errors.hasOwnProperty('ed') &&
        (errors.ed === false || (_isString(errors.ed) && errors.ed !== ''))
      ) {
        selector = 'div.date-picker';
        i += 1;
      } else if (errors.et === null) {
        selector = 'div.time-picker';
        i += 1;
      }

      if (selector !== '') {
        document.querySelectorAll(`${selector}`)[i].querySelector('input').click();
      }
    }
  }

  setAddressContext(context = []) {
    const ret = { streetName: '' };

    const getPredicate = (id) => (el) => safe(() => el.id.includes(id));
    const postcodeObj = context.find(getPredicate('postcode'));
    const placeObj = context.find(getPredicate('place'));
    const countryObj = context.find(getPredicate('country'));

    if (postcodeObj) ret.postalCode = postcodeObj.text;
    if (placeObj) ret.city = placeObj.text;

    if (countryObj) {
      ret.countryISO = safe(() => countryObj.short_code.toUpperCase());
      ret.country = countryObj.text;
    }

    return ret;
  }

  getAddressProps(suggestion) {
    const { address, text, properties, place_type } = suggestion;

    const ret = this.setAddressContext(suggestion.context);
    const street = safe(() => properties.address);
    const isAddress = place_type.includes('address');
    const isPoi = place_type.includes('poi');

    if (place_type.includes('country')) {
      ret.country = text;
    }

    if (place_type.includes('place')) {
      ret.city = text;
    }

    if (isAddress && address) {
      ret.streetName = address;
    }

    if (isAddress || isPoi) {
      if (text && ret.streetName) ret.streetName += ' ';
      if (text) ret.streetName += text;
    }

    if (place_type.includes('poi')) {
      if (street && ret.streetName) ret.streetName += ', ';
      if (street) ret.streetName += street;
    }

    return cleanDeep(ret);
  }

  formatAddress(suggestion = {}) {
    const { center } = suggestion;
    let ret;

    if (center) {
      ret = {
        formattedAddress: suggestion.place_name,
        coordinates: { latitude: center[1], longitude: center[0] },
        ...this.getAddressProps(suggestion)
      };
    } else {
      ret = {
        siteId: suggestion.id,
        address: suggestion.address,
        formattedAddress: suggestion.name
      };
    }

    if (this.props.isStreet && ret.streetName) {
      this.setValue(ret.streetName);
    }

    this.props.onLocationSelected(ret);
  }

  getInputValue() {
    const { initialValue, isStreet, isValue } = this.props;
    if (typeof initialValue === 'string') return initialValue;
    let { streetNumber, streetName, value, formattedAddress } = initialValue || {};
    streetNumber = streetNumber ? streetNumber + ' ' : '';
    const street = streetNumber + (streetName || '') || value || '';
    return isStreet
      ? street
      : isValue
      ? value || formattedAddress || streetName
      : formattedAddress || '';
  }

  setValue(value) {
    if (this.valueSetter) this.valueSetter(value);
  }

  getLanguage() {
    return this.props.language || this.props.locale;
  }

  getMapbox() {
    return (
      <MapboxComplete
        fixtures={this.props.fixtures}
        searchType={this.props.searchType}
        placeholder={this.props.placeholder}
        showLocationButton={this.props.showLocationButton}
        searchHistoryEnabled={this.props.searchHistoryEnabled}
        language={this.getLanguage()}
        inputValue={this.getInputValue()}
        onInputChange={this.onChange}
        onSelect={this.handleSuggestSelected}
        getValueSetter={this.setValueSetter}
      />
    );
  }

  getDisabledDiv() {
    return <span className="sub-class-disabled">{this.getInputValue()}</span>;
  }

  render() {
    return (
      <div
        className={classnames('location-input', {
          'has-input-pin': this.props.getLocation
        })}
      >
        {this.props.disabled ? this.getDisabledDiv() : this.getMapbox()}
      </div>
    );
  }
}

LocationInput.propTypes = {
  disabled: PropTypes.bool,
  openDatePickerOnSelect: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  onLocationSelected: PropTypes.func.isRequired,
  externalErrors: PropTypes.object,
  /** https://docs.mapbox.com/api/search/geocoding/#data-types */
  searchType: PropTypes.array,
  /** https://docs.mapbox.com/api/search/geocoding/#forward-geocoding */
  language: PropTypes.string,
  /** Show current location suggestion */
  showLocationButton: PropTypes.bool,
  /** Enable search history **/
  searchHistoryEnabled: PropTypes.bool
};

LocationInput.defaultProps = {
  searchType: ['address', 'poi', 'place'],
  openDatePickerOnSelect: false,
  showLocationButton: true,
  isStreet: false,
  isValue: false
};

export default connect((state) => {
  return { locale: localeSelector(state) };
})(LocationInput);
