import _map from 'lodash/map';
import { safe, isEmpty } from '../../utils/utils';
import { cleanFilesForApi } from '../../helpers';

export function createCustomFieldsArray(constValues = {}, memberValues = []) {
  const formValues = { ...constValues };

  memberValues.forEach((item) => {
    const fieldId = safe(() => item.companyCustomField.id);
    const fieldValue = safe(() => item.value);

    if (fieldId && !isEmpty(fieldValue) && formValues[fieldId] === undefined) {
      formValues[fieldId] = fieldValue;
    }
  });

  return _map(formValues, (v, k) => {
    const { fileId } = v || {};

    return {
      companyCustomFieldId: k,
      value: fileId || v
    };
  });
}

export function formatCustomFieldsForApiCall(userData = {}, formValues = {}) {
  const requiredData = {
    ...userData,
    memberCustomValues: createCustomFieldsArray(formValues, userData.memberCustomValues)
  };

  return cleanFilesForApi(requiredData);
}
