const sep = '/';
const amazonWest = "https://s3-eu-west-1.amazonaws.com";
const westRenaultDocuments = amazonWest + sep + 'rcimob-assets/renault/documents';
const rciAmazon = "https://rcimob-assets.s3-eu-west-1.amazonaws.com";

export const links = {
  appStoreLink: "https://itunes.apple.com/fr/app/renault-mobility/id1189566096?mt=8",
  playStoreLink: "https://play.google.com/store/apps/details?id=com.rcimobility.renaultmobility.b2c",
  regularTarifsUrl: westRenaultDocuments + sep + "Renault+Mobility+-+Conditions+Tarifaires+Particuliers.pdf",
  proTarifsUrl: westRenaultDocuments + sep + "Renault+Mobility+-+Conditions+Tarifaires+Professionnels.pdf",
  carnetElectric: westRenaultDocuments + sep + "Renault+Mobility+-+Carnet+de+bord+-+Electric.pdf",
  carnetThermal: westRenaultDocuments + sep + "Renault+Mobility+-+Carnet+de+bord+-+Thermal.pdf",
  defaultFavicon: rciAmazon + '/web-app/images/default-favicon.ico'
};
