import React, { Component } from 'react';
import Glideslider from '../Slider/Glideslider';
import { FormattedMessage } from 'react-intl';
import { testProp } from '../../utils/utils';

const max = 9;
const min = 1;
const step = 1;

class PersonSlider extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = {
      sum: this.props.value || 1,
      sliderSum: this.props.value || 1,
      step: step
    };
  }

  setCallbacks() {
    this.plusOne = () => {
      this.setState((prevState) => {
        if (prevState.sum < max) {
          const newVal = prevState.sum + 1;
          this.props.onChange(newVal);
          return {
            sum: newVal,
            sliderSum: newVal
          };
        } else {
          return null;
        }
      });
    };

    this.minusOne = () => {
      this.setState((prevState) => {
        if (prevState.sum > min) {
          const newVal = prevState.sum - 1;
          this.props.onChange(newVal);
          return {
            sum: newVal,
            sliderSum: newVal
          };
        } else {
          return null;
        }
      });
    };
  }

  componentDidMount() {
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.resetValue(pp);
  }

  resetValue(pp) {
    const { value } = this.props;

    if (testProp.call(this, pp, { value }) && value === 1) {
      this.setState({ sum: 1, sliderSum: 1 });
    }
  }

  onSlideEnd(value) {
    const sum = parseInt(value, 10);
    this.props.onChange(sum);
    this.setState({ sum, sliderSum: sum });
  }

  onSliderChange(value) {
    const sum = parseInt(value, 10);
    this.props.onChange(sum);
    this.setState({
      sum,
      sliderSum: sum
    });
  }

  onInputChange(event) {
    if (event) {
      const sum = parseInt(event, 10);
      this.setState({
        sum
      });

      if (sum >= min && sum <= max) {
        this.setState({ sliderSum: sum });
      }
    } else {
      this.setState({ sum: 1 });
    }
  }

  render() {
    return (
      <div>
        <div className="places">
          <div className="title">
            <FormattedMessage id="car.filter.label_nb_persons" />
          </div>
          <div className="wrapper">
            <div className="slider">
              <Glideslider
                min={min}
                max={max}
                color={this.props.color}
                step={this.state.step}
                defaultValue={this.state.sliderSum}
                onChange={(value) => this.onSliderChange(value)}
                onSlideEnd={(value) => this.onSlideEnd(value)}
              />
            </div>
            <div className="field">
              <input
                name="places"
                type="text"
                value={this.state.sum}
                id="figure-places"
                disabled
                min={min}
                onChange={(value) => this.onInputChange(value)}
                max={max}
                step={step}
              />
              <div className="actions">
                <button onClick={this.plusOne}>
                  <span />
                </button>
                <button onClick={this.minusOne}>
                  <span />
                </button>
              </div>
              <FormattedMessage id="car.filter_persons" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PersonSlider;
