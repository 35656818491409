import { getJsonData } from '../helpers/api';
import config from '../constants/config';
import { messagesSelector, userContractSelector } from '../helpers/selectors';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { isLocalEnv } from '../utils/utils';
import defaultBrandData from '../mocks/defaultBrand.json';

function showFetchConfigError(state) {
  const messages = messagesSelector(state);
  const message = messages['error.get.site.config'];

  if (message) addErrorMsg(message);
  else addErrorMsg('Error while loading site config');
}

function handleLocalBrand(dispatch, userContract) {
  if (isLocalEnv()) {
    dispatch({
      type: 'brand/GET_ONE_SUCCESS',
      rawBrand: defaultBrandData,
      userContract
    });

    dispatch(setConfigFetchedState(true));
    return true;
  }
}

// noinspection JSUnusedGlobalSymbols
export function getAwsBrand() {
  return (dispatch, getState) => {
    const state = getState();
    const { awsBaseUrl, awsBrandsMapFileName } = config;
    const userContract = userContractSelector(state);

    if (handleLocalBrand(dispatch, userContract)) return;

    const handleError = () => {
      showFetchConfigError(state);
    };

    getJsonData(awsBaseUrl + '/' + awsBrandsMapFileName).then((map) => {
      const { [window.location.hostname]: brandFilePathPart } = map || {};

      if (brandFilePathPart) {
        getJsonData(awsBaseUrl + '/' + brandFilePathPart).then((rawBrand) => {
          const { theme } = rawBrand || {};

          if (theme) {
            dispatch({ type: 'brand/GET_ONE_SUCCESS', rawBrand, userContract });
            dispatch(setConfigFetchedState(true));
          } else handleError();
        }, handleError);
      } else handleError();
    }, handleError);
  };
}

export function setConfigFetchedState(payload) {
  return {
    type: 'brand/SET_CONFIG_FETCHED_STATE',
    payload
  };
}

// noinspection JSUnusedGlobalSymbols
export function setAppLoaderState(payload) {
  return {
    type: 'brand/SET_APP_LOADER_STATE',
    payload
  };
}
