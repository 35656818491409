import React from 'react';
import { FormattedMessage } from 'react-intl';

import config from '../../constants/config';
import ProfilePopup from './ProfilePopup';

const CreditCardPopup = (props) => {
  return (
    <ProfilePopup
      bodyMsg={
        <FormattedMessage
          id="label.creditCard_message"
          values={{
            newline: <br />,
            amountToTake: config.amountToTake
          }}
        />
      }
      {...props}
    />
  );
};

export default CreditCardPopup;
