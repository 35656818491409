import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import { renderPlaceholderTextField } from './fields';
import PropTypes from 'prop-types';

import { checkEmail } from '../../helpers';
import Popup from '../popup/Popup';

const validate = (values, props) => {
  const errors = {};
  const { messages } = props.i18n;

  const emailError = checkEmail(values.login, messages);
  if (emailError) errors.login = emailError;

  return errors;
};

class SendActivationEmailForm extends Component {
  render() {
    const { messages } = this.props.i18n;
    const { handleSubmit, fetching } = this.props;

    return (
      <div>
        <form className="form-content" noValidate onSubmit={handleSubmit}>
          <p className="single">
            <FormattedMessage id="label.activation.link.expired" />
          </p>
          <div className="mt-15">
            <Field
              name="login"
              component={renderPlaceholderTextField}
              placeholder={messages['generic.email']}
              type="email"
            />
          </div>
          <button type="submit" className="btn btn-secondary mt-15" disabled={fetching}>
            <FormattedMessage id="label.activation.link.send" />
          </button>
        </form>
      </div>
    );
  }
}

Popup.propTypes = {
  i18n: PropTypes.object,
  onSubmit: PropTypes.func,
  fetching: PropTypes.bool
};

export default reduxForm({
  form: 'sendActivationEmailForm',
  validate,
  fields: ['login']
})(SendActivationEmailForm);
