import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import b2c from './../assets/images/b2c.svg';
import b2b from './../assets/images/b2b.svg';
import rv from './../assets/images/rv.svg';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../actions/popup';
import * as UserActions from '../actions/user';
import { connect } from 'react-redux';

class BusinessTabs extends Component {
  constructor(props) {
    super(props);
    this.setVrView = this.setVrView.bind(this);
    this.setB2bView = this.setB2bView.bind(this);
  }

  setVrView() {
    this.props.displayRV();
    this.props.enableDirectSubscribe();
    this.props.popup_Login_SetVisibility(true);
    this.props.popupStep_Subscribe_SetVisibility(true);
  }

  setB2bView() {
    this.props.displayB2B();
    this.props.enableDirectSubscribe();
    this.props.popup_Login_SetVisibility(true);
    this.props.popupStep_Subscribe_SetVisibility(true);
  }

  render() {
    return (
      <div className="business-tabs">
        <div>
          <ReactSVG wrapper="span" className="react-svg" src={b2c} />
          <FormattedMessage id="service.usage.private" />
        </div>
        <div onClick={this.setVrView}>
          <ReactSVG wrapper="span" className="react-svg" src={rv} />
          <FormattedMessage id="service.usage.vr" />
        </div>
        <div onClick={this.setB2bView}>
          <ReactSVG wrapper="span" className="react-svg" src={b2b} />
          <FormattedMessage id="service.usage.corporate" />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions, ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BusinessTabs);
