import PropTypes from 'prop-types';
import React, { Component } from 'react';
import _get from 'lodash/get';
import _isNumber from 'lodash/isNumber';
import Measure from 'react-measure';

export default class PositionMarker extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = {
      visibility: 'hidden',
      marginLeft: 0,
      marginTop: 0
    };
  }

  setCallbacks() {
    this.handleResize = (contentRect) => {
      const { width, height } = _get(contentRect, 'scroll', { width: 0, height: 0 });
      let { h_align, v_align, shift_h, shift_v } = this.props;
      const half_w = width / 2;
      const half_h = height / 2;

      if (h_align === 'left') this.marginLeft = -width;
      else if (h_align === 'right') this.marginLeft = 0;
      else this.marginLeft = half_w - width;

      if (v_align === 'top') this.marginTop = -height;
      else if (v_align === 'bottom') this.marginTop = 0;
      else this.marginTop = half_h - height;

      if (_isNumber(shift_h)) this.marginLeft += shift_h;
      if (_isNumber(shift_v)) this.marginTop += shift_v;

      if (height !== 0 && width !== 0) {
        this.props.getSize({ width, height });

        this.setState({
          marginLeft: this.marginLeft,
          marginTop: this.marginTop,
          visibility: 'visible'
        });
      }
    };

    this.mesuredMarker = ({ measureRef }) => {
      return (
        <div
          style={{
            display: 'table',
            marginLeft: this.state.marginLeft,
            marginTop: this.state.marginTop,
            visibility: this.state.visibility,
            ...this.props.outterDivStyle
          }}
          ref={measureRef}
        >
          <div
            style={{
              verticalAlign: 'middle',
              display: 'inline-flex',
              ...this.props.innerDivStyle
            }}
          >
            {this.props.children}
          </div>
        </div>
      );
    };
  }

  render() {
    return (
      <Measure scroll onResize={this.handleResize}>
        {this.mesuredMarker}
      </Measure>
    );
  }
}

PositionMarker.defaultProps = {
  h_align: 'center' || 'left' || 'right',
  v_align: 'center' || 'top' || 'bottom',
  shift_v: 0, // shift vertically
  shift_h: 0, // shift horizontally
  getSize: (size) => size, // callback
  outterDivStyle: {},
  innerDivStyle: {}
};
PositionMarker.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number
};
