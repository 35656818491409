// language=CSS

export default `
.atcb-button-wrapper {
  padding: 0;
}

.atcb-button {
  width: 100%;
  min-width: 100%;
  margin: 0;
  border-radius: 0;
  max-height: 40px;
  font-weight: normal;
  background-color: white;
  box-shadow: none;
  color: #333;
}

.atcb-button:not([disabled]):focus,
.atcb-button:not([disabled]):hover {
  color: #333;
  background-color: white;
  box-shadow: rgba(0 0 0 / 20%) 2px 5px 18px -1px,rgba(0 0 0 / 15%) 2px 2px 10px -3px
}

.atcb-initialized {
  width: 100% !important;
}

.atcb-checkmark {
  display: none !important;
}
`;
