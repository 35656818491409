import React from 'react';

export default function Member({ color, ...props } = { color: '#000' }) {
  return (
    <svg
      width="19px"
      height="19px"
      viewBox="0 0 19 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="member" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Desktop-Artboard-14"
          transform="translate(-756.000000, -517.000000)"
          fillRule="nonzero"
          fill={color}
        >
          <g id="Group-6" transform="translate(705.000000, 242.000000)">
            <g id="Group-2" transform="translate(41.000000, 262.000000)">
              <g id="noun_profile_1041806" transform="translate(10.000000, 13.000000)">
                <path
                  d="M9.36000121,0 C4.20000054,0 0,4.20000054 0,9.36000121 C0,14.5200019 4.20000054,18.7200024 9.36000121,18.7200024 C14.5200019,18.7200024 18.7200024,14.5200019 18.7200024,9.36000121 C18.7200024,4.20000054 14.5200019,0 9.36000121,0 Z M4.84800063,16.4400021 C4.92000064,13.9440018 6.9840009,11.7840015 9.36000121,11.7840015 C11.7360015,11.7840015 13.8000018,13.9440018 13.8720018,16.4400021 C12.5760016,17.2800022 11.0160014,17.7600023 9.36000121,17.7600023 C7.70400099,17.7600023 6.14400079,17.2800022 4.84800063,16.4400021 Z M7.24800094,8.18400106 C7.24800094,7.03200091 8.18400106,6.07200078 9.36000121,6.07200078 C10.5360014,6.07200078 11.4720015,7.00800091 11.4720015,8.18400106 C11.4720015,9.36000121 10.5360014,10.2960013 9.36000121,10.2960013 C8.18400106,10.2960013 7.24800094,9.33600121 7.24800094,8.18400106 Z M14.7840019,15.768002 C14.4720019,13.4400017 12.7680016,11.5200015 10.6080014,10.9680014 C11.6640015,10.4880014 12.4080016,9.40800121 12.4080016,8.18400106 C12.4080016,6.50400084 11.0400014,5.11200066 9.33600121,5.11200066 C7.63200099,5.11200066 6.26400081,6.48000084 6.26400081,8.18400106 C6.26400081,9.43200122 7.00800091,10.4880014 8.06400104,10.9680014 C5.90400076,11.5200015 4.22400055,13.4400017 3.8880005,15.768002 C2.11200027,14.2320018 0.960000124,11.9280015 0.960000124,9.36000121 C0.960000124,4.72800061 4.72800061,0.960000124 9.36000121,0.960000124 C13.9920018,0.960000124 17.7600023,4.72800061 17.7600023,9.36000121 C17.7600023,11.9280015 16.6080021,14.2320018 14.7840019,15.768002 Z"
                  id="Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
