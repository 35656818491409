import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

// images
import appStoreImg from '../../assets/images/app-store.png';
import googlePlayImg from '../../assets/images/google-play.png';
import { currentBrandSelector } from '../../helpers/selectors';
import { connect } from 'react-redux';

export class MobileBanner extends Component {
  render() {
    const { currentBrand, productName } = this.props;
    const { playStoreLink, appStoreLink, mobileBannerImage } = currentBrand || {};

    return (
      <div className="background-bg-dark" style={{ minHeight: '2px' }}>
        {(playStoreLink || appStoreLink) && (
          <div className="mobile-banner">
            {mobileBannerImage && (
              <div className="phone-image-container">
                <img alt="phone" className="phone-image" src={mobileBannerImage} />
              </div>
            )}
            <div className="download-app">
              <p className="title pt-15 pb-15 f-32 f-xs-26">
                <FormattedMessage id="mobile_banner.text" values={{ productName }} />
              </p>
              {appStoreLink && (
                <a href={appStoreLink}>
                  <img src={appStoreImg} alt="App Store" className="mobile-store-img" />
                </a>
              )}
              {playStoreLink && (
                <a href={playStoreLink}>
                  <img
                    src={googlePlayImg}
                    alt="Google Play"
                    className="mobile-store-img ml-10"
                  />
                </a>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentBrand: currentBrandSelector(state) };
};

export default connect(mapStateToProps)(MobileBanner);
