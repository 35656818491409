import React, { Component } from 'react';
import pageIconSrc from '../../../src/assets/images/action-validated.svg';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { messagesSelector } from '../../helpers/selectors';
import { getMessage as t } from '../../utils/utils';

class RegisterSuccess extends Component {
  insertFirstName() {
    const { firstName, m } = this.props;
    return firstName || t(m, 'generic.user');
  }

  insertEmail() {
    const { email, m } = this.props;
    if (email) return <strong>{email}</strong>;
    else return t(m, 'part.your.email');
  }

  // Hide success message for now, because the message tells user to check his email
  // But the email of the user is already validated
  // TODO: change the message or success message or keep this removed
  showSuccessInfo() {
    const { isRegisterIncompleteUser, isProfileIncompleteUser, isCreationPage } =
      this.props;
    return false; // isCreationPage || (!isRegisterIncompleteUser && !isProfileIncompleteUser);
  }

  render() {
    const {
      m,
      onSubmit,
      isRegisterIncompleteUser,
      isProfileIncompleteUser,
      isCreationPage
    } = this.props;

    let titleKey = 'register.title.account.created';

    if (!isCreationPage) {
      if (isRegisterIncompleteUser) titleKey = 'register.title.account.updated';
      if (isProfileIncompleteUser) titleKey = 'register.title.account.linked';
    }

    return (
      <div className="register-success-page">
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={pageIconSrc}
            alt="page icon"
          />
        </div>
        <div className="scope-content-body">
          <div className="scope-field-container">
            <div className="scope-info-title">
              <FormattedMessage
                id={titleKey}
                values={{ firstName: this.insertFirstName() }}
              />
            </div>
            {this.showSuccessInfo() && (
              <div className="scope-info-text">
                <FormattedMessage
                  id="register.success.info"
                  values={{ email: this.insertEmail() }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="scope-content-footer">
          <button className="scope-submit-button" onClick={onSubmit}>
            {t(m, 'generic.ok')}
          </button>
        </div>
      </div>
    );
  }
}

RegisterSuccess.propTypes = {
  onSubmit: PropTypes.func,
  email: PropTypes.string,
  firstName: PropTypes.string,
  isCreationPage: PropTypes.bool,
  isProfileIncompleteUser: PropTypes.bool,
  isRegisterIncompleteUser: PropTypes.bool
};

function mapStateToProps(state) {
  return {
    m: messagesSelector(state)
  };
}

export default connect(mapStateToProps)(RegisterSuccess);
