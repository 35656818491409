import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as BookingActions from './../../actions/booking';
import * as PopupActions from './../../actions/popup';
import {
  vehicleTypeOptions,
  transmissionOptions,
  energyOptions,
  otherFiltersOptions
} from '../../constants/options';
import { ONE_WAY } from '../../constants/generic';
import { USAGE_TYPE_BUSINESS } from '../../constants/backend';
import {
  renderVehicleType,
  renderCheckboxGroup,
  renderCheckboxOtherGroup,
  renderPlaces
} from './fields';
import _get from 'lodash/get';
import _isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { DropdownButton } from 'react-bootstrap';
import { Field, change } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import TripType from './TripType';
import Popup from '../popup/Popup';
import { userDataSelector, isPrimaryColorDarkSelector } from '../../helpers/selectors';
import {
  formatDataForSearchBookingsApiCall,
  setMomentTimeForRequest,
  getUsageFromContract,
  getAWayFromContract
} from '../../helpers';
import {
  gtmPushBasicSearchData,
  setQueryValues,
  trySet,
  safe,
  isObjEmpty
} from '../../utils/utils';

function getSizeProps() {
  return {
    isMobile: window.innerWidth < 768
  };
}

class FiltersBookings extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = getSizeProps();
  }

  componentDidMount() {
    this.addResizeListener();
    this.setWindowSize();
  }

  componentWillUnmount() {
    this.removeResizeListener();
  }

  setCallbacks() {
    this.setWindowSize = () => {
      this.setState(getSizeProps());
    };

    this.showMobileFilters = () => {
      this.props.popup_FiltersBookings_SetVisibility(true);
    };

    this.closeMobileFilters = () => {
      this.props.popup_FiltersBookings_SetVisibility(false);
    };

    this.typeSubmit = (data) => {
      const {
        searchBookingsForm,
        dispatch,
        search,
        user,
        brand: {
          branded: { contract }
        }
      } = this.props;
      const { values: searchFormValues } = searchBookingsForm || {};
      const oneWay = data.tripType === ONE_WAY;

      if (oneWay) {
        dispatch(change('searchBookings', 'endDate', null, true));
        dispatch(change('searchBookings', 'endTime', null, true));
      }

      if (this.state.isMobile) {
        const companyId = _get(user, 'company.id');
        const userId = _get(user, 'id');

        if (oneWay) {
          searchFormValues.endDate = null;
          searchFormValues.endTime = null;
        }

        const formattedData = formatDataForSearchBookingsApiCall(
          searchFormValues,
          companyId,
          contract
        );

        trySet(formattedData, 'usageTypes', [_get(data, 'usageTypes')]);

        dispatch(change('searchBookings', 'usageTypes', data.usageTypes, true));

        if (_isEmpty(searchBookingsForm.syncErrors)) {
          search(formattedData).then((data) => {
            if (data.type === 'booking/SEARCH_SUCCESS') {
              gtmPushBasicSearchData(data, searchFormValues, userId);
            }
          });
        }
      } else {
        if (searchFormValues) {
          if (data.usageTypes && data.usageTypes !== searchFormValues.usageTypes) {
            dispatch(change('searchBookings', 'usageTypes', data.usageTypes, true));
          }
          if (data.tripType && data.tripType !== searchFormValues.tripType) {
            dispatch(change('bookingType', 'tripType', data.tripType, true));
          }
        }
      }
    };

    this.applyFilters = () => {
      const { searchBookingsForm } = this.props;
      const companyId = _get(this.props, 'user.data.company.id');
      const formValues = searchBookingsForm.values;

      const formattedData = formatDataForSearchBookingsApiCall(
        setMomentTimeForRequest(formValues),
        companyId,
        this.props.brand.branded.contract
      );

      setQueryValues(formValues);
      this.props.search(formattedData);
      this.closeMobileFilters();
    };

    this.resetFilters = () => {
      this.props.dispatch(change('searchBookings', 'vehicleType', null));
      this.props.dispatch(change('searchBookings', 'motorisation', null));
      this.props.dispatch(change('searchBookings', 'transmission', null));
      this.props.dispatch(change('searchBookings', 'accessories', null));
      this.props.dispatch(change('searchBookings', 'places', 1, true));
    };
  }

  getSelectedFilters() {
    const { values } = this.props;
    let selectedFilters = [];

    if (values && values.motorisation) {
      selectedFilters = selectedFilters.concat(...values.motorisation);
    }

    if (values && values.accessories) {
      selectedFilters = selectedFilters.concat(...values.accessories);
    }

    if (values && values.transmission) {
      selectedFilters = selectedFilters.concat(...values.transmission);
    }

    selectedFilters = [
      ...selectedFilters,
      values && values.places && values.places > 0 ? values.places + ' places' : null
    ];

    return selectedFilters;
  }

  addResizeListener() {
    window.addEventListener('resize', this.setWindowSize, { capture: true });
  }

  removeResizeListener() {
    window.removeEventListener('resize', this.setWindowSize, { capture: true });
  }

  renderField({ name, keyLabel, component, options, className = '' }) {
    const label = <FormattedMessage id={keyLabel} />;

    return (
      <Field
        title={label}
        name={name}
        component={component}
        options={options}
        className={className}
      />
    );
  }

  renderTripTypeFilter() {
    const {
      i18n: { messages },
      brand: {
        branded: { contract }
      },
      user: { id: userId },
      searchBookingsForm
    } = this.props;

    const hasBothTrip =
      safe(() => contract.interfaceConfig.oneWayDisplay) &&
      safe(() => contract.interfaceConfig.returnTripDisplay);

    const hasBothUsage =
      safe(() => contract.businessCarSharing) && safe(() => contract.privateCarSharing);

    if (hasBothTrip || hasBothUsage) {
      const tripType = _get(
        this.props,
        'form.bookingType.values.tripType',
        getAWayFromContract(contract)
      );

      const usageType = getUsageFromContract(contract);

      const initialValues = {
        usageTypes: _get(searchBookingsForm, 'values.usageTypes', usageType),
        tripType
      };

      const titleKey =
        tripType === ONE_WAY ? 'booking.type.one_way' : 'booking.type.return_trip';

      const titleUsageKey =
        _get(searchBookingsForm, 'values.usageTypes', usageType) === USAGE_TYPE_BUSINESS
          ? 'booking.usage.business'
          : 'booking.usage.private';

      const title = (
        <span>
          {hasBothTrip && messages[titleKey]}
          {hasBothTrip && hasBothUsage && ' - '}
          {hasBothUsage && messages[titleUsageKey]}
        </span>
      );

      return (
        <DropdownButton
          title={title}
          id={`tripType`}
          className="filters-btn border-primary-active"
        >
          <div className="trip-type">
            <div className="title sep">
              <FormattedMessage id="booking.type.label" />
            </div>
            <TripType
              messages={messages}
              onSubmit={this.typeSubmit}
              user={userId}
              initialValues={initialValues}
              contract={contract}
            />
          </div>
        </DropdownButton>
      );
    }
  }

  renderPersons() {
    let count =
      this.getSelectedFilters().find((filter) => filter && filter.includes('places')) ||
      '1 places';

    count = count.split(' ')[0];

    const label = (
      <span>
        <FormattedMessage id="car.filter.label_nb_persons" />
        <span>{' ' + count}</span>
      </span>
    );

    return (
      <DropdownButton
        title={label}
        id={`seats-filter`}
        className="filters-btn border-primary-active"
      >
        <Field
          name="places"
          color={this.props.brand.branded.theme.colors.primary}
          component={renderPlaces}
        />
      </DropdownButton>
    );
  }

  renderVehicleTypeFilter() {
    let title = <FormattedMessage id="car.filter.vehicle_type" />;
    const { messages } = this.props.i18n;
    const vehicleTypeValues = vehicleTypeOptions(messages).map((it) => it.value);

    const hasFilter = vehicleTypeValues.some((item) => {
      if (this.props.values.vehicleType === item) {
        title = messages['car.filter.' + item];
        return true;
      }
      return false;
    });

    return (
      <DropdownButton
        title={title}
        id={`vehicleType-filter`}
        className={classnames('filters-btn', {
          'border-primary-active': hasFilter,
          'border-primary-hover': !hasFilter
        })}
      >
        {this.renderField({
          name: 'vehicleType',
          keyLabel: 'car.filter.vehicle_type',
          component: renderVehicleType,
          options: vehicleTypeOptions(messages),
          className: this.props.useWhiteSvg ? 'use-white-svg' : ''
        })}
      </DropdownButton>
    );
  }

  renderEnergyFilter() {
    const { messages } = this.props.i18n;
    let title = <FormattedMessage id="car.filter_energy" />;
    const energyValues = energyOptions(messages).map((it) => it.value);

    let valuesReduced = energyValues.reduce((values, item) => {
      if (this.getSelectedFilters().includes(item)) {
        values.push(messages['car.' + item]);
      }
      return values;
    }, []);

    const hasFilter = valuesReduced.length > 0;

    if (hasFilter) {
      title = valuesReduced.join(' - ');
    }

    return (
      <DropdownButton
        title={title}
        id={`energy-filter`}
        className={
          hasFilter
            ? 'filters-btn border-primary-active'
            : 'filters-btn border-primary-hover'
        }
      >
        {this.renderField({
          name: 'motorisation',
          keyLabel: 'car.filter_energy',
          component: renderCheckboxGroup,
          options: energyOptions(messages)
        })}
      </DropdownButton>
    );
  }

  renderTransmissionFilter() {
    let title = <FormattedMessage id="car.filter_gearbox_type" />;
    const { messages } = this.props.i18n;
    const gearboxValues = transmissionOptions(messages).map((it) => it.value);

    let valuesReduced = gearboxValues.reduce((values, item) => {
      if (this.getSelectedFilters().includes(item)) {
        values.push(messages['car.' + item]);
      }
      return values;
    }, []);

    const hasFilter = valuesReduced.length > 0;

    if (hasFilter) {
      title = valuesReduced.join(' - ');
    }

    return (
      <DropdownButton
        title={title}
        id={`transmission-filter`}
        className={
          hasFilter
            ? 'filters-btn border-primary-active'
            : 'filters-btn border-primary-hover'
        }
      >
        {this.renderField({
          name: 'transmission',
          keyLabel: 'car.filter_gearbox_type',
          component: renderCheckboxGroup,
          options: transmissionOptions(messages)
        })}
      </DropdownButton>
    );
  }

  renderOtherFilters() {
    const { messages } = this.props.i18n;
    const otherValues = otherFiltersOptions(messages).map((it) => it.value);

    let valuesReduced = otherValues.reduce((values, item) => {
      if (this.getSelectedFilters().includes(item)) {
        values.push(item);
      }
      return values;
    }, []);

    const hasFilter = valuesReduced.length > 0;

    const title = hasFilter ? (
      <FormattedMessage id="car.filter.other-nb" values={{ nb: valuesReduced.length }} />
    ) : (
      <FormattedMessage id="car.filter.other" />
    );

    return (
      <DropdownButton
        title={title}
        id={`energy-filter`}
        className={
          hasFilter
            ? 'filters-btn border-primary-active'
            : 'filters-btn border-primary-hover'
        }
      >
        {this.renderField({
          name: 'accessories',
          keyLabel: 'car.filter.other',
          component: renderCheckboxOtherGroup,
          options: otherFiltersOptions(messages)
        })}
      </DropdownButton>
    );
  }

  renderMobileButtonFilter() {
    const { user, values } = this.props;
    const getNbActiveFilters = (obj) => {
      Object.keys(obj).forEach(
        (k) => ((!obj[k] && obj[k] !== undefined) || obj[k] === 1) && delete obj[k]
      );
      return Object.keys(obj).length;
    };

    const nb = getNbActiveFilters(values);

    if (this.state.isMobile) {
      return (
        <div className="filtering-panel visible-xs">
          {!!_get(user, 'id') && (
            <div className="filter-item">{this.renderTripTypeFilter()}</div>
          )}
          <div className="filter-item">
            <button className="filters-btn" onClick={this.showMobileFilters}>
              <FormattedMessage id="label.filters" />
              {nb > 0 && ' - ' + nb}
            </button>
          </div>
        </div>
      );
    }
  }

  renderMobile() {
    const {
      i18n: { messages },
      searchBookingsForm
    } = this.props;

    return (
      <div className="search-filters-layout">
        <div className="filtering-panel mobile">
          <div className="filter-item">
            <Field
              name="places"
              color={this.props.brand.branded.theme.colors.primary}
              component={renderPlaces}
            />
          </div>
          <div className="filter-item">
            {this.renderField({
              name: 'vehicleType',
              keyLabel: 'car.filter.vehicle_type',
              component: renderVehicleType,
              options: vehicleTypeOptions(messages),
              className: this.props.useWhiteSvg ? 'use-white-svg' : ''
            })}
          </div>
          <div className="filter-item">
            {this.renderField({
              name: 'motorisation',
              keyLabel: 'car.filter_energy',
              component: renderCheckboxGroup,
              options: energyOptions(messages)
            })}
          </div>
          <div className="filter-item">
            {this.renderField({
              name: 'transmission',
              keyLabel: 'car.filter_gearbox_type',
              component: renderCheckboxGroup,
              options: transmissionOptions(messages)
            })}
          </div>
          <div className="filter-item">
            {this.renderField({
              name: 'accessories',
              keyLabel: 'car.filter.other',
              component: renderCheckboxOtherGroup,
              options: otherFiltersOptions(messages)
            })}
          </div>
          <div className="submit-container">
            <button
              className={
                isObjEmpty(searchBookingsForm.syncErrors)
                  ? 'btn primary-btn'
                  : 'btn primary-btn disabled'
              }
              onClick={this.applyFilters}
            >
              <FormattedMessage id="label.apply" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  renderDesktopFilters() {
    const { user } = this.props;

    if (!this.state.isMobile) {
      return (
        <div className="filtering-panel hidden-xs">
          {!!safe(() => user.id) && (
            <div className="filter-item"> {this.renderTripTypeFilter()}</div>
          )}
          <div className="filter-item">{this.renderPersons()}</div>
          <div className="filter-item">{this.renderVehicleTypeFilter()}</div>
          <div className="filter-item">{this.renderEnergyFilter()}</div>
          <div className="filter-item">{this.renderTransmissionFilter()}</div>
          <div className="filter-item">{this.renderOtherFilters()}</div>
        </div>
      );
    }
  }

  filtersBookingPopup() {
    if (this.props.popup.type_FiltersBookings_IsVisible) {
      return (
        <Popup
          isOpen={this.props.popup.type_FiltersBookings_IsVisible}
          onCloseRequested={this.closeMobileFilters}
          id="FiltersMobile-popup"
        >
          <div>
            <div className="title-filters">
              <FormattedMessage id="label.filters" />
            </div>
            <a className="clear-filters-action" onClick={this.resetFilters} href="#">
              <FormattedMessage id="generic.clear" />
            </a>
            {this.renderMobile()}
          </div>
        </Popup>
      );
    }
  }

  render() {
    return (
      <div>
        {this.filtersBookingPopup()}
        {this.renderMobileButtonFilter()}
        {this.renderDesktopFilters()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { searchBookings: searchBookingsForm } = state.form;
  return {
    ...state,
    user: userDataSelector(state),
    useWhiteSvg: isPrimaryColorDarkSelector(state),
    searchBookingsForm
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...PopupActions,
      dispatch
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(FiltersBookings);
