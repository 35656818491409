import { STORAGE_KEY } from '../../constants/generic';
import { getDefaultLocale } from '../../utils/utils';

export function setLanguage(locale) {
  if (!locale) locale = getDefaultLocale();

  window.localStorage.setItem(STORAGE_KEY.LOCALE, locale);

  return {
    type: 'SET_LANGUAGE',
    payload: {
      locale: locale,
      messages: require('../../i18n/' + locale)
    }
  };
}
