export function popup_FiltersBookings_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/FILTERS_BOOKINGS/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_Login_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/LOGIN/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_CreditCard_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/CREDIT_CARD/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_AnonymConfirm_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/ANONYM_CONFIRM/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_AccountValidation_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/ACCOUNT_VALIDATION/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_ActivationEmailSent_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/ACTIVATION_EMAIL_SENT/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_BookingRecap_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/BOOKING_RECAP/SET_VISIBILITY',
    payload: visibility
  };
}

export function popupStep_ForgotPassword_SetVisibility(visibility) {
  return {
    type: 'POPUP/STEP/FORGOT_PASSWORD/SET_VISIBILITY',
    payload: visibility
  };
}

export function popupStep_ExternalLogin_SetVisibility(visibility) {
  return {
    type: 'POPUP/STEP/EXTERNAL_LOGIN/SET_VISIBILITY',
    payload: visibility
  };
}

export function popupStep_ResetPasswordEmailSent_SetVisibility(visibility) {
  return {
    type: 'POPUP/STEP/RESET_PASSWORD_EMAIL_SENT/SET_VISIBILITY',
    payload: visibility
  };
}

export function popupStep_Subscribe_SetVisibility(visibility) {
  return {
    type: 'POPUP/STEP/SUBSCRIBE/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_Faq_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/FAQ/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_ResetPassword_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/RESET_PASSWORD/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_BookingCancel_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/BOOKING_CANCEL/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_BookingSuccess_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/BOOKING_SUCCESS/SET_VISIBILITY',
    payload: visibility
  };
}

export function popup_Generic_SetVisibility(visibility, data) {
  return {
    type: 'POPUP/TYPE/GENERIC/SET_VISIBILITY',
    payload: visibility,
    popupData: data
  };
}

export function popup_BookingEdit_SetVisibility(payload) {
  return {
    type: 'POPUP/TYPE/BOOKING_EDIT/SET_VISIBILITY',
    payload
  };
}

export function popup_PaymentError_SetVisibility(visibility) {
  return {
    type: 'POPUP/TYPE/PAYMENT_ERROR/SET_VISIBILITY',
    payload: visibility
  };
}

export function popupStep_AddAccount_Show() {
  return {
    type: 'POPUP/STEP/ADD_ACCOUNT/SHOW'
  };
}

export function setAddToCalendarData(payload) {
  return {
    type: 'POPUP/DATA/ADD_TO_CALENDAR/SET',
    payload
  };
}

export function clearAddToCalendarData() {
  return {
    type: 'POPUP/DATA/ADD_TO_CALENDAR/CLEAR'
  };
}

export function enableDirectSubscribe() {
  return {
    type: 'POPUP/ACTION/DIRECT_SUBSCRIBE/ENABLE'
  };
}

export function closeAllPopups() {
  return {
    type: 'POPUP/ACTION/CLOSE_ALL'
  };
}
