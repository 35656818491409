import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Field } from 'redux-form';
import Dropzone from 'react-dropzone';
import moment from 'moment';
import classnames from 'classnames';
import _ from 'lodash';
import { getBase64 } from '../../helpers';
import LocationInput from '../partials/LocationInput';
import DatePicker from '../partials/DatePicker';
import TimePicker from '../partials/TimePicker';
import PersonSlider from '../forms/PersonSlider';

export const renderPlaces = ({ color, input }) => {
  return (
    <PersonSlider
      color={color}
      input={input}
      value={input.value}
      onChange={(data) => input.onChange(data)}
    />
  );
};

export const renderPlaceholderTextField = ({
  input,
  placeholder,
  meta: { touched, error },
  ...props
}) => {
  const check =
    touched && !error ? (
      props.type === 'password' && input.value.length < 8 ? null : (
        <i className="icon-checkmark valid-icon" />
      )
    ) : null;

  return (
    <div className="field-wrapper">
      <div className="input-wrapper">
        <label className={props.type}>
          {props.icon ? props.icon : ''}
          <input
            className={'form-control' + (touched && error ? ' error-border' : '')}
            placeholder={placeholder}
            {...input}
            {...props}
          />
        </label>
        {check}
      </div>
      {touched && error && <div className="error-msg">{touched && error}</div>}
    </div>
  );
};

export const renderInviteCodeTextField = ({
  input,
  placeholder,
  meta: { touched, error },
  ...props
}) => (
  <div className="field-wrapper">
    <div className="input-wrapper">
      <label className={input.name}>
        {props.icon ? props.icon : ''}
        <input
          className={'form-control' + (touched && error ? ' error-border' : '')}
          placeholder={placeholder}
          {...input}
        />
      </label>
    </div>
    {touched && error && <div className="error-msg">{touched && error}</div>}
  </div>
);

export const renderTextField = ({
  input,
  asyncValidating = false,
  customError = null,
  mandatory = true,
  label,
  viewOnly = false,
  sm = false,
  lg = false,
  meta: { touched, error, warning },
  ...props
}) => (
  <div className="field-wrapper">
    <Row>
      <Col sm={lg ? 4 : 3} md={sm ? 4 : 3} className="label-wrapper">
        <label htmlFor={input.name}>{label}</label>
        <div className="vam-label" />
      </Col>
      <Col sm={lg ? 8 : 6} md={sm ? (lg ? 5 : 4) : lg ? 9 : 6}>
        {viewOnly ? (
          <div className="mb-15">
            <div className="value">{input.value}</div>
            <div className="vam-label" />
          </div>
        ) : (
          <div>
            <div className="input-wrapper">
              <input
                className={
                  'form-control' +
                  (touched && error && asyncValidating ? ' error-border' : '')
                }
                {...input}
                {...props}
              />
              {(!props.disabled && touched && !error && mandatory && !customError) ||
              (!props.disabled &&
                !mandatory &&
                !warning &&
                !error &&
                !customError &&
                input.value &&
                touched) ? (
                <i className="icon-checkmark valid-icon" />
              ) : null}
            </div>
            <div className="error-msg">{touched && (error || customError)}</div>
          </div>
        )}
      </Col>
    </Row>
  </div>
);

export const renderCheckboxField = ({
  input,
  label,
  labelClassName,
  wrapperClassName,
  meta: { touched, error },
  ...props
}) => (
  <div className={classnames('custom-checkbox', wrapperClassName)}>
    <input
      className={'form-control' + (touched && error ? ' error-border' : '')}
      type="checkbox"
      checked={input.value}
      {...input}
      {...props}
    />
    <label htmlFor={input.name}>
      <span className="chk-btn" />
      <div className={'chk-lbl' + (labelClassName ? ' ' + labelClassName : '')}>
        {label}
      </div>
    </label>
    <div className="error-msg">{touched && error}</div>
  </div>
);

export const renderLocationField = ({
  input,
  sm = false,
  lg = false,
  smInput,
  label,
  viewOnly = false,
  isStreet = false,
  isValue,
  searchType,
  meta: { touched, error }
}) => (
  <div className="field-wrapper">
    <Row>
      <Col sm={lg ? 4 : 3} md={sm ? 4 : 3} className="label-wrapper">
        <label htmlFor="input.name">{label}</label>
        <div className="vam-label" />
      </Col>
      <Col sm={lg ? 8 : 6} md={sm ? (lg ? 5 : 4) : lg ? 9 : 6}>
        {viewOnly ? (
          <div className="mb-15">
            <div className="value">
              {typeof _.get(input, 'value') === 'string'
                ? input.value
                : isValue
                ? _.get(input, 'value.value', '') ||
                  _.get(input, 'value.formattedAddress')
                : isStreet
                ? _.has(input, 'value.value', null)
                  ? _.get(input, 'value.value', null)
                  : _.get(input, 'value.streetNumber', '') +
                    ' ' +
                    _.get(input, 'value.streetName', '')
                : _.get(input, 'value.formattedAddress', '')}
            </div>
            <div className="vam-label" />
          </div>
        ) : (
          <div>
            <div className={'input-wrapper' + (smInput ? ' input-wrapper-sm' : '')}>
              <LocationInput
                placeholder="Recherche..."
                onLocationSelected={(data) => {
                  input.onChange(data);
                }}
                initialValue={input.value}
                onEmpty={(data) => {
                  input.onChange(data);
                }}
                onChange={(data) => {
                  input.onChange(data);
                }}
                isStreet={isStreet}
                searchType={searchType}
                isValue={isValue}
              />
              {!error && input.value && touched ? (
                <i className="icon-checkmark valid-icon" />
              ) : null}
            </div>
            <div className="error-msg">{touched && error}</div>
          </div>
        )}
      </Col>
    </Row>
  </div>
);

export const renderPlaceholderLocationField = ({
  isValue,
  input,
  isError = false,
  getLocation,
  countries,
  openDatePickerOnSelect,
  smInput,
  externalErrors,
  label,
  placeholder,
  fixtures,
  maxFixtures,
  fixturesTitle,
  historyTitle,
  searchHistoryEnabled,
  meta: { touched, error }
}) => (
  <div>
    <label className="hidden-xs">{label}</label>
    <div className={'input-wrapper' + (smInput ? ' input-wrapper-sm' : '')}>
      <LocationInput
        placeholder={placeholder}
        onLocationSelected={(data) => {
          input.onChange(data);
        }}
        onChange={(data) => {
          input.onChange(data);
        }}
        onEmpty={(data) => {
          input.onChange(data);
        }}
        initialValue={input.value}
        isValue={isValue}
        countries={countries}
        getLocation={getLocation}
        fixtures={fixtures}
        externalErrors={externalErrors}
        maxFixtures={maxFixtures}
        fixturesTitle={fixturesTitle}
        historyTitle={historyTitle}
        searchHistoryEnabled={searchHistoryEnabled}
        openDatePickerOnSelect={openDatePickerOnSelect}
      />
    </div>
    <div className="error-msg">{(touched && error) || isError}</div>
  </div>
);

export const renderDatePicker = ({
  input,
  label,
  errorCallback = () => {},
  changeCallback = () => {},
  isEndDate = null,
  disabledUntil = null,
  disabledStartUntil = null,
  meta: { touched, error }
}) => (
  <div>
    <DatePicker
      label={label}
      disabledUntil={disabledUntil}
      className="primary-border"
      disabledStartUntil={disabledStartUntil}
      isEndDate={isEndDate}
      showError={touched && error}
      value={input.value}
      onError={(data) => {
        errorCallback(data);
      }}
      onBlur={input.onBlur}
      onDateSelected={(data) => {
        changeCallback(moment(data.value).toDate());
        input.onChange(moment(data.value).format('YYYY-MM-DD'));
      }}
    />
  </div>
);

export const renderTimePicker = ({
  input,
  label,
  errorCallback = () => {},
  changeCallback = () => {},
  meta: { touched, error },
  inputFormat = 'HH:mm',
  isSameDay = null,
  startDate = null,
  startTime = null,
  isEndTime = false
}) => (
  <div>
    <TimePicker
      label={label}
      showError={touched && error}
      value={input.value}
      isEndTime={isEndTime}
      isSameDay={isSameDay}
      startDate={startDate}
      startTime={startTime}
      onError={(data) => {
        errorCallback(data);
      }}
      inputFormat={inputFormat}
      onTimeSelected={(data) => {
        changeCallback(moment.utc(data.value).format(inputFormat));
        input.onChange(moment.utc(data.value).format(inputFormat));
      }}
    />
  </div>
);

export const renderRadioGroup = ({
  input,
  options,
  meta: { touched, error },
  ...props
}) => (
  <div>
    {props.title && <div className="title">{props.title}</div>}
    <div className={`custom-radio ${touched && error ? 'error-border' : ''}`}>
      {options.map((o, index) => {
        return (
          <span key={'radio-' + index}>
            <input
              id={input.name + '_' + o.value}
              type="radio"
              {...input}
              value={o.value}
              checked={o.value === input.value}
              onClick={() => document.dispatchEvent(new MouseEvent('click'))}
            />
            <label
              htmlFor={input.name + '_' + o.value}
              key={o.value}
              className="border-primary-active"
            >
              <span className="radio-btn" /> {o.title}
            </label>
          </span>
        );
      })}
    </div>
  </div>
);

export const renderUsageType = ({
  input,
  options,
  meta: { touched, error },
  ...props
}) => (
  <div>
    {props.title && <div className="title">{props.title}</div>}
    <div className="custom-radio">
      {options.map((o, index) => {
        const id = input.name + '_' + o.value;
        return (
          <span key={'radio-' + index}>
            <input
              id={id}
              type="radio"
              {...input}
              value={o.value}
              checked={o.value === input.value}
            />
            <label
              htmlFor={id}
              key={o.value}
              className={o.value === input.value ? 'primary-border' : ''}
            >
              <span className="radio-btn" /> {o.title}
            </label>
          </span>
        );
      })}
    </div>
    {touched && error && <div className="error-msg dis-ib">{touched && error}</div>}
  </div>
);

export const renderRadioTabGroup = ({ input, options, meta: { touched, error } }) => (
  <div className="checkbox-tabs">
    {options.map((option, index) => {
      const isChecked = option.value === input.value;
      const id = input.name + '_' + option.value;
      return (
        <div className="tab primary-border" key={index}>
          <input
            id={id}
            type="radio"
            name={input.name}
            {...input}
            value={option.value}
            checked={isChecked}
          />
          <label
            htmlFor={id}
            key={option.value}
            className={isChecked ? 'primary-border' : ''}
          >
            {option.title}
          </label>
        </div>
      );
    })}
    <div className="error-msg dis-ib">{touched && error}</div>
  </div>
);

export const renderTextAreaField = ({
  input,
  label,
  sm,
  meta: { touched, error },
  ...props
}) => (
  <div className="field-wrapper">
    <Row>
      <Col sm={3} md={sm ? 4 : 3} className="label-wrapper">
        <label htmlFor={input.name}>{label}</label>
        <div className="vam-label" />
      </Col>
      <Col sm={6} md={sm ? 4 : 6}>
        <div className="input-wrapper">
          <textarea
            className={'form-control' + (touched && error ? ' error-border' : '')}
            {...input}
            {...props}
          />
        </div>
        <div className="error-msg">{touched && error}</div>
      </Col>
      <Col sm={3} md={sm ? 4 : 3} />
    </Row>
  </div>
);

export const renderPlaceholderTextAreaField = ({
  input,
  label,
  meta: { touched, error },
  ...props
}) => (
  <div className="field-wrapper">
    <div className="input-wrapper">
      <textarea
        className={'form-control' + (touched && error ? ' error-border' : '')}
        {...input}
        {...props}
      />
      {touched && !error ? <i className="icon-checkmark valid-icon" /> : null}
    </div>
    <div className="error-msg">{touched && error}</div>
  </div>
);

// TODO: set the input value
export const renderCheckboxGroup = ({ input, options, meta, ...props }) => (
  <div className="energy">
    {props.title && <div className="title">{props.title}</div>}
    {options.map((option, index) => {
      const id = input.name + '_' + option.value;
      return (
        <div key={index} className="custom-checkbox">
          <input
            id={id}
            type="checkbox"
            value={option.value}
            checked={input.value.indexOf(option.value) !== -1}
            onChange={(event) => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }
              return input.onChange(newValue);
            }}
          />
          <label htmlFor={id} key={option.value}>
            <span className="chk-btn" />
            <div className="chk-lbl">{option.title}</div>
          </label>
        </div>
      );
    })}
  </div>
);

export const renderCheckboxOtherGroup = ({ input, options, meta, ...props }) => (
  <div className="others">
    {props.title && <div className="title">{props.title}</div>}
    {options.map((option, index) => {
      const id = input.name + '_' + option.value;
      return (
        <div key={index} className="custom-checkbox">
          <input
            id={id}
            type="checkbox"
            value={option.value}
            checked={input.value.indexOf(option.value) !== -1}
            onChange={(event) => {
              const newValue = [...input.value];
              if (event.target.checked) {
                newValue.push(option.value);
              } else {
                newValue.splice(newValue.indexOf(option.value), 1);
              }
              return input.onChange(newValue);
            }}
          />
          <label htmlFor={id} key={option.value}>
            <span className="chk-btn" />
            <div className="chk-lbl">{option.title}</div>
          </label>
        </div>
      );
    })}
  </div>
);

export const renderSelectField = ({
  input,
  placeholder,
  options,
  showError,
  viewOnly,
  fluid,
  sm,
  xs,
  meta: { touched, error, warning },
  ...props
}) => (
  <div>
    {viewOnly ? (
      <div className="mb-15">
        <div className="value">
          {input.name.indexOf('Month') !== -1
            ? input.value
              ? moment()
                  .month(input.value - 1)
                  .format('MMMM')
                  .capitalize()
              : null
            : input.value}
        </div>
        <div className="vam-label" />
      </div>
    ) : (
      <div
        className={classnames({
          'custom-select': true,
          'w-100': fluid,
          'custom-select-sm': sm,
          'custom-select-xs': xs,
          disabled: !!warning
        })}
      >
        {
          <div>
            <Field
              name={input.name}
              className="no-margin"
              component="select"
              disabled={!!warning || props.disabled}
            >
              {placeholder ? <option value="">{placeholder}</option> : null}
              {options.map((option, i) => (
                <option key={i} value={option.value}>
                  {option.title}
                </option>
              ))}
            </Field>
          </div>
        }
      </div>
    )}
    {showError && !viewOnly ? <div className="error-msg">{touched && error}</div> : null}
  </div>
);

// Custom non-reusable components
export const renderVehicleType = ({ input, options, meta, ...props }) => {
  const needWhiteIcon = props.className === 'use-white-svg';
  return (
    <div>
      {props.title && <div className="title">{props.title}</div>}
      <div className={classnames('vehicle-type', { 'use-white-icon': needWhiteIcon })}>
        {options.map((o, index) => {
          const id = input.name + '_' + o.value;
          return (
            <div key={'radio-' + index} className="radio-icon">
              <input
                id={id}
                type="radio"
                {...input}
                value={o.value}
                checked={o.value === input.value}
                onClick={(e) => {
                  input.value = o.value === input.value ? null : e.target.value;
                  input.onChange(input.value);
                }}
              />
              <label htmlFor={id} key={o.value} className="border-primary-hover">
                <i className={'label-icon ' + o.icon} />
                <span className="info">{o.title}</span>
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
