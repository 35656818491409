import { STORAGE_KEY } from '../constants/generic';
import { getAppObj } from './utils';
import config from '../constants/config';
import { keycloakUrlMap } from '../constants/maps';
import Keycloak from 'keycloak-js';

export const KEYCLOAK_STATUS = {
  AUTH: 0,
  UNAUTH: 1,
  FAIL: 2
};

export function getKeycloakUrl(env = config.env) {
  return keycloakUrlMap[env] || keycloakUrlMap.prod;
}

export function initKeycloak(settings, cb) {
  const { AUTH, UNAUTH, FAIL } = KEYCLOAK_STATUS;
  try {
    getAppObj().keycloak = new Keycloak(settings);
    getAppObj()
      .keycloak.init({
        checkLoginIframe: true,
        checkLoginIframeInterval: 10
      })
      .then((authenticated) => {
        cb(authenticated ? AUTH : UNAUTH);
      })
      .catch((e) => cb(FAIL, e));
  } catch (e) {
    cb(FAIL, e);
  }
}

export function isLoginPending() {
  return localStorage.getItem(STORAGE_KEY.LOGIN_PENDING);
}

export function setLoginPending() {
  localStorage.setItem(STORAGE_KEY.LOGIN_PENDING, true);
}

export function clearLoginPending() {
  localStorage.removeItem(STORAGE_KEY.LOGIN_PENDING);
}

export function getKeycloakEmail() {
  return getAppObj().keycloak?.idTokenParsed?.email;
}
