import React from 'react';

export default function NoTailArrowRight(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7.01 11.02" {...props}>
      <path
        fillRule="evenodd"
        d="M.17.21A.6.6,0,0,1,1,.14H1L6.8,5h0A.59.59,0,0,1,7,5.51a.59.59,0,0,1-.21.55h0L1,10.88a.59.59,0,0,1-.84-.07l0,0a.61.61,0,0,1,.07-.85h0l5.3-4.42L.22,1.1h0A.6.6,0,0,1,.14.25h0Z"
      />
    </svg>
  );
}
