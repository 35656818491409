import {
  CUSTOM_FIELD_FORM_BOOKING,
  CUSTOM_FIELD_FORM_SUBSCRIPTION
} from '../../constants/backend';

import { reducerType } from '../../reducers/customFields';

function getCompanyCustomFields(type, companyId) {
  return {
    type: `${reducerType}/GET_${type}_FIELDS`,
    payload: {
      request: {
        method: 'get',
        url: `v2/customizations/${companyId}/fields?form=${type}`
      }
    }
  };
}

export function getMemberCustomFields(companyId) {
  return getCompanyCustomFields(CUSTOM_FIELD_FORM_SUBSCRIPTION, companyId);
}

export function getBookingCustomFields(companyId) {
  return getCompanyCustomFields(CUSTOM_FIELD_FORM_BOOKING, companyId);
}
