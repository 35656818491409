import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';

import user from './user';
import popup from './popup';
import i18n from './i18nReducer';
import keycloak from './keycloak';
import creditCard from './creditCard';
import booking from './booking';
import brand from './brand';
import customFields from './customFields';
import impersonate from './impersonate';

export default combineReducers({
  form,
  user,
  popup,
  i18n,
  creditCard,
  booking,
  brand,
  customFields,
  keycloak,
  impersonate
});
