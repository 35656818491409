import { Field } from 'redux-form';
import { NeutralDatePicker } from '../../forms/NeutralDatePicker';
import React from 'react';
import { showErrorInfo } from '../../forms/newFields';

export function DateFieldInner({ label, mandatory, ...props }) {
  return (
    <div className="scope-field-container">
      <div className="scope-field-label">
        {label}
        {mandatory && ' *'}
      </div>
      <NeutralDatePicker {...props} />
      {showErrorInfo(props)}
    </div>
  );
}

export default function DateField({ name, ...props }) {
  return <Field name={name} component={DateFieldInner} {...props} />;
}
