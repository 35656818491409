import React, { Component } from 'react';
import { safe } from '../../utils/utils';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { currentBrandSelector } from '../../helpers/selectors';

class DeepLinkMessage extends Component {
  constructor(props) {
    super(props);
    this.initState();
    this.setCallbacks();
  }
  initState() {
    this.state = { seconds: 30 };
  }
  setCallbacks() {
    this.handleRedirect = () => {
      window.history.replaceState({}, '', '/');
      window.location.href = this.getDeepLink();
    };
  }
  componentDidMount() {
    this.componentPropsUpdated();
  }
  componentDidUpdate() {
    this.componentPropsUpdated();
  }
  componentPropsUpdated() {
    this.startCountDown();
    this.hanleCountDownRedirect();
  }
  componentWillUnmount() {
    window.clearInterval(this.countDownInterval);
  }
  hanleCountDownRedirect() {
    if (this.state.seconds < 1) {
      this.handleRedirect();
    }
  }
  decreaseSeconds = () => {
    this.setState((state) => {
      return { seconds: state.seconds - 1 };
    });
  };
  startCountDown() {
    if (!this.countDownInterval && this.getDeepLink()) {
      this.countDownInterval = window.setInterval(() => {
        this.decreaseSeconds();
      }, 1000);
    }
  }
  getDeepLink() {
    return safe(() => this.props.brand.deepLinkUrl);
  }
  render() {
    if (this.getDeepLink()) {
      return (
        <p className="description">
          <FormattedMessage id="label.account_activation_redirect" />{' '}
          <span>{this.state.seconds}</span>
          <span>s</span>
          <br />
          <button onClick={this.handleRedirect} className="btn primary-btn mt-30">
            <FormattedMessage id="label.account_activation_redirect_btn" />
          </button>
        </p>
      );
    }
    return null;
  }
}

function mapStateToProps(state) {
  return { brand: currentBrandSelector(state) };
}
DeepLinkMessage = connect(mapStateToProps)(DeepLinkMessage);

export default DeepLinkMessage;
