export const BROWSER_OS_WINDOWS_PHONE = 'WINDOWS_PHONE';
export const BROWSER_OS_ANDROID = 'ANDROID';
export const BROWSER_OS_APPLE_MOBILE = 'APPLE_MOBILE';

export const daysOfWeek = [
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
  'SUNDAY'
];

// Renault Mobility France companies
export const RMF_companies = {
  valid: '58107ebe-dc4c-403f-a54e-25271fd43da9',
  dev: 'fa16bdda-438d-4c12-8fd0-a306014edb35',
  staging: '58107ebe-dc4c-403f-a54e-25271fd43da9',
  prod: 'fb10f1b9-abb4-483a-a308-8afae9749d57'
};
