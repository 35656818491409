import {
  VEHICLE_TYPE_PASSENGER,
  VEHICLE_TYPE_COMMERCIAL,
  USAGE_TYPE_PRIVATE,
  USAGE_TYPE_BUSINESS,
  FILTER_BOOKING_ACCESSORY_AIR_CONDITIONING,
  FILTER_BOOKING_ACCESSORY_BLUETOOTH,
  FILTER_BOOKING_ACCESSORY_FOLDING_SEATS,
  FILTER_BOOKING_ACCESSORY_GPS,
  FILTER_BOOKING_ACCESSORY_RADIO_CD,
  FILTER_BOOKING_ACCESSORY_LARGE_TRUCK,
  FILTER_BOOKING_ACCESSORY_BABY_SEAT
} from './backend';

import { ONE_WAY, RETURN_TRIP } from './generic';

export const vehicleTypeOptions = (messages) => [
  {
    value: VEHICLE_TYPE_PASSENGER,
    title: messages['car.filter.PASSENGER'],
    icon: 'tourism'
  },
  {
    value: VEHICLE_TYPE_COMMERCIAL,
    title: messages['car.filter.COMMERCIAL'],
    icon: 'commercial'
  }
];

export const bookingTypeOptions = (messages) => [
  {
    value: ONE_WAY,
    title: messages['booking.type.one_way']
  },
  {
    value: RETURN_TRIP,
    title: messages['booking.type.return_trip']
  }
];

export const usageOptions = (messages) => [
  {
    value: USAGE_TYPE_PRIVATE,
    title: messages['booking.usage.private']
  },
  {
    value: USAGE_TYPE_BUSINESS,
    title: messages['booking.usage.business']
  }
];

export const energyOptions = (messages) => [
  {
    value: 'DIESEL',
    title: messages['car.DIESEL']
  },
  {
    value: 'PETROL',
    title: messages['car.PETROL']
  },
  {
    value: 'ELECTRIC',
    title: messages['car.ELECTRIC']
  },
  {
    value: 'HYBRID',
    title: messages['car.HYBRID']
  },
  {
    value: 'LPG',
    title: messages['car.LPG']
  }
];

export const otherFiltersOptions = (messages) => [
  {
    value: FILTER_BOOKING_ACCESSORY_LARGE_TRUCK,
    title: messages['car.filter.accessories.large_truck']
  },
  {
    value: FILTER_BOOKING_ACCESSORY_FOLDING_SEATS,
    title: messages['car.FOLDING_SEATS']
  },
  {
    value: FILTER_BOOKING_ACCESSORY_RADIO_CD,
    title: messages['car.filter.accessories.radio_cd']
  },
  {
    value: FILTER_BOOKING_ACCESSORY_BLUETOOTH,
    title: messages['car.filter.accessories.bluetooth']
  },
  {
    value: FILTER_BOOKING_ACCESSORY_GPS,
    title: messages['car.GPS']
  },
  {
    value: FILTER_BOOKING_ACCESSORY_AIR_CONDITIONING,
    title: messages['car.filter.accessories.air_conditioning']
  },
  {
    value: FILTER_BOOKING_ACCESSORY_BABY_SEAT,
    title: messages['car.filter.accessories.baby_seat']
  }
];

export const transmissionOptions = (messages) => [
  {
    value: 'MANUAL',
    title: messages['car.MANUAL']
  },
  {
    value: 'AUTOMATIC',
    title: messages['car.AUTOMATIC']
  }
];
