import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import {
  INCORRECT_SHORT_ID_MSG,
  PROFILE_EDIT_MEMBER_APPROVED_MSG
} from '../constants/backend';

export const errorCodes = {
  [PROFILE_EDIT_MEMBER_APPROVED_MSG]: 'error_profile_edit_member_approved',
  [INCORRECT_SHORT_ID_MSG]: 'error.invite.code.invalid',
  'The driving licence is not valid.': 'profile_error_driver_license_invalid',
  'The phone number is not valid': 'error.phone.format.invalid',
  'No super company was found for this shortId': 'error.invite.code.invalid',
  'No usage for these booking dates': 'error.booking.no.usage',
  'error.account.413': 'file_upload_size_exceeded',
  'member.profile.different.brand': 'error.invite.code.invalid',
  'member.cannot.register.without.sso': 'error.register.without.sso'
};

export const partialErrorCodes = {
  profile: {
    drivingLicenseMissing: {
      partialErrorMsg: 'drivingLicence',
      translationKey: 'profile_error_driver_license_invalid'
    },
    phoneNumberInvalid: {
      partialErrorMsg: 'phoneNumber',
      translationKey: 'error.phone.format.invalid'
    }
  },
  fileSizeExceeded: {
    partialErrorMsg: 'The maximum size',
    translationKey: 'file_upload_size_exceeded'
  }
};

function checkPartialError(partialError, fullMessage) {
  const { partialErrorMsg, translationKey } = partialError;
  if (_includes(fullMessage, partialErrorMsg)) {
    return translationKey;
  }
}

function checkPartialErrors(partialErrors, fullMessage) {
  let key = undefined;

  if (partialErrors && fullMessage) {
    _forEach(partialErrors, (error) => {
      const resp = checkPartialError(error, fullMessage);
      if (resp) {
        key = resp;
        return false; // exit loop manually
      }
    });
  }

  return key;
}

function checkValidationErrors(partialErrors, validationErrors) {
  let key = undefined;

  if (validationErrors) {
    _forEach(validationErrors, (validationError) => {
      const staticKey = errorCodes[validationError];

      if (staticKey) {
        key = staticKey;
        return false; // exit loop manually
      }

      const partialKey = checkPartialErrors(partialErrors, validationError);

      if (partialKey) {
        key = partialKey;
        return false; // exit loop manually
      }
    });
  }

  return key;
}

function getErrorFromBundle(bundle, code, errorCodePrefixes) {
  let msg = undefined;

  if (errorCodePrefixes) {
    _forEach(errorCodePrefixes, (prefix) => {
      const key = prefix + code;
      msg = bundle[key];
      if (msg) return false; // exit loop manually
    });
  }

  return msg || bundle[code];
}

/*
  error - error returned as response
  bundle - translation bundle
  partialErrors - array of partial errors
  def - default error message
  errorCodePrefixes - array of errorCode prefixes
  */
export function getErrorMsg({
  error,
  bundle,
  partialErrors,
  errorCodePrefixes,
  defKey = 'label.generic.error'
}) {
  if (bundle) {
    if (error) {
      const { message, developerMessage, errorCode, validationErrors } = error || {};
      const firstValidationError = validationErrors ? validationErrors[0] : undefined;
      const errorMsg = developerMessage || message;

      const code =
        errorCodes[errorCode] ||
        errorCode ||
        errorCodes[errorMsg] ||
        checkPartialErrors(partialErrors, errorMsg) ||
        checkValidationErrors(partialErrors, validationErrors);

      return (
        getErrorFromBundle(bundle, code, errorCodePrefixes) ||
        errorMsg ||
        firstValidationError ||
        errorCode ||
        code ||
        bundle[defKey] ||
        defKey
      );
    }

    return bundle[defKey];
  }

  return defKey;
}
