import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as BookingActions from '../actions/booking';
import * as PopupActions from '../actions/popup';
import { formatDataForSearchBookingsApiCall } from '../helpers';
import { browserHistory } from '../helpers/components';

import {
  currentBrandSelector,
  messagesSelector,
  userCompanyIdSelector,
  userIdSelector,
  currentThemeSelector
} from '../helpers/selectors';

import {
  gtmPushBasicSearchData,
  handleCarImageFail,
  getBookingCurrency,
  getPrice,
  safe
} from '../utils/utils';

import _map from 'lodash/map';
import classnames from 'classnames';
import { Col, Row } from 'react-bootstrap';
import carPlaceholder from './../assets/images/car-placeholder.svg';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import IconBattery from './svg/Battery';
import { addInfoMsg } from '../utils/flashMessage/creator';
import { USAGE_TYPE_PRIVATE } from '../constants/backend';

const capitalizeStyle = {
  textTransform: 'capitalize'
};

export class SearchEditBookings extends Component {
  constructor(props) {
    super(props);
    this.setVars();
  }

  componentDidMount() {
    this.searchBookings();
  }

  componentWillUnmount() {
    this.props.clearBookingEditFormData();
    this.props.clearBookingEditData();
  }

  setVars() {
    this.imageColSize = 5;
    this.innerContentColSize = 7;
  }

  handleEmptyDataRedirect() {
    const { editBookingData, editBookingFormData } = this.props;
    if (!(editBookingData && editBookingFormData)) {
      browserHistory.push('/my-bookings');
      return true;
    }
  }

  searchBookings() {
    if (this.handleEmptyDataRedirect()) return;

    const {
      m,
      search,
      currentBrand,
      userId,
      companyId,
      editBookingData,
      editBookingFormData
    } = this.props;

    const { id: bookingId, carSharingInfo } = editBookingData || {};
    const { usageType } = carSharingInfo || {};
    const { contract } = currentBrand || {};
    const formData = { ...editBookingFormData, usageTypes: usageType };

    const formattedData = formatDataForSearchBookingsApiCall(
      formData,
      companyId,
      contract,
      bookingId
    );

    search(formattedData).then((data) => {
      if (data.type === 'booking/SEARCH_SUCCESS') {
        const results = data.payload.data.results || [];
        if (!results.length) addInfoMsg(m['search.result.empty']);

        gtmPushBasicSearchData(data, editBookingFormData, userId);
      }
    });
  }

  getCarPicture(booking) {
    const { vehicle } = booking || {};
    let { pictureUrl } = vehicle || {};

    return (
      <img
        alt=""
        onError={(e) => handleCarImageFail(e, this.props.theme)}
        src={pictureUrl ? pictureUrl : carPlaceholder}
        className="booking-history-details-picture"
      />
    );
  }

  getVehicleTitle(booking) {
    const { vehicle } = booking || {};
    const { brand, model } = vehicle || {};
    return (
      <span className="booking-history-details-car-title">{brand + ' ' + model}</span>
    );
  }

  getBatteryLevel(booking) {
    const { vehicle } = booking || {};
    const { fuelLevel } = vehicle || {};
    const { percentage } = fuelLevel || {};

    return (
      <span className="booking-history-details-battery-level">{percentage}&nbsp;%</span>
    );
  }

  getBatteryData(booking) {
    const { vehicle } = booking || {};
    const { fuelLevel } = vehicle || {};
    const { percentage } = fuelLevel || {};

    if (percentage) {
      return (
        <div className="booking-history-details-battery-container">
          <IconBattery
            className="booking-history-details-battery-icon"
            percentage={percentage}
          />
          {this.getBatteryLevel(booking)}
        </div>
      );
    }
  }

  getPlateNumber(booking) {
    const { vehicle } = booking || {};
    const { registrationNumber } = vehicle || {};

    return (
      <span className="booking-history-details-plate-number">{registrationNumber}</span>
    );
  }

  getStartAddress(booking) {
    const { start } = booking || {};
    const { address } = start || {};
    const { formattedAddress } = address || {};

    return (
      <span className="booking-history-details-start-address">{formattedAddress}</span>
    );
  }

  getStartDateTime(booking) {
    const { start } = booking || {};
    const { date: startDate } = start || {};

    const momentDate = moment(startDate);
    const what = <FormattedMessage id="generic.start" />;
    const isToday = momentDate.isSame(new Date(), 'day');
    const at = isToday ? <FormattedMessage id="label.booking.today.part" /> : ':';
    const time = ' ' + momentDate.format('hh:mm a');

    return (
      <span className="booking-history-details-start-date-time">
        {what}
        {isToday ? ' ' : ''}
        {at}
        {time}
      </span>
    );
  }

  getBookingAction(index) {
    return [
      <div key="hr" className="booking-history-details-hr" />,
      <div key="action-container" className="booking-history-details-action-container">
        <button
          onClick={() => this.showRecapPopup(index)}
          className="generic-submit-button primary-btn"
        >
          <FormattedMessage id="generic.book" />
        </button>
      </div>
    ];
  }

  showFirstBookingInfoRows(booking) {
    return [
      <div className="booking-history-details-battery-parent-row" key="booking-title">
        {this.getVehicleTitle(booking)}
        {this.getBatteryData(booking)}
      </div>,
      <div className="booking-history-details-content-body-text-row" key="plate-number">
        {this.getPlateNumber(booking)}
      </div>
    ];
  }

  getEstimatedPrice(booking) {
    const { carSharingInfo } = booking || {};
    const { cost } = carSharingInfo || {};
    const { estimatedPriceForDuration } = cost || {};
    return estimatedPriceForDuration;
  }

  isPrivateUsage(booking) {
    return safe(() => booking.carSharingInfo.usageType) === USAGE_TYPE_PRIVATE;
  }

  getBookingPriceRow(booking) {
    const currency = getBookingCurrency(booking);
    const price = getPrice(this.getEstimatedPrice(booking), currency);

    if (price && this.isPrivateUsage(booking)) {
      return (
        <div className="booking-history-details-content-body-text-row">
          <span style={capitalizeStyle}>
            <FormattedMessage id="label.price" />
            {': '}
          </span>
          <span className="f-18 bold">{price}</span>
        </div>
      );
    }
  }

  showRecapPopup(index) {
    const { popup_BookingRecap_SetVisibility, loadBookingPopupData } = this.props;
    popup_BookingRecap_SetVisibility(true);
    loadBookingPopupData(index);
  }

  showEditBookings() {
    const { vehiclesData } = this.props;
    return _map(vehiclesData, (booking, index) => (
      <div className="booking-history-details-content" key={index}>
        <div className="booking-history-details-content-body">
          <Row className="remove-row-margin">
            <Col
              className={classnames(
                'remove-col-padding',
                'booking-history-details-picture-column'
              )}
              xs={12}
              sm={this.imageColSize}
              md={this.imageColSize}
              lg={this.imageColSize}
            >
              {this.getCarPicture(booking)}
            </Col>
            <Col
              className="remove-col-padding"
              xs={12}
              sm={this.innerContentColSize}
              md={this.innerContentColSize}
              lg={this.innerContentColSize}
            >
              {this.showFirstBookingInfoRows(booking)}
              <div className="booking-history-details-content-body-text-row">
                {this.getStartAddress(booking)}
              </div>
              <div className="booking-history-details-content-body-text-row">
                {this.getStartDateTime(booking)}
              </div>
              {this.getBookingPriceRow(booking)}
              {this.getBookingAction(index)}
            </Col>
          </Row>
        </div>
      </div>
    ));
  }

  render() {
    return (
      <div className={classnames('booking-history', 'booking-edit-list')}>
        <div className="booking-history-padding">
          <div className="booking-history-title">
            <FormattedMessage id="title.update.booking" />
          </div>
          <div className="scope-edit-list">{this.showEditBookings()}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { editBookingData, editBookingFormData, vehiclesData } = state.booking;
  return {
    m: messagesSelector(state),
    vehiclesData,
    editBookingData,
    editBookingFormData,
    currentBrand: currentBrandSelector(state),
    userId: userIdSelector(state),
    theme: currentThemeSelector(state),
    companyId: userCompanyIdSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...PopupActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchEditBookings);
