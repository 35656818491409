import React from 'react';
import LocationInput from '../../partials/LocationInput';
import { Field } from 'redux-form';
import arrowDownSrc from '../../../assets/images/no-tail-arrow-down.svg';
import { ReactSVG } from 'react-svg';
import classnames from 'classnames';
import checkCircleMark from '../../../assets/images/checkmark.svg';
import { getLabelFromOptions } from '../../../helpers/components';
import { objGetFirstValue } from '../../../utils/utils';

// TODO: use only defined props
export const profileTextField = ({
  meta,
  input = {},
  disabled,
  placeholder,
  type = 'text',
  ...props
}) => {
  if (disabled) {
    return (
      <span className={classnames('new-design-field', 'sub-class-disabled')}>
        {input.value}
      </span>
    );
  } else {
    return (
      <input
        className="new-design-field"
        type={type}
        placeholder={placeholder}
        {...input}
        {...props}
      />
    );
  }
};

export const profileRadioGroup = ({ input, options, disabled }) => {
  if (disabled) {
    return (
      <input
        value={getLabelFromOptions(options, input.value)}
        disabled
        className="new-design-field"
      />
    );
  }

  return (
    <div className="new-design-radio-group">
      {options.map((o, index) => {
        const id = input.name + '_' + o.value;

        return (
          <div key={index} className="new-design-radio-container">
            <input
              className="new-design-radio-input"
              id={id}
              type="radio"
              {...input}
              value={o.value}
              checked={o.value === input.value}
            />
            <label className="new-design-radio-label" htmlFor={id} key={o.value}>
              <span className="new-design-radio-btn" />
              <span className="new-design-radio-title">{o.title}</span>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export const profileAddressField = ({
  input,
  disabled,
  isStreet = false,
  isValue = false,
  searchType,
  placeholder,
  showLocationButton = true
}) => {
  const innerWrapper = (child) =>
    disabled ? (
      <div className={classnames('new-design-field', 'sub-class-disabled')}>{child}</div>
    ) : (
      child
    );

  return (
    <div className="new-design-address-container">
      {innerWrapper(
        <LocationInput
          disabled={disabled}
          showLocationButton={showLocationButton}
          placeholder={placeholder}
          onLocationSelected={(data) => {
            input.onChange(data);
          }}
          initialValue={input.value}
          onEmpty={(data) => {
            input.onChange(data);
          }}
          onChange={(data) => {
            input.onChange(data);
          }}
          isStreet={isStreet}
          searchType={searchType}
          isValue={isValue}
        />
      )}
    </div>
  );
};

export const genericAddressField = ({ input, ...props }) => {
  return (
    <LocationInput
      onLocationSelected={(data) => {
        input.onChange(data);
      }}
      initialValue={input.value}
      onEmpty={(data) => {
        input.onChange(data);
      }}
      onChange={(data) => {
        input.onChange(data);
      }}
      {...props}
    />
  );
};

export const ProfileSelectField = ({ input, options, disabled }) => {
  const displayValue = options[input.value] || objGetFirstValue(options);

  if (disabled) {
    return (
      <span className={classnames('new-design-field', 'sub-class-disabled')}>
        {displayValue}
      </span>
    );
  }

  function getOptions() {
    const ret = [];

    for (const optionKey in options) {
      ret.push(
        <option key={optionKey} value={optionKey}>
          {options[optionKey]}
        </option>
      );
    }

    return ret;
  }

  return (
    <div className="new-design-select-container">
      <div className="new-design-select-styled">
        <span className="new-design-select-value">{displayValue}</span>
        <ReactSVG className="react-svg" src={arrowDownSrc} />
      </div>
      <Field name={input.name} className="new-design-select-field" component="select">
        {getOptions()}
      </Field>
    </div>
  );
};

export const roundSwitch = ({ input, meta, ...props }) => (
  <label className="switch">
    <input type="checkbox" {...input} checked={input.value} {...props} />
    <span className="slider round" />
  </label>
);

export const checkCircle = ({ input }) => (
  <label className="check-circle-container">
    <input
      className="check-circle-input"
      type="checkbox"
      {...input}
      checked={input.value}
    />
    <div className={classnames('check-circle', { checked: input.value })}>
      <ReactSVG className="react-svg" src={checkCircleMark} />
    </div>
  </label>
);

export function showErrorInfo({ meta }) {
  const { touched, error } = meta || {};
  const isError = touched && error;
  const errorType = typeof error === 'string' ? 'text' : 'border';

  if (isError) {
    return <div className={classnames('field-error-info', errorType)}>{error}</div>;
  }
}
