import { FormattedMessage } from 'react-intl';
import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';

const textStyle = {
  wordBreak: 'break-word'
};

function GenericPopup({ genericPopupKey, genericPopupText }) {
  return (
    <div
      className={classnames(
        'pt-15',
        'pl-20',
        'pr-20',
        'pb-dt-35',
        'pb-xs-25',
        'text-center'
      )}
    >
      <p className="mt-xs-25 mt-dt-35 f-16">
        {genericPopupKey ? (
          <FormattedMessage id={genericPopupKey} />
        ) : (
          <span style={textStyle}>{genericPopupText}</span>
        )}
      </p>
    </div>
  );
}

function mapStateToProps(state) {
  const { genericPopupKey, genericPopupText } = state.popup;
  return { genericPopupKey, genericPopupText };
}

export default connect(mapStateToProps)(GenericPopup);
