import React, { Component } from 'react';
import { reduxForm } from 'redux-form';
import pageIconSrc from '../../../src/assets/images/selfie-circle.svg';
import arrowLeftSrc from '../../../src/assets/images/arrow-left.svg';
import IndexLink from '../partials/IndexLink'
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import { messagesSelector, currentThemeSelector } from '../../helpers/selectors';
import { connect } from 'react-redux';
import { getMessage as t } from '../../utils/utils';
import classnames from 'classnames';
import { USER_FORM_NAMES } from '../../constants/form';
import { documentPresent } from '../../helpers/components';
import FileField from './fields/file';

function validate(values = {}, props = {}) {
  if (!props.hideFileUpload && !documentPresent(values.selfieImage)) {
    return { selfieImage: true };
  }
}

class SelfieForm extends Component {
  getSubmitButtonLabel() {
    const { isSubscribePage, m } = this.props;
    return isSubscribePage ? t(m, 'generic.next') : t(m, 'generic.save');
  }

  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  render() {
    const {
      handleSubmit,
      m,
      userDataUpdating,
      hideFileUpload,
      theme
    } = this.props;

    const { selfie } = theme.icons || {};

    return (
      <form className="selfie-page" onSubmit={handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={selfie ? selfie : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'profile.section.selfie')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-fields">
              {hideFileUpload ? (
                <div className="scope-field-container">
                  <div className="scope-info-text">
                    {t(m, 'label.file.upload.only.mobile')}
                  </div>
                </div>
              ) : (
                <div className="scope-field-container">
                  <div className="scope-info-text">
                    {t(m, 'profile.selfie.section.info')}
                  </div>
                </div>
              )}
              {!hideFileUpload && (
                <FileField
                  name="selfieImage"
                  label={t(m, 'label.identity_card_selfie')}
                  mandatory
                />
              )}
            </div>
          </div>
        </div>
        {!hideFileUpload && (
          <div className="scope-content-footer">
            <button
              type="submit"
              className={classnames('scope-submit-button', 'primary-btn', {
                'sub-class-disabled': userDataUpdating
              })}
            >
              {this.getSubmitButtonLabel()}
            </button>
          </div>
        )}
      </form>
    );
  }
}

SelfieForm.defaultProps = {
  isSubscribePage: false
};

SelfieForm.propTypes = {
  isSubscribePage: PropTypes.bool
};

const reduxComponent = reduxForm({
  form: USER_FORM_NAMES.SELFIE,
  enableReinitialize: true,
  destroyOnUnmount: false,
  validate,
  fields: ['selfieImage']
})(SelfieForm);

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;
  return {
    m: messagesSelector(state),
    userDataUpdating,
    theme: currentThemeSelector(state)
  };
};

export default connect(mapStateToProps)(reduxComponent);
