import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';
import classnames from 'classnames';

import { checkShortId } from '../../helpers';
import { getErrorMsg } from '../../helpers/errors';
import { renderInviteCodeTextField } from './fields';
import _isEmpty from 'lodash/isEmpty';
import Loader from '../svg/Loader';
import InviteCode from '../svg/InviteCode';

import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/popup';
import * as UserActions from '../../actions/user';
import { connect } from 'react-redux';
import { currentThemeSelector, messagesSelector } from '../../helpers/selectors';
import { getMessage as t } from '../../utils/utils';

const validate = (values, props) => {
  const errors = {};

  const inviteCodeError = checkShortId(values.inviteCode, props.m);
  if (inviteCodeError) errors.inviteCode = inviteCodeError;

  return errors;
};

class InviteCodeForm extends Component {
  componentWillUnmount() {
    this.props.removeInviteError();
  }

  render() {
    const {
      m,
      handleSubmit,
      inviteError,
      inviteCodeFetching,
      brandColor,
      isInPopup
    } = this.props;

    const wrapperClass = classnames({
      'invite-wrapper': true,
      'invite-inline': !isInPopup
    });

    const inviteErrorEmpty = _isEmpty(inviteError);
    const actionsClass = classnames('text-center', { 'inline-actions': !isInPopup });

    return (
      <div className={wrapperClass}>
        {!isInPopup && (
          <div className="invite-title">
            <FormattedMessage id="service.corporate.intro" />
          </div>
        )}

        {inviteCodeFetching && _isEmpty(inviteError) && (
          <div className={classnames('text-center', 'invite-loader')}>
            <Loader className="svg-loader" color={brandColor} />
          </div>
        )}

        {!inviteCodeFetching && _isEmpty(inviteError) && (
          <form
            className={classnames('form-content', 'login-form', 'invite-code-form')}
            onSubmit={handleSubmit}
            noValidate
          >
            <Field
              name="inviteCode"
              component={renderInviteCodeTextField}
              placeholder={t(m, 'label.invite_code_placeholder')}
              icon={<InviteCode className="svg" color={brandColor} />}
              type="text"
            />
            <div className={actionsClass}>
              <button className="btn btn-md btn-accent primary-btn">
                <FormattedMessage id="label.apply" />
              </button>
            </div>
          </form>
        )}

        {!_isEmpty(inviteError) && !inviteCodeFetching && (
          <div className="error-msg error-resp">
            {getErrorMsg({ bundle: m, error: inviteError })}
          </div>
        )}

        {!inviteCodeFetching && !inviteErrorEmpty && (
          <div className={classnames(actionsClass, 'retry-action')}>
            <button
              className="btn btn-md btn-accent primary-btn"
              onClick={() => this.props.onRetry()}
            >
              <FormattedMessage id="label.edit.invite.code" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { inviteError, inviteCodeFetching } = state.user;

  const {
    colors: { primary: brandColor }
  } = currentThemeSelector(state);

  return { inviteError, inviteCodeFetching, brandColor, m: messagesSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions, ...UserActions }, dispatch);
}

const reduxComponent = reduxForm({
  form: 'invite-code-form',
  validate,
  fields: ['inviteCode']
})(InviteCodeForm);

export default connect(mapStateToProps, mapDispatchToProps)(reduxComponent);
