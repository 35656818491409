import classnames from 'classnames';

export function isError(meta) {
  const { touched, error } = meta || {};
  return touched && error;
}

export function getErrorInfo(meta) {
  if (isError(meta)) {
    return <div className={classnames('field-error-info', 'text')}>{meta.error}</div>;
  }
}
