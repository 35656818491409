import React, { Component } from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { renderRadioTabGroup, renderUsageType } from './fields';
import { FormattedMessage } from 'react-intl';
import { bookingTypeOptions, usageOptions } from '../../constants/options';

class TripType extends Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  renderUsagesType() {
    const { messages, contract, user } = this.props;
    if (contract.privateCarSharing && contract.businessCarSharing && user)
      return (
        <Field
          name="usageTypes"
          component={renderUsageType}
          options={usageOptions(messages)}
        />
      );
  }

  renderBookingType() {
    const { messages, contract } = this.props;
    if (
      contract.interfaceConfig.oneWayDisplay &&
      contract.interfaceConfig.returnTripDisplay
    )
      return (
        <Field
          name="tripType"
          component={renderRadioTabGroup}
          options={bookingTypeOptions(messages)}
        />
      );
  }

  handleClose() {
    document.dispatchEvent(new MouseEvent('click'));
  }

  handleCancel() {
    this.props.dispatch(reset('bookingType'));
    this.handleClose();
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <div>
        <form className="form-content" noValidate onSubmit={handleSubmit}>
          {this.renderUsagesType()}
          {this.renderBookingType()}
          <div className="actions-wrapper">
            <a onClick={this.handleCancel} className="secondary-bg">
              <FormattedMessage id="generic.cancel" />
            </a>
            <button
              className="btn-save primary-btn"
              type="submit"
              onClick={this.handleClose}
            >
              <FormattedMessage id="generic.save" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const fields = ['usageTypes', 'tripType'];

export default reduxForm({
  form: 'bookingType',
  fields: [fields]
})(TripType);
