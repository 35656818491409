import React from 'react';

export default function Clock(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.2 19.2" {...props}>
      <path d="M13,14.27a.55.55,0,0,0,.41.18.53.53,0,0,0,.35-.13.54.54,0,0,0,0-.76L10.14,9.4V2.88a.54.54,0,1,0-1.08,0V9.6a.59.59,0,0,0,.13.36Z" />
      <path d="M9.6,0a9.6,9.6,0,1,0,9.6,9.6A9.62,9.62,0,0,0,9.6,0Zm0,18.12A8.52,8.52,0,1,1,18.12,9.6,8.53,8.53,0,0,1,9.6,18.12Z" />
    </svg>
  );
}
