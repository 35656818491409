import _map from 'lodash/map';
import _mapValues from 'lodash/mapValues';
import _get from 'lodash/get';
import _isArray from 'lodash/isArray';
import _assign from 'lodash/assign';
import _isString from 'lodash/isString';
import _deburr from 'lodash/deburr';
import _capitalize from 'lodash/capitalize';
import _isObject from 'lodash/isObject';
import _isEmpty from 'lodash/isEmpty';
import _forEachRight from 'lodash/forEachRight';
import _set from 'lodash/set';
import _includes from 'lodash/includes';
import _forEach from 'lodash/forEach';
import _isElement from 'lodash/isElement';
import _cloneDeepWith from 'lodash/cloneDeepWith';
import memoizeOne from 'memoize-one';
import getSymbolFromCurrency from 'currency-symbol-map';

import config from '../constants/config';
import moment from 'moment';
import store from '../store';

import {
  FILE_STATUS_BACK,
  FILE_STATUS_FRONT,
  FILE_STATUS_SELFIE,
  VEHICLE_TYPE_COMMERCIAL,
  VEHICLE_TYPE_PASSENGER
} from '../constants/backend';
import {
  BROWSER_OS_ANDROID,
  BROWSER_OS_APPLE_MOBILE,
  BROWSER_OS_WINDOWS_PHONE,
  RMF_companies
} from '../constants/staticData';

import { isValidElement as isReactElement } from 'react';
import carPlaceholder from './../assets/images/car-placeholder.svg';
import _find from 'lodash/find';
import { setDeepRouterQuery } from '../helpers';
import { browserHistory, historyToNavigate } from '../helpers/components';
import { currencyFormats } from '../constants/currencies';
import { multiSpace } from '../constants/regex';
import { book, creation as cr } from '../constants/routes';
import { matchPath } from 'react-router-dom';

import {
  isAppliedUserSelector,
  isExternalIncompleteUserSelector,
  isProfileIncompleteUserSelector,
  isRegisterIncompleteUserSelector
} from '../helpers/selectors';

// checks if passed value is empty
export function isEmpty(value) {
  return value === undefined || value === null || value === '' || Number.isNaN(value);
}

export function isValidId(id) {
  const regexGuid =
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return regexGuid.test(id);
}

function formatGtmDate(startDate, endDate) {
  let calculation = {
    startDate: null,
    endDate: null,
    duration: null
  };

  const momentStartDate = moment(startDate);
  const momentEndDate = moment(endDate);

  if (momentStartDate && momentEndDate) {
    let minutes = momentEndDate.diff(momentStartDate, 'm');
    let hours = momentEndDate.diff(momentStartDate, 'h');

    if (hours > 0) minutes = Math.abs(hours * 60 - minutes);
    hours = hours < 10 ? '0' + hours : hours > 99 ? '??' : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    calculation.duration = `${hours}:${minutes}`;
  }

  if (momentStartDate) calculation.startDate = momentStartDate.format('DD/MM/YYYY HH:mm');
  if (momentEndDate) calculation.endDate = momentEndDate.format('DD/MM/YYYY HH:mm');

  return calculation;
}

export function gtmPushBasicBookingData(data, numberOfVehicles = '', userId = '') {
  const response = _get(data, 'payload.data');
  const dataSent = _get(data, 'meta.previousAction.payload.request.data');
  const gtmDateObject = formatGtmDate(
    _get(dataSent, 'start.date'),
    _get(dataSent, 'end.date')
  );
  const price = _get(response, 'carSharingInfo.cost.estimatedPriceForDuration');

  let searchData = {
    event: 'booking Confirmation' // capital C
  };

  if (price) {
    const parsedPrice = parsePrice(price, getBookingCurrency(response));
    if (parsedPrice) searchData.Price = parsedPrice;
  }

  if (_get(dataSent, 'vehicle.model'))
    searchData.VehicleModel = _deburr(_capitalize(dataSent.vehicle.model));
  if (numberOfVehicles) searchData.NumberOfVehicles = numberOfVehicles;
  if (gtmDateObject.startDate) searchData.StartDay = gtmDateObject.startDate;
  if (gtmDateObject.endDate) searchData.EndDay = gtmDateObject.endDate;
  if (gtmDateObject.duration) searchData.Duration = gtmDateObject.duration;
  if (_get(dataSent, 'start.address.postalCode'))
    searchData.Location = dataSent.start.address.postalCode;
  else if (_get(dataSent, 'start.address.city'))
    searchData.Location = dataSent.start.address.city;
  if (_get(dataSent, 'vehicle.type') === VEHICLE_TYPE_PASSENGER)
    searchData.VehicleCategory = 'Tourisme';
  if (_get(dataSent, 'vehicle.type') === VEHICLE_TYPE_COMMERCIAL)
    searchData.VehicleCategory = 'Utilitaire';

  gtmPush(searchData, userId);
}

export function gtmPushBasicSearchData(data, searchFormValues, userId = '') {
  const response = _get(data, 'payload.data.results');
  const dataSent = _get(data, 'meta.previousAction.payload.request.data');
  const gtmDateObject = formatGtmDate(
    _get(dataSent, 'start.date'),
    _get(dataSent, 'end.date')
  );
  const postalCode = _get(searchFormValues, 'location.postalCode');
  const city = _get(searchFormValues, 'location.city');

  let searchData = {
    NumberOfVehicles: _get(response, 'length', 0),
    event: 'booking step 1'
  };

  if (postalCode) searchData.Location = postalCode;
  else if (city) searchData.Location = city;
  if (gtmDateObject.startDate) searchData.StartDay = gtmDateObject.startDate;
  if (gtmDateObject.endDate) searchData.EndDay = gtmDateObject.endDate;
  if (gtmDateObject.duration) searchData.Duration = gtmDateObject.duration;
  if (_get(dataSent, 'vehicleType') === VEHICLE_TYPE_PASSENGER)
    searchData.VehicleCategory = 'Tourisme';
  if (_get(dataSent, 'vehicleType') === VEHICLE_TYPE_COMMERCIAL)
    searchData.VehicleCategory = 'Utilitaire';

  gtmPush(searchData, userId);
}

export function gtmPush(eventObj, userId = '') {
  if (!getAppObj().gtmId) return;
  const userStatus = { loginStatus: userId ? 'Loggedin' : 'Not Loggedin' };
  window.dataLayer = window.dataLayer || [];
  if (userId) userStatus.customerID = userId;
  window.dataLayer.push(_assign(eventObj, userStatus));
}

const getUserAgent = () => safe(() => navigator.userAgent);

const getOperatingSystemMem = memoizeOne((userAgent) => {
  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) return BROWSER_OS_WINDOWS_PHONE;
  if (/android/i.test(userAgent)) return BROWSER_OS_ANDROID;
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)
    return BROWSER_OS_APPLE_MOBILE;
  return false;
});

export const getOperatingSystem = () => getOperatingSystemMem(getUserAgent());

// trim strings in collection | returns new collection | not recursive
export function trimStrings(collection) {
  const iterator = _isArray(collection) ? _map : _mapValues;
  return iterator(collection, (string) => (_isString(string) ? string.trim() : string));
}

// return passed value or object | will return undefined if 'any' is empty
export function nulify(any) {
  const checker = _isObject(any) ? _isEmpty : isEmpty;
  return checker(any) ? undefined : any;
}

// add container to JSON property / if all container properties isEmpty return udnefined / delete empty properties
export function append(collection) {
  const isArray = _isArray(collection);
  let index = isArray ? collection.length - 1 : 0;

  _forEachRight(collection, (value, key) => {
    if (isEmpty(value)) isArray ? collection.splice(index, 1) : delete collection[key];
    --index;
  });
  return _isEmpty(collection) ? undefined : collection;
}

// works like lodash _.set except it will not create path if value is empty
export function trySet(object, path, value, defaultValue) {
  const emptyValue = nulify(value) === undefined;
  if (emptyValue) {
    const emptyDefault = defaultValue === undefined;
    if (emptyDefault) return object;
    value = defaultValue;
  }
  return _set(object, path, value);
}

// trimFields - String (field name) or Array of Strings | function will mutate object
export function trimFields(fields, excludeFromTrim) {
  function notExcluded(field, key) {
    let toTrim = true;

    if (excludeFromTrim) {
      if (_isArray(excludeFromTrim)) toTrim = !_includes(excludeFromTrim, key);
      else toTrim = key !== excludeFromTrim;
    }

    if (_isString(field)) return toTrim;
    else if (toTrim) trimFields(field);
  }

  if (_isObject(fields)) {
    _forEach(fields, (field, key) => {
      if (notExcluded(field, key)) fields[key] = field.trim();
    });
  }

  return fields;
}

export function isHtmlElement(value) {
  return isReactElement(value) || _isElement(value);
}

export function safeDeepClone(object) {
  return _cloneDeepWith(object, (value) => {
    if (isHtmlElement(value)) return null;
  });
}

export function strToBool(value) {
  return value === 'true';
}

export function addSepPart(sep, part) {
  return part ? sep + part : '';
}

export function getFileExt(fileName) {
  if (fileName) {
    const extIndex = fileName.lastIndexOf('.');
    if (extIndex > 0) return fileName.substring(extIndex + 1);
  }
}

export function addStrPart(part, sep = ', ') {
  return part ? part + sep : '';
}

export function getFormattedAddress({
  streetName,
  postalCode,
  city,
  countryIso,
  streetNumber
}) {
  const space = ' ';
  const coma = ', ';
  const end = '';

  return (
    addStrPart(streetNumber, space) +
    addStrPart(streetName, coma) +
    addStrPart(postalCode, space) +
    addStrPart(city, coma) +
    addStrPart(countryIso, end)
  );
}

export function toYearDateApi(any) {
  if (any) {
    const momentDate = moment(any);
    const validDate = momentDate.isValid();
    if (validDate) return momentDate.format('YYYY-MM-DD');
  }
}

export function getFileIdFromField(field) {
  if (field) return field.fileId;
}

export function setSelfieFile(fileId) {
  if (fileId) {
    return {
      type: FILE_STATUS_SELFIE,
      fileId
    };
  }
}

export function setFrontFile(fileId) {
  if (fileId) {
    return {
      type: FILE_STATUS_FRONT,
      fileId
    };
  }
}

export function setBackFile(fileId) {
  if (fileId) {
    return {
      type: FILE_STATUS_BACK,
      fileId
    };
  }
}

export function handleCarImageFail(e, theme = {}) {
  const { vehiclePlaceholder } = theme.icons || {};
  e.target.onerror = null;
  e.target.src = vehiclePlaceholder ? vehiclePlaceholder : carPlaceholder;
}

export function getShortId(id = '') {
  const splitArray = id.split('-');
  return splitArray[0];
}

export function getMessage(messages, id) {
  return messages[id] || id;
}

export function getMessageOptions(messages, options, id) {
  return getMessage(messages, getMessage(options, id));
}

export function addToIndex(array = [], index, data) {
  if (index >= 0) array[index] = data;
  else array.push(data);
}

export function jsonParseSafe(text) {
  return safe(() => JSON.parse(text));
}

export function localStorageGetJson(key) {
  return safe(() => JSON.parse(localStorage.getItem(key)));
}

export function sessionStorageSetJson(key, data) {
  safe(() => sessionStorage.setItem(key, JSON.stringify(data)));
}

export function sessionStorageGetJson(key) {
  return safe(() => JSON.parse(sessionStorage.getItem(key)));
}

export function checkSearchExternalErrors(externalsErrors) {
  let nextFieldFocus = { ...externalsErrors };

  for (const error in externalsErrors) {
    if (
      _isString(externalsErrors[error]) ||
      (externalsErrors[error] === null && (error === 'st' || error === 'et'))
    ) {
      nextFieldFocus[error] = externalsErrors[error];
    } else if (
      typeof externalsErrors[error] === 'undefined' ||
      (externalsErrors[error] === null && (error === 'sd' || error === 'ed'))
    ) {
      delete nextFieldFocus[error];
    }
  }

  return nextFieldFocus;
}

export function setQueryValues(data) {
  browserHistory.replace({
    pathname: window.location.pathname,
    query: setDeepRouterQuery(data)
  });
}

export const findUserCountry = memoizeOne((mapCountries, userCompanyId) => {
  return _find(mapCountries, (country = {}) => {
    return country.companyId === userCompanyId;
  });
});

export const fallbackFunc = () => undefined;
export const fallbackObj = Object.freeze({});
export const fallbackArray = Object.freeze([]);

// localhost
export const isLocalEnv = () => {
  return process.env.NODE_ENV === 'development';
};

// dev, valid, staging
export const isTestEnv = () => {
  const { env } = config;
  return env && env !== 'prod';
};

// all 'dev' envs
export const isDevEnv = () => {
  return isTestEnv() || isLocalEnv();
};

// check for 'Renault Mobility France' companyId
export function isRMF_companyId(companyId) {
  return RMF_companies[config.env] === companyId;
}

export const createGetFileTypeFinder = (typeNeeded) => {
  return (file) => {
    const { type: fileType } = file || {};
    return fileType === typeNeeded;
  };
};

export const objGetFirstKey = (obj) => {
  // noinspection LoopStatementThatDoesntLoopJS
  for (const objKey in obj) {
    return objKey;
  }
};

export const objGetFirstValue = (obj) => {
  return obj[objGetFirstKey(obj)];
};

export function navigateToCreationPage(companyId) {
  const state = store.getState();
  const isAppliedUser = isAppliedUserSelector(state);
  const isRegisterIncompleteUser = isRegisterIncompleteUserSelector(state);
  const isProfileIncompleteUser = isProfileIncompleteUserSelector(state);
  const isExternalIncompleteUser = isExternalIncompleteUserSelector(state);

  if (isExternalIncompleteUser || isProfileIncompleteUser) {
    navigateToCreationInfo(companyId);
  } else if (isRegisterIncompleteUser) {
    if (isAppliedUser) navigateToCreationActivateAccount(companyId);
    else navigateToCreationInfo(companyId);
  } else {
    navigateToCreationCredentials(companyId);
  }
}

export function navigateToCreationCredentials(companyId) {
  browserHistory.push(`/${cr.container}/${cr.credentials}/${companyId}`);
}

export function navigateToCreationActivateAccount(companyId) {
  browserHistory.push(`/${cr.container}/${cr.activate}/${companyId}`);
}

export function navigateToCreationInfo(companyId, replace) {
  historyToNavigate(`/${cr.container}/${cr.info}/${companyId}`, replace);
}

export function navigateToCreationDrivingLicense(companyId) {
  browserHistory.push(`/${cr.container}/${cr.files}/${cr.drivingLicense}/${companyId}`);
}

export function navigateToCreationIdentityDocument(companyId, replace) {
  historyToNavigate(
    `/${cr.container}/${cr.files}/${cr.identityDocument}/${companyId}`,
    replace
  );
}

export function navigateToCreationSelfie(companyId) {
  browserHistory.push(`/${cr.container}/${cr.files}/${cr.selfie}/${companyId}`);
}

export function navigateToCreationMoreInfo(companyId, replace) {
  historyToNavigate(`/${cr.container}/${cr.moreInfo}/${companyId}`, replace);
}

export function navigateToCreationSuccess(replace) {
  historyToNavigate(`/${cr.container}/${cr.success}`, replace);
}

export function navigateToHomePage() {
  browserHistory.push('/');
}

export function navigateToBookings() {
  browserHistory.push('/my-bookings');
}

export function navigateToWelcomePage() {
  browserHistory.push('/welcome-message');
}

const isLastCharSlash = (str) => str[str.length - 1] === '/';

export function comparePage(
  page,
  { useIncludes = false, pathname = window.location.pathname } = {}
) {
  const root = pathname === '/';

  if (!root && isLastCharSlash(pathname)) {
    pathname = pathname.slice(0, -1);
  }

  return useIncludes ? pathname.includes(page) : pathname === page;
}

export function isHomePage() {
  return comparePage('/');
}

export function isBookingPage() {
  return comparePage(`/${book}`);
}

export function isSwitchPage() {
  return comparePage('/profile/switch');
}

export function isSubscribePage() {
  return comparePage('/creation/', { useIncludes: true });
}

export function isCreationPage() {
  return matchPath(`${cr.container}/*`, window.location.pathname);
}

export function isCreationSuccessPage() {
  return matchPath(`${cr.container}/${cr.success}`, window.location.pathname);
}

export function isPasswordUpdatePage() {
  return comparePage('/password-update');
}

export function getBookingCurrency(booking, fallback = 'EUR') {
  const { carSharingInfo } = booking || {};
  const { cost } = carSharingInfo || {};
  const { currency } = cost || {};
  return currency || fallback;
}

export function removeQueryFromUrl() {
  browserHistory.replace({
    pathname: window.location.pathname
  });
}

export function safe(f) {
  try {
    return f();
  } catch (e) {}
}

export function getAppObj() {
  return safe(() => window.APP) || {};
}

export function serialize(data) {
  let query = '';

  for (const param in data || {}) {
    const value = data[param];

    if (!isEmpty(value)) {
      if (query) query += '&';
      query += encodeURIComponent(param) + '=' + encodeURIComponent(value);
    }
  }

  return query;
}

export function addQuery(data) {
  data = serialize(data);
  return data ? '?' + data : '';
}

// quick validator
export function validNumber(number) {
  return number || number === 0;
}

// currency symbol converter
export function getCurrencySymbol(currency) {
  return getSymbolFromCurrency(currency) || currency || '€';
}

// raw price parser
export function parsePrice(price, currency = 'EUR') {
  const defined = currencyFormats[currency];
  if (defined) return defined(price).format();
  return currencyFormats.DEFAULT(price, getCurrencySymbol(currency)).format();
}

// main price getter
export function getPrice(price, currency) {
  if (validNumber(price)) return parsePrice(price, currency);
}

export function getSavedLanguage() {
  return safe(() => window.localStorage.getItem('LOCALE'));
}

export function getNavigatorLanguage() {
  return safe(() => navigator.language.substring(0, 2));
}

export function getNavigatorCountry() {
  return safe(() => navigator.language.split('-')[1]);
}

export function getDefaultLocale() {
  return getSavedLanguage() || getNavigatorLanguage() || 'en';
}

// use .call | don't pass pp on init
export function newProp(pp, prop) {
  const { [prop]: prev } = pp || {};
  const { [prop]: next } = this.props;
  return prev !== next || !pp;
}

// improved version of 'newProps'
// use .call | don't pass pp on init
export function testProp(pp, testProps) {
  for (let prop in testProps) {
    const { [prop]: prev } = pp || {};
    const { [prop]: next } = this.props;

    if (prev !== next || !pp) {
      return true;
    }
  }
}

export function removeMultiSpace(str) {
  return str.replace(multiSpace, ' ').trim();
}

// works for obj and array
export const isObjEmpty = (obj) => {
  for (const i in obj || {}) return false;
  return true;
};

export const getPrevAction = (action) => {
  return action?.meta?.previousAction || {};
};
