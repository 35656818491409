import { showPrivacyPolicy, showTOS, showTOU } from './components';
import { getLocationProp } from '../components/partials/LocationInput/utils';
import {
  addToIndex,
  createGetFileTypeFinder,
  getFileIdFromField,
  getFormattedAddress,
  setBackFile,
  setFrontFile,
  setSelfieFile,
  toYearDateApi
} from '../utils/utils';
import { cleanUserDataForApi, formatBirthDateForApi } from './index';
import {
  FILE_STATUS_BACK,
  FILE_STATUS_FRONT,
  FILE_STATUS_SELFIE
} from '../constants/backend';
import _findIndex from 'lodash/findIndex';

function formatManagerEmail(payload = {}, formValues = {}, companyData = {}) {
  const { managerEmail } = formValues;
  const { sendEmailsToManager } = companyData;

  if (sendEmailsToManager) payload.managerEmail = managerEmail;

  return payload;
}

export function formatSpecialFieldsForApiCall(
  payload = {},
  formValues = {},
  companyData = {}
) {
  payload = { ...payload };
  payload = formatManagerEmail(payload, formValues, companyData);

  return payload;
}

export function formatAcceptTermsForApiCall(payload, acceptTerms, registerCompany) {
  const { termsOfUse, termsOfSubscription, privacyPolicy, commercialPurposes } =
    acceptTerms;

  const data = {
    ...payload,
    commercialOffers: commercialPurposes,
    termsAndConditionsAcceptance: {
      termsOfUseAccepted: termsOfUse,
      termsOfSubscriptionAccepted: termsOfSubscription,
      privacyPolicyRead: privacyPolicy
    }
  };

  if (registerCompany) {
    const fields = data.termsAndConditionsAcceptance;

    if (!showTOU(registerCompany)) {
      delete fields.termsOfUseAccepted;
    }

    if (!showTOS(registerCompany)) {
      delete fields.termsOfSubscriptionAccepted;
    }

    if (!showPrivacyPolicy(registerCompany)) {
      delete fields.privacyPolicyRead;
    }
  }

  return data;
}

export function getErrorStatus(action) {
  const { error: errorResp } = action;
  const { response } = errorResp || {};
  const { status: errorStatus } = response || {};

  return errorStatus;
}

export function parseAddressForApi(formValues) {
  const { address, city, postalCode, country } = formValues;
  const streetName = getLocationProp(address, 'streetName');
  const countryISO = getLocationProp(country, 'countryISO');

  return {
    city,
    country: countryISO,
    formattedAddress: getFormattedAddress({
      streetName,
      city,
      postalCode,
      countryIso: countryISO
    }),
    postalCode,
    streetName
  };
}

export function formatPersonalDetailsForApiCall(userData = {}, formValues = {}) {
  const { phoneNumber, civility, birthDate, firstName, lastName } = formValues;
  const { phonePrefix, phoneSuffix } = phoneNumber || {};

  const requiredData = {
    ...userData,
    firstName,
    lastName,
    civility,
    birthDate: formatBirthDateForApi(birthDate),
    address: parseAddressForApi(formValues),
    phoneNumber: {
      countryCode: phonePrefix,
      nationalNumber: phoneSuffix
    }
  };

  return cleanUserDataForApi(requiredData);
}

export function formatDrivingLicenseForApiCall(userData, formValues) {
  const {
    licenceNumber,
    deliveranceDate,
    cityDeliverance,
    expirationDateNotPresent,
    expirationDate,
    documentFront: drivingFront,
    documentBack: drivingBack
  } = formValues;

  const newData = {
    ...userData,
    drivingLicence: {
      licenceNumber,
      cityDeliverance: getLocationProp(cityDeliverance, ['city', 'streetName']),
      deliveranceDate: toYearDateApi(deliveranceDate),
      expirationDate: toYearDateApi(!expirationDateNotPresent && expirationDate),
      files: [
        setFrontFile(getFileIdFromField(drivingFront)),
        setBackFile(getFileIdFromField(drivingBack))
      ]
    }
  };

  return cleanUserDataForApi(newData);
}

export function formatIdentityDocumentForApiCall(userData = {}, formValues = {}) {
  const { documentFront: identityFront, documentBack: identityBack } = formValues;
  const { identityDocument } = userData;
  const { files } = identityDocument || {};
  const newFiles = files ? [...files] : [];

  const findFront = createGetFileTypeFinder(FILE_STATUS_FRONT);
  const findBack = createGetFileTypeFinder(FILE_STATUS_BACK);

  const frontFile = setFrontFile(getFileIdFromField(identityFront));
  const backFile = setBackFile(getFileIdFromField(identityBack));

  const frontFileIndex = _findIndex(newFiles, findFront);
  const backFileIndex = _findIndex(newFiles, findBack);

  addToIndex(newFiles, frontFileIndex, frontFile);
  addToIndex(newFiles, backFileIndex, backFile);

  const newData = { ...userData, identityDocument: { files: newFiles } };
  return cleanUserDataForApi(newData);
}

export function formatSelfieForApiCall(userData = {}, formValues = {}) {
  const { selfieImage } = formValues;
  const { identityDocument } = userData;
  const { files } = identityDocument || {};
  const newFiles = files ? [...files] : [];

  const findSelfie = createGetFileTypeFinder(FILE_STATUS_SELFIE);
  const selfieFile = setSelfieFile(getFileIdFromField(selfieImage));
  const selfieFileIndex = _findIndex(newFiles, findSelfie);

  addToIndex(newFiles, selfieFileIndex, selfieFile);

  const newData = { ...userData, identityDocument: { files: newFiles } };
  return cleanUserDataForApi(newData);
}
