import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import headIcon from '../../assets/images/action-validated.svg';
import IndexLink from '../partials/IndexLink';
import { AddToCalendarButton } from 'add-to-calendar-button-react';
import * as BookingActions from '../../actions/booking';
import { addToCalendarDataSelector, productNameSelector } from '../../helpers/selectors';
import { getShortId } from '../../utils/utils';
import { cleanDeep } from '../../utils/cleanDeep';
import _capitalize from 'lodash/capitalize';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import { ADD_TO_CALENDAR } from '../../constants/generic';
import calendarButtonStyle from '../../assets/styles/libs/calendarButtonStyle';

function convertToTimeZone(date = '') {
  const offset = date.substring(19, 20) === '-' ? '+' : '-';
  const zone = parseInt(date.substring(20, 22) || 0, 10);
  return `Etc/GMT${offset}${zone}`;
}

export class BookingSuccessPopup extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
  }

  componentDidMount() {
    this.appendClass();
  }

  appendClass() {
    const calendarEl = document.getElementsByClassName('atcb-light')[0];

    if (calendarEl) {
      const sheet = new CSSStyleSheet();
      sheet.replaceSync(calendarButtonStyle);
      calendarEl.shadowRoot.adoptedStyleSheets = [sheet];
    }
  }

  setCallbacks() {
    const getCalendarEventMem = memoizeOne((addToCalendarData, productName, intl) => {
      const { formatMessage } = intl || {};
      const { vehicle, start, end, id } = addToCalendarData || {};
      const { brand, model, registrationNumber: plateNumber } = vehicle || {};
      const { address, date: startDate } = start || {};
      const { date: endDate } = end || {};
      const { formattedAddress } = address || {};

      const vehicleText = _capitalize(formatMessage({ id: 'label.car' }));
      const plateNumberText = formatMessage({ id: 'label.plateNumber' });
      const carSharingText = formatMessage({ id: 'label.carSharing' });
      const bookingText = formatMessage({ id: 'label.booking' });

      function getTitle() {
        const titlePrefix = productName || carSharingText;
        const bookingPart = bookingText.toLowerCase();
        return `${titlePrefix} ${bookingPart}`;
      }

      function getDesc() {
        let desc = [];
        if (vehicle) desc.push(`${vehicleText}: ${brand} ${model}`);
        if (plateNumber) desc.push(`${plateNumberText}: ${plateNumber}`);
        if (id) desc.push(`${bookingText} ID: ${getShortId(id)}`);
        return desc.join(', ');
      }

      const dateFormat = ADD_TO_CALENDAR.DATE_FORMAT;
      const timeFormat = ADD_TO_CALENDAR.TIME_FORMAT;

      const data = {
        name: getTitle(),
        description: getDesc(),
        location: formattedAddress,
        startDate: moment(startDate).format(dateFormat),
        startTime: moment(startDate).format(timeFormat),
        endDate: moment(endDate).format(dateFormat),
        endTime: moment(endDate).format(timeFormat),
        timeZone: convertToTimeZone(startDate)
      };

      return cleanDeep(data);
    });
    this.getCalendarEvent = () => {
      const { addToCalendarData, productName, intl } = this.props;
      return getCalendarEventMem(addToCalendarData, productName, intl);
    };
  }

  render() {
    const { onCloseRequested } = this.props;
    const { formatMessage } = this.props.intl;
    const addToCalendarLabel = formatMessage({ id: 'booking.add.calendar' });

    return (
      <div
        className={classnames(
          'pt-15',
          'pl-20',
          'pr-20',
          'pb-30',
          'text-center',
          'booking-success-popup'
        )}
      >
        <p className="bold mt-xs-25 mt-dt-35 f-25 mb-dt-25 mb-xs-15">
          <img className="head-icon" src={headIcon} alt="" />
        </p>
        <p className="bold f-20">
          <FormattedMessage id="booking.success.title" />
        </p>
        <p className="f-15 mt-20">
          <FormattedMessage id="booking.success.sub.title" />
        </p>
        <div className="mt-40 hr-dashed" />
        <Row className="mt-35">
          <Col xs={12} sm={6} md={6} className="pr-dt-10 mb-xs-10">
            <div
              onClick={onCloseRequested}
              className={classnames('scope-button-container', 'sub-class-left')}
            >
              <IndexLink
                to="/my-bookings"
                activeClassName="active"
                className={classnames('scope-button', 'scope-button-main', 'primary-btn')}
              >
                <FormattedMessage id="label.my_reservations" />
              </IndexLink>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} className="pl-dt-10">
            <div className={classnames('scope-button-container', 'sub-class-right')}>
              <AddToCalendarButton
                {...this.getCalendarEvent()}
                options={ADD_TO_CALENDAR.OPTIONS}
                label={addToCalendarLabel}
                lightMode="light"
                listStyle="modal"
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    productName: productNameSelector(state),
    addToCalendarData: addToCalendarDataSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingActions }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BookingSuccessPopup));
