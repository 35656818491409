import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import classnames from 'classnames';

function isLegacySize(size) {
  return size === 'legacy';
}

function setSizeProp(size) {
  if (!isLegacySize(size)) return size;
}

function setLegacySize(size) {
  if (isLegacySize(size)) return size;
}

function isHtmlChildren(component) {
  const { type } = component;
  return typeof type === 'string';
}

function getChildren(children, onCloseRequested) {
  if (children) {
    if (isHtmlChildren(children)) {
      return children;
    } else {
      return React.cloneElement(children, {
        onCloseRequested: onCloseRequested
      });
    }
  }
}

const Popup = ({
  isOpen,
  onCloseRequested,
  id,
  size,
  dark,
  backdropClass,
  outerClose,
  children,
  isError,
  buttonPositionStatic
}) => {
  return (
    <div className={classnames('modal-parent', 'sub-' + id)}>
      {outerClose && (
        <button
          type="button"
          className={isOpen ? 'outer-close' : 'hide'}
          onClick={onCloseRequested}
        />
      )}
      <Modal
        backdropClassName={classnames(backdropClass, { isError })}
        className={classnames({
          dark,
          isError,
          buttonPositionStatic,
          legacySize: setLegacySize(size)
        })}
        dialogClassName={id}
        onHide={onCloseRequested}
        size={setSizeProp(size)}
        animation={true}
        show={isOpen}
        id={id}
      >
        <div className="close-button-wrap">
          <button type="button" className="close" onClick={onCloseRequested}>
            <i className="icon-cross" />
          </button>
        </div>
        {getChildren(children, onCloseRequested)}
      </Modal>
    </div>
  );
};

Popup.defaultProps = {
  size: 'legacy'
};

Popup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCloseRequested: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  size: PropTypes.string,
  outerClose: PropTypes.bool,
  children: PropTypes.object.isRequired,
  buttonPositionStatic: PropTypes.bool
};

export default Popup;
