import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  activeProfileSelector,
  inactiveProfilesSelector,
  userProfilesSelector,
  messagesSelector,
} from '../../helpers/selectors';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import * as PopupActions from '../../actions/popup';
import * as CustomFieldsActions from '../../actions/customFields';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import arrowLeftSrc from '../../assets/images/arrow-left.svg';
import profileIcon from '../../assets/images/profile-shield.svg';
import IndexLink from '../partials/IndexLink';
import { addErrorMsg } from '../../utils/flashMessage/creator';
import { getErrorMsg } from '../../helpers/errors';
import { getAppObj } from '../../utils/utils';

class SwitchProfile extends Component {
  constructor(props) {
    super(props);
    this.initState();
  }

  initState() {
    this.state = { switchInProgress: false };
  }

  showActiveProfileButton() {
    const { superCompanyName } = this.props.activeProfile || {};

    if (superCompanyName) {
      return (
        <div className="scope-field-container">
          <div className="scope-field-label">
            <FormattedMessage id="label.your.active.profile" />
          </div>
          <div
            className={classnames(
              'scope-button',
              'scope-active-account',
              'scope-pseudo-btn'
            )}
          >
            <span className="scope-button-content">
              <img src={profileIcon} alt="profile-icon" />
              <span className="scope-button-name">{superCompanyName}</span>
            </span>
            <span className="scope-button-dot" />
          </div>
          <div className="scope-button">
            <span className="anonymize" onClick={() => this.handleConfirmAnonym()}>
              <FormattedMessage id="label.profile.anonymize" />
            </span>
          </div>
        </div>
      );
    }
  }

  handleConfirmAnonym() {
    this.props.popup_AnonymConfirm_SetVisibility(true);
  }

  openRegisterModal() {
    this.props.popupStep_AddAccount_Show();
  }

  showErrorMsg(data) {
    addErrorMsg(
      getErrorMsg({
        bundle: this.props.m,
        error: ((data.error || {}).response || {}).data
      })
    );
  }

  activateProfile(profile) {
    const { id } = profile || {};

    this.setState({ switchInProgress: true });
    this.props.switchUserProfile(id).then((data) => {
      if (data.type === 'user/SWITCH_PROFILE_SUCCESS') {
        this.props.getUserProfiles().then(() => {
          this.props.getUserInfo().then((data) => {
            this.setState({ switchInProgress: false });
            if (data.type === 'user/GET_INFO_SUCCESS') {
              getAppObj().incompleteChecked = false;
            }
          });
        });
      } else {
        this.showErrorMsg(data);
        this.setState({ switchInProgress: false });
      }
    });
  }

  getOtherProfilesButtons() {
    return this.props.inactiveProfiles.map((profile) => {
      const { superCompanyName, id } = profile || {};

      return (
        <button
          key={id}
          className={classnames('btn', 'secondary-btn', 'scope-button')}
          onClick={() => this.activateProfile(profile)}
        >
          <span className="scope-button-content">
            <img src={profileIcon} alt="profile-icon" />
            <span className="scope-button-name">{superCompanyName}</span>
          </span>
          <span className="scope-button-dot" />
        </button>
      );
    });
  }

  showOtherProfiles() {
    if (this.props.inactiveProfiles) {
      return (
        <div className={classnames('scope-field-container', 'scope-other-accounts')}>
          <div className="scope-field-label">
            <FormattedMessage id="label.your.other.profiles" />
          </div>
          {this.getOtherProfilesButtons()}
        </div>
      );
    }
  }

  displaySwitchProgress() {
    if (this.state.switchInProgress) {
      return (
        <div>
          <div className="scope-progress-overlay" />
          <div className="scope-progress-msg">
            <FormattedMessage id="info.switching.profile" />
            <span>&nbsp;...</span>
          </div>
        </div>
      );
    }
  }

  render() {
    return (
      <div className="switch-profile-page">
        <div className="scope-content-header">
          <div className="scope-content-header-title-container">
            <IndexLink to="/profile" className="scope-content-header-button-return">
              <ReactSVG className="react-svg" src={arrowLeftSrc} />
            </IndexLink>
            <div className="scope-content-header-title">
              <FormattedMessage id="label.switch.profile" />
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-field-container">
            <div className="scope-info-text">
              <FormattedMessage
                id="info.switch.profile"
                values={{
                  strong: (chunks) => <strong>{chunks}</strong>
                }}
              />
            </div>
          </div>
          <div className="scope-section">
            <div className="scope-section-fields">
              {this.showActiveProfileButton()}
              {this.showOtherProfiles()}
            </div>
          </div>
        </div>
        <div className="scope-content-footer">
          <button
            disabled={!this.props.profilesFetched}
            className={classnames('scope-submit-button', 'primary-btn')}
            onClick={() => this.openRegisterModal()}
          >
            <FormattedMessage id="label.add.new.profile" />
          </button>
        </div>
        {this.displaySwitchProgress()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    profilesFetched: userProfilesSelector(state),
    inactiveProfiles: inactiveProfilesSelector(state),
    activeProfile: activeProfileSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { ...UserActions, ...PopupActions, ...CustomFieldsActions },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchProfile);
