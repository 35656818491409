import React, { Component } from 'react';
import { connect } from 'react-redux';
import PersonalDetailsForm from './PersonalDetailsForm';
import {
  messagesSelector,
  personalDetailsInitialValuesSelector,
  userDataSelector,
  userIdSelector
} from '../../../helpers/selectors';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../../actions/user';
import { displayProfileUpdateSuccess } from '../../../helpers/components';
import { formatPersonalDetailsForApiCall } from '../../../helpers/formatters';

class PersonalDetails extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.updatePersonalInfo = this.updatePersonalInfo.bind(this);
  }

  updatePersonalInfo(formValues) {
    const { updateInformations, userId, userData, userDataUpdating, m } = this.props;

    if (!userDataUpdating) {
      updateInformations(
        userId,
        formatPersonalDetailsForApiCall(userData, formValues)
      ).then((data) => {
        if (data.type === 'user/UPDATE_INFO_SUCCESS') {
          displayProfileUpdateSuccess(m, 'profile.section.personal.info');
        }
      });
    }
  }

  render() {
    const { initialValues } = this.props;

    return (
      <PersonalDetailsForm
        onSubmit={this.updatePersonalInfo}
        initialValues={initialValues}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;

  return {
    userDataUpdating,
    initialValues: personalDetailsInitialValuesSelector(state),
    m: messagesSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state)
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PersonalDetails);
