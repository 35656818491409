import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { checkCircle, showErrorInfo } from '../forms/newFields';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getMessage as t } from '../../utils/utils';

import {
  acceptTermsValuesSelector,
  messagesSelector,
  sharedCompanySelector
} from '../../helpers/selectors';

import { showTOU, showTOS, showPrivacyPolicy } from '../../helpers/components';
import { scrollToErrorInfo } from '../../helpers/scrollToError';
import { USER_FORM_NAMES } from '../../constants/form';
import _isEmpty from 'lodash/isEmpty';
import { FormattedMessage } from 'react-intl';

const validate = (values, props) => {
  const errors = {};
  const { termsOfUse, termsOfSubscription, privacyPolicy } = values;
  const { company } = props;

  if (showTOU(company) && !termsOfUse) {
    errors.termsOfUse = true;
  }

  if (showTOS(company) && !termsOfSubscription) {
    errors.termsOfSubscription = true;
  }

  if (showPrivacyPolicy(company) && !privacyPolicy) {
    errors.privacyPolicy = true;
  }

  return errors;
};

class AcceptTermsForm extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  componentDidMount() {
    this.passFormSubmit();
  }

  bindFunctions() {
    this.getTermsOfUseField = this.getTermsOfUseField.bind(this);
    this.getTermsOfRegisterField = this.getTermsOfRegisterField.bind(this);
    this.getPrivacyPolicyField = this.getPrivacyPolicyField.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  passFormSubmit() {
    const { passFormSubmit } = this.props;
    if (passFormSubmit) passFormSubmit(this.validateAndSubmit);
  }

  validateAndSubmit(action) {
    const { handleSubmit, validate, fieldValues, change, dispatch } = this.props;
    const errors = validate(fieldValues, this.props);

    if (_isEmpty(errors)) {
      dispatch(handleSubmit(action));
    } else {
      change('_validate', new Date());
      dispatch(handleSubmit(() => ''));
    }
  }

  getCompanyName() {
    const { company, m } = this.props;
    const { name } = company || {};
    return name || t(m, 'generic.company');
  }

  getTermsOfUseField(props) {
    const { company } = props;
    const { termsOfUseUrl } = company || {};

    if (showTOU(company)) {
      return (
        <div className="scope-field-container">
          <div className="scope-check-circle-container">
            {checkCircle(props)}
            <span className="scope-check-circle-info">
              <FormattedMessage
                id="register.terms.usage.text"
                values={{
                  link: (
                    <a target="_blank" href={termsOfUseUrl}>
                      <FormattedMessage id="register.terms.usage.link.label" />
                    </a>
                  ),
                  company: <strong>{this.getCompanyName()}</strong>
                }}
              />
              *
            </span>
          </div>
          {showErrorInfo(props)}
        </div>
      );
    } else return null;
  }

  getTermsOfRegisterField(props) {
    const { company } = props;
    const { termsOfSubscriptionUrl } = company || {};

    if (showTOS(company)) {
      return (
        <div className="scope-field-container">
          <div className="scope-check-circle-container">
            {checkCircle(props)}
            <span className="scope-check-circle-info">
              <FormattedMessage
                id="register.terms.usage.subscription.text"
                values={{
                  link: (
                    <a target="_blank" href={termsOfSubscriptionUrl}>
                      <FormattedMessage id="register.terms.usage.subscription.link.label" />
                    </a>
                  ),
                  company: <strong>{this.getCompanyName()}</strong>
                }}
              />
              *
            </span>
          </div>
          {showErrorInfo(props)}
        </div>
      );
    } else return null;
  }

  getPrivacyPolicyField(props) {
    const { company } = props;
    const { privacyPolicyUrl } = company || {};

    if (showPrivacyPolicy(company)) {
      return (
        <div className="scope-field-container">
          <div className="scope-check-circle-container">
            {checkCircle(props)}
            <span className="scope-check-circle-info">
              <FormattedMessage
                id="register.terms.privacy.text"
                values={{
                  link: (
                    <a target="_blank" href={privacyPolicyUrl}>
                      <FormattedMessage id="register.terms.privacy.link.label" />
                    </a>
                  ),
                  company: <strong>{this.getCompanyName()}</strong>
                }}
              />
              *
            </span>
          </div>
          {showErrorInfo(props)}
        </div>
      );
    } else return null;
  }

  getAdsField() {
    const { m } = this.props;
    return (
      <div className="scope-field-container">
        <div className="scope-check-circle-container">
          <Field name="commercialPurposes" component={checkCircle} />
          <span className="scope-check-circle-info">{t(m, 'register.terms.ads')}</span>
        </div>
      </div>
    );
  }

  showForProfile(name) {
    return this.props.isSubscribePage || !this.props.initialValues[name];
  }

  isImportedUser() {
    const { company: c } = this.props;

    const wasFalse = (name) => {
      return !this.props.initialValues[name];
    };

    return (
      (showTOU(c) && wasFalse('termsOfUse')) ||
      (showTOS(c) && wasFalse('termsOfSubscription')) ||
      (showPrivacyPolicy(c) && wasFalse('privacyPolicy'))
    );
  }

  showForCredentials() {
    return !this.props.isCreationPage || this.isImportedUser();
  }

  render() {
    const { company, m } = this.props;
    return (
      <div className={classnames('accept-terms-form', 'scope-section')}>
        <div className="scope-section-title pb-xs-0 pt-dt-0" />
        <div className="scope-section-fields">
          {this.showForProfile('termsOfUse') && (
            <Field
              name="termsOfUse"
              component={this.getTermsOfUseField}
              company={company}
              m={m}
            />
          )}
          {this.showForProfile('termsOfSubscription') && (
            <Field
              name="termsOfSubscription"
              component={this.getTermsOfRegisterField}
              company={company}
              m={m}
            />
          )}
          {this.showForProfile('privacyPolicy') && (
            <Field
              name="privacyPolicy"
              component={this.getPrivacyPolicyField}
              company={company}
              m={m}
            />
          )}
          {this.showForCredentials('commercialPurposes') && this.getAdsField()}
        </div>
      </div>
    );
  }
}

AcceptTermsForm.propTypes = {
  passFormSubmit: PropTypes.func,
  isSubscribePage: PropTypes.bool, // old register
  isCreationPage: PropTypes.bool // new register
};

export const ACCEPT_TERMS_FIELDS_NAMES = [
  'termsOfUse',
  'termsOfSubscription',
  'privacyPolicy',
  'commercialPurposes'
];

AcceptTermsForm = reduxForm({
  form: USER_FORM_NAMES.ACCEPT_TERMS,
  validate,
  onSubmitFail: scrollToErrorInfo,
  enableReinitialize: true,
  destroyOnUnmount: false,
  fields: ACCEPT_TERMS_FIELDS_NAMES
})(AcceptTermsForm);

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    company: sharedCompanySelector(state),
    fieldValues: acceptTermsValuesSelector(state)
  };
}

export default connect(mapStateToProps)(AcceptTermsForm);
