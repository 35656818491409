import axios from 'axios';
import config from '../constants/config';
import { objGetFirstKey } from '../utils/utils';
import urlJoin from 'url-join';
import store from '../store';
import { getFileUploadUrl } from '../actions/files';

export const emailAsyncValidate = (fieldObj, messages, brandId) => {
  return new Promise((resolve, reject) => {
    const fieldName = objGetFirstKey(fieldObj);
    const fieldValue = fieldObj[fieldName];
    axios
      .get(
        config.getApiUrl() +
          '/v2/users?login=' +
          encodeURIComponent(fieldValue) +
          '&brandId=' +
          brandId
      )
      .then((response) => {
        if (response.data.usedByMember) {
          reject({
            [fieldName]: messages['error.form.email.already_exists']
          });
        } else {
          resolve();
        }
      })
      .catch(resolve);
  });
};

export const getJsonData = (url) => {
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

// TODO: it seems that manual call of axios that results in 401 is not intercepted and logout is not triggered

export const newFileUpload = (file) => {
  return new Promise((resolve, reject) => {
    const { type } = file || {};
    store
      .dispatch(getFileUploadUrl(type))
      .then((data) => {
        if (data?.type === 'files/GET_UPLOAD_URL_SUCCESS') {
          const { url, fileId } = data?.payload?.data || {};
          axios({
            url,
            method: 'put',
            data: file,
            headers: {
              'x-amz-acl': 'private',
              'Content-Type': file.type,
              'Content-Length': file.size
            }
          })
            .then(() => {
              resolve({ fileId });
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          reject(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createGetCountryIsoByIpAction = (fallbackCountry) => {
  return (success) => {
    getJsonData(config.geoIpUrl).then(
      (data = '') => success(data.split(';')[1] || fallbackCountry),
      () => success(fallbackCountry)
    );
  };
};

export const forwardGeocoding = (query, params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlJoin(config.mapboxEndpoint, 'mapbox.places', `${query}.json`), { params })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const reverseGeocoding = ([lat, lng], params) => {
  return new Promise((resolve, reject) => {
    axios
      .get(urlJoin(config.mapboxEndpoint, 'mapbox.places', `${lng},${lat}.json`), {
        params
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
