import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage } from 'react-intl';

import { checkEmail } from '../../helpers';
import { renderPlaceholderTextField } from './fields';
import { connect } from 'react-redux';
import { getMessage as t } from '../../utils/utils';
import { messagesSelector } from '../../helpers/selectors';

const validate = (values, props) => {
  const errors = {};

  const emailError = checkEmail(values.login, props.m);
  if (emailError) errors.login = emailError;

  return errors;
};

class ForgotPasswordForm extends Component {
  render() {
    const { handleSubmit, forgotPasswordError, forgotPasswordFetching, m } = this.props;

    return (
      <div>
        <div className="forgot-pass-desc">
          <FormattedMessage id="forgot_password.description_line1" />
          <br />
          <FormattedMessage id="forgot_password.description_line2" />
        </div>
        <form
          className="form-content forgot-password-form"
          onSubmit={handleSubmit}
          noValidate
        >
          <Field
            name="login"
            component={renderPlaceholderTextField}
            placeholder={t(m, 'generic.email')}
            type="email"
          />
          {forgotPasswordError && (
            <div className="text-danger text-center mt-5">{forgotPasswordError}</div>
          )}
          <div className="text-center">
            <button
              disabled={!!forgotPasswordFetching}
              className="btn btn-accent primary-btn btn-md mt-10"
            >
              <FormattedMessage id="forgot_password.submit" />
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { m: messagesSelector(state) };
};

ForgotPasswordForm = reduxForm({
  form: 'forgotPassword',
  fields: ['login'],
  validate
})(ForgotPasswordForm);

ForgotPasswordForm = connect(mapStateToProps)(ForgotPasswordForm);

export default ForgotPasswordForm;
