import { connect } from 'react-redux';

import {
  isExternalIncompleteUserSelector,
  isProfileIncompleteUserSelector,
  localeSelector,
  messagesSelector,
  personalDetailsInitialValuesSelector,
  registerCompanySelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';

import { getCreationId, showApiError } from '../../helpers/components';
import {
  linkUserProfile,
  registerExternalUser,
  saveCreationSuccessData,
  updateInformations
} from '../../actions/user';
import PersonalDetailsForm from '../account/PersonalDetails/PersonalDetailsForm';
import {
  formatAcceptTermsForApiCall,
  formatPersonalDetailsForApiCall
} from '../../helpers/formatters';
import {
  navigateToCreationDrivingLicense,
  navigateToCreationSuccess
} from '../../utils/utils';
import { useCallback, useRef } from 'react';
import { getKeycloakEmail } from '../../utils/keycloak';
import { verifyUser } from '../../actions/keycloak';

function CreationPersonalInfo(p) {
  const r = useRef({ fetching: false }).current;

  const formatDataForExternalUser = useCallback(
    (payload) => {
      return {
        ...payload,
        locale: p.locale,
        companyId: getCreationId(p.routeParams),
        login: getKeycloakEmail()
      };
    },
    [p.locale, p.routeParams]
  );

  const formatDataForProfileUser = useCallback(
    (payload) => {
      return {
        ...payload,
        locale: p.locale,
        companyId: getCreationId(p.routeParams)
      };
    },
    [p.locale, p.routeParams]
  );

  const updatePersonalInfo = useCallback(
    (info, terms) => {
      if (r.fetching) return;
      r.fetching = true;

      let registerAction;
      let payload;

      if (p.isExternalIncompleteUser) {
        registerAction = (id, data) => p.registerExternalUser(data);
        payload = formatPersonalDetailsForApiCall(p.userData, info);
        payload = formatDataForExternalUser(payload);
      } else if (p.isProfileIncompleteUser) {
        registerAction = (id, data) => p.linkUserProfile(data);
        payload = formatPersonalDetailsForApiCall({}, info);
        payload = formatDataForProfileUser(payload);
      } else {
        registerAction = p.updateInformations;
        payload = formatPersonalDetailsForApiCall(p.userData, info);
      }
      payload = formatAcceptTermsForApiCall(payload, terms, p.registerCompany);

      registerAction(p.userId, payload).then((data) => {
        if (
          ['user/REGISTER_SSO_SUCCESS', 'user/LINK_PROFILE_SUCCESS'].includes(data?.type)
        ) {
          p.verifyUser(false).then((resp) => {
            r.fetching = false;
            if (resp?.type === 'keycloak/VERIFY_USER_SUCCESS') {
              navigateToCreationDrivingLicense(getCreationId(p.routeParams));
            } else {
              p.saveCreationSuccessData({
                email: payload.login,
                firstName: payload.firstName
              });
              navigateToCreationSuccess();
            }
          });
        } else if (data?.type === 'user/UPDATE_INFO_SUCCESS') {
          r.fetching = false;
          navigateToCreationDrivingLicense(getCreationId(p.routeParams));
        } else {
          r.fetching = false;
          showApiError(p.m, data);
        }
      });
    },
    [
      p.userData,
      p.registerCompany,
      r.fetching,
      p.isExternalIncompleteUser,
      formatDataForExternalUser,
      formatDataForProfileUser,
      p.userId,
      p.routeParams,
      p.m
    ]
  );

  return (
    <PersonalDetailsForm
      onSubmit={updatePersonalInfo}
      initialValues={p.initialValues}
      isSubscribePage
      isCreationPage
    />
  );
}

const mapStateToProps = (state) => {
  return {
    initialValues: personalDetailsInitialValuesSelector(state),
    isExternalIncompleteUser: isExternalIncompleteUserSelector(state),
    isProfileIncompleteUser: isProfileIncompleteUserSelector(state),
    registerCompany: registerCompanySelector(state),
    m: messagesSelector(state),
    locale: localeSelector(state),
    userId: userIdSelector(state),
    userData: userDataSelector(state)
  };
};

const mapDispatchToProps = {
  updateInformations,
  registerExternalUser,
  saveCreationSuccessData,
  linkUserProfile,
  verifyUser
};

export default connect(mapStateToProps, mapDispatchToProps)(CreationPersonalInfo);

// TODO: when linking profile | preserve linking status
