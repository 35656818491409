import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import cautionIcon from '../../assets/images/caution.svg';
import * as BookingActions from '../../actions/booking';
import { userIdSelector } from '../../helpers/selectors';

export class BookingCancelPopup extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.handleBookingCancel = this.handleBookingCancel.bind(this);
  }

  handleBookingCancel() {
    const { cancellingBooking, cancelBookingId } = this.props;
    if (cancellingBooking === cancelBookingId) return;

    this.props.cancelBooking(cancelBookingId).then((data) => {
      if (data.type === 'booking/CANCEL_BOOKING_SUCCESS') {
        this.props.getBookingsByMemberId(this.props.userId);
        this.props.onCloseRequested();
      }
    });
  }

  render() {
    const { onCloseRequested, cancellingBooking } = this.props;

    return (
      <div
        className={classnames(
          'pt-15',
          'pl-20',
          'pr-20',
          'pb-25',
          'text-center',
          'booking-cancel-popup'
        )}
      >
        <p className="bold mt-xs-25 mt-dt-35 f-25 mb-dt-25 mb-xs-15">
          <img className="scope-icon" src={cautionIcon} alt="" />
        </p>
        <p className="bold f-20">
          <FormattedMessage
            id="label.you_are_trying_to_cancel_your_booking"
            values={{ newline: <br /> }}
          />
        </p>
        <p className="f-15 mt-20">
          <FormattedMessage
            id="label.are_you_sure_you_want_to_cancel"
            values={{ newline: <br /> }}
          />
        </p>
        <div className="mt-40 hr-dashed" />
        <Row className="mt-30">
          <Col xs={12} sm={6} md={6} className="pr-dt-10 mb-xs-10">
            <div className={classnames('scope-button-container', 'sub-class-left')}>
              <div
                onClick={onCloseRequested}
                className={classnames(
                  'scope-button',
                  'scope-button-return',
                  'secondary-bg',
                  {
                    'sub-class-disabled': cancellingBooking
                  }
                )}
              >
                <FormattedMessage id="label.return" />
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6} md={6} className="pl-dt-10">
            <div className={classnames('scope-button-container', 'sub-class-right')}>
              <div
                onClick={this.handleBookingCancel}
                className={classnames(
                  'scope-button',
                  'scope-button-cancel',
                  'primary-btn ',
                  {
                    'sub-class-disabled': cancellingBooking
                  }
                )}
              >
                <FormattedMessage id="label.cancel_my_booking" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    cancelBookingId: state.booking.cancelBookingId,
    cancellingBooking: state.booking.cancellingBooking,
    userId: userIdSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingCancelPopup);
