import { getErrorStatus } from '../helpers/formatters';
import { getGlobalState, messagesSelector } from '../helpers/selectors';
import { addErrorMsg } from '../utils/flashMessage/creator';
import { jsonParseSafe } from '../utils/utils';
import { STORAGE_KEY } from '../constants/generic';

const defaultState = {
  isExternalIncompleteUser: false,
  remoteConfig: jsonParseSafe(localStorage.getItem(STORAGE_KEY.KEYCLOAK_CONFIG))
};

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'keycloak/VERIFY_FAILED': {
      const globalState = getGlobalState();
      const messages = messagesSelector(globalState);

      let errorMsg = '';

      if (getErrorStatus(action.payload) === 401) {
        errorMsg = messages['error.user.verify.fail'];
      } else if (getErrorStatus(action.payload) === 422) {
        errorMsg = messages['error.login.422.user.not.enabled'];
      } else if (getErrorStatus(action.payload) === 403) {
        errorMsg = messages['error.login.403.user.anonymized'];
      } else if (getErrorStatus(action.payload) === 404) {
        state = { ...state, isExternalIncompleteUser: true };
      } else {
        errorMsg = messages['error.form.technical'];
      }

      if (errorMsg) addErrorMsg(errorMsg);

      break;
    }

    case 'keycloak/SET_REGISTER_STATUS': {
      state = { ...state, isExternalIncompleteUser: action.payload };
      break;
    }

    case 'keycloak/GET_REMOTE_CONFIG_SUCCESS': {
      const remoteConfig = JSON.stringify(action.payload.data);

      localStorage.setItem(STORAGE_KEY.KEYCLOAK_CONFIG, remoteConfig);
      state = { ...state, remoteConfig };

      break;
    }

    default: {
      break;
    }
  }

  return state;
}
