import React, { Component } from 'react';
import { connect } from 'react-redux';
import IdentityDocumentForm from './IdentityDocumentForm';
import { displayProfileUpdateSuccess } from '../../helpers/components';
import { formatIdentityDocumentForApiCall } from '../../helpers/formatters';
import { bindActionCreators } from 'redux';
import * as UserActions from '../../actions/user';
import {
  isRenaultFranceUserSelector,
  identityDocumentFormInitialValuesSelector,
  messagesSelector,
  userDataSelector,
  userIdSelector
} from '../../helpers/selectors';

class IdentityDocument extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.updateIdentityDocument = this.updateIdentityDocument.bind(this);
  }

  updateIdentityDocument(formValues) {
    const { m, updateInformations, userId, userData, userDataUpdating } = this.props;

    if (!userDataUpdating) {
      updateInformations(
        userId,
        formatIdentityDocumentForApiCall(userData, formValues)
      ).then((data) => {
        if (data.type === 'user/UPDATE_INFO_SUCCESS') {
          displayProfileUpdateSuccess(m, 'label.identity_card');
        }
      });
    }
  }

  render() {
    const { initialValues, isRenaultFranceUser } = this.props;

    return (
      <IdentityDocumentForm
        onSubmit={this.updateIdentityDocument}
        hideFileUpload={isRenaultFranceUser}
        initialValues={initialValues}
      />
    );
  }
}

const mapStateToProps = (state) => {
  const { userDataUpdating } = state.user;

  return {
    userId: userIdSelector(state),
    userData: userDataSelector(state),
    m: messagesSelector(state),
    isRenaultFranceUser: isRenaultFranceUserSelector(state),
    initialValues: identityDocumentFormInitialValuesSelector(state),
    userDataUpdating
  };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(IdentityDocument);
