import React, { Component } from 'react';
import BookingEditPopupForm from './form';
import { connect } from 'react-redux';
import {
  bookingEditPopupDataSelector,
  editBookingInitialValuesSelector
} from '../../../helpers/selectors';
import { bindActionCreators } from 'redux';
import * as BookingActions from '../../../actions/booking';
import * as PopupActions from '../../../actions/popup';
import { browserHistory } from '../../../helpers/components';
import { FormattedMessage } from 'react-intl';

class BookingEditPopup extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
  }

  bindFunctions() {
    this.editBookingSearch = this.editBookingSearch.bind(this);
  }

  editBookingSearch(data) {
    const { selectedBooking } = this.props;

    this.props.loadBookingEditFormData(data);
    this.props.loadBookingEditData(selectedBooking);
    this.props.popup_BookingEdit_SetVisibility(false);
    this.props.clearBookingEditPopupData();

    browserHistory.push('/edit-booking');
  }

  render() {
    const { initialValues } = this.props;
    return (
      <div className="booking-edit-popup">
        <div className="scope-title">
          <FormattedMessage id="title.modify.booking" />
        </div>
        <div className="scope-body">
          <BookingEditPopupForm
            initialValues={initialValues}
            onSubmit={this.editBookingSearch}
          />
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...BookingActions, ...PopupActions }, dispatch);
}

const mapStateToProps = (state) => {
  return {
    selectedBooking: bookingEditPopupDataSelector(state),
    initialValues: editBookingInitialValuesSelector(state)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingEditPopup);
