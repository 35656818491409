import React, { Component } from 'react';
import pageIconSrc from '../../assets/images/passport-circle.svg';
import { getMessage as t } from '../../utils/utils';

import {
  aboutUrlSelector,
  currentThemeSelector,
  legalInfoUrlSelector,
  messagesSelector,
  personalDataUrlSelector,
  privacyPolicyUrlSelector,
  termsOfUseUrlSelector,
  termsOfSubscriptionUrlSelector,
  additionalLegalDocumentSelector,
  localeSelector
} from '../../helpers/selectors';

import { connect } from 'react-redux';
import IndexLink from '../partials/IndexLink';
import { ReactSVG } from 'react-svg';
import arrowLeftSrc from '../../assets/images/arrow-left.svg';
import memoizeOne from 'memoize-one';

const selectAlDocumentNameMem = memoizeOne((locale, translations) => {
  return translations?.find((one) => one.language === locale)?.label;
});

function selectAlDocumentName(p) {
  const { name, translations, id } = p.additionalLegalDocument;
  return selectAlDocumentNameMem(p.locale, translations) || name || id;
}

function getListItem(url, name) {
  if (url) {
    return (
      <div className="scope-field-container">
        <div className="scope-info-text">
          <a target="_blank" href={url}>
            {name}
          </a>
        </div>
      </div>
    );
  }
}

class LegalInfo extends Component {
  displayBackButton() {
    const { isSubscribePage } = this.props;

    if (!isSubscribePage) {
      return (
        <IndexLink to="/profile" className="scope-content-header-button-return">
          <ReactSVG className="react-svg" src={arrowLeftSrc} />
        </IndexLink>
      );
    }
  }

  render() {
    const p = this.props;
    const { m, theme } = p;
    const { legalInfo } = theme.icons || {};

    return (
      <form className="identity-document-page" onSubmit={p.handleSubmit} noValidate>
        <div className="scope-content-header">
          <img
            className="scope-content-header-picture"
            src={legalInfo ? legalInfo : pageIconSrc}
            alt="page icon"
          />
          <div className="scope-content-header-title-container">
            {this.displayBackButton()}
            <div className="scope-content-header-title">
              {t(m, 'profile.section.legal.info')}
            </div>
          </div>
        </div>
        <div className="scope-content-body">
          <div className="scope-section">
            <div className="scope-section-title">{t(m, 'profile.title.legal.url')}</div>
            <div className="scope-section-fields">
              {getListItem(p.legalInfoUrl, t(m, 'footer.label.legal.information'))}
              {getListItem(p.additionalLegalDocument?.fileUrl, selectAlDocumentName(p))}
              {getListItem(p.personalDataUrl, t(m, 'footer.label.personal.data'))}
              {getListItem(p.termsOfUseUrl, t(m, 'footer_society_term'))}
              {getListItem(
                p.termsOfSubscriptionUrl,
                t(m, 'register.terms.usage.subscription.link.label')
              )}
              {getListItem(p.privacyPolicyUrl, t(m, 'footer_society_policy_privacy'))}
              {getListItem(p.aboutUrl, t(m, 'footer_society_about'))}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    m: messagesSelector(state),
    locale: localeSelector(state),
    aboutUrl: aboutUrlSelector(state),
    theme: currentThemeSelector(state),
    legalInfoUrl: legalInfoUrlSelector(state),
    termsOfUseUrl: termsOfUseUrlSelector(state),
    personalDataUrl: personalDataUrlSelector(state),
    privacyPolicyUrl: privacyPolicyUrlSelector(state),
    additionalLegalDocument: additionalLegalDocumentSelector(state),
    termsOfSubscriptionUrl: termsOfSubscriptionUrlSelector(state)
  };
};

export default connect(mapStateToProps)(LegalInfo);
