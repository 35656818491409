export const VEHICLE_TYPE_PASSENGER = 'PASSENGER';
export const VEHICLE_TYPE_COMMERCIAL = 'COMMERCIAL';

export const USAGE_TYPE_PRIVATE = 'PRIVATE';
export const USAGE_TYPE_BUSINESS = 'BUSINESS';
export const USAGE_TYPE_ALL = 'ALL';

export const FILE_TYPE_REMOTE = 'REMOTE';
export const FILE_TYPE_LOCAL = 'LOCAL';
export const FILE_STATUS_FRONT = 'FRONT';
export const FILE_STATUS_BACK = 'BACK';
export const FILE_STATUS_SELFIE = 'SELFIE';
export const BOOKING_VOUCHER_DIFFERENT_CODE = 'booking.voucher.different.code';
export const MEMBER_STATUS_APPLIED = 'APPLIED';
export const CUSTOM_FIELD_FORM_SUBSCRIPTION = 'SUBSCRIPTION';
export const CUSTOM_FIELD_FORM_BOOKING = 'CAR_SHARING_BOOKING';
export const CUSTOM_FIELD_STATUS_CONDITIONED = 'conditioned';
export const CUSTOM_FIELD_STATUS_YES = 'true';
export const CUSTOM_FIELD_TYPE_BOOLEAN = 'BOOLEAN';
export const CUSTOM_FIELD_TYPE_TEXT = 'TEXT';
export const CUSTOM_FIELD_TYPE_NUMERIC = 'NUMERIC';
export const CUSTOM_FIELD_TYPE_FILE = 'FILE';
export const CUSTOM_FIELD_TYPE_PHONE_NUMBER = 'PHONE_NUMBER';
export const CUSTOM_FIELD_CONDITION_VISIBLE = 'visibleCondition';
export const FILTER_BOOKING_ACCESSORY_AIR_CONDITIONING = 'AIR_CONDITIONING';
export const FILTER_BOOKING_ACCESSORY_LARGE_TRUCK = 'LARGE_TRUCK';
export const FILTER_BOOKING_ACCESSORY_FOLDING_SEATS = 'FOLDING_SEATS';
export const FILTER_BOOKING_ACCESSORY_RADIO_CD = 'RADIO_CD';
export const FILTER_BOOKING_ACCESSORY_BLUETOOTH = 'BLUETOOTH';
export const FILTER_BOOKING_ACCESSORY_GPS = 'GPS';
export const FILTER_BOOKING_ACCESSORY_BABY_SEAT = 'BABY_SEAT';
export const WORLDPAY_PAYMENT_AUTHORIZED = 'AUTHORISED';
export const BOOKING_PAST = 'PAST';
export const BOOKING_CANCELED = 'CANCELED';
export const CIVILITY_MR = 'MR';
export const CIVILITY_MRS = 'MRS';

export const PROFILE_EDIT_MEMBER_APPROVED_MSG =
  'Name, birth info and marital status cannot be changed once the member is APPROVED';
export const INCORRECT_SHORT_ID_MSG =
  "Short id parameter can't be null. It must be alphanumeric and size must be greater or equals to 8 digits";

export const EXTERNAL_INVOICE_ITALIAN = 'ITALIAN';
export const PAYMENT_PROVIDER = { WORLDPAY: 'WORLDPAY', STRIPE: 'STRIPE' };
export const STRIPE_PUBLISHABLE_KEYS = {
  test: 'pk_test_51IdcRkBzRDYOLpNJOLpDnqXaBe8ZOMhhOsTbFjwrk8zrmohk7BXOrQJVmi4Kw86MwnrmWUnU8zeAhiHbMoNnJdyF00Uoln1puF',
  production:
    'pk_live_51IdcRkBzRDYOLpNJ9HAnlhs4bJsj5GDUse854qFkJL0vKBw8aDzExGeiMAaWEfxpeh2lGqLaW2byKZ29N7TZFN8m006WrC3upD'
};
export const VEHICLE_USAGES = {
  TEST_DRIVE: 'TEST_DRIVE',
  PUBLIC_SERVICE: 'PUBLIC_SERVICE'
};

export const MEMBER_STATUSES = {
  NOT_APPROVED: 'NOT_APPROVED',
  APPROVED: 'APPROVED',
  TO_REVIEW: 'TO_REVIEW',
  TO_COMPLETE: 'TO_COMPLETE'
};

export const DOCUMENT_STATUS = {
  APPROVED: 'APPROVED',
  NOT_APPROVED: 'NOT_APPROVED',
  TO_REVIEW: 'TO_REVIEW'
};
