/* global $ */

import React, { Component } from 'react';
import shortid from 'shortid';
import { modalClassNames } from '../../constants/datePicker';
import moment from 'moment';
import PropTypes from 'prop-types';
import { safe } from '../../utils/utils';

const hideStyle = {
  display: 'none'
};

export class NeutralDatePicker extends Component {
  constructor(props, context) {
    super(props, context);

    this.inputId = shortid.generate();
    this.inputSelector = '#' + this.inputId;
    this.dateFormat = 'DD MMMM YYYY';

    this.openPicker = () => {
      if (this.pickerInstance) {
        setTimeout(() => this.pickerInstance.open());
      }
    };

    this.handleOnClose = () => {
      document.activeElement.blur();
      props.input.onBlur();
    };
  }

  componentDidMount() {
    this.setupPickadate();
    this.componentPropsUpdated();
  }

  componentDidUpdate(pp) {
    this.componentPropsUpdated(pp);
  }

  componentPropsUpdated(pp) {
    this.updatePickerView(pp);
  }

  updatePickerView(pp) {
    if (this.formValueUpdated(pp)) {
      const { value } = this.props.input;

      if (this.pickerInstance) {
        const dateValue = moment(value).toDate();
        this.pickerInstance.set('select', dateValue);
      }
    }
  }

  formValueUpdated(pp) {
    const { value: nV } = this.props.input || {};
    const { value: pV } = safe(() => pp.input) || {};
    return nV !== pV || !pp;
  }

  getContextDate(context) {
    const { select, clear } = context;
    if (typeof select === 'number') return select;
    if (clear === null) return -1;
  }

  hideOnChangeWarning() {
    return undefined;
  }

  setupPickadate() {
    const { input, maxDate } = this.props;

    this.pickerInstance = $(this.inputSelector)
      .pickadate({
        klass: modalClassNames,
        onClose: this.handleOnClose,
        clear: '',
        selectMonths: true,
        selectYears: 110,
        max: maxDate,
        onSet: (context) => {
          let selectedDate = this.getContextDate(context);
          if (selectedDate === -1) input.onChange('');
          if (selectedDate) input.onChange(selectedDate);
        }
      })
      .pickadate('picker');

    this.updatePickerView(this.props);
  }

  showDate() {
    const { input } = this.props;
    const { value } = input;

    if (value) return moment(value).format(this.dateFormat);
    else return '';
  }

  render() {
    const { placeholder, disabled } = this.props;
    return (
      <div>
        <input
          disabled={disabled}
          onClick={this.openPicker}
          placeholder={placeholder}
          value={this.showDate()}
          onChange={this.hideOnChangeWarning}
          className="new-design-date-picker"
        />
        <input id={this.inputId} style={hideStyle} />
      </div>
    );
  }
}

NeutralDatePicker.propTypes = {
  maxDate: PropTypes.object,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};
