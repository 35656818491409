import keyIconSrc from '../../assets/images/key-circle.svg';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  brandIdSelector,
  messagesSelector,
  userEmailSelector,
  userIdSelector
} from '../../helpers/selectors';
import {
  getMessage as t,
  navigateToCreationInfo,
  navigateToHomePage,
  navigateToCreationCredentials
} from '../../utils/utils';
import {
  getUserInfo,
  logout,
  selfAnonymize,
  sendConfirmationEmail
} from '../../actions/user';
import { MEMBER_STATUS_APPLIED, MEMBER_STATUSES as STATUS } from '../../constants/backend';
import { getCreationId, showApiError } from '../../helpers/components';
import classnames from 'classnames';
import { addInfoMsg, addSuccessMsg } from '../../utils/flashMessage/creator';
import Popup from '../popup/Popup';
import AnonymizeConfirmPopup from '../popup/AnonymizeConfirmPopup';
import { FormattedMessage } from 'react-intl';

const readyStatuses = [STATUS.APPROVED, STATUS.TO_REVIEW];

function CreationActivation(p) {
  const r = useRef({ timer: null }).current;
  const [deletePopupIsOpen, setDeletePopupStatus] = useState(false);
  const [fetchingEmail, setFetchingEmail] = useState(false);
  const [fetchingDelete, setFetchingDelete] = useState(false);

  function sendEmail() {
    setFetchingEmail(true);
    p.sendConfirmationEmail({ login: p.userEmail, brandId: p.brandId }).then((data) => {
      setFetchingEmail(false);
      if (data?.type === 'user/SEND_CONFIRMATION_EMAIL_SUCCESS') {
        addSuccessMsg(t(p.m, 'register.activation.email.sent'));
      } else {
        showApiError(p.m, data);
      }
    });
  }

  const openDeletePopup = () => {
    setDeletePopupStatus(true);
  };

  const closeDeletePopup = useCallback(() => {
    setDeletePopupStatus(false);
  }, [setDeletePopupStatus]);

  function checkUserStatus() {
    p.getUserInfo(true).then((data) => {
      if (data?.type === 'user/GET_INFO_SUCCESS') {
        const { status } = data?.payload?.data || {};

        if (readyStatuses.includes(status)) {
          clearInterval(r.timer);
          navigateToHomePage();
        } else if (status !== MEMBER_STATUS_APPLIED) {
          clearInterval(r.timer);
          navigateToCreationInfo(getCreationId(p.routeParams), true);
        }
      } else {
        showApiError(p.m, data);
        p.logout(true);
      }
    });
  }

  const confirmDeletion = useCallback(() => {
    clearTimeout(r.timer);
    closeDeletePopup();
    setFetchingDelete(true);
    p.selfAnonymize(false).then((data) => {
      setFetchingDelete(false);
      if (data?.type === 'user/SELF_ANONYMIZE_SUCCESS') {
        addInfoMsg(t(p.m, 'profile.anonymize.success'));
        navigateToCreationCredentials(getCreationId(p.routeParams));
      } else {
        showApiError(p.m, data);
      }
    });
  }, [r.timer, p.m, p.routeParams]);

  useEffect(() => {
    checkUserStatus();
    r.timer = setInterval(checkUserStatus, 5000);
    return () => {
      clearTimeout(r.timer);
    };
  }, []);

  return (
    <div className="account-activation-page">
      <div className="scope-content-header">
        <img className="scope-content-header-picture" src={keyIconSrc} alt="key icon" />
        <div className="scope-content-header-title-container">
          <div className="scope-content-header-title">
            <FormattedMessage id="register.activation.title" />
          </div>
        </div>
      </div>
      <div className="scope-content-body">
        <div className="scope-section">
          <div className="scope-section-info">
            <FormattedMessage
              id="register.activation.info"
              values={{ userEmail: <strong>{p.userEmail}</strong> }}
            />
          </div>
        </div>
        {deletePopupIsOpen && (
          <Popup isOpen onCloseRequested={closeDeletePopup} id="anonymConfirm-popup">
            <AnonymizeConfirmPopup handleSubmit={confirmDeletion}>
              <FormattedMessage id="anonymize.confirm.description" />
            </AnonymizeConfirmPopup>
          </Popup>
        )}
      </div>
      <div className={classnames('scope-content-footer', 'sub-dual-submit')}>
        <button
          type="submit"
          className={classnames('scope-submit-button', 'primary-btn', {
            'sub-class-disabled': fetchingEmail
          })}
          onClick={sendEmail}
        >
          <FormattedMessage id="button.send.activation.email" />
        </button>
        <button
          type="submit"
          className={classnames('scope-submit-button', 'secondary-btn', {
            'sub-class-disabled': fetchingDelete
          })}
          onClick={openDeletePopup}
        >
          <FormattedMessage id="button.delete.profile" />
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  logout,
  getUserInfo,
  sendConfirmationEmail,
  selfAnonymize
};

function mapStateToProps(state) {
  return {
    m: messagesSelector(state),
    brandId: brandIdSelector(state),
    userEmail: userEmailSelector(state),
    userLoggedIn: userIdSelector(state)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreationActivation);
