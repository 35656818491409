import React, { Component } from 'react';
import { browserHistory } from '../../helpers/components';
import { ReactSVG } from 'react-svg';

import { FormattedMessage } from 'react-intl';
import mobileImg from '../../assets/images/mobile-app-rf.png';

import arrowLeftSrc from '../../assets/images/arrow-left.svg';
import corporate from '../../assets/images/usage-types/corporate.svg';
import vr from '../../assets/images/tools-circle.svg';
import { bindActionCreators } from 'redux';
import * as PopupActions from '../../actions/popup';
import * as UserActions from '../../actions/user';

import { connect } from 'react-redux';
import { links } from '../../constants/links';
import appStoreImg from '../../assets/images/app-store.png';
import googlePlayImg from '../../assets/images/google-play.png';
import InviteCodeForm from './InviteCodeForm';

import {
  mapCountriesSelector,
  messagesSelector,
  profileCompanyIdsSelector,
  profilePrivateRentalAllowedSelector,
  userCompanySelector,
  productNameSelector,
  brandIdSelector,
  isSsoOnlyResp
} from '../../helpers/selectors';

import {
  isRMF_companyId,
  getMessage as t,
  navigateToCreationPage
} from '../../utils/utils';

class SubscribeForm extends Component {
  constructor(props) {
    super(props);
    this.setCallbacks();
    this.initState();
  }

  initState() {
    this.state = {
      corporateUsage: false,
      privateUsage: false,
      vrUsage: false,
      mobileAppLinks: false
    };
  }

  setCallbacks() {
    this.vrAction = () => {
      const { directSubscribe, closeAllPopups, closeModal } = this.props;
      return directSubscribe ? closeAllPopups() : closeModal();
    };

    this.handleInviteSubmit = (data) => {
      this.props.checkInviteCode(data.inviteCode, true).then((data) => {
        if (data.type === 'user/CHECK_INVITE_CODE_SUCCESS') {
          const companyId = data.payload.data.id;

          this.props.getCompany(companyId).then((resp) => {
            const id = this.props.brandId;

            if (resp.payload.data.brand.id !== id) {
              this.props.addInvitError({ errorCode: 'member.profile.different.brand' });
            } else if (
              resp.payload.data.brand.id === id &&
              this.props.profileCompanyIds &&
              this.props.profileCompanyIds.some((id) => id === companyId)
            ) {
              this.props.addInvitError({
                errorCode: 'info.account.company.already.linked'
              });
            } else if (isSsoOnlyResp(resp)) {
              this.props.addInvitError({
                errorCode: 'error.register.without.sso'
              });
            } else {
              this.props.closeAllPopups();
              navigateToCreationPage(companyId);
            }
            this.props.checkInviteCodeStopFetch();
          });
        }
      });
    };
  }

  componentWillUnmount() {
    this.props.resetSubscribeDisplay();
  }

  componentDidMount() {
    if (this.props.user.serviceRv) {
      this.setState({ vrUsage: true });
    }
    if (this.props.user.serviceB2b) {
      this.setState({ corporateUsage: true });
    }

    this.handleRenaultFranceUser();
    this.handleFastRedirect();
  }

  handleRenaultFranceUser() {
    const { showRenaultFrancePopup } = this.props.user;

    if (showRenaultFrancePopup) {
      this.displayRenaultFrancePopup();
      this.props.setFlagToOpenRenaultFrancePopup(false);
    }
  }

  handleFastRedirect() {
    const { user, booking, popup } = this.props;
    const { currentSearchCompanyId } = booking;
    const { type_BookingRecap_IsVisible } = popup;
    const { data: userLoggedIn } = user;

    if (!userLoggedIn && type_BookingRecap_IsVisible && currentSearchCompanyId) {
      this.pickCountryToRedirect(currentSearchCompanyId);
    }
  }

  showCorporateRentalOffer() {
    return (
      <div className="corporate" onClick={() => this.setState({ corporateUsage: true })}>
        <FormattedMessage id="service.usage.corporate" />
      </div>
    );
  }

  showPrivateRentalOffer() {
    const { popup, profilePrivateRentalAllowed } = this.props;
    if (popup.step_AddAccount_IsVisible && !profilePrivateRentalAllowed) return;

    return (
      <div className="private" onClick={() => this.setState({ privateUsage: true })}>
        <FormattedMessage id="service.usage.private" />
      </div>
    );
  }

  showVrRentalOffer() {
    const { popup } = this.props;

    if (!popup.step_AddAccount_IsVisible) {
      return (
        <div className="vr" onClick={() => this.setState({ vrUsage: true })}>
          <FormattedMessage id="service.usage.vr" />
        </div>
      );
    }
  }

  renderServiceChoice() {
    const { productName = 'Car sharing' } = this.props.brand.branded || {};

    if (!this.state.corporateUsage && !this.state.privateUsage && !this.state.vrUsage) {
      return (
        <div>
          <h3>
            <FormattedMessage id="service.intro_label" values={{ productName }} />
          </h3>
          <div className="offers">
            {this.showCorporateRentalOffer()}
            {this.showPrivateRentalOffer()}
            {this.showVrRentalOffer()}
          </div>
        </div>
      );
    }
  }

  renderCorporate() {
    const {
      directSubscribe,
      brand: { branded }
    } = this.props;

    const { corporate: corpIconUrl } = branded.servicesIcons || {};

    return (
      <div className="wrapper">
        <h2>
          {!directSubscribe && (
            <span onClick={() => this.setState({ corporateUsage: false })}>
              <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
            </span>
          )}
          <FormattedMessage id="service.usage.corporate" />
          {this.closeBtn()}
        </h2>
        <div className="text-center">
          <ReactSVG className="react-svg" src={corpIconUrl ? corpIconUrl : corporate} />
        </div>
        <div className="text-center">
          <FormattedMessage id="service.corporate.intro" />
        </div>
        <InviteCodeForm
          onSubmit={this.handleInviteSubmit}
          onRetry={this.props.removeInviteError}
          isInPopup={true}
        />
      </div>
    );
  }

  renderPrivate() {
    const {
      m,
      brand: {
        branded: { mapCountries = {}, productName = 'Car sharing' }
      },
      popup,
      profileCompanyIds
    } = this.props;

    const flags = Object.keys(mapCountries).reduce((ret, isoCode) => {
      const { [isoCode]: isoCountry } = mapCountries || {};
      const { companyId, iconUrl } = isoCountry || {};

      if (popup.step_AddAccount_IsVisible && profileCompanyIds) {
        if (profileCompanyIds.includes(companyId)) return ret;
      }

      if (companyId) {
        ret.push(
          <li onClick={() => this.pickCountryToRedirect(companyId)} key={isoCode}>
            <i style={{ backgroundImage: `url(${iconUrl})` }} />
            <span>{t(m, 'iso__' + isoCode)}</span>
          </li>
        );
      }

      return ret;
    }, []);

    return (
      <div className="wrapper">
        <h2>
          <span onClick={() => this.setState({ privateUsage: false })}>
            <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
          </span>
          <FormattedMessage id="service.usage.private" />
        </h2>
        <div className="text-center">
          <FormattedMessage id="service.private.intro" values={{ productName }} />
        </div>
        <div className="text-center">
          <FormattedMessage id="service.private.select_country" />
          <ul className="flag-country-selector">{flags}</ul>
        </div>
      </div>
    );
  }

  renderMobileAppLinks() {
    const { productName } = this.props;

    return (
      <div className="wrapper">
        <h2>
          <span onClick={() => this.setState({ mobileAppLinks: false })}>
            <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
          </span>
          <span>{productName}</span>
        </h2>
        <div className="text-center">
          <img src={mobileImg} alt="Renault Mobility France" />
        </div>
        <div className="text-center padd-text">
          <FormattedMessage id="service.private.renaultfr" values={{ productName }} />
        </div>

        <div className="text-center imgs">
          <a href={links.appStoreLink}>
            <img
              src={appStoreImg}
              alt="App Store"
              className="mobile-store-img mr-5 va-top"
            />
          </a>
          <a href={links.playStoreLink}>
            <img
              src={googlePlayImg}
              alt="Google Play"
              className="mobile-store-img ml-5 va-top mb-xs-10"
            />
          </a>
        </div>
      </div>
    );
  }

  renderVR() {
    const {
      directSubscribe,
      brand: { branded }
    } = this.props;

    const { vr: vrIconUrl } = branded.servicesIcons || {};

    return (
      <div className="wrapper">
        <h2>
          {!directSubscribe && (
            <span onClick={() => this.setState({ vrUsage: false })}>
              <ReactSVG className="react-svg back-arrow" src={arrowLeftSrc} />
            </span>
          )}
          <FormattedMessage id="service.usage.vr" />
          {this.closeBtn()}
        </h2>

        <div className="text-center">
          <ReactSVG className="react-svg" src={vrIconUrl ? vrIconUrl : vr} />
        </div>
        <div className="text-center ws">
          <FormattedMessage id="service.vr.intro" />
        </div>

        <div className="text-center">
          <button className="btn btn-md btn-accent primary-btn" onClick={this.vrAction}>
            ok
          </button>
        </div>
      </div>
    );
  }

  closeBtn() {
    const { directSubscribe, closeAllPopups } = this.props;

    if (directSubscribe)
      return (
        <div className="close-button-wrap">
          <button type="button" onClick={closeAllPopups}>
            <i className="icon-cross" />
          </button>
        </div>
      );
  }

  pickCountryToRedirect(companyId) {
    const { closeAllPopups } = this.props;

    if (isRMF_companyId(companyId)) {
      this.displayRenaultFrancePopup();
    } else {
      closeAllPopups();
      navigateToCreationPage(companyId);
    }
  }

  displayRenaultFrancePopup() {
    this.setState({ mobileAppLinks: true, privateUsage: false });
  }

  render() {
    return (
      <div className="subscription">
        {!this.state.mobileAppLinks && this.renderServiceChoice()}
        {this.state.mobileAppLinks && <div>{this.renderMobileAppLinks()}</div>}
        {this.state.corporateUsage && <div>{this.renderCorporate()}</div>}
        {this.state.privateUsage && <div>{this.renderPrivate()}</div>}
        {this.state.vrUsage && <div>{this.renderVR()}</div>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    m: messagesSelector(state),
    mapCountries: mapCountriesSelector(state),
    directSubscribe: state.popup.directSubscribe,
    profileCompanyIds: profileCompanyIdsSelector(state),
    profilePrivateRentalAllowed: profilePrivateRentalAllowedSelector(state),
    userCompany: userCompanySelector(state),
    brandId: brandIdSelector(state),
    productName: productNameSelector(state)
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ ...PopupActions, ...UserActions }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscribeForm);
