import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Tab, Tabs } from 'react-bootstrap';
import _orderBy from 'lodash/orderBy';
import _map from 'lodash/map';
import _groupBy from 'lodash/groupBy';
import * as BookingActions from '../actions/booking';
import * as PopupActions from '../actions/popup';
import BookingHistoryDetails from './partials/BookingHistoryDetails';
import { getOperatingSystem } from '../utils/utils';
import { isPrimaryColorDarkSelector } from '../helpers/selectors';
import memoizeOne from 'memoize-one';

export class BookingHistory extends Component {
  constructor(props) {
    super(props);
    this.bindFunctions();
    this.setCallbacks();
    this.setBrowserOs();
  }

  componentDidMount() {
    this.props.getBookingsByMemberId(this.props.user.data.id);
  }

  getSortedBookings(bookings) {
    const dataGrouped = _groupBy(bookings, (booking) => {
      const { start } = booking;
      const { date: startDate } = start || {};
      const momentDate = moment(startDate);

      return momentDate.format();
    });

    const arrayData = _map(dataGrouped, (grouped, day) => {
      return {
        day,
        grouped
      };
    });

    return _orderBy(arrayData, ['day'], ['desc']);
  }

  // Actions

  showBookingCancelPopup(bookingId) {
    this.props.loadBookingCancelId(bookingId);
    this.props.popup_BookingCancel_SetVisibility(true);
  }

  showBookingUpdatePopup(bookingData) {
    this.props.loadBookingEditPopupData(bookingData);
    this.props.popup_BookingEdit_SetVisibility(true);
  }

  // Other

  setBrowserOs() {
    this.browserOs = getOperatingSystem();
  }

  bindFunctions() {
    this.showBookingCancelPopup = this.showBookingCancelPopup.bind(this);
    this.showBookingUpdatePopup = this.showBookingUpdatePopup.bind(this);
  }

  setCallbacks() {
    const getUpcomingGroupedBookingsMem = memoizeOne(
      (myActiveBookings, myUpcomingBookings) => {
        const one = myActiveBookings || [];
        const two = myUpcomingBookings || [];
        const final = one.concat(two);
        return this.getSortedBookings(final);
      }
    );
    this.getUpcomingGroupedBookings = () => {
      const { myActiveBookings, myUpcomingBookings } = this.props.booking;
      return getUpcomingGroupedBookingsMem(myActiveBookings, myUpcomingBookings);
    };

    const getFinishedGroupedBookingsMem = memoizeOne((myFinishedBookings) => {
      const final = myFinishedBookings || [];
      return this.getSortedBookings(final);
    });
    this.getFinishedGroupedBookings = () => {
      const { myFinishedBookings } = this.props.booking;
      return getFinishedGroupedBookingsMem(myFinishedBookings);
    };
  }

  render() {
    return (
      <div className="booking-history">
        <div className="booking-history-padding">
          <div className="booking-history-title">
            <FormattedMessage id="label.my_reservations" />
          </div>
          <Tabs
            defaultActiveKey={1}
            className="booking-history-tabs"
            id="booking-history-tabs"
          >
            <Tab eventKey={1} title={<FormattedMessage id="generic.upcoming" />}>
              <BookingHistoryDetails
                useWhiteIcons={this.props.useWhiteIcons}
                browserOs={this.browserOs}
                groupedBookings={this.getUpcomingGroupedBookings()}
                cancelAction={this.showBookingCancelPopup}
                updateAction={this.showBookingUpdatePopup}
                bookingsType="upcoming"
              />
            </Tab>
            <Tab eventKey={2} title={<FormattedMessage id="label.booking.past" />}>
              <BookingHistoryDetails
                useWhiteIcons={this.props.useWhiteIcons}
                browserOs={this.browserOs}
                groupedBookings={this.getFinishedGroupedBookings()}
                bookingsType="past"
              />
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return { ...state, useWhiteIcons: isPrimaryColorDarkSelector(state) };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ...BookingActions,
      ...PopupActions
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingHistory);
